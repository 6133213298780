import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'app/Shared/shared.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
//import { GlobalVariablesService } from 'app/Shared/global-variables.service';
//import { Component, OnInit, ViewChild } from '@angular/core';
//import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
//import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
import { LeaveType, Occurance, duration, leavesgrades, leavestypes_entity, LeaveTypes_Hourly, LeaveType_Daily } from 'app/Classes/leave-type';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-leaves-types',
  templateUrl: './leaves-types.component.html',
  styleUrls: ['./leaves-types.component.css']
})
export class LeavesTypesComponent extends BaseComponent implements OnInit {
  objLeaveType: LeaveType;
  objOccurance: Occurance;
  objduration: duration;
  objleavesgrades: leavesgrades;
  objleavestypes_entity: leavestypes_entity;
  objLeaveTypes_Hourly: LeaveTypes_Hourly;
  objLeaveTypes_Daily: LeaveType_Daily;
  lstLeaveType: LeaveType[];
  lstOccurance: Occurance[];
  lstduration: duration[];
  lstleavesgrades: leavesgrades[];
  lstleavestypes_entity: leavestypes_entity[];
  lstLeaveTypes_Hourly: LeaveTypes_Hourly[];
  lstLeaveType_Daily: LeaveType_Daily[];
  panelOpenState = false;
  panelLeavesTypesHourly = false;
  isHourly: string = "1";
  allowedBeforeDays: string = "";
  allowedAfterDays: string = "";
  isSpecificEntity: string = "0"
  isSpecificGroup: string = "0"


  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);

    this.objLeaveType = new LeaveType();
    this.objOccurance = new Occurance();
    this.objduration = new duration();
    this.objleavesgrades = new leavesgrades();
    this.objleavestypes_entity = new leavestypes_entity();
    this.objLeaveTypes_Hourly = new LeaveTypes_Hourly();
    this.objLeaveTypes_Daily = new LeaveType_Daily();
    this.lstLeaveType = [];
    this.lstOccurance = [];
    this.lstduration = [];
    this.lstleavesgrades = [];
    this.lstleavestypes_entity = [];
    this.lstLeaveTypes_Hourly = [];
    this.lstLeaveType_Daily = [];
  }

  ngOnInit() {
  }


  //////////////////Save Section///////////////
  Save(type: string) {
    if (type == 'leavestypes') {
      this.SaveLeavesTypes()
    }
    else if (type == 'occuerrence') {
      this.SaveLeaveTypes_occurance();
    }
    else if (type == 'leavesgrades') {
      this.SaveLeavesTypes_Grade();
    }
    else if (type == 'duration') {
      this.SaveLeaveTypes_Duration();
    }
    else if (type == 'leavestypes_entity') {
      this.SaveLeaveTypes_Entity();
    }
  }

  SaveLeaveTypes_Entity() {
    this._svc.SaveObject(this.objleavestypes_entity, "LeaveTypes_Entity/SaveLeaveTypes_Entity").subscribe(
      data => {
        if (this.saveResponse(data, "Leave Type Entity")) {

        }
      })
  }

  SaveLeaveTypes_occurance() {
    this._svc.SaveObject(this.objOccurance, "LeaveTypes_occurance/SaveLeaveTypes_occurance").subscribe(
      data => {
        if (this.saveResponse(data, "Leave Types Occurance")) {

        }
      })
  }

  SaveLeavesTypes() {
    this._svc.SaveObject(this.objLeaveType, "LeavesTypes/SaveLeavesTypes").subscribe(
      data => {
        if (this.saveResponse(data, "Leave Types")) {

        }
      })
  }

  SaveLeavesTypes_Grade() {
    this._svc.SaveObject(this.objleavesgrades, "LeavesTypes_Grade/SaveLeavesTypes_Grade").subscribe(
      data => {
        if (this.saveResponse(data, "Leave Types Grade")) {

        }
      })
  }

  SaveLeaveTypes_Duration() {
    this._svc.SaveObject(this.objduration, "LeaveTypes_Duration/SaveLeaveTypes_Duration").subscribe(
      data => {
        if (this.saveResponse(data, "Leave Types Duration")) {

        }
      }, (err) => {
        this.global.openDialog("Modules", "Error While Saving.")
      });
  }
  saveResponse(data: any, calleFrom: string) {
    if (data.includes()) {
      this.global.openDialog(calleFrom, "Record has been saved.")
      return true;
    }
    else if (data.includes("Success")) {
      this.global.openDialog(calleFrom, "Record has been saved.")
      return true;
    }
    else if (data.includes("NameExists")) {
      this.global.openDialog(calleFrom, "English Name Already Exists");
      return false;
    }
    else if (data.includes("Name_LLExists")) {
      this.global.openDialog(calleFrom, "Local Language Name Already Exists");
      return false;
    }
    else {
      this.global.openDialog(calleFrom, "Error While Saving");
      return false;
    }
  }
  ///////////////clear section //////////////
  Clear(type: string) {
    if (type == 'leavestypes') {
      this.objLeaveType = new LeaveType();
    }
    else if (type == 'occuerrence') {
      this.objOccurance = new Occurance();
    }
    else if (type == 'leavesgrades') {
      this.objleavesgrades = new leavesgrades()
    }
    else if (type == 'duration') {
      this.objduration = new duration();
    }
    else if (type == 'leavestypes_entity') {

    }
  }

  //////////////delete section 
  ShowConfirmDialog(type: string) {
    if (type == 'leavestypes') {

    }
    else if (type == 'occuerrence') {

    }
    else if (type == 'leavesgrades') {

    }
    else if (type == 'duration') {

    }
    else if (type == 'leavestypes_entity') {

    }
  }

  ///////////// select section ////////////

  LogicalGroups = new FormControl();
  LogicalGroup: string[] = ['Group 1', 'Group 2', 'Group 3', 'Group 4']
  ////Datasourd section 
  leavestypes_entity_displayedColumns: string[] = ['Selected', 'fK_EntityId', 'edit'];
  leavestypes_entity_dataSource = new MatTableDataSource<leavestypes_entity>();
  leavestypes_entity_selection = new SelectionModel<leavestypes_entity>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  leavestypes_displayedColumns: string[] = ['Selected', "leaveTypeName", "leaveTypeName_LL", "isHourlyLeave", "monthlyBalancing", "minDuration", "maxDuration", 'edit'];
  leavestypes_dataSource = new MatTableDataSource<LeaveType>();
  leavestypes_selection = new SelectionModel<LeaveType>(true, []);
  @ViewChild(MatSort, { static: true }) LeaveType_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) LeaveType_paginator: MatPaginator;


  occuerrence_displayedColumns: string[] = ['Selected', "Duration", "maximumOccur", 'edit'];
  occuerrence_dataSource = new MatTableDataSource<Occurance>();
  occuerrence_selection = new SelectionModel<Occurance>(true, []);
  @ViewChild(MatSort, { static: true }) occuerrence_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) occuerrence_paginator: MatPaginator;


  leavesgrades_displayedColumns: string[] = ['Selected', 'gradeBalance', 'edit'];
  leavesgrades_dataSource = new MatTableDataSource<leavesgrades>();
  leavesgrades_selection = new SelectionModel<leavesgrades>(true, []);
  @ViewChild(MatSort, { static: true }) leavesgrades_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) leavesgrades_paginator: MatPaginator;


  duration_displayedColumns: string[] = ['Selected', 'maximumDuration', "Duration", 'edit'];
  duration_dataSource = new MatTableDataSource<duration>();
  duration_selection = new SelectionModel<duration>(true, []);
  @ViewChild(MatSort, { static: true }) duration_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) duration_paginator: MatPaginator;


  /////////////////////////////////////
  onItemChange(id: any) {
    if (id == "1") {
      this.objLeaveType.isHourlyLeave = true;
      this.isHourly = "1";
    }
    else {
      this.objLeaveType.isHourlyLeave = false;
      this.isHourly = "2";
    }
  }
  concat_Days(type: string) {
    if (type == "allowedBeforeDays") {

    }
    else {

    }
  }
  RadioChanged(id: any, key: string) {
    ;
    if (key == 'SpecificEntity') {
      this.isSpecificEntity = id;
    }
    else if (key == 'SpecificGroup') {
      this.isSpecificGroup = id;
    }
  }
}