export class Org {
}

export class OrgCompany
{
    companyId :number;
    fK_ParentId :number;
    country :number;
    companyShortName : string;
    companyName : string;
    companyName_LL : string;
    address : string;
    phoneNumber : string;
    fax : string;
    url : string;
    logo : string;
    cREATED_BY : string;
    cREATED_DATE : string;
    lAST_UPDATE_BY : string;
    lAST_UPDATE_DATE : string;

    

    /**
     *
     */
    constructor() {
        this.companyId = 0;
        this.fK_ParentId = 0;
        this.country = 0;
        this.companyShortName ="";
        this.companyName ="";
        this.companyName_LL ="";
        this.address ="";
        this.phoneNumber ="";
        this.fax ="";
        this.url ="";
        this.logo ="";
        this.cREATED_BY ="";
        this.cREATED_DATE ="";
        this.lAST_UPDATE_BY ="";
        this.lAST_UPDATE_DATE ="";
    }
}


export class OrgLevel
    {
        fK_CompanyId :number;
        levelId :number;
        levelName : string;
        levelName_LL : string;
        /**
         *
         */
        constructor() {
            this.fK_CompanyId = 0;
            this.levelId = 0;
            this.levelName = "";
            this.levelName_LL = "";

        }
    }      


    export class OrgUnit
    {
        orgUnitId : number;
        fK_CompanyId : number;
        fK_ParentId : number;
        fK_LevelId : number;
        orgUnitCode : string;
        orgUnitName : string;
        orgUnitName_LL : string;
        cREATED_BY : string;
        cREATED_DATE : string;
        lAST_UPDATE_BY : string;
        lAST_UPDATE_DATE : string;
        companyName: string;
        /**
         *
         */
        constructor() {
            this.orgUnitId = 0;
            this.fK_CompanyId = 0;
            this.fK_ParentId = 0;
            this.fK_LevelId = 0;
            this.orgUnitCode = "";
            this.orgUnitName = "";
            this.orgUnitName_LL = "";
            this.cREATED_BY = "";
            this.cREATED_DATE = "";
            this.lAST_UPDATE_BY = "";
            this.lAST_UPDATE_DATE = "";
            this.companyName = "";
        }
    }

    export class OrgStructure
    {

         id : number;
         hasChildren : number;
         parentid : number;
         unitName:string;
         value:string;
         childName:string;
        /**
         *
         */
        constructor() {
            this.id = 0;
            this.hasChildren = 0;
            this.parentid = 0;
            this.unitName ="";
            this.value  ="";
            this.childName  ="";
        }
    }

    export  class Org_WorkLocation
    {
        radius : number;
        fK_CompanyId : number;
        workLocationId : number;
        workLocationCode : string;
        workLocationName : string;
        workLocationName_LL : string;
        gPSCoordinates : string;
        cREATED_BY : string;
        cREATED_DATE : string;
        lAST_UPDATE_BY : string;
        lAST_UPDATE_DATE : string;
        active : boolean;
        select: boolean;
/**
 *
 */
constructor() {
    this.radius = 0;
    this.fK_CompanyId = 0;
    this.workLocationId = 0;
    this.workLocationCode = "";
    this.workLocationName = "";
    this.workLocationName_LL = "";
    this.gPSCoordinates = "";
    this.cREATED_BY = "";
    this.cREATED_DATE = "";
    this.lAST_UPDATE_BY = "";
    this.lAST_UPDATE_DATE = "";
    this.active = false;
    this.select = false;
}
}
        
export class Org_logicalGroup
{
    fK_CompanyId : number;
    groupId : number;
    groupName : string;
    groupName_LL : string;
    cREATED_BY : string;
    cREATED_DATE : string;
    lAST_UPDATE_BY : string;
    lAST_UPDATE_DATE : string;
    active : boolean;
    select : boolean;
    /**
     *
     */
    constructor() {
        this.fK_CompanyId = 0;
        this.groupId = 0;
        this.groupName = "";
        this.groupName_LL = "";
        this.cREATED_BY = "";
        this.cREATED_DATE = "";
        this.lAST_UPDATE_BY = "";
        this.lAST_UPDATE_DATE = "";
        this.active = false;
        this.select = false;
    }
}

export class OrgCompany_Languages
{
    fK_CompanyId : number;
    fK_LocalLanguageId : number;
    fK_DefaultLanguageId : number;
    companyLanguages : string;
    /**
     *
     */
    constructor() {
        this.fK_CompanyId = 0;
        this.fK_LocalLanguageId = 0;
        this.fK_DefaultLanguageId = 0;
        this.companyLanguages = "";
    }
}