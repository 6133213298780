import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';


@Component({
  selector: 'app-video-lecture-request',
  templateUrl: './video-lecture-request.component.html',
  styleUrls: ['./video-lecture-request.component.css']
})
export class VideoLectureRequestComponent extends BaseComponent implements OnInit {

  objVideoCourse : VideoCourse;
  lstVideoCourse : VideoCourse[];
  selectedId : number =0
  displayedColumns: string[] = ['select', 'studentName', 'teacherName','courseName',   'isActive',  'isApproved', 'Edit'];
  dataSource = new MatTableDataSource<VideoCourse>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService,public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.objVideoCourse = new VideoCourse();
    this.lstVideoCourse = []
  }

  ngOnInit() {
      this.getAll();
  }

  ShowConfirmDialog(id : any): void {
    this.selectedId = id; 
    debugger;
    const message = 'Are you sure you want to approve the request.?';
    const dialogData = new CustomConfirmDialogModel("Approve Request  ", message);
    const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
      width: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.edit();
      }
    });
  }

  edit(){
    debugger;

      this.objVideoCourse =  this.dataSource.data.filter(x => x.id == this.selectedId)[0];
      this._svc.put(this.objVideoCourse, 'CourseSubscription').subscribe(
        data => {
          debugger;
          this.global.openDialog("Forms", "Updated succesfully...!")
          this.getAll();
        }, (err) => {
          this.global.openDialog("Forms", "Error While Saving.")
        })   

  }

  Delete(id : any){
    this.objVideoCourse =  this.dataSource.data.filter(x => x.id == id)[0];
   }

   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  save(){
    debugger;

    this._svc.Save(this.objVideoCourse, 'Course').subscribe(
      data => {
        debugger;
        this.getAll();
      }, (err) => {
        this.global.openDialog("Forms", "Error While Saving.")
      })
  }

  pageNumber :number =1
  pageSize :number =10

  getAll(){
    this._svc.GetDetails("CourseSubscription?pageNumber=" + this.pageNumber + "&pageSize=1" + this.pageSize ).subscribe(rtnData => {
 
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstVideoCourse= rtnData.data;
            this.dataSource.data = this.lstVideoCourse;
         }  
      });
  }

}



export class VideoCourse {
    id: number;
    teacherId:  number;
    courseId:  number;
    studentId:  number;
    teacherName: string
    courseName: string
    studentName: string
    isActive: boolean
    isDeleted: boolean
    isApproved: boolean

    /**
     *
     */
    constructor() {
      this.id = 0;
      this.teacherId = 0;
      this.courseId = 0;
      this.studentId = 0;
      this.teacherName = ""
      this.courseName =  ""
      this.studentName =  ""
      this.isActive =  false
      this.isDeleted = false
      this.isApproved = false
  
    }
  }


export class  approverequest{
  
  studentId: number 
  id: number ;
  paidAmount: number ;

  /**
   *
   */
  constructor() {
    this.studentId = 0; 
    this.id = 0;
    this.paidAmount = 0;
  }
}