import { Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from '../Shared/language-translate.service';
import { BaseComponent } from '../Shared/base-component';
import { Login, Sys_users, Languages, UserInput } from '../Classes/login';
import { SharedService } from '../Shared/shared.service';
import { GlobalVariablesService } from '../Shared/global-variables.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  objLogin: Login;
  Languages: Languages[];
  objSys_users: Sys_users;
  UserInput: UserInput;
  strError: string = "";
  selectedLangualge: number = 0;
  isLoading:boolean = false
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private router: Router) {
    super(languageTranslateService);
    this.objLogin = new Login();
    this.objSys_users = new  Sys_users();
    this.Languages = [];
    this.UserInput = new UserInput();
  }

  ngOnInit() {

    //this.getlanguage();
  }
  getlanguage() {
    

    this._svc.getGenericParmas(this.objSys_users.companyId, 'FK_CompanyId','Language/GetAllLanguages').subscribe(
      data => {
        this.Languages = data;
        this.global.Languages = this.langObject;
      }, (err) => {
        // alert("error occured ")
      });
  }
  getLogin() {
    debugger;
    // if (this.selectedLangualge == 0) {
    //   alert("Please select Languange.")
    // }
    // else {
      var test = this.selectedLangualge;
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "./assets/Theme_assets/scripts/main.07a59de7b920cd76b874.js";
      document.getElementsByTagName("head")[0].appendChild(script);


      // if(this.InitialValidation()){
      //   alert(this.strError);
      // }
      // else {
        ;
      this._svc.LoginUser(this.objLogin, 'security/LoginUser').subscribe(
        data => {
          if (data.errorMessage == "Success") {
          debugger;
            this.objSys_users = data;
            this.selectedLangualge = 1;
          //  this.getlanguage();
            // this.selectedLangualge = this.objSys_users.
            sessionStorage.setItem('objSys_users', JSON.stringify(this.objSys_users));
            sessionStorage.setItem('user_FullName',this.objSys_users.user_FullName);
            sessionStorage.setItem('lang', this.selectedLangualge.toString());
            
            this.GetModulesAndForms();
          }
          else 
          alert("invalid Credential");
        }, (err) => {
          alert("Error in Connecting API");
        });
      // }

    //}
  }

  GetModulesAndForms() {
    // "Forms/GetModulesAndForms"
    this.UserInput.lang = Number(this.selectedLangualge);
    sessionStorage.setItem("lang",this.selectedLangualge.toString());
    sessionStorage.setItem("userid",this.objSys_users.userId.toString());
    if(this.objSys_users.userType == 1 ){
      sessionStorage.setItem("isTeacher","false")
      this.router.navigateByUrl('/studentdashboard');
    }
    else if(this.objSys_users.userType == 2 ){
      sessionStorage.setItem("isTeacher","true")
      this.router.navigateByUrl('/teacherboard');
    }
    else{
      this.router.navigateByUrl('/homepage');
    }

  }
  InitialValidation() {
    this.strError = "";
    if (this.global.isStringNullOrEmplty(this.objLogin.UserName)) {
      this.strError = "Please enter User Name.";
    }
    else if (this.global.isStringNullOrEmplty(this.objLogin.Password)) {
      this.strError = "Please enter passowrd.";
    }
    if (this.strError == "")
      return false;
    else
      return true;
  }

  language: language[] = [
    { value: 'English', viewValue: 'English' },
    { value: 'Arabic', viewValue: 'Arabic' }
  ];
}

interface language {
  value: string;
  viewValue: string;
}

