export class WorkSechedule {
    scheduleId: number;
    fK_CompanyId: number;
    scheduleName: string;
    scheduleName_LL: string;
    scheduleType: number;
    graceIn: number;
    graceOut: number;
    earlyOutIsFromGrace: boolean;
    delayIsFromGrace: boolean;
    isDefault: boolean;
    isRamadanSch: boolean;
    parentScheduleId: number;
    isActive: boolean;
    createD_BY: string;
    lasT_UPDATE_BY: string;
    createD_DATE: Date;
    lasT_UPDATE_DATE: Date;
    Selected: boolean = false;
    /**
     *
     */
    constructor() {
        this.scheduleId = 0;
        this.fK_CompanyId = 0;
        this.scheduleName = "";
        this.scheduleName_LL = "";
        this.scheduleType = 1;
        this.graceIn = 0;
        this.graceOut = 0;
        this.earlyOutIsFromGrace = false;
        this.delayIsFromGrace = false;
        this.isDefault = false;
        this.isRamadanSch = false;
        this.parentScheduleId = 0;
        this.isActive = true;
        this.createD_BY = "";
        this.lasT_UPDATE_BY = "";
    }
}

export class SaveWorkSchedule_Time {
    fK_ScheduleId: number;
    dayId: number;
    day_name: string;
    fromTime: number;
    toTime: number;

    fromTime_timeFormate: string;
    toTime_timeFormate: string;

    flixibleTime_timeFormate: string;
    duration_timeFormate: string;

    flixibleTime: number;
    workHours: number;
    // duration : number;
    isOffDay: boolean;
    /**
     *
     */
    constructor() {
        this.fK_ScheduleId = 0;
        this.dayId = 0;
        this.fromTime = 0;
        this.toTime = 0;
        this.flixibleTime = 0;
        this.workHours = 0;
        this.isOffDay = false;

        this.fromTime_timeFormate = "";
        this.toTime_timeFormate = "";
        this.flixibleTime_timeFormate = "";
        this.duration_timeFormate = ""

    }
}

export class NoticeBoardAnnuncement {
    noticeBoardAnnuncementID: number;
    fKClassID: number;
    fKSubjectID: number;
    showntoAll: boolean;
    noticeFileName: string;
    noticeBoardDetails: string;
    pinonTop: number;
    createdBy: string;
    createdDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
    selected: boolean;
    constructor() {
        this.noticeBoardAnnuncementID = 0;
        this.fKClassID = 0;
        this.fKSubjectID = 0;
        this.showntoAll = false;
        this.noticeFileName = "";
        this.noticeBoardDetails = "";
        this.pinonTop = 0;
        this.createdBy = "";
        this.createdDate = new Date();
        this.modifiedBy = "";
        this.modifiedDate = new Date();
    }
}
