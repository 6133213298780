import { Component, OnInit, ViewChild } from '@angular/core';
import { Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { RoomsComponent } from '../rooms/rooms.component';
import { CameraComponent } from '../camera/camera.component';
import { SettingsComponent } from '../settings/settings.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { HttpClient } from '@angular/common/http';


import {  HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';


// import { ToastService } from 'src/app/services/toast.service';
import { VideoChatService } from 'app/Shared/services/video-chat.service';
import { ApiServiceService } from 'app/Shared/api-service.service';
import { environment } from 'environments/environment';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
// import { environment } from 'src/environments/environment';



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    @ViewChild('rooms', { static: true }) rooms: RoomsComponent;
    @ViewChild('camera', { static: true }) camera: CameraComponent;
    @ViewChild('settings', { static: true }) settings: SettingsComponent;
    @ViewChild('participants', { static: true }) participants: ParticipantsComponent;

    activeRoom: Room;
    display_chat:string = "none";
    objchatclass: chatclass
    chatlist :chatlist[];

    objchatlist :chatlist;
    private notificationHub: HubConnection;
    objreportclass: reportclass;
    constructor(
        private readonly videoChatService: VideoChatService, private apiService: ApiServiceService,
        private global: GlobalVariablesService
        //  ,private toastService: ToastService 
         
         ,
        private http: HttpClient) {
        this.objreportclass = new reportclass();
        this.objchatclass =  new chatclass()
        this.chatlist  = [];
        this.objchatlist = new chatlist();
        this.envData = environment;
        this.baseURL = this.envData.apiConn;    
        this.baseURLFile = this.envData.baseURL
    }
    baseURLFile :string =""
    cameraOn :boolean = true;
    async ngOnInit() {
        if (this.rooms != undefined) {
            await this.rooms.updateRooms();
        }
        const builder =
            new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withUrl(`${location.origin}/notificationHub`);

        this.notificationHub = builder.build();
        this.notificationHub.on('RoomsUpdated', async updated => {
            if (updated) {
                await this.rooms.updateRooms();
            }
        });
        await this.notificationHub.start();

        if (this.notificationHub == null) {
            const builder =
                new HubConnectionBuilder()
                    .configureLogging(LogLevel.Information)
                    .withUrl(`${location.origin}/notificationHub`);

            this.notificationHub = builder.build();
            this.notificationHub.on('RoomsUpdated', async updated => {
                if (updated) {
                    await this.rooms.updateRooms();
                }
            });
            await this.notificationHub.start();
        }
    this.cameraOn = true;
    }

    async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
        await this.camera.initializePreview(deviceInfo);
    }

    async onLeaveRoom(_: boolean) {
        debugger;
        if (this.activeRoom) {
            this.activeRoom.disconnect();
            this.activeRoom = null;
        }

        this.camera.finalizePreview();
        const videoDevice = this.settings.hidePreviewCamera();
        this.camera.initializePreview(videoDevice);

        this.participants.clear();
    }


    async SwitchOffcamera(){
        debugger;
        if(this.cameraOn == true){
        this.cameraOn = false;
        this.camera.finalizePreview();
        const videoDevice = this.settings.hidePreviewCamera();
        this.camera.initializePreview(videoDevice);
    
    }

        else{
            this.camera.finalizePreview();
            const tracks = await this.settings.showPreviewCamera();
            this.cameraOn = true
        }
    }

    async onRoomChanged(roomName: string) {
        debugger;
        // alert("onRoomChanged in Home")
        if (roomName) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }

            this.camera.finalizePreview();
            const tracks = await this.settings.showPreviewCamera();

            this.activeRoom =
                await this.videoChatService
                    .joinOrCreateRoom(roomName, tracks);

            this.participants.initialize(this.activeRoom.participants);
            this.registerRoomEvents();

            this.notificationHub.send('RoomsUpdated', true);
        }
    }

    onParticipantsChanged(_: boolean) {
        debugger;
        this.videoChatService.nudge();
    }

    private registerRoomEvents() {
        debugger;
        this.activeRoom
            .on('disconnected',
                (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
            .on('participantConnected',
                (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {
            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }


    display = "none";
    reportreason: string = ""

    openModal() {
        debugger;
        
        this.reportreason = "";
        // this.objreportclass.reportTime =  new Date().toISOString();
        this.display = "block";
    }
    onCloseHandled() {
        debugger;
        this.objreportclass.reportReason = this.reportreason;
        // this.objreportclass.corporateAccId = Number(sessionStorage.getItem('corporateAccId'));

        this.display = "none";
    }

    onsaveHandled() {
        debugger;

        this.objreportclass.reportReason = this.reportreason;
        // this.objreportclass.corporateAccId = Number(sessionStorage.getItem('corporateAccId'));
        this.objreportclass.classId = Number(sessionStorage.getItem('romname'));
        this.objreportclass.reportedById = Number(sessionStorage.getItem("userid"));
        this.objreportclass.reportedId = 0;
        // var token = sessionStorage.getItem("token");
        // this.objreportclass.authtoken = token;

        this.apiService.post(this.objreportclass, 'CorporateBooking/AbuseReport').subscribe(rtnData => {
            debugger;
            if (rtnData.message == "success") {
              this.global.openDialog("Reported","Reported Successfully.!")
                // this.toastService.success("Reported Successfully.!");
                this.display = "none";
            }
            else {
              this.global.openDialog("Reported","Some error occured.!")
                // this.toastService.error("Some error occured.!");
            }
        });


    }

    openForm(){
        this.getchat()
        this.display_chat ="block"
    }

    closeForm(){
        debugger;
        this.display_chat = "none";
    }

    text :string =""
    sendchat(){
        debugger
        // var token = sessionStorage.getItem("token");
        // this.objchatclass.authtoken = token;
        // this.objchatclass.corporateId = Number(sessionStorage.getItem('corporateAccId'));
        this.objchatclass.classId = Number(sessionStorage.getItem('romname'));
        this.objchatclass.text = sessionStorage.getItem("user_FullName") +"&&"+ this.text;
        this.apiService.post(this.objchatclass, 'CorporateBooking/chat').subscribe(rtnData => {
            debugger;
            this.text = "";
            this.getchat()
        });
    }
    checkactivestatus:checkactivestatus


    activestatus(){
        debugger
        this.checkactivestatus = new checkactivestatus()
        var token = sessionStorage.getItem("token");
        this.checkactivestatus.authtoken = token;
        this.checkactivestatus.corporateAccId = Number(sessionStorage.getItem('corporateAccId'));
        this.checkactivestatus.sessionId = Number(sessionStorage.getItem('romname'));

        this.checkactivestatus.userId = Number(sessionStorage.getItem("userid"));
        var isteacher = sessionStorage.getItem("isTeacher")
        if(isteacher == "true"){
            this.checkactivestatus.isTutor = true;
        }
        else{
            this.checkactivestatus.isTutor = false;
        }
        
        this.apiService.post(this.objchatclass, 'Corporate/scheduleclasses/active/status').subscribe(rtnData => {
            debugger;
            this.getchat()
        });
    }

   getchat(){
        // var cid =sessionStorage.getItem('corporateAccId')
        var sid = sessionStorage.getItem('romname')
        this.apiService.GetDetails("CorporateBooking/chat?classId="+sid).subscribe(rtnData => {
            debugger;
            this.chatlist=[]
             for(var i = 0; i < rtnData.data.length; i++ ){
                var temp = rtnData.data[i];
                var tt = temp.split("&&");
                this.objchatlist = new chatlist();
                this.objchatlist.message = tt[1];
                this.objchatlist.senderName = tt[0];
                this.chatlist.push(this.objchatlist);

             }
        });
    }




    image: File | undefined;
  resData: any;
  selectedFile :any;
  envData;
  baseURL : string ="";

  onFileSelected(event:any) {
    debugger
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    debugger;
    this.onSubmit();
  }
  onSubmit() {
    debugger;
        const payload = new FormData();
        var sid = sessionStorage.getItem('romname')
        payload.append('classid',sid);
        payload.append('file', this.selectedFile, this.selectedFile.name);
        this.http.post(this.baseURL + "CorporateBooking/FileUpload",
            payload, { headers: { 'encType': "multipart/form-data"}
        }).subscribe((data: any) => {
            this.resData = data;
            this.objchatclass.classId = Number(sessionStorage.getItem('romname'));
            console.log(data);
            console.log(data.data);
            this.text =  this.baseURLFile +"" + data.data;
            console.log(this.objchatclass.text)
            this.sendchat()

            console.log(this.resData);
            debugger;
          });
      }

    //   scheduleclasses/active/status
    
}



export class reportclass {
    // authtoken: string
    // corporateAccId: number
    reportedId: number
    reportedById: number
    classId: number
    reportReason: string
    // reportTime: string

    /**
     *
     */
    constructor() {
        // this.authtoken = ""
        // this.corporateAccId = 0
        this.reportedId = 0
        this.reportedById = 0
        this.classId = 0
        this.reportReason = ""
        // this.reportTime = ""

    }
}


export class chatclass{
    // authtoken: string;
    // corporateId:number;
    classId:number;
    text: string;
    /**
     *
     */
    constructor() {
        // this.authtoken = "string";
        // this.corporateId = 0;
        this.classId = 0;
        this.text = "string"
      }
  }

  export class chatlist{
    senderName : string;
    message : string;

    /**
     *
     */
    constructor() {
        this.senderName  ="";
        this.message ="";
    
    }
  }


  export class checkactivestatus{
    
        authtoken : string
        corporateAccId : number
        sessionId : number
        userId : number
        isTutor : boolean

        /**
         *
         */
        constructor() {
            this.authtoken = ""
            this.corporateAccId = 0
            this.sessionId = 0
            this.userId = 0
            this.isTutor = true
                    
        }
      
  }