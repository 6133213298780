import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { OrgUnit, Org_logicalGroup,OrgCompany } from 'app/Classes/org';
import { Sys_Groups } from 'app/Classes/groups';
import { Sys_users } from 'app/Classes/login';
import { SelectionModel } from '@angular/cdk/collections';
import { Student, StudentAttendance, StudentComplaint, StudentTestResult, Student_History } from 'app/Classes/student';
import { DomSanitizer } from '@angular/platform-browser';
import { Classes } from 'app/Classes/classes';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.css']
})
export class StudentProfileComponent extends BaseComponent implements OnInit  {
  lstOrgUnit: OrgUnit[];
  lstSys_Groups: Sys_Groups[];
  lstOrg_logicalGroup: Org_logicalGroup[];
  Sys_Users: Sys_users;
  lstSys_Users: Sys_users[];
  OrgCompany1: OrgCompany;
  Active: boolean = false;
  std: Student;
  objStudent_History: Student_History;
  lstStudent_History: Student_History[];
  lstClasses: Classes[];

  objStudentAttendance : StudentAttendance;
  objStudentTestResult:StudentTestResult;
  objStudentComplaint : StudentComplaint;
  lstStudentAttendance : StudentAttendance[];
  lstStudentTestResult:StudentTestResult[];
  lstStudentComplaint : StudentComplaint[];

  Isnew:boolean=false;

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService , private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.lstOrg_logicalGroup=[];
    this.lstOrgUnit =[];
    this.lstSys_Groups = [];
    this.Sys_Users = new Sys_users();
    this.lstSys_Users = [];
    this.OrgCompany1  = new OrgCompany();
    this.std = new Student();
    
    this.objStudent_History = new  Student_History();
    this.lstStudent_History = []

    this.objStudentAttendance = new  StudentAttendance();
    this.objStudentTestResult = new StudentTestResult();
    this.objStudentComplaint = new  StudentComplaint();
    this.lstStudentAttendance = [];
    this.lstStudentTestResult = [];
    this.lstStudentComplaint = [];
    this.lstClasses = [];
   }

  displayedColumns: string[] = ['select', 'UserName', 'FullName', 'EmployeeNo', 'Edit'];
  dataSource = new MatTableDataSource<Sys_users>();
  selection = new SelectionModel<Sys_users>(true, []);


  HistorydisplayedColumns: string[] = ['select', "schoolName" ,  "class",  "totlaMarks" ,  "marksObtained","grade" ,"passingDate", 'Edit'];
  HistorydataSource = new MatTableDataSource<Student_History>();
  Historyselection = new SelectionModel<Student_History>(true, []);


  StudentComplaintdisplayedColumns: string[] = ['select', "complaintDetails" ,  "isActive",  "createdDate" ,  'Edit'];
  StudentComplaintdataSource = new MatTableDataSource<StudentComplaint>();
  StudentComplaintselection = new SelectionModel<StudentComplaint>(true, []);

  ngOnInit() {
    this.GetAllGroupsByCompany();
    this.GetOrgUnitByCompany_ForDropdown();
    this.GetCompanyDetails();
    this.SearchApplicationUser();
    this.getAllClasses();
    // this.Sys_Users.userId = 1
  }

  GetAllGroupsByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Groups/GetAllGroupsByCompany').subscribe(
      data => {
        console.log(data)
        this.lstSys_Groups = data;
        for(var i = 0 ; i< this.lstSys_Groups.length ; i++){
          this.lstSys_Groups[i].select = false;
        }

      }, (err) => {
        // alert("error occured ")
      });
  }

  GetCompanyDetails() {
  
    var companyid = this.global.FK_CompanyId();
    this._svc.getGenericParmas(companyid, 'CompanyId', 'Org/GetCompanyDetails').subscribe(
      data => {
        console.log(data)
        this.OrgCompany1 = data;
      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }

  edit(userid: any , userName: any) {
    debugger;
      this.Isnew = true;
      this.Sys_Users = this.dataSource.data.filter( x=> x.userId == userid )[0];
      this.imagepath = this.Sys_Users.picture;
      this.transform();
      this.std  = new Student();
      
      this._svc.getGenericParmas(userid, "UserID", "Student/GetStudentbyID").subscribe(
        data => {
          if(data.length > 0){
              this.std = data[0];

              this.getStudentHistorybyID();
              this.GetStudentComplaintRecordbystudentid();
          }
         
        }, (err) => {
          this.global.openDialog("Students", "Some Error Occured.");
        });
  }

  GetStudentComplaintRecordbystudentid(){
    this._svc.getGenericParmas(this.std.id, "FK_studentid", "Student/GetStudentComplaintRecordbystudentid").subscribe(
      data => {
        if(data.length > 0){
          this.StudentComplaintdataSource.data = data;
        }
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
      });
  }
  editStudentComplaint(id : any){
    this.objStudentComplaint = this.StudentComplaintdataSource.data.filter( x=> x.studentComplaintID == id )[0]
  }
getStudentHistorybyID(){
  this._svc.getGenericParmas(this.std.id, "StudentID", "Student/Get_StudentHistory").subscribe(
    data => {
      if(data.length > 0){
        this.HistorydataSource.data = data;
      }
    }, (err) => {
      this.global.openDialog("Students", "Some Error Occured.");
    });
}

editHistory(studentHistoryID : any){
this.objStudent_History = this.HistorydataSource.data.filter( x => x.studentHistoryID == studentHistoryID )[0]
}
GetOrgUnitByCompany_ForDropdown() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'OrgUnit/GetOrgUnitByCompany_ForDropdown').subscribe(
      data => {
        console.log(data)
        this.lstOrgUnit = data;
      }, (err) => {

      });
  }
  InitialValideation(){
    var  error= "";
    if(this.Active == true){
      this.Sys_Users.active = 1;  
    }
    else{
      this.Sys_Users.active = 0;  
    }
  }

  CreateApplicationUser() {
    debugger;;
    // this.Sys_Users.userId = Number(this.Sys_Users.userId);
    ;
    this.Sys_Users.userType=1;
    this.Sys_Users.companyId = Number(this.OrgCompany1.companyId)

    this.Sys_Users.userStatus = Number(this.Sys_Users.userStatus)
    this.Sys_Users.userType = Number(this.Sys_Users.userType)
    this.Sys_Users.isFirstLogin = Number(this.Sys_Users.isFirstLogin)
    // this.Sys_Users.fK_EmployeeId = Number(this.Sys_Users.fK_EmployeeId)
    this.Sys_Users.fK_CompanyId =  this.global.FK_CompanyId();
    this.Sys_Users.lAST_UPDATE_DATE = new Date();
    this.Sys_Users.cREATED_DATE = new Date();
    this.Sys_Users.lastLoginDate = new Date();
    this.Sys_Users.lastChange_Password = new Date();
    this.Sys_Users.fK_OrgUnitId = Number(this.Sys_Users.fK_OrgUnitId);
    this.Sys_Users.groupIds = this.Sys_Users.fK_LogicalGroup.toString();
    // for(var i = 0 ; i< this.lstSys_Groups.length ; i++){
    //   if(this.lstSys_Groups[i].select == true){
    //     this.Sys_Users.groupIds = this.Sys_Users.groupIds +","+ this.lstSys_Groups[i].groupID;
    //   }
      
    // }
    
   // this.Sys_Users.fK_GroupId = Number(this.Sys_Users.fK_GroupId );
    this.Sys_Users.userDefaultLAng = 'a';
    //this.Sys_Users.remarks ="";

    this._svc.CreateApplicationUser(this.Sys_Users, 'Users/CreateApplicationUser').subscribe(
      data => {
        /////gddddd
        ;
        this.clear();
        this.SearchApplicationUser();
        this.global.openDialog("Students", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
        // alert("error occured ")
      });
  }

  clear(){
    this.Sys_Users = new Sys_users();
    this.Isnew = false;
  }
  SearchApplicationUser() {
    debugger;
    this.Sys_Users.userDefaultLAng = '1';
    this.Sys_Users.fK_CompanyId = this.global.FK_CompanyId();
    // "Users/SearchApplicationUser"
    this.Sys_Users.userType=1;
    this._svc.SearchApplicationUser(this.Sys_Users, "Users/SearchApplicationUser").subscribe(
      data => {
        console.log(data)
        this.lstSys_Users = data;
        if(this.lstSys_Users.length > 0 ){
        for (var i = 0; i < this.lstSys_Users.length; i++) {
          this.lstSys_Users[i].select = false;
        }
        this.dataSource.data = this.lstSys_Users;
      } else {
        this.dataSource.data =[];
        this.global.openDialog("Users","No record found.");
      }
      }, (err) => {
        this.global.openDialog("Users","Some Error Occured.");
      });
  }

  SaveStd(){
    debugger;
    

    this.std.userID = this.global.convertNumbre(this.Sys_Users.userId)
    this.std.height = this.global.convertNumbre(this.std.height)
    this.std.weight = this.global.convertNumbre(this.std.weight) 
    this._svc.Save(this.std, 'Student/AddUpdateStudent').subscribe(
      data => {
        /////gddddd
        ;
        this.clear();
        this.SearchApplicationUser();
        this.global.openDialog("Students", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
        // alert("error occured ")
      });

  }

  imagepath: string = '';
  transform() {
    if(this.Sys_Users.pictureExt.toLowerCase() == 'jpg' || this.Sys_Users.pictureExt.toLowerCase() == 'jpeg'){
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + this.imagepath);//data:image/jpg;base64, 
   
    }
    // if(this.Sys_Users.pictureExt.toLowerCase() == 'jpeg'){
    //   return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64, ' + this.imagepath);//data:image/jpg;base64, 
   
    // }
    if(this.Sys_Users.pictureExt.toLowerCase() == 'png'){
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + this.imagepath);//data:image/jpg;base64, 
   
    }
    
    // return this.sanitizer.bypassSecurityTrustResourceUrl(this.imagepath);
  }
  handleInputChange(e) {
    
debugger;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    var ext = file.name.split('.');
    this.Sys_Users.pictureExt = ext[1];
    reader.readAsDataURL(file);
  }
  imageSrc: any;
  Picture : string;
  _handleReaderLoaded(e) {
debugger;
    let reader = e.target;
    this.imageSrc = reader.result;
    this.Sys_Users.picture = reader.result;

    if(this.Sys_Users.pictureExt.toLowerCase() == 'jpg' || this.Sys_Users.pictureExt.toLowerCase() == 'jpeg'){
      this.imagepath = this.Sys_Users.picture.replace('data:image/jpeg;base64,', '');//data:image/jpg;base64, 
   
    }
    // if(this.Sys_Users.pictureExt.toLowerCase() == 'jpeg'){
    //   this.imagepath = this.Sys_Users.picture.replace('data:image/jpeg;base64,', '');//data:image/jpg;base64, 
   
    // }
    if(this.Sys_Users.pictureExt.toLowerCase() == 'png'){
      this.imagepath = this.Sys_Users.picture.replace('data:image/png;base64,', '');;//data:image/jpg;base64, 
   
    }
   // this.imagepath = this.Sys_Users.picture.replace('data:image/png;base64,', '');
   // this.imagepath = this.Sys_Users.picture;
    this.transform();
  }

  SaveStudent_History() {
    debugger;
 //   this.objStudent_History.salary = Number(this.objTeacherProfessionalRecord.salary);
    this.objStudent_History.studentID = this.std.id;
    this.objStudent_History.createdBy = this.global.getUserID();
    this.objStudent_History.lastUpdatedBy = this.global.getUserID();
    this.objStudent_History.studentHistoryID = Number(this.objStudent_History.studentHistoryID)
    // this.objteacher.createdDate = new Date(this.objteacher.createdDate);
    // this.objteacher.lastUpdatedDate = new Date(this.objteacher.lastUpdatedDate);
    //  this.bindReligion();
    this._svc.Save(this.objStudent_History, 'Student/AddUpdateStudentHistory').subscribe(
      data => {
        if (data.includes("Success")) {
          this.getStudentHistorybyID()
          // var teacherID = data.split(":")[1];
          // this.objteacher.teacherID = Number(teacherID);
          this.global.openDialog("Student", "Record has been saved.");
        }
        else {
          this.global.openDialog("Student", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Student", "Some Error Occured.");
      });
  }
  ClearStudent_History() {
    this.objStudent_History = new Student_History();
  }

  getAllClasses(){
    this._svc.GetDetails('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }

  new(){
    this.Isnew = true;
  }
}
