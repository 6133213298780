import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Employee_Type } from 'app/Classes/employee';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';


@Component({
  selector: 'app-employee-type',
  templateUrl: './employee-type.component.html',
  styleUrls: ['./employee-type.component.css']
})
export class EmployeeTypeComponent extends BaseComponent implements OnInit {

  Employee_Type: Employee_Type;
  lstEmployee_Type: Employee_Type[];

  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.Employee_Type = new Employee_Type();
    this.lstEmployee_Type = [];
  }

  displayedColumns: string[] = ['select', 'typeName', 'typeName_LL', 'isInternaltype', 'employeeNumberInitial', 'edit'];
  dataSource = new MatTableDataSource<Employee_Type>();
  selection = new SelectionModel<Employee_Type>(true, []);

  ngOnInit() {
    this.GetEmployeeStatusByCompany();
  }
  validate(){
    var s = "";
    if(this.global.isStringNullOrEmplty(this.Employee_Type.typeName)){
      s = "Type Name ,"
    }
    if(this.global.isStringNullOrEmplty(this.Employee_Type.typeName_LL)){
      s = s +  "Type Name local language ,"
    }
    if(this.global.isStringNullOrEmplty(this.Employee_Type.employeeNumberInitial)){
      s = s + "Employee Number Initial ,"
    }
    return s; 
  }
  SaveEmployeeType() {
    ;
    var s = this.validate();
    if(s == ""){
     this.Employee_Type.fK_CompanyId = this.global.FK_CompanyId();
    this._svc.SaveEmployeeType(this.Employee_Type, 'EmployeeType/SaveEmployeeType').subscribe(
      data => {
        ;
        this.Clear();
        this.GetEmployeeStatusByCompany();
        this.global.openDialog("Employee Type", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Employee Type", "Some Error Occured.");
      });
    } 
    else 
    {
      this.global.openDialog("Employee Type", "please fill the following " + s);
    }
  }
  GetEmployeeStatusByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'EmployeeType/GetEmployeeStatusByCompany').subscribe(
      data => {
        ;
       this.lstEmployee_Type = data;
       if (this.lstEmployee_Type.length > 0) {
       for (var i = 0; i < this.lstEmployee_Type.length; i++) {
            this.lstEmployee_Type[i].select = false;
          }
          this.dataSource.data = this.lstEmployee_Type;
        } else {
          this.global.openDialog("Employee Type", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Employee Type", "Some Error Occured.");
      });
  }

  Clear() {
    this.Employee_Type = new Employee_Type();
  }

  CosiderIsInternaltype(EmployeeTypeId: any) {

  }
  checkEmpStatus(id: any) {

  }

  edit(id: any) {
    ;
    this.Employee_Type = this.lstEmployee_Type.filter(x => x.employeeTypeId == id)[0];
  }
  selectEmpType(row: any) {
    ;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].employeeTypeId == row) {
        if (this.dataSource.data[i].select) {
          this.dataSource.data[i].select = false;
        }
        else {
          this.dataSource.data[i].select = true;
        }
      }
    }
  }

  ShowConfirmDialog(): void {

    const message = 'Are you sure you want to delete selected Records?';
    const dialogData = new CustomConfirmDialogModel("Employee Type  ", message);
    const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
      width: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


      if (dialogResult) {
        this.DeleteEmployeeType();
      }
    });
  }

  DeleteEmployeeType() {
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.dataSource.data[i].employeeTypeId, 'EmployeeTypeId', 'EmployeeType/DeleteEmployeeType').subscribe(
          data => {
            ;
            this.GetEmployeeStatusByCompany()
            this.global.openDialog("Employee Type", "Record has been deleted.");

          }, 
          (err) => {
            this.global.openDialog("Employee Type", "Some Error Occured.");
          });
      }
    }

  }

}
