export class Subjects {
     subjectID: number;
     subjectName: string;
     subjectMedium: string;
     fK_ClassID: number;
     className: string;
     fK_SchoolID: number;
     createdBy: string;
     createdDate: Date;
     lastUpdatedBy: string;
     lastUpdatedDate: Date;
     isActive: boolean;
     select: boolean;

     /**
      *
      */
     constructor() {
          this.subjectID = 0;
          this.subjectName = "";
          this.subjectMedium = "";
          this.fK_ClassID = 0;
          this.fK_SchoolID = 0;
          this.createdBy = "";
          this.createdDate = new Date();
          this.lastUpdatedBy = "";
          this.lastUpdatedDate = new Date();
          this.isActive = false;
          this.select = false;
          this.className = "";
     }
}

export class AssignSubjecttoTeacher {
     assignSubjectID: number;
     fK_SubjectID: number;
     fK_TeacherID: number;
     active: boolean;
     createdby: string;
     createdDate: Date;
     lastUpdatedby: string;
     lastUpdatedDate: Date;
     fkclassID: number;
     className: string;
     subjectName: string;
     teacherName: string;
     select: boolean;
     /**
      *
      */
     constructor() {
          this.assignSubjectID = 0;
          this.fK_SubjectID = 0;
          this.fK_TeacherID = 0;
          this.active = false;
          this.createdby = "";
          this.createdDate = new Date();
          this.lastUpdatedby = "";
          this.lastUpdatedDate = new Date();
          this.fkclassID = 0;
          this.className = "";
          this.subjectName = "";
          this.teacherName = "";
          this.select = false;
     }
}



export class Assignments {
     assignmentID: number;
     subjectID: number;
     assingmentMarks: number;
     dueDate: Date;
     createdDate: Date;
     assigmnentFileName: string;
     createdBy: string;
     isActive: boolean;
     assignmentDetails: string;
     assignmentSubject: string;
     lastUpdatedby: string;
     lastupdatedDate: Date;
     filedata:string;
     select: boolean;


  
     /**
      *
      */
     constructor() {



          this.assignmentID = 0;
          this.subjectID = 0;
          this.assingmentMarks = 0;
          this.dueDate = new Date();
          this.createdDate = new Date();
          this.assigmnentFileName = "";
          this.createdBy = "";
          this.isActive = false;
          this.assignmentDetails = "";
          this.assignmentSubject = "";
          this.lastUpdatedby = "";
          this.lastupdatedDate = new Date();
          this.select = false;
          this.filedata ="";
     }
}

export class AssignmentDetails
{
     assignmentdetailID : number;  
     fk_assignmentID : number;  
     fk_studentID : number;  
     fk_ClassID : number;  
     assignmentName : string;  
     createdDate : Date;  
     todate : Date;  
     fromDate: Date;
     createdBy : string;  
     obtainedMarks : number;  
     studentsRemarks : string;  
     teacherRemarks : string; 
     assigmnentFileName: string;
     assignmentSubject: string;
     className: string;
     select: boolean;
     /**
      *
      */
     constructor() {
          this.assignmentdetailID = 0;  
          this.fk_assignmentID = 0;  
          this.fk_studentID = 0;  
          this.fk_ClassID = 0;  
          this.assignmentName = "";  
          this.createdDate = new Date();  
          this.todate  = new Date();
          this.fromDate = new Date();
          this.createdBy = "";  
          this.obtainedMarks = 0;  
          this.studentsRemarks = "";  
          this.teacherRemarks = "";  
          this.assigmnentFileName = "";  
          this.assignmentSubject = "";  
          this.className = "";  
          this.select= false;
     } 
}
