export class Student {
    id: number;
    userID: number;
    weight: number;
    height: number;
    dateofBirth: Date;
    gender: string;
    bloodGroup: string;
    birthPlace: string;
    hand: string;
    anyDisability: string;
    religion: string;
    bForm: string;
    nationality: string;
    picture: string;
    picturePath: string;
    addressHouseFlatStreet: string;
    cityOrTown: string;
    postalCode: string;
    fatherName: string;
    fatherAddressHouseFlatStreet: string;
    fatherCNIC: string;
    fatherNTN: string;
    fatherPhoneNoLandline: string;
    fatherMobileNo: string;
    fatherQualification: string;
    fatherOccupation: string;
    fatherEmailAddress: string;
    fatherCompany: string;
    fatherDepartment: string;
    fatherDesignation: string;
    emergencyContactName: string;
    emergencyRelation: string;
    emergencyPhoneNoLandline: string;
    emergencyMobileNo: string;
    emergencyAddress: string;
    emergencyCityTown: string;
    fk_ClassID: number;
    /**
     *
     */
    constructor() {
        this.id = 0;
        this.userID = 0;
        this.weight = 0;
        this.height = 0;
        this.dateofBirth = new Date;
        this.gender = '';
        this.bloodGroup = '';
        this.birthPlace = '';
        this.hand = '';
        this.anyDisability = '';
        this.religion = '';
        this.bForm = '';
        this.nationality = '';
        this.picture = '';
        this.picturePath = '';
        this.addressHouseFlatStreet = '';
        this.cityOrTown = '';
        this.postalCode = '';
        this.fatherName = '';
        this.fatherAddressHouseFlatStreet = '';
        this.fatherCNIC = '';
        this.fatherNTN = '';
        this.fatherPhoneNoLandline = '';
        this.fatherMobileNo = '';
        this.fatherQualification = '';
        this.fatherOccupation = '';
        this.fatherEmailAddress = '';
        this.fatherCompany = '';
        this.fatherDepartment = '';
        this.fatherDesignation = '';
        this.emergencyContactName = '';
        this.emergencyRelation = '';
        this.emergencyPhoneNoLandline = '';
        this.emergencyMobileNo = '';
        this.emergencyAddress = '';
        this.emergencyCityTown = '';
        this.fk_ClassID = 0;
    }
}
export class Student_History {
    studentHistoryID: number;
    studentID: number;
    schoolName: string;
    class: string;
    totlaMarks: string;
    marksObtained: string;
    grade: string;
    passingDate: string;
    certificateImage: string;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    select: boolean;

    /**
     *
     */
    constructor() {
        this.studentHistoryID = 0;
        this.studentID = 0;
        this.schoolName = "";
        this.class = "";
        this.totlaMarks = "";
        this.marksObtained = "";
        this.grade = "";
        this.passingDate = "";
        this.certificateImage = "";
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.select = false;
    }
}



export class StudentTestResult {
    StudentTestResultID: number;
    FK_StudentID: number;
    ClassID: number;
    SubjectID: number;
    TestID: number;
    ObtainedMarks: number;
    TeacherID: number;
    TotalMarks: number;
    Percentage: string;
    CreatedBy: string;
    CreatedDate: Date;
    LastUpdatedBy: string;
    LastUpdatedDate: Date;
    IsActive: boolean;
    /**
     *
     */
    constructor() {
        this.StudentTestResultID = 0;
        this.FK_StudentID = 0;
        this.ClassID = 0;
        this.SubjectID = 0;
        this.TestID = 0;
        this.ObtainedMarks = 0;
        this.TeacherID = 0;
        this.TotalMarks = 0;
        this.Percentage = "";
        this.CreatedBy = "";
        this.CreatedDate = new Date();
        this.LastUpdatedBy = "";
        this.LastUpdatedDate = new Date();
        this.IsActive = false;
    }

}

export class StudentComplaint {
    studentComplaintID: number;
    fK_StudentID: number;
    complaintID: number;
    complaintDetails: number;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    name : string;
    complaintName :string;
    /**
     *
     */
    constructor() {
        this.studentComplaintID = 0;
        this.fK_StudentID = 0;
        this.complaintID = 0;
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.name  = "";
        this.complaintName  = "";
    }
}


export class StudentAttendance {

    studentAttendanceID: number;
    fK_StudentID: number;
    classID: number;
    studentAttendanceStatus: string;
    studentAttendanceDate: Date;
    leaveID: number;
    teacherID: number;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;

    user_FullName:string;
    userName :string;
    user_FullName_LL :string;

    /**
     *
     */
    constructor() {



        this.studentAttendanceID = 0;
        this.fK_StudentID = 0;
        this.classID = 0;
        this.studentAttendanceStatus = "p";
        this.studentAttendanceDate = new Date();
        this.leaveID = 0;
        this.teacherID = 0;
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.user_FullName= "";
        this.userName = "";
        this.user_FullName_LL = "";
    }
}

export class StudentLeave
{
     leaveID : number;  
     studentID : number;  
     leaveTypeID : number;  
     leaveTypeText : string;  
     leaveDateFrom : Date;  
     leaveDateTo : Date;  
     noOfdasy : number;  
     attachementDetails : string;  
     attachementID : number;  
     createdBy : string;  
     createdDate : Date;  
     lastUpdatedBy : string;  
     lastUpdatedDate : Date;  
     isActive : boolean;  
     studentName: string
     select: boolean;
     /**
      *
      */
     constructor() {
        this.leaveID = 0;  
        this.studentID = 0;  
        this.studentName = "";
        this.leaveTypeID = 0;  
        this.leaveTypeText = "";  
        this.leaveDateFrom = new Date();  
        this.leaveDateTo = new Date();  
        this.noOfdasy = 0;  
        this.attachementDetails = "";  
        this.attachementID = 0;  
        this.createdBy = "";  
        this.createdDate = new Date();  
        this.lastUpdatedBy = "";  
        this.lastUpdatedDate = new Date();  
        this.isActive = false; 
        this.select = false;
     }
}

export class  StudentFee {
    userFullName: string = "";
    studentID: number = 0;
    className: string = "";
    classID: number = 0;
    feeID: number = 0;
    feeType: string = "";
    feeAmount: number = 0;
    dueDate: Date = new Date();
    paymentDate: Date = new Date();
    paymentAmount: number = 0;
    paymentMethod: string = "";
    lateFee: number = 0;
    status: string = "";

    constructor(data: any) {
        this.userFullName = data.UserFullName || "";
        this.studentID = data.StudentID || 0;
        this.className = data.ClassName || "";
        this.classID = data.ClassID || 0;
        this.feeID = data.FeeID || 0;
        this.feeType = data.FeeType || "";
        this.feeAmount = data.FeeAmount || 0;
        this.dueDate = data.DueDate || new Date();
        this.paymentDate = data.PaymentDate || new Date();
        this.paymentAmount = data.PaymentAmount || 0;
        this.paymentMethod = data.PaymentMethod || "";
        this.lateFee = data.LateFee || 0;
        this.status = data.Status || "";
    }
}


export class StudentFeeRequest {
    classID: number;
    fromDate: Date;
    toDate: Date;
    status: string 
    studentId: string 
    constructor() {
        this.classID = 0;
        this.fromDate = new Date();
        this.toDate = new Date();
        this.status = ""
        this.studentId  = ""
    }
}
