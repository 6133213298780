import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { BaseComponent } from 'app/Shared/base-component';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { RamazanPeriod } from 'app/Classes/ramazan-period';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
@Component({
  selector: 'app-ramadan-period',
  templateUrl: './ramadan-period.component.html',
  styleUrls: ['./ramadan-period.component.css']
})
export class RamadanPeriodComponent extends BaseComponent implements OnInit {

  RamazanPeriod: RamazanPeriod;
  listRamazanPeriod: RamazanPeriod[];
  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) { 
      super(languageTranslateService);
    this.RamazanPeriod = new RamazanPeriod();
    this.listRamazanPeriod = [];
    }

  ngOnInit() {
    this.GetAllRamadanPeriods_ByCompany();
  }
  
  displayedColumns: string[] = ['selected', 'FromDate', 'ToDate', 'edit'];
  dataSource = new MatTableDataSource<RamazanPeriod>();
  selection = new SelectionModel<RamazanPeriod>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  edit(id: any){
   this.RamazanPeriod =  this.dataSource.data.filter( x => x.ramadanPeriodId)[0];
  }
  select(id: any){
    for(var i = 0 ; i < this.dataSource.data.length ; i++){
      if(id ==  this.dataSource.data[i].ramadanPeriodId){
        this.dataSource.data[i].selected = !this.dataSource.data[i].selected;
      }
    }
  }
  initialValidations(){
    var s = "";
    let d1 = new Date(this.RamazanPeriod.toDate);
    let d2 = new Date(this.RamazanPeriod.fromDate);
    if(d2 > d1){
      s = "To Date must be greater than or equal From Date";
    }
    return s;
  }
  Save(){
    this.RamazanPeriod.fk_CompanyId = this.global.FK_CompanyId();
    var isError = this.initialValidations();
    if (isError == "") {
      this._svc.SaveObject(this.RamazanPeriod, "RamadanPeriod/SaveRamadanPeriod").subscribe(
        data => {
          ;
          if (data.includes("msgid=0")) {
            this.global.openDialog("Ramadan Period", "Record has been saved.")
            this.GetAllRamadanPeriods_ByCompany();
            this.Clear();
          }
          else if (data.includes("Success")) {
            this.global.openDialog("Ramadan Period", "Record has been saved.")
            this.GetAllRamadanPeriods_ByCompany();
            this.Clear();
          }
          else if (data.includes("NameExists")) {
            this.global.openDialog("Ramadan Period", "English Name Already Exists");
          }
          else if (data.includes("Name_LLExists")) {
            this.global.openDialog("Ramadan Period", "Local Language Name Already Exists");
          }
          else {
            this.global.openDialog("Ramadan Period", "Error While Saving");
          }
        }, (err) => {
          this.global.openDialog("Ramadan Period", "Record has been saved.")
        });
    }
    else {
      this.global.openDialog("Ramadan Period", "Please fill the mentioned fields : " + isError)
    }
  }
  Clear(){
    this.RamazanPeriod = new RamazanPeriod();
  }

  ShowConfirmDialog(): void {

    var tempList = this.dataSource.data.filter(t => t.selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("Ramadan Period", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.Delete();
        }
      });
    }
    else {
      this.global.openDialog("Ramadan Period", "No Record selected.");
    }
  }
  Delete() {

    var tempList = this.dataSource.data.filter(t => t.selected == true);
    if (tempList.length > 0) {
      var j = 0;
      for (var i = 0; i < tempList.length; i++) {
        this._svc.DeleteGenericParmas(tempList[i].ramadanPeriodId, 'RamadanPeriodId', 'RamadanPeriod/DeleteRamadanPeriod', "TA").subscribe(
          data => {
            if (j == 0) {
              this.Clear();
              this.GetAllRamadanPeriods_ByCompany();
              this.global.openDialog("Work Schedule", "Record has been Deleted.");
            }
            j++
          }, (err) => {
            this.global.openDialog("Ramadan Period", "Some Error Occured.");
          });
      }
    }
    else {
      this.global.openDialog("Ramadan Period", "No Record selected.");
    }
  }
  GetAllRamadanPeriods_ByCompany(){
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "RamadanPeriod/GetAllRamadanPeriods_ByCompany", "TA").subscribe(
      data => {
        ;
        this.listRamazanPeriod = [];
        this.listRamazanPeriod = data;
        for (var i = 0; i < this.listRamazanPeriod.length; i++) {
          this.listRamazanPeriod[i].selected= false;
        }
        this.dataSource.data = this.listRamazanPeriod;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, (err) => {
        // alert("error occured ")
      });

  }
}
