export class Groups {
}

export class Sys_Groups {
    groupID: number;
    fK_CompanyId: number;
    defaultPage: number;
    groupName_LL: string;
    groupName: string;
    priviliges: Sys_Priviliges[];
    select : boolean;
    constructor() {
        this.groupID = 0;
        this.fK_CompanyId = 0;
        this.defaultPage = 0;
        this.groupName_LL = "";
        this.groupName = "";
        this.priviliges = [];
        this.select = false;
    }
}

export class Sys_Priviliges
{
    groupID : number;
    formID : number;
    allowAdd : number;
    allowSave : number;
    allowView : number;
    allowDelete : number;
    allowPrint : number;
/**
 *
 */
constructor() {
    this.groupID =0 ;
    this.formID =0 ;
    this.allowAdd =0 ;
    this.allowSave =0 ;
    this.allowView =0 ;
    this.allowDelete =0 ;
    this.allowPrint =0 ;


}
}