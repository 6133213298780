import { Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Employee_Status } from 'app/Classes/employee';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';

@Component({
  selector: 'app-employee-status',
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.css']
})
export class EmployeeStatusComponent  extends BaseComponent implements OnInit {

  lstEmployee_Status: Employee_Status[];
  objEmployee_Status: Employee_Status;

  constructor(public languageTranslateService: LanguageTranslateService,public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.lstEmployee_Status = [];
    this.objEmployee_Status = new  Employee_Status();
}

displayedColumns: string[] = ['select', 'statusCode', 'statusName', 'statusName_LL','cosiderEmployeeActive','edit'];
dataSource = new MatTableDataSource<Employee_Status>();
  selection = new SelectionModel<Employee_Status>(true, []);
  ngOnInit() {
  this.GetEmployeeStatusByCompany();
  }
  GetEmployeeStatusByCompany(){
    
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId','EmployeeStatus/GetEmployeeStatusByCompany').subscribe(
      data => {
        ;

        this.lstEmployee_Status= data;
        if(this.lstEmployee_Status.length > 0){
        for(var i = 0 ; i< this.lstEmployee_Status.length ; i++){
          this.lstEmployee_Status[i].select = false;
        }
        this.dataSource.data = data;
      }
      else {
        this.global.openDialog("Employee Status","No record found.");
      }
      }, (err) => {
        this.global.openDialog("Employee Status","Some Error Occured.");
      }); 
  
  }
Validate(){
  var s = "";
  if(this.global.isStringNullOrEmplty(this.objEmployee_Status.statusCode)){
    s = "Status Code ,"
  }
  if(this.global.isStringNullOrEmplty(this.objEmployee_Status.statusName)){
    s = s + "Status Name ,"
  }
  if(this.global.isStringNullOrEmplty(this.objEmployee_Status.statusName_LL)){
    s = s + "Status Name Local Language ,"
  }
  if(this.global.isStringNullOrEmplty(this.objEmployee_Status.statusDescription)){
    s = s + "Status Description ,"
  }

  return s; 
}
  SaveEmployeeStatus(){
    
    var s = this.Validate();
    if(s == ""){
    this.objEmployee_Status.fK_CompanyId = this.global.FK_CompanyId();
    this._svc.SaveEmployeeStatus(this.objEmployee_Status,'EmployeeStatus/SaveEmployeeStatus').subscribe(
      data => {
        ;
        this.Clear();
        this.GetEmployeeStatusByCompany();
        this.global.openDialog("Employee Status","Record has been saved.");
      }, (err) => {
        this.global.openDialog("Employee Status","Some Error Occured.");
      }); 
    } else {
      this.global.openDialog("Employee Status","Please fill the following " + s);
    }
  }
  Clear(){
    this.objEmployee_Status = new  Employee_Status()
  }
  checkEmpStatus(id: any){

  }
  editEmpStatus(id: any){
    this.objEmployee_Status = this.lstEmployee_Status.filter(x=> x.statusId == id)[0] 
  }

  CosiderEmployeeActive(lookUpValueId: any){

  }
  edit(){
    
  }
  selectEmpStatus(row: any) {
    ;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].statusId == row) {
        if (this.dataSource.data[i].select) {
          this.dataSource.data[i].select = false;
        }
        else {
          this.dataSource.data[i].select = true;
        }
      }
    }
  }

  ShowConfirmDialog(): void {

    const message = 'Are you sure you want to delete selected Records?';
    const dialogData = new CustomConfirmDialogModel("Application  ", message);
    const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
      width: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


      if (dialogResult) {
        this.DeleteEmployeeStatus();
      }
    });
  }


  

  DeleteEmployeeStatus(){
    
  for (var i = 0; i < this.dataSource.data.length; i++) {
    if (this.dataSource.data[i].select == true) {
      this._svc.DeleteGenericParmas(this.dataSource.data[i].statusId, 'StatusId', 'EmployeeStatus/DeleteEmployeeStatus').subscribe(
        data => {
          ;
          this.GetEmployeeStatusByCompany();
          this.global.openDialog("Employee Status","Record has been deleted.");
        }, (err) => {
          this.global.openDialog("Employee Status","Some Error Occured.");
        });
    }
  }
  
}

}
