export class TAPolicy {
    Selected: boolean;
    taPolicyId: number;
    fK_CompanyId: number;
    taPolicyName: string;
    taPolicyName_LL: string;
    graceIn: number;
    graceOut: number;
    delayIsFromGrace: boolean;
    earlyOutIsFromGrace: boolean;
    consider_FILO_Only: boolean;
    considerAbsentIfNotCompleteNoOfHours: boolean;
    beforeAfterSchedule: number;
    noOfNotCompleteHours: number;
    active: boolean;
    oT_Eligibility: boolean;
    oT_HasHighTime: boolean;
    fK_WorkDay_OT_TypeId: number;
    fK_OffDay_OT_TypeId: number;
    fK_OTHoliday_OT_TypeId: number;
    fK_ReligionHoliday_OT_TypeId: number;
    createD_BY: string;
    lasT_UPDATE_BY: string;
    createD_DATE: Date;
    lasT_UPDATE_DATE: Date;
    /**
     *
     */
    constructor() {
        this.taPolicyId = 0;
        this.fK_CompanyId = 0;
        this.taPolicyName = "";
        this.taPolicyName_LL = "";
        this.graceIn = 0;
        this.graceOut = 0;
        this.delayIsFromGrace = false;
        this.earlyOutIsFromGrace = false;
        this.consider_FILO_Only = false;
        this.considerAbsentIfNotCompleteNoOfHours = false;
        this.beforeAfterSchedule = 0;
        this.noOfNotCompleteHours = 0;
        this.active = false;
        this.oT_Eligibility = false;
        this.oT_HasHighTime = false;
        this.fK_WorkDay_OT_TypeId = 0;
        this.fK_OffDay_OT_TypeId = 0;
        this.fK_OTHoliday_OT_TypeId = 0;
        this.fK_ReligionHoliday_OT_TypeId = 0;
        this.createD_BY = "";
        this.lasT_UPDATE_BY = "";
        this.Selected = false;
    }
}
export class TAPolicy_Break {
    breakId: number;
    fK_CompanyId: number;
    fK_PolicyId: number;
    fK_lkpBreakType: number;
    fK_AttendanceReasonId: number;
    isFlixible: boolean;
    flixibleDuration: number;
    fromTime: number;
    toTime: number;
    DisplayfromTime: string;
    DisplaytoTime: string;
    toBeCompensated: boolean;
    noOfAllowedBreak: number;
    Selected : boolean;
    /**
     *
     */
    constructor() {
        this.breakId = 0;
        this.fK_CompanyId = 0;
        this.fK_PolicyId = 0;
        this.fK_lkpBreakType = 0;
        this.fK_AttendanceReasonId = 0;
        this.isFlixible = false;
        this.flixibleDuration = 0;
        this.fromTime = 0;
        this.toTime = 0;
        this.toBeCompensated = false;
        this.noOfAllowedBreak = 0;
        this.Selected = false;
        this.DisplayfromTime = "";
        this.DisplaytoTime = "";
    }
}

export class TAPolicy_Voilation {
    violationName: string;
    violationName_LL: string;
    fK_CompanyId: number;
    violationId: number;
    fK_TAPolicyId: number;
    violationRuleType: number;
    variable1: number;
    variable2: number;
    variable3: number;
    scenarioMode: number;
    considerAbsent: boolean;
    cREATED_BY: string;
    lAST_UPDATE_BY: string;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_CompanyId = 0;
        this.violationId = 0;
        this.fK_TAPolicyId = 0;
        this.violationName = "";
        this.violationName_LL = "";
        this.violationRuleType = 0;
        this.variable1 = 0;
        this.variable2 = 0;
        this.variable3 = 0;
        this.scenarioMode = 0;
        this.considerAbsent = false;
        this.cREATED_BY = "";
        this.lAST_UPDATE_BY = "";
        this.Selected = false;
    }
}
export class TAPolicy_ViolationActions{
    fK_CompanyId	: number;
    violationId	: number;
    actionSequence	: number;
    fK_lkpViolationActionId	: number;
    Selected : boolean
    /**
     *
     */
    constructor() {
        this.fK_CompanyId = 0;
        this.violationId = 0;
        this.actionSequence = 0;
        this.fK_lkpViolationActionId = 0;
        this.Selected = false;
    }

}
