// export const DROPDOWN_LANGS = ['English',  'Arabic']
// export const BUTTONS = ['Save','Delete','Clear','Cancel','Update']
// export const ApplicationSetting =['Application Settings' , 'Application Settings' , 'Customer English Name' ,'Customer English Short Name', 'Customer Arabic Name', 'Customer Arabic Short Name']
// export const ApplicationType = 'Application Type'
// export const Login = 'Log in'
// export const LookUpValue = 'اسم البحث'
// export const LookUpValueCode = 'كود البحث'
// export const OrgUnit = "الوحدة التنظيمية"; 
// export const Group  = "مجموعة"
// export const EmployeeNo = 'رقم الموظف'
// export const Search = 'بحث';
// export const AddNew = 'اضف جديد';
// export const Clear = 'مٹانا';
// export const Delete = 'ڈیلیٹ';
// export const UserName  = 'اسم المستخدم';
// export const FullName = "الاسم الكامل" 
// export const Edit = "ترمیم"
// export const Save = 'محفوظ کریں';  
// export const addLanguage = "إضافة لغة"
// export const ModalHeader ="إضافة لغة"
// export const AddNewUser ="إضافة مستخدم جديد"
// export const Organization ="منظمة"
// export const Name = "اسم" 
// export const ShortName = "اسم قصير"
// export const Address = "عنوان"
// export const PhoneNo = "رقم الهاتف"
// export const Fax = "فاكس"
// export const URL = "URL"
// export const  Level ="مستوى "
// export const  LevelName="اسم المستوى"
// export const  ArabicName="الاسم العربي"

// export const OrganizationStructure = 'هيكلية المؤسسة'
// export const EnglishName ='الاسم بالانجليزية'
// export const EntityCode = 'رمز وحدة العمل'
// export const EntityInfo = 'معلومات وحدة العمل'


// export const UserID ="معرف المستخدم";
// export const Email = "البريد الإلكتروني"; 
// export const FullNameInlocalLanguage ="الاسم الكامل باللغة المحلية ";
// export const UniqueID ="معرف فريد";
// export const ConfirmPassword ="پاس ورڈ کی تصدیق کریں";
// export const Company = "شركة";
// export const PhoneNumber ="رقم الهاتف";
// export const OrganizationEntity ="الكيان التنظيمي"
// export const JobDescription = "المسمى الوظيفي";
// export const isActive ="نشط";

// export const languageName ="Language Name";
// export const LangId = "LangId";
// export const LangCode = "Lang Code";
// export const  lookUpValue_lang = "lookUpValue_lang";
// export const Country = "Country";
// export const orgUnit = "Org Unit";

// export const orgUnitName_LL = "orgUnitName Local Language";
// export const orgUnitName ="orgUnitName";
// export const orgUnitCode = "orgUnitCode";
// export const fK_LevelId = "fK_LevelId";
// export const WorkLocation = "Work Location"

// export const radius = "Radius";
// export const workLocationId = "Work Location Id";
// export const workLocationCode = "Work Location Code";
// export const workLocationName = "Work Location Name";
// export const workLocationName_LL = "Work Location Name";
// export const gPSCoordinates = "GPS Coordinates";

// export const logicalGroup ='logical Group';
// export const GroupId = "Group Id"
// export const GroupName = "گروپ";
// export const groupName_LL = "Group Name Local Language";

// export const statusCode = "Status Code"
// export const StatusName = "Status Name"
// export const StatusName_LL = "Status Name Local Language"
// export const StatusDescription = "Status Description"
// export const CosiderEmployeeActive = "Cosider Employee Active"


// export const  TypeName ="Type Name"
// export const  TypeName_LL ="Type Name Local Language"
// export const  IsInternaltype ="Is Internal Type"
// export const  EmployeeNumberInitial ="Employee Number Initial"


// export const GroupName_LL = "Group Name Local Language";
// export const  employeeName = "Employee Name"
// export const  joinDate = "Join Date"

// export const  FK_Status ="Status"
// export const  FK_EmployeeTypeId ="EmployeeTypeId"
// export const  FK_CompanyId ="اسکول کا نام"
// export const  FK_OrgUnitId ="اسکول برانچ کا نام"
// export const  FK_WorkLocation ="WorkLocation"
// export const  FK_LogicalGroup ="LogicalGroup"
// export const  FK_lkpNationality ="Nationality"
// export const  FK_lkpReligion ="Religion"
// export const  FK_lkpMaritalStatus ="MaritalStatus"
// export const  FK_lkpGrade ="Grade"
// export const  FK_lkpDesignation ="Designation"
// export const  IsTerminated ="Is Terminated"
// export const  EmployeeActiveCard ="Employee Active Card"
// export const  ExternalPartyName ="External Party Name"
// export const  employeeName_LL ="Employee Name Local Language"
// export const  Gender ="Gender"
// export const  OrgUnitHierarchy ="Org Unit Hierarchy"
// export const  Remarks ="دوسری معلومات"
// export const  EmpImagePath ="Emp Image Path"
// export const  MobileNo ="موبائل نمبر"
// export const  ContractEndDate ="Contract End Date"
// export const  DOB ="DOB"
// export const  TerminateDate ="Terminate Date"
// export const  select ="select"
// export const AddNewEmployee ="Add New Employee"
// export const CompanyName = "CompanyName";
// export const GroupID = 'GroupID';
// export const DefaultPage = 'DefaultPage'
// export const addUnit = 'Add Unit'
// export const AddNewGroup = 'Add New Group'
// export const Language = 'Language'
// export const DefaultLanguage = 'Default Language'
// export const LocalLanguage = 'Local Language'
// export const LanguageList = 'LanguageList'

// export const ScheduleName = "Schedule Name"
// export const ScheduleNameLocalLanguage = "Schedule Name Local Language"
// export const Normal = "Normal"
// export const Flexible = "Flexible"
// export const Open = "Open"
// export const GraceIn = "Grace In"
// export const GraceOut = "Grace Out"
// export const ConsiderDelayFromGrace = "Consider Delay From Grace"
// export const ConsiderEarlyOutFromGrace = "Consider Early Out From Grace"
// export const Default = "Default"
// export const Active = "Active"
// export const DelayIsFromGrace = "Delay Is From Grace"
// export const EarlyOutIsFromGrace = "Early Out Is From Grace"
// export const Selected = "Selected"
// export const IsRamadanSch ="Is RamadanS"
// export const IsDefault = "Is Default"
// export const AddRamazanSchedule = "Add Ramazan Schedule"
// export const scheduleType = "schedule Type"
// export const ViewRamazanSchedule = "View Ramazan Schedule"

// export const  TAPolicyName ="Policy Name"	
// export const TAPolicyName_LL ="Policy Name Local Language"	

// export const Consider_FILO_Only ="Consider FILO Only"
// export const ConsiderAbsentIfNotCompleteNoOfHours ="Consider Absent If Not Complete No Of Hours"
// export const BeforeAfterSchedule ="Before After Schedule"
// export const NoOfNotCompleteHours = "No Of Not Complete Hours"
// export const OT_Eligibility ="OT_Eligibility"
// export const OT_HasHighTime ="OT_HasHighTime"
// export const FK_WorkDay_OT_TypeId ="FK_WorkDay_OT_TypeId"	
// export const FK_OffDay_OT_TypeId	="FK_OffDay_OT_TypeId"
// export const FK_OTHoliday_OT_TypeId	="FK_OTHoliday_OT_TypeId"
// export const FK_ReligionHoliday_OT_TypeId = 'ReligionHoliday OT TypeId'
// export const TAPolicy ="TA Policy"
// export const PolicyBreak ="Policy Break"
// export const PolicyViolations ="Policy Violations"
// export const PolicyViolationActions ="Policy Violation Actions"

// export const flixibleDuration ="Flixible Duration";
// export const fromTime ="From Time";
// export const toTime ="To Time";
// export const isFlixible ="Is Flixible";
// export const toBeCompensated ="To Be Compensated";
// export const noOfAllowedBreak ="No Of Allowed Break";


// export const violationName = "Violation Name"
// export const violationName_LL = "Violation Name LL"
// export const violationRuleType = "Violation Rule Type"
// export const variable1 = "Variable 1"
// export const variable2 = "Variable 2"
// export const variable3 = "Variable 3"
// export const scenarioMode = "Scenario Mode"
// export const considerAbsent ="Consider Absent"

// export const violationId ="violationId"
// export const actionSequence	="actionSequence"
// export const fK_lkpViolationActionId ="fK_lkpViolationActionId"

// export const BeforeSchedule = "Before Schedule"
// export const AfterSchedule = "After Schedule"


// export const Add  = "Add"
// export const Remove = "Remove"
// export const Absent = "Absent"
// export const DelayEarlyOut = "Delay & Early Out"
// export const MissingInorOut = "Missing In or Out"

// export const Continuous ="Continuous"
// export const Seperated ="Seperated"
// export const Occuerence ="Occuerence"

// export const Notice  = "Notice"
// export const Warning  = "Warning"
// export const DeductfromSalary  = "Deduct from Salary"
// export const Suspension  = "Suspension"
// export const Dismiss  = "Dismiss"
// export const Overtime = "Overtime"

// export const WorkDay ="Work Day"
// export const OffDay ="Off Day"
// export const Holiday ="Holiday"
// export const ReligionHoliday ="Religion Holiday"
// export const leavestypes = "Leaves Types"
// export const occuerrence = "Occuerrence"
// export const duration = "Duration"
// export const leavesgrades = "Leaves Grades " 
// export const leavestypes_entity = "Leaves Types Entity"
// export const fK_LeaveTypeId = "Leave TypeId"
// export const maximumOccur ="Maximum Occur"

// export const maximumRamadanDuration = "Maximum Ramadan Duration"
// export const maximumDuration = "Maximum Duration"
// export const gradeBalance= "Grade Balance"

// export const leaveTypeCode = "Leave Type Code";
// export const leaveTypeName = "leave Type Name";
// export const leaveTypeName_LL = "Leave Type Name Local Language";
// export const isHourlyLeave = "Is Hourly Leave";
// export const balance = "Balance"
// export const monthlyBalancing = "Monthly Balancing";
// export const minDuration = "Min Duration"
// export const maxDuration = "Max Duration";
// export const minServiceDays = "Min Service Days";
// export const maxRoundBalance = "Max Round Balance";
// export const allowIfBalanceInsufficient = "Allow If Balance Insufficient";
// export const generalGuide = "General Guide";
// export const generalGuide_LL = "General Guide in Local Language";
// export const ParentLeaveType = "Parent Leave Type";
// export const allowedForSelfService = "Parent Leave Type"
// export const showRemainingBalance = "Show Remaining Balance"
// export const isNotificationException = "Is Notification Exception";
// export const attachmentIsMandatory = "Attachment IsvMandatory"
// export const remarksIsMandatory = "Remarks Is Mandatory";
// export const isSpecificGrade = "Is Specific Grade";
// export const isSpecificEntity = "Is Specific Entity";
// export const allowedGender = "Allowed Gender";
// export const allowForSpecificEmployeeType = "Allow For Specific Employee Type";
// export const EmployeeTypeId = "Employee Type Id";
// export const allowedBeforeDays = "Allowed Before Days";
// export const allowedAfterDays = "Allowed After Days";
// export const allowInRamadan = "Allow In Ramadan";
// export const leaveApproval_WF = "leave Approval WF"
// export const isAutoApprove = "Is Auto Approve"
// export const autoApproveAfter = "Auto Approve After";
// export const autoApprovePolicy = "Auto Approve Policy";
// export const balanceConsideration_TC = "Balance Consideration TC";
// export const ConsequenceTransactions ="Consequence Transactions"
// export const FirstInOut ="First In\Last Out"
// export const LastInFirstOut ="Last In\First Out"
// export const MinimumTimeBetweenTransactions ="Minimum Time Between Transactions"
// export const TAPolicyGraceBy ="TA Policy Grace By"
// export const WorkSchedule ="Work Schedule"
// export const LeaveWorkFlow ="Leave WorkFlow"
// export const ApplicationUsersType ="Application Users Type"
// export const SystemUsersOnly ="System Users Only"
// export const ActiveDirectoryOnly ="Active Directory Only"
// export const Both ="Both"
// export const ShowLoginPage ="Show Login Page"
// export const Yes ="Yes"
// export const No ="No"
// export const WeekStartDay ="Week Start Day"
// export const ConsiderAbsentAfter ="Consider Absent After"
// export const ConsiderAbsentEvenIfAttend ="Consider Absent Even If Attend"
// export const ShiftScheduleWithNoShifts ="Shift Schedule With No Shifts"
// export const RadioButtonsRestDay ="Radio Buttons Rest Day"
// export const DefaultWorkSchedule ="Default Work Schedule"
// export const WorkDaysMinutes ="Work Days Minutes"
// export const AutoPersonalHourlyLeaveForDelay ="Auto Personal Hourly Leave For Delay"
// export const AutoPersonalHourlyLeaveDuration ="Auto Personal Hourly Leave Duration"
// export const AutoPersonalHourlyLeaveForEarlyOut ="Auto Personal Hourly Leave For Early Out"
// export const ArchivingMonths ="Archiving Months"
// export const ShowDailyLeaveRequest ="Show Daily Leave Request"
// export const ShowDailyLeaveRequestLinkInViolationCorrection ="Show Daily Leave Request Link In Violation Correction"
// export const ShowHourlyLeaveRequestLinkInViolationCorrection ="Show Hourly Leave Request Link In Violation Correction"
// export const ConsiderLeaveOnOffDays ="Consider Leave On Off Days"
// export const ViolationJustificationDays ="Violation Justification Days"
// export const ExcludeFromViolationJustificationDaysChecklist ="Exclude From Violation Justification Days Check list"
// export const Holidays ="Holidays"
// export const DailyLeaves ="Daily Leaves"
// export const RestDay ="Rest Day"
// export const AutoPersonalHourlyLeaveForDuration ="Auto Personal Hourly Leave For Duration"
// export const SpecificEntity = "Specific Entity"
// export const employees = "Employees"
// export const Entity = "Entity"
// export const LogicalGroup = "Logical Group"
// export const EmployeeList = "Employee List"
// export const entityList = "Entity List"
// export const WorklocationsList = "Work Locations"
// export const Temporary = "Temporary"

// export const SpecificGroup = 'Specific Group'
// export const Worklocation ="Worklocation"
// export const entity ="Entity"
// export const Manager = "Manager"
// export const fk_ParentModule  ="Parent Module"
// export const moduleName  ='Module Name';
// export const seq ="Sequence"
// export const moduleName_Lang= "Parent Module"

// export const formID = "Form ID";
// export const formName ="Form Name";
// export const moduleID = "Module ID" ;
// export const formPath = "Form Path";
// export const formOrder = "Form Order" ;
// export const parentID = "Parent ID" ;
// export const visible ="Visible";
// export const hasMobile  = "Has Mobile";
// export const showToEmp = "Show To Emp";
// export const showToClient = "Show To Client";

// //----------------------- Student Statrt
// export const StudentName =" نام طالب علم "
// export const Weight ='(kg) وزن'
// //export const Gender = 'Gender'
// export const Height ='(cm) اونچائی'
// export const DateofBirth= 'تاریخ پیدائش'
// export const BloodGroup = 'بلڈ گروپ'
// export const BirthPlace = "پیدائش کی جگہ"
// export const Hand = 'Hand'
// export const AnyDisability = 'معذوری'
// export const Religion = 'مذہب'
// export const BForm ='B فارم'
// export const Nationality = 'Nationality'
// export const Picture ='تصویر'
// export const AddressHouseFlatStreet = 'پتہ (مکان / فلیٹ / گلی)'
// export const CityOrTown ='شہر / قصبہ'
// export const PostalCode ='Postal Code'
// //export const PhoneNo ='Phone No.'
// //export const MobileNo ='Mobile No'
// export const EmailAddress = 'ای میل اڈریس'
// export const School ='اسکول'
// export const TaxExemption ='Tax Exemption'
// export const ClassSection ='Class / Section'
// export const RegistrationNo ='Registration No'
// export const RegistrationDate ='Registration Date'
// export const JoiningDate ='Joining Date'
// export const RollNo = 'Roll No'
// export const PreviousSchool ='Previous School'
// export const PreviousClass ='Previous Class'
// export const TaxPayer ='Tax Payer'

// export const FatherName ='Father Name'
// export const FatherAddress ='Father Address'
// export const FatherCNIC ='Father C.N.I.C #'
// export const FatherNTN ='Father N.T.N'
// export const FatherMaritalStatus ='Father Marital Status'
// export const FatherMarried ='Father Married'
// export const FatherQualification ='Father Qualification'
// export const FatherOccupation ='Father Occupation'
// export const FatherCompany ='Father Company'
// export const FatherDepartment ='Father Department'
// export const FatherDesignation ='Father Designation'
// export const FatherAddressHouseFlatStreet ='Father Address (House/Flat/Street)'
// export const FatherCityTown ='Father City / Town'
// export const FatherPostalCode ='Father Postal Code'
// export const FatherPhoneNoLandline ='Father Phone No (Landline)'
// export const FatherMobileNo ='Father Mobile No'
// export const FatherEmailAddress ='Father Email Address'
// export const MotherName = 'Mother Name'
// export const MotherCNIC ='Mother C.N.I.C #'
// export const MotherNTN = 'Mother N.T.N'
// export const MotherMaritalStatus = 'Mother Marital Status'
// export const MotherQualification ='Mother Qualification'
// export const MotherOccupation ='Mother Occupation'
// export const MotherCompany ='Mother Company'
// export const MotherDepartment ='Mother Department'
// export const MotherDesignation ='Mother Designation'
// export const MotherAddressHouseFlatStreet ='Mother Address (House/Flat/Street)'
// export const MotherCityTown ='Mother City / Town'
// export const MotherPostalCode = 'Mother Postal Code'
// export const MotherPhoneNo ='Mother Phone No (Landline)'
// export const MotherMobileNo ='Mother Mobile No'
// export const MotherEmailAddress ='Mother Email Address'
// export const EmergencyContactName ='Emergency Contact Name'
// export const EmergencyRelation ='Emergency Relation (with student)'
// export const EmergencyPhoneNoLandline = 'EmergencyPhoneNo (Landline)'
// export const EmergencyMobileNo ='Emergency Mobile No'
// export const EmergencyAddress ='Emergency Address (House/Flat/Street)'
// export const EmergencyCityTown ='Emergency City / Town'
// export const studentProfile ='طالب علم کا پروفائل'
// export const StudentDetails ='طالب علم کی تفصیل'
// export const Password ="پاس ورڈ"
// export const JobDesc ="کام کی تفصیل"


// export const ClassName = "کلاس کا نام"
// export const Fee = "فیس";
// export const Medium = "میڈیم"
// export const Section = "سیکشن" 
// export const Female = "عورت";
// export const Male = "مرد"
// export const Other  = "دیگر"


// export const TeacherID = "استاد";
// export const TeacherFName = "Teacher Fisrt Name";
// export const TeacherLName = "Teacher Last Name";
// export const TeacherGender = "ٹیچر جنس";
// export const Teacher = "استاد";
// export const TeacherCNIC = "ٹیچر CNIC";
// export const TeacherPhoneNumber = "اساتذہ کا فون نمبر";
// export const TeacherAddress = "استاد کا پتہ";
// export const TeacherCity = "شہر کا نام";
// export const TeacherDistt = "ڈسٹرکٹ";
// export const TeacherType = "اساتذہ کی قسم";
// export const TeacherContactNumber = "رابطہ نمبر";
// export const TeacherEmailID = "ای میل آئی ڈی";
// export const SchoolID = "اسکول";
// export const TeacherEmergencyContactNumber = "ایمرجنسی رابطہ نمبر";
// export const TeacherEmergency_ContactName = "ایمرجنسی سے رابطہ کا نام";
// export const TeacherEmergency_relatioship = "Teacher Emergency Relatioship";
// export const TeacherPicture = "ٹیچر تصویر";
// export const TeacherPictureExt = "ٹیچر تصویر Ext";
// export const TeacherJoiningDate = "Teacher Joining Date";
// export const TeacherDOB = "تاریخ پیدائش";
// export const CreatedBy = "Created By";
// export const CreatedDate = "Created Date";
// export const LastUpdatedBy = "Last Updated By";
// export const LastUpdatedDate = "Last Updated Date";
// export const IsActive = "ایکٹو ";
// export const TeacherReligion = "ٹیچر کا مذہب";


// export const TeacherSpecialityID = "Teacher Speciality ID";
// export const FK_TeacherID = "استاد";
// export const FK_SubjectID = "مزمون";


// export const TeacherProfessionalRecordID = "ٹیچر پروفیشنل ریکارڈ";
// export const institutename = "انسٹی ٹیوٹ کا نام";
// export const TenureFromDate = "Tenure From Date";
// export const TenureToDate = "Tenure To Date";
// export const AppoaintedAS = "Appoainted AS";
// export const Lastdesignation = "Last Designation";
// export const Salary = "تنخواہ";
// export const TeacherProfessionalcertificate = "Teacher Professional Certificate";
// export const TeacherProfessionalcertificate_Ext = "Teacher Professional Certificate Ext";


// export const TeacherEducationalrecordID = "اساتذہ کا تعلیمی ریکارڈ";
// export const DegreeName = "ڈگری کا نام";
// export const InstituteName = "انسٹی ٹیوٹ کا نام";
// export const FromDate = "اس تاریخ سے";
// export const ToDate = "آج تک";
// export const MarksObtained = "مارکس حاصل";
// export const TotalMarks = "کل نمبر";
// export const Percntage = "فیصد";
// export const GradeObtained = "گریڈ حاصل کیا";
// export const DegreeImage = "ڈگری تصویر";
// export const DegreeImage_Ext = "Ext ڈگری تصویر";


// export const TeacherComplaintRecordID = "اساتذہ کی شکایت کا ریکارڈ";
// export const FK_ComplaintID = "شکایت";
// export const IsOtherThanTeacherComplaint = "دسری قسم کی شکائیت";
// export const TeacherOtherComplaint = "ٹیچر کی دوسری شکایت";
// export const ComplaintDetail = "شکایت کی تفصیل";

// export const TeacherSpeciality = "Teacher Speciality"
// export const TeacherProfessionalRecord = "ٹیچر پروفیشنل ریکارڈ"
// export const TeacherEducationalRecord = "اساتذہ کا تعلیمی ریکارڈ"
// export const TeacherComplaintRecord = "اساتذہ کی شکایت"

// export const Visiting = "وزیٹنگ";
// export const Permanent = "مستقل";


// export const teacherProfile ='استاد کا پروفائل'
// export const teacherName ="استاد کا نام"

// export const SubjectID = "ID مضمون";
// export const SubjectName = "کتاب کا نام";
// export const SubjectMedium = "کتاب میڈیم";
// export const FK_ClassID = "FK Class ID";
// export const FK_SchoolID = "FK School ID";

// export const StudentHistoryID = "Student History";
// export const StudentID = "Student";
// export const SchoolName = "School Name";
// export const Class = "Class";
// export const TotlaMarks = "Totla Marks";
// export const Grade = "Grade";
// export const PassingDate = "Passing Date";
// export const CertificateImage = "Certificate Image";


// export const StudentHistory = "Student History";

// export const StudentAttendanceID = "Student Attendance ID";
// export const FK_StudentID = "FK Student ID";
// export const ClassID = "Class ID";
// export const StudentAttendanceStatus = "Student Attendance Status";
// export const StudentAttendanceDate = "Student Attendance Date";
// export const LeaveID = "Leave ID";
// export const StudentComplaintID = "Student Complaint ID";
// export const ComplaintID = "Complaint ID";
// export const StudentTestResultID = "Student Test Result ID";
// export const TestID = "Test ID";
// export const ObtainedMarks = "Obtained Marks";
// export const Percentage = "Percentage";

// export const TestResult = "Test Result"
// export const StudentComplaint = "Student Complaint"
// export const StudentTestResult = "Student Test Result"
// export const StudentAttendance ="Student Attendance"
// export const Present = "Present"
// export const userType = "User Type"
// export const onLeave = "On Leave"
// export const AssignSubjectID = "AssignSubjectID";

// export const LeaveTypeID = "LeaveTypeID";
// export const LeaveTypeText = "LeaveTypeText";
// export const LeaveDateFrom = "LeaveDateFrom";
// export const LeaveDateTo = "LeaveDateTo";
// export const noOfdasy = "noOfdasy";
// export const AttachementDetails = "AttachementDetails";
// export const AttachementID = "AttachementID";
// export const TeacherLeaveID = "TeacherLeaveID";
// export const TeacherLeaveTypeID = "TeacherLeaveTypeID";
// export const TeacherLeaveTypeText = "TeacherLeaveTypeText";
// export const TeacherLeaveDateFrom = "TeacherLeaveDateFrom";
// export const TeacherLeaveDateTo = "TeacherLeaveDateTo";
// export const TeacherLeavenoOfdasy = "TeacherLeavenoOfdasy";
// export const TeacherAttachementDetails = "TeacherAttachementDetails";
// export const TeacherAttachementID = "TeacherAttachementID";

// export const assignmentID = "assignmentID";
// export const subjectID = "subjectID";
// export const assingmentMarks = "assingmentMarks";
// export const dueDate = "dueDate";
// export const createdDate = "createdDate";
// export const assigmnentFileName = "assigmnentFileName";
// export const createdBy = "createdBy";
// export const assignmentDetails = "assignmentDetails";
// export const assignmentSubject = "assignmentSubject";
// export const lastUpdatedby = "lastUpdatedby";
// export const lastupdatedDate = "lastupdatedDate";

// export const assignmentdetailID = "assignmentdetailID";
// export const fk_assignmentID = "fk_assignmentID";
// export const fk_studentID = "fk_studentID";
// export const fk_ClassID = "fk_ClassID";
// export const assignmentName = "assignmentName";
// export const studentsRemarks = "studentsRemarks";
// export const teacherRemarks = "teacherRemarks";


// export const noticeBoardAnnuncementID = "NoticeBoardAnnuncementID";
// export const fKClassID = "FKClassID";
// export const fKSubjectID = "FKSubjectID";
// export const showntoAll = "ShowntoAll";
// export const noticeFileName = "NoticeFileName";
// export const noticeBoardDetails = "NoticeBoardDetails";
// export const pinonTop = "PinonTop";
// export const modifiedBy = "ModifiedBy";
// export const modifiedDate = "ModifiedDate";

export const DROPDOWN_LANGS = ['English', 'Arabic']
export const BUTTONS = ['محفوظ کریں', 'حذف کریں', 'صاف', 'منسوخ کریں', 'اپ ڈیٹ']
export const ApplicationSetting = ['درخواست کی ترتیبات۔', 'درخواست کی ترتیبات۔', 'کسٹمر کا انگریزی نام۔', 'کسٹمر انگریزی مختصر نام۔', 'کسٹمر عربی نام', 'کسٹمر عربی مختصر نام']
export const Login = 'لاگ ان کریں';
export const LookUpValue = ' نام';
export const LookUpValueCode = ' کوڈ';
export const OrgUnit = "تنظیمی یونٹ";
export const Group = "گروپ";
export const EmployeeNo = 'ملازم نمبر';
export const Search = 'تلاش کریں';
export const AddNew = 'نیا شامل کریں';
export const Clear = 'صاف';
export const Delete = 'حذف کریں';
export const Save = 'محفوظ کریں';
export const UserName = 'صارف کا نام';
export const FullName = "پورا نام"
export const Edit = "ترمیم"
export const addLanguage = "زبان شامل کریں"
export const ModalHeader = "موڈل ہیڈر"
export const AddNewUser = "نیا صارف شامل کریں"
export const Organization = "تنظیم"
export const Name = "نام"
export const ShortName = "مختصر نام"
export const Address = "پتہ"
// export const PhoneNo = "فون نمبر"
export const Fax = "فیکس"
export const URL = "یو آر ایل"
export const Level = "سطح"
export const LevelName = "سطح کا نام"
export const ArabicName = "عربی نام"
export const OrganizationStructure = 'تنظیمی ڈھانچہ'
export const EnglishName = 'انگریزی نام'
export const EntityCode = 'ہستی کا کوڈ'
export const EntityInfo = 'ہستی کی معلومات'

export const UserID = "صارف کی شناخت";
export const Email = "ای میل";
export const FullNameInlocalLanguage = "مقامی زبان میں مکمل نام ";
export const UniqueID = "منفرد شناختی";
export const ConfirmPassword = "پاس ورڈ کی تصدیق کریں";
export const Company = "کمپنی";
export const PhoneNumber = "فون نمبر";
export const OrganizationEntity = "تنظیم کا وجود"
export const JobDescription = "کام کی تفصیل";
export const isActive = "فعال ہے"
export const Password = "پاس ورڈ"

export const languageName = "زبان"
export const LangId = "زبان کی شناخت"
export const LangCode = "زبان کا کوڈ"
export const lookUpValue_lang = "قدر کی زبان"
export const Country = "ملک"
export const orgUnit = "تنظیمی یونٹ"

export const orgUnitName_LL = "مقامی زبان میں یونٹ کا نام";
export const orgUnitName = "یونٹ کا نام";
export const orgUnitCode = "یونٹ کوڈ";
export const fK_LevelId = "ایف کے_لیول آئی ڈی";
export const CompanyName = "کمپنی کا نام";

export const OrgStructure = "تنظیمی ڈھانچہ";

export const id = "آئی ڈی"
export const HasChildren = "بچے ہیں";
export const parentid = "والدین کی شناخت ";
export const UnitName = "یونٹ کا نام";
export const value = "قدر";
export const ChildName = "بچے کا نام";
export const WorkLocation = "کام کی جگہ"


export const radius = "رداس";
export const workLocationId = "کام کی جگہ کی شناخت";
export const workLocationCode = "کام کے مقام کا کوڈ";
export const workLocationName = "کام کے مقام کا نام";
export const workLocationName_LL = "کام کے مقام کا نام";
export const gPSCoordinates = "جی پی ایس کوآرڈینیٹ"

export const logicalGroup = 'منطقی گروپ';
export const GroupId = "گروپ آئی ڈی"
export const GroupName = "گروہ کا نام";
export const GroupName_LL = "گروپ کا نام مقامی زبان";


export const statusCode = "اسٹیٹس کوڈ"
export const StatusName = "اسٹیٹس نام"
export const StatusName_LL = "اسٹیٹس نام مقامی زبان"
export const StatusDescription = "حالت کی تفصیل"
export const CosiderEmployeeActive = "ملازم کو فعال سمجھیں"

export const TypeName = "نام ٹائپ کریں"
export const TypeName_LL = "نام مقامی زبان ٹائپ کریں"
export const IsInternaltype = "اندرونی قسم ہے"
export const EmployeeNumberInitial = "ملازم نمبر ابتدائی"
export const EmployeeNumber = "ملازم کا نمبر"

export const employeeName = "ملازم کا نام"
export const joinDate = "تاریخ شمولیت"

export const FK_Status = "حیثیت "
export const FK_EmployeeTypeId = "ملازم ٹائپ آئی ڈی"
export const FK_CompanyId = "سکول کا نام"
export const FK_OrgUnitId = "برانچ کا نام"
export const FK_WorkLocation = "کام کی جگہ"
export const FK_LogicalGroup = "منطقی گروپ"
export const FK_lkpNationality = "قومیت"
export const FK_lkpReligion = "مذہب"
export const FK_lkpMaritalStatus ="ازدواجی حیثیت"
export const FK_lkpGrade = "گریڈ"
export const FK_lkpDesignation ="عہدہ"
export const IsTerminated = "ختم ہو گیا ہے"
export const EmployeeActiveCard ="ملازم فعال کارڈ"
export const ExternalPartyName = "بیرونی پارٹی کا نام"
export const employeeName_LL ="ملازم کا نام مقامی زبان"
export const Gender = "Gender"
export const OrgUnitHierarchy = "تنظیم یونٹ درجہ بندی"
export const Remarks = "ریمارکس"
export const EmpImagePath = "امپ امیج پاتھ"
// export const MobileNo = "موبائل نمبر"
export const ContractEndDate ="معاہدہ ختم ہونے کی تاریخ"
export const DOB = "پیدائش کی تاریخ"
export const TerminateDate = "تاریخ ختم کریں"
export const select = "منتخب کریں"
export const AddNewEmployee ="نیا ملازم شامل کریں"
export const addUnit = 'یونٹ شامل کریں'


export const GroupID = 'گروپ آئی ڈی';
export const DefaultPage ='ڈیفالٹ پیج'
export const AddNewGroup ='نیا گروپ شامل کریں'
export const Language = 'زبان'

export const DefaultLanguage ='ڈیفالٹ لینگویج'
export const LocalLanguage = 'مقامی زبان'
export const LanguageList = 'زبان کی فہرست'

export const ScheduleName = "شیڈول کا نام"
export const ScheduleNameLocalLanguage ="شیڈول نام مقامی زبان"
export const Normal = "نارمل"
export const Flexible ="لچکدار"
export const Open = "کھلا"
export const GraceIn ="گریس ان"
export const GraceOut ="گریس آؤٹ"
export const ConsiderDelayFromGrace = "فضل سے تاخیر پر غور کریں"
export const ConsiderEarlyOutFromGrace = "فضل سے جلد آؤٹ پر غور کریں"
export const Default = "ڈیفالٹ"
export const Active = "فعال"
export const DelayIsFromGrace = "تاخیر فضل سے ہے"
export const EarlyOutIsFromGrace ="ابتدائی آؤٹ فضل سے ہے"
export const Selected = "منتخب شدہ"
export const IsRamadanSch ="کیا رمضان ہے"
export const IsDefault = "ڈیفالٹ ہے"
export const AddRamazanSchedule ="رمضان شیڈول شامل کریں"
export const SaveRamazanSchedule ="رمضان شیڈول محفوظ کریں";
export const scheduleType ="شیڈول کی قسم"
export const ViewRamazanSchedule = "رمضان کا شیڈول دیکھیں"



export const TAPolicyName = "پالیسی کا نام"
export const TAPolicyName_LL ="پالیسی کا نام ایل ایل"

export const Consider_FILO_Only ="صرف FILO پر غور کریں"
export const ConsiderAbsentIfNotCompleteNoOfHours = "اگر گھنٹوں کی تعداد مکمل نہ ہو تو غیر حاضر ہونے پر غور کریں"
export const BeforeAfterSchedule = "شیڈول سے پہلے"
export const NoOfNotCompleteHours = "نہیں مکمل گھنٹے"
export const OT_Eligibility = "اوور ٹائم کے لیے اہل"
export const OT_HasHighTime = "زیادہ وقت اوور ٹائم ہے"
export const FK_WorkDay_OT_TypeId = "ورک ڈے او ٹی ٹائپ آئی ڈی"
export const FK_OffDay_OT_TypeId = "آف ڈے او ٹی ٹائپ آئی ڈی"
export const FK_OTHoliday_OT_TypeId = "چھٹیوں کی قسم کی شناخت"
export const FK_ReligionHoliday_OT_TypeId = 'مذہب کی چھٹیاں ٹائپ آئی ڈی'


export const TAPolicy = "ٹی اے پالیسی"
export const PolicyBreak = "پالیسی بریک"
export const PolicyViolations = "پالیسی کی خلاف ورزی"
export const PolicyViolationActions = "پالیسی کی خلاف ورزی کے اقدامات"



export const flixibleDuration ="فکسبل دورانیہ"
export const fromTime = "وقت سے";
export const toTime = "وقت پر";
export const isFlixible = "لچکدار ہے";
export const toBeCompensated ="معاوضہ دیا جائے";
export const noOfAllowedBreak ="اجازت نہیں وقفے کی";


export const violationName = "خلاف ورزی کا نام"
export const violationName_LL = "خلاف ورزی کا نام"
export const violationRuleType ="خلاف ورزی کے اصول کی قسم"

export const variable1 = "متغیر 1"
export const variable2 = "متغیر 2"
export const variable3 = "متغیر 3"
export const scenarioMode = "منظر نامہ"
export const considerAbsent = "غیر حاضر پر غور کریں"

export const violationId = "خلاف ورزی کی شناخت"
export const actionSequence = "عمل کی ترتیب"
export const fK_lkpViolationActionId = "ایل کے پی کی خلاف ورزی کی کارروائی کی شناخت"


export const BeforeSchedule = "شیڈول سے پہلے"
export const AfterSchedule = "شیڈول کے بعد"

export const Add = "شامل کریں"
export const Remove = "ختم"


export const fromDate = "اس تاریخ سے";
export const toDate = "تاریخ تک";


export const overtimeTypeName = "اوور ٹائم ٹائپ نام";
export const overtimeTypeName_LL = "اوور ٹائم ٹائپ نام مقامی زبان";
export const minOvertime = "کم وقت کے ساتھ";
export const maxOvertime = "زیادہ سے زیادہ وقت کے ساتھ۔";
export const overtimeRate = "اوور ٹائم ریٹ";
export const compensateToLeave = "چھوڑنے کے لیے معاوضہ";
export const mustRequested = "درخواست کی جائے";
export const approvalRequired = "منظوری درکار ہے";
export const minAutoApproveDuration = "کم از کم آٹو منظوری کا دورانیہ";;
export const approvalBy = "کی طرف سے منظوری";
export const allowEditOverTime = "اوور ٹائم میں ترمیم کی اجازت دیں۔";

export const overtimeCalculationConsideration = "اوور ٹائم حساب کتاب پر غور";
export const overtimeChangeValue = "اوور ٹائم چینج ویلیو";
export const Leave = "چھوڑو";

export const Absent = "غیر حاضر"
export const DelayEarlyOut = "تاخیر اور ابتدائی آؤٹ"
export const MissingInorOut = "اندر یا باہر غائب ہے"


export const Continuous = "مسلسل"
export const Seperated = "الگ"
export const Occuerence = "وقوع"

export const Notice = "نوٹس"
export const Warning = "انتباہ"
export const DeductfromSalary = "تنخواہ سے کٹوتی"
export const Suspension = "معطلی"
export const Dismiss = "برطرف"
export const Overtime = "اضافی وقت"

export const WorkDay = "کام کا دن"
export const OffDay = "چھٹی کا دن"
export const Holiday = "چھٹی"
export const ReligionHoliday = "مذہب کی چھٹی"
export const leavestypes = "چھٹیوں کی اقسام"
export const occuerrence = "قبضہ"
export const duration = "دورانیہ"
export const leavesgrades = "چھٹی کے درجات "
export const leavestypes_entity = "چھٹی کی اقسام ہستی"


export const fK_RelatedTAReason = "متعلقہ ٹیراسن"
export const allowedAfter = "بعد میں اجازت ہے"
export const defaultNoOfDays = "پہلے سے طے شدہ دن"
export const flexibleAllowedTime = "آسان اجازت شدہ وقت"
export const allowedBefore = "پہلے اجازت ہے"
export const overtimeValidityDays = "اوور ٹائم درستگی کے دن"

export const isConsiderInWork = "کام پر غور ہے"
export const allowedAfterSpecificTime = "مخصوص وقت کے بعد اجازت ہے۔"
export const allowedBeforeSpecificTime = "مخصوص وقت سے پہلے اجازت ہے۔"
export const notAllowedWithStudyNursing = "مطالعہ نرسنگ کے ساتھ اجازت نہیں ہے"
export const hasFullDayPermission = "فل ڈے کی اجازت ہے"
export const hasPermissionForPeriod = "مدت کے لیے اجازت ہے"
export const hasSpecificTime = "مخصوص وقت ہے"
export const hasFlexiblePermission = "آسانی سے اجازت ہے"
export const considerRequestWithinBalance = "بیلنس کے اندر درخواست پر غور کریں"
export const convertToLeave_ExceedDuration = "دورانیے کو چھوڑنے کے لیے تبدیل کریں"
export const mustHaveTransaction = "لازمی لین دین"
export const deductBalanceFromOvertime = "اوور ٹائم سے بیلنس کم کریں"
export const fK_LeaveTypeId = "چھٹی ٹائپ آئی ڈی"
export const maximumOccur = "زیادہ سے زیادہ واقع ہوتا ہے"

export const maximumRamadanDuration = "زیادہ سے زیادہ رمضان کا دورانیہ"
export const maximumDuration = "زیادہ سے زیادہ دورانیہ"

export const gradeBalance = "گریڈ بیلنس"


export const leaveTypeCode = "تعطیلات ٹائپ کوڈ";
export const leaveTypeName = "تعطیلات کا نام ٹائپ کریں";
export const leaveTypeName_LL = "تعطیلات کا نام مقامی زبان";
export const isHourlyLeave = "گھنٹہ کی چھٹی ہے";
export const balance = "بقیہ"
export const monthlyBalancing = "ماہانہ توازن";
export const minDuration = "کم سے کم دورانیہ"
export const maxDuration = "زیادہ سے زیادہ دورانیہ";
export const minServiceDays = "کم سے کم خدمت کے دن";
export const maxRoundBalance = "زیادہ سے زیادہ گول بیلنس";
export const allowIfBalanceInsufficient = "اگر بیلنس ناکافی ہو تو اجازت دیں";
export const generalGuide = "جنرل گائیڈ";
export const generalGuide_LL = "مقامی زبان میں عمومی رہنما";
export const ParentLeaveType = "والدین کی چھٹی کی قسم";
export const allowedForSelfService = "والدین کی چھٹی کی قسم"
export const showRemainingBalance = "بقایا بیلنس دکھائیں"
export const isNotificationException = "اطلاع کا استثنا ہے";
export const attachmentIsMandatory = "منسلکہ لازمی ہے"
export const remarksIsMandatory = "ریمارکس لازمی ہیں";
export const isSpecificGrade = "مخصوص گریڈ ہے";
export const isSpecificEntity = "مخصوص ہستی ہے";
export const allowedGender = "اجازت ہے جنس";
export const allowForSpecificEmployeeType = "مخصوص ملازم کی قسم کی اجازت دیں";
export const EmployeeTypeId = "ملازم کی قسم کی شناخت";
export const allowedBeforeDays = "دنوں سے پہلے اجازت ہے";
export const allowedAfterDays = "دنوں کے بعد اجازت ہے";
export const allowInRamadan = "رمضان میں اجازت دیں";
export const leaveApproval_WF = "منظوری ڈبلیو ایف چھوڑ دیں"
export const isAutoApprove = "آٹو اپرویو ہے"
export const autoApproveAfter = "آٹو اپرویو کے بعد";
export const autoApprovePolicy = "خودکار پالیسی منظور کریں";
export const balanceConsideration_TC = "بیلنس کنڈیشن ٹی سی";

export const isDailyLeaves = "روزانہ کی چھٹیاں ہیں" 
export const ConsequenceTransactions ="نتیجہ لین دین"
export const FirstInOut ="فرسٹ ان - لسٹ آؤٹ"
export const LastInFirstOut ="آخری میں \ پہلا آؤٹ"
export const MinimumTimeBetweenTransactions ="لین دین کے درمیان کم از کم وقت"
export const TAPolicyGraceBy ="TA پالیسی گریس بائی"
export const WorkSchedule ="کام کا شیڈول"
export const LeaveWorkFlow ="چھٹی ورک فلو"
export const ApplicationUsersType ="درخواست صارفین کی قسم"
export const SystemUsersOnly ="صرف نظام استعمال کرنے والے"
export const ActiveDirectoryOnly ="صرف ایکٹو ڈائریکٹری"
export const Both ="دونوں"
export const ShowLoginPage ="لاگ ان پیج دکھائیں"
export const Yes ="جی ہاں"
export const No ="نہیں"
export const WeekStartDay ="ہفتے کا آغاز دن"
export const ConsiderAbsentAfter ="بعد میں غیر حاضر ہونے پر غور کریں"
export const ConsiderAbsentEvenIfAttend ="غیر حاضری پر غور کریں یہاں تک کہ اگر حاضر ہوں"
export const ShiftScheduleWithNoShifts ="غیر شفٹوں کے شفٹ شیڈول"
export const RadioButtonsRestDay ="ریڈیو بٹن آرام کا دن"
export const DefaultWorkSchedule ="ڈیفالٹ ورک شیڈول"
export const WorkDaysMinutes ="کام کے دن منٹ"
export const AutoPersonalHourlyLeaveForDelay ="تاخیر کے لیے آٹو پرسنل فی گھنٹہ چھٹی"
export const AutoPersonalHourlyLeaveDuration ="آٹو پرسنل فی گھنٹہ چھٹی کا دورانیہ"
export const AutoPersonalHourlyLeaveForEarlyOut ="آٹو پرسنل فی گھنٹہ چھٹی"
export const ArchivingMonths ="آرکائنگ ماہ"
export const ShowDailyLeaveRequest ="روزانہ چھٹی کی درخواست دکھائیں"
export const ShowDailyLeaveRequestLinkInViolationCorrection ="خلاف ورزی کی اصلاح میں ڈیلی چھٹی کی درخواست کا لنک دکھائیں"
export const ShowHourlyLeaveRequestLinkInViolationCorrection ="خلاف ورزی کی اصلاح میں گھنٹہ چھوڑنے کی درخواست کا لنک دکھائیں"
export const ConsiderLeaveOnOffDays ="چھٹیوں کے دن پر غور کریں"
export const ViolationJustificationDays ="خلاف ورزی کے جواز کے دن"
export const ExcludeFromViolationJustificationDaysChecklist ="خلاف ورزی کے جواز کے دنوں کی چیک لسٹ سے خارج کریں"
export const Holidays ="چھٹیاں"
export const DailyLeaves ="روزانہ پتے"
export const RestDay ="آرام کا دن"
export const AutoPersonalHourlyLeaveForDuration ="مدت کے لیے آٹو پرسنل فی گھنٹہ چھٹی"
export const SpecificEntity = "مخصوص ہستی"
export const All ="تمام"
export const employees = "ملازمین"
export const Entity = "ہستی"
export const LogicalGroup = "منطقی گروپ"
export const EmployeeList = "ملازمین کی فہرست"
export const entityList = "ہستی کی فہرست"
export const WorklocationsList = "کام کے مقامات"
export const Temporary = "عارضی"
export const SpecificGroup = 'مخصوص گروپ'
export const Worklocation ="کام کی جگہ"
export const entity ="ہستی"
export const Manager = "منیجر"

export const fk_ParentModule  ="والدین ماڈیول"
export const moduleName  ='ماڈیول کا نام';
export const seq ="تسلسل"
export const moduleName_Lang= "والدین ماڈیول"


export const formID = "فارم آئی ڈی";
export const formName ="فارم کا نام";
export const moduleID = "ماڈیول آئی ڈی" ;
export const formPath = "فارم پاتھ۔";
export const formOrder = "فارم آرڈر" ;
export const parentID = "والدین کی شناخت" ;
export const visible ="نمودار";
export const hasMobile  = "موبائل ہے";
export const showToEmp = "ملازم کو دکھائیں";
export const showToClient = "کلائنٹ کو دکھائیں";



//----------------------- Student Statrt
export const StudentName ="طالب علم کا نام"
export const Weight ='وزن (kg)'
// export const Gender = 'جنس'
export const Height ='اونچائی(cm)'
export const DateofBirth= 'پیدائش کی تاریخ'
export const BloodGroup = 'بلڈ گروپ'
export const BirthPlace = "جائے پیدائش"
export const Hand = 'ہاتھ۔'
export const AnyDisability = 'کوئی بھی معذوری'
export const Religion = 'مذہب'
export const BForm ='بی فارم #'
export const Nationality = 'قومیت'
export const Picture ='تصویر'
export const AddressHouseFlatStreet = 'پتہ (مکان/فلیٹ/گلی)'
export const CityOrTown ='شہر / قصبہ'
export const PostalCode ='ڈاک کامخصوص نمبر'
export const PhoneNo ='فون نمبر.'
export const MobileNo ='موبائل نمبر'
export const EmailAddress = 'ای میل اڈریس'
export const School ='اسکول'
export const TaxExemption ='ٹیکس کی چھوٹ'
export const ClassSection ='کلاس / سیکشن'
export const RegistrationNo ='رجسٹریشن نمبر'
export const RegistrationDate ='رجسٹریشن کی تاریخ'
export const JoiningDate ='شامل ہونے کی تاریخ'
export const RollNo = 'رول نمبر'
export const PreviousSchool ='پچھلا سکول'
export const PreviousClass ='پچھلی کلاس'
export const TaxPayer ='ٹیکس ادا کرنے والا'

export const FatherName ='والد کا نام'
export const FatherAddress ='والد کا پتہ'
export const FatherCNIC ='والد سی این آئی سی#'
export const FatherNTN ='باپ این ٹی این'
export const FatherMaritalStatus ='باپ کی ازدواجی حیثیت'
export const FatherMarried ='باپ شادی شدہ'
export const FatherQualification ='باپ کی اہلیت'
export const FatherOccupation ='باپ کا پیشہ'
export const FatherCompany ='باپ کا کمپنی'
export const FatherDepartment ='باپ کا شعبہ'

export const FatherDesignation ='باپ کا عہدہ'
export const FatherAddressHouseFlatStreet ='والد کا پتہ (گھر/فلیٹ/گلی)'
export const FatherCityTown ='فادر سٹی / ٹاؤن'
export const FatherPostalCode ='والد کا پوسٹل کوڈ'
export const FatherPhoneNoLandline ='والد کا فون نمبر (لینڈ لائن)'
export const FatherMobileNo ='باپ موبائل نمبر'
export const FatherEmailAddress ='والد کا ای میل پتہ'
export const MotherName = 'ماں کا نام'
export const MotherCNIC ='ماں سی این آئی سی #'
export const MotherNTN = 'ماں این ٹی این '
export const MotherMaritalStatus = 'ماں کی ازدواجی حیثیت'
export const MotherQualification ='ماں کی اہلیت'
export const MotherOccupation ='ماں کا پیشہ'
export const MotherCompany ='مدر کمپنی'
export const MotherDepartment ='ماں کا شعبہ'
export const MotherDesignation ='ماں کا عہدہ'
export const MotherAddressHouseFlatStreet ='ماں کا پتہ (گھر/فلیٹ/گلی)'
export const MotherCityTown ='مدر سٹی / ٹاؤن'
export const MotherPostalCode = 'مدر پوسٹل کوڈ'
export const MotherPhoneNo ='مدر فون نمبر (لینڈ لائن)'
export const MotherMobileNo ='ماں موبائل نمبر'
export const MotherEmailAddress ='ماں کا ای میل پتہ'
export const EmergencyContactName ='ہنگامی رابطہ نام'
export const EmergencyRelation ='ہنگامی تعلقات (طالب علم کے ساتھ)'
export const EmergencyPhoneNoLandline = 'ایمرجنسی فون نمبر (لینڈ لائن)'
export const EmergencyMobileNo ='ایمرجنسی موبائل نمبر'
export const EmergencyAddress ='ہنگامی پتہ (گھر/فلیٹ/گلی)'
export const EmergencyCityTown ='ایمرجنسی سٹی / ٹاؤن'
export const studentProfile ='طالب علم کا پروفائل'
export const StudentDetails ='طالب علم کی تفصیلات'
export const JobDesc = "کام کی تفصیل";

export const ClassName = "کلاس کا نام"
export const Fee = "فیس";
export const Medium = "میڈیم"
export const Section = "سیکشن" 

export const Female = "عورت";
export const Male = "مرد"
export const Other  = "دیگر"

export const TeacherID = "استاد";
export const TeacherFName = "ستاد کا نام";
export const TeacherLName = "استاد کا آخری نام";
export const TeacherGender = "جنس ٹیچر";
export const Teacher = "استاد";
export const TeacherCNIC = "سیٹیچر سی این آئی";
export const TeacherPhoneNumber = "ٹیچر کا فون نمبر";
export const TeacherAddress = "استاد کا پتہ";
export const TeacherCity = " استادشہر";
export const TeacherDistt = "ضلع ٹیچر";
export const TeacherType = "ٹیچر کی قسم";
export const TeacherContactNumber = "استاد کا رابطہ نمبر";
export const TeacherEmailID = "ٹیچر کا ای میل آئی ڈی";
export const SchoolID = "سکول کی شناخت";
export const TeacherEmergencyContactNumber = "ٹیچر ایمرجنسی رابطہ نمبر";
export const TeacherEmergency_ContactName = "ٹیچر ایمرجنسی رابطہ نام";
export const TeacherEmergency_relatioship = "ٹیچر ایمرجنسی ریلیشن شپ";
export const TeacherPicture = "استاد کی تصویر";
export const TeacherPictureExt = "ٹیٹیچر کی تصویر ای ایکس";
export const TeacherJoiningDate = "استاد شمولیت کی تاریخ";
export const TeacherDOB = "استاد تاریخ پیدائش";
export const CreatedBy = "بنائی گئی";
export const CreatedDate = "تشکیل کی تاریخ";
export const LastUpdatedBy = "آخری اپ ڈیٹ شدہ از";
export const LastUpdatedDate = "آخری اپ ڈیٹ تاریخ";
export const IsActive = "فعال ہے";
export const TeacherReligion = "استاد مذہب";


export const TeacherSpecialityID = "اساتذہ کی خصوصی شناخت";
export const FK_TeacherID = "استاد";
export const FK_SubjectID = "مضمون";


export const TeacherProfessionalRecordID = "ٹیچر پروفیشنل ریکارڈ";
export const institutename = "انسٹی ٹیوٹ نامe";
export const TenureFromDate = "مدت سے تاریخ";
export const TenureToDate = "مدت آج تک";
export const AppoaintedAS = "اے ایس مقرر کیا گیا";
export const Lastdesignation = "آخری عہدہ";
export const Salary = "تنخواہ";
export const TeacherProfessionalcertificate = "ٹیچر پروفیشنل سرٹیفکیٹ";
export const TeacherProfessionalcertificate_Ext = "ٹیچر پروفیشنل سرٹیفکیٹ ای ایکس ٹی۔";


export const TeacherEducationalrecordID = "اساتذہ تعلیمی ریکارڈ";
export const DegreeName = "ڈگری کا نام";
export const InstituteName = "انسٹی ٹیوٹ کا نام";
export const FromDate = "اس تاریخ سے";
export const ToDate = "تاریخ تک";
export const MarksObtained = "مارکس حاصل";
export const TotalMarks = "کل مارکس";
export const Percntage = "فیصد";
export const GradeObtained = "گریڈ حاصل کیا";
export const DegreeImage = "ڈگری امیج";
export const DegreeImage_Ext = "ڈگری امیج ای ایکس ٹی";


export const TeacherComplaintRecordID = "اساتذہ کی شکایت کا ریکارڈ";
export const FK_ComplaintID = "شکایت";
export const IsOtherThanTeacherComplaint = "اساتذہ کی شکایت کے علاوہ ہے";
export const TeacherOtherComplaint = "استاد دوسری شکایت";
export const ComplaintDetail = "شکایت کی تفصیل";

export const TeacherSpeciality = "استاد کی خاصیت"
export const TeacherProfessionalRecord = "پیشہ ورانہ ریکارڈ"
export const TeacherEducationalRecord = "تعلیمی ریکارڈ"
export const TeacherComplaintRecord = "شکایت کا ریکارڈ"
export const Visiting = "دورہ کرنا";
export const Permanent = "مستقل";

export const teacherProfile ='ٹیچر پروفائل'
export const teacherName ="استاد کا نام"

export const SubjectID = "موضوع کی شناخت";
export const SubjectName = "موضوع کا نام";
export const SubjectMedium = "موضوع میڈیم";
export const FK_ClassID = "ایف کے کلاس آئی ڈی";
export const FK_SchoolID = "ایف کے سکول کی شناخت";
// ===================
export const StudentHistoryID = "طالب علم کی تاریخ کی شناخت";
export const StudentHistory = "طالب علم کی تاریخ";
export const StudentID = "طالب علم کی شناخت";
export const SchoolName = "سکول کا نام";
export const Class = "کلاس";
export const TotlaMarks = "ل مارکس";
export const Grade = "گریڈ";
export const PassingDate = "پاسنگ ڈیٹ";
export const CertificateImage = "سرٹیفکیٹ امیج";
export const StudentAttendanceID = "طالب علم کی حاضری کی شناخت";
export const FK_StudentID = "طالب علم کی شناخت";
export const ClassID = "جماعتی شناخت";
export const StudentAttendanceStatus = "طلباء کی حاضری کی حالت";
export const StudentAttendanceDate = "طلباء کی حاضری کی تاریخ";
export const LeaveID = "چھٹی کی شناخت";
export const StudentComplaintID = "طالب علم کی شکایت کی شناخت";
export const ComplaintID = "شکایت کی شناخت";
export const StudentTestResultID = "طالب علم ٹیسٹ کے نتائج کی شناخت";
export const TestID = "ٹیسٹ آئی ڈی";
export const ObtainedMarks = "مارکس حاصل کیے";
export const Percentage = "فیصد";

export const TestResult = "ٹیسٹ کا نتیجہ"
export const StudentComplaint = "طالب علم کی شکایت"
export const StudentTestResult = "طالب علم ٹیسٹ کے نتائج"
export const StudentAttendance ="طلبہ کی حاضری"

export const userType = "صارف کی قسم"
export const Present = "موجودہ"

export const onLeave = "چھٹی پر"
export const assignSubjectID = "مضمون کی شناخت تفویض کریں";

export const LeaveTypeID = "چھٹی ٹائپ آئی ڈی";
export const LeaveTypeText = "چھٹی کی قسم کا متن";
export const LeaveDateFrom = "چھٹی کی تاریخ";
export const LeaveDateTo = "چھٹی کی تاریخ";
export const noOfdasy = "دنوں کی تعداد";
export const AttachementDetails = "منسلکات کی تفصیلات";
export const AttachementID = "منسلکہ آئی ڈی";

export const TeacherLeaveID = "اساتذہ کی چھٹی کی شناخت";

export const TeacherLeaveTypeID = "اساتذہ کی چھٹی۔ٹائپ آئی ڈی";

export const TeacherLeaveTypeText = "اساتذہ کی چھٹی۔متن ٹائپ کریں";
export const TeacherLeaveDateFrom = "اساتذہ کی چھٹی۔تاریخ کا فارم";
export const TeacherLeaveDateTo = "اساتذہ کی چھٹی کی تاریخ";
export const TeacherLeavenoOfdasy = "اساتذہ کی چھٹیوں کی تعداد";
export const TeacherAttachementDetails = "ٹیچر اٹیچمنٹ کی تفصیلات";
export const TeacherAttachementID = "ٹیچر اٹیچمنٹ کی تفصیلات آئی ڈی";

export const assignmentdetailID = "اسائنمنٹ ڈیٹیل آئی آئی ڈی";
export const fk_assignmentID = "ایف کےاسائنمنٹ آئی ڈی";

export const fk_studentID = "ایف کے طالب علم کی شناخت";
export const fk_ClassID = "ایف کے کلاس آئی ڈی";
export const assignmentName = "اسائنمنٹ نام";
export const studentsRemarks = "طلباء ریمارکس";
export const teacherRemarks = "ٹیچر ریمارکس";
export const assignmentID = "اسائنمنٹ آئی ڈی";
export const subjectID = "موضوع کی شناخت";
export const assingmentMarks = "اسسمنٹ مارکس";
export const dueDate = "واجب الادا تاریخ";
export const createdDate = "تشکیل کی تاریخ";
export const assigmnentFileName = "اسائنمنٹ فائل نام";
export const createdBy = "بنائی گئی";
export const assignmentDetails = "تفصیلاتاسائنمنٹ";
export const assignmentSubject = "اسائنمنٹ کا موضوع";
export const lastUpdatedby = "آخری اپ ڈیٹ شدہ";
export const lastupdatedDate = "آخری تاریخ";
export const noticeBoardAnnuncementID = "نوٹس بورڈ انوینسمنٹ آئی ڈی";
export const fKClassID = "ایف کے کلاس آئی ڈی";
export const fKSubjectID = "یف کے سبجیکٹ آئی ڈی";
export const showntoAll = "تمام";
export const noticeFileName = "نوٹس فائل نام";
export const noticeBoardDetails = "نوٹس بورڈ تفصیلات";
export const pinonTop = "پنن ٹاپ";
export const modifiedBy = "ترمیم شدہ";
export const modifiedDate = "ترمیم شدہ تاریخ";
export const isOnline= "is Online";
export const LectureName= "Lecture Name"
export const CourseName= "Course Name"