import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Classes } from 'app/Classes/classes';
import { OrgUnit } from 'app/Classes/org';
import { teacher } from 'app/Classes/teacher';
@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})
export class ClassesComponent extends BaseComponent implements OnInit {

  objClasses: Classes;
  lstClasses: Classes[];
  lstOrgUnit: OrgUnit[];
  lstTeacher: teacher[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.objClasses = new Classes();
    this.lstClasses = [];
    this.lstOrgUnit = [];
    this.lstTeacher = [];
    this.value = new Date();
  }
  selectedDate: string;
  onDateSelected(d:any){
    debugger;
    this.value = d;
    var ti = d.getTime();
    var ofs = d.getTimezoneOffset();
    var nvewd =  new Date(ti + Math.abs(ofs) * 60000 )
    this.objClasses.classDate = nvewd.toISOString();
  }
  displayedColumns: string[] = ['select', 'ClassName', 'Fee', 'Active', 'Edit'];
  dataSource = new MatTableDataSource<Classes>();
  selection = new SelectionModel<Classes>(true, []);

  ngOnInit() {
    var d = new Date();
    var ti = d.getTime();
    var ofs = d.getTimezoneOffset();
    var nvewd =  new Date(ti + Math.abs(ofs) * 60000 )
    this.objClasses.classDate = nvewd.toISOString();

    this.GetOrgUnitByCompany_ForDropdown();
    this.getAll();
    this.getAllteacer();
  }
  getAllteacer(){
    this._svc.GetDetails('Teacher/GetAllTeacher').subscribe(
      data => {
          this.lstTeacher = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  GetOrgUnitByCompany_ForDropdown() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'OrgUnit/GetOrgUnitByCompany_ForDropdown').subscribe(
      data => {
        
        this.lstOrgUnit = data;
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
  }

  initialValideation(){

    if(this.global.isStringNullOrEmplty(this.objClasses.className)){
      return "Please enter Class Name.";
    }
    else if(this.global.isNumberNullOrEmplty(this.objClasses.fee)){
      return "Please enter Fee.";
    }
    else {
      return "";
    }
  }

  value: Date;
  selectedTime: string ="";
    timeSeleted(atr: any){
    debugger;
    this.selectedTime = atr;
    var temp = atr.split('-')
    this.objClasses.timeFrom = temp[0]
    this.objClasses.timeTo= temp[1]
  }

  Save(){
  var x = this.initialValideation() ;
  if (x != "") {
    this.global.openDialog("Classes", x)
  }
  else {
    this.objClasses.fee = this.global.convertNumbre(this.objClasses.fee);
    this.objClasses.fk_OrgUnitId = this.global.convertNumbre(this.objClasses.fk_OrgUnitId);
    this.objClasses.createdby = this.global.getUserID();
    this.objClasses.lastUpdatedby = this.global.getUserID();
    
    this._svc.Save(this.objClasses, 'Classes/SaveClasses').subscribe(
      data => {
        debugger;
        if (data == "Success") {
          this.global.openDialog("Classes", "Record has been saved.")
          this.getAllteacer();
          this.getAll();
        }
        else if (data == "Duplicate") {
          this.global.openDialog("Forms", "Name alreay exist.")
        }
        else {
          this.global.openDialog("Forms", "Error While Saving.")
        }
      }, (err) => {
        this.global.openDialog("Forms", "Error While Saving.")
      })
  }

}
clear() {
  this.objClasses = new Classes();
}
ConfirmDelete() {

}

select(id: any){
  for(var i = 0 ; i< this.dataSource.data.length ; i++ ){
    if(this.dataSource.data[i].classID == id){
      this.dataSource.data[i].select = !this.dataSource.data[i].select;
    }
  }
}
edit(id: any){
  debugger;
  this.objClasses = this.dataSource.data.filter(x => x.classID == id )[0]
  }

  getAll(){
    this._svc.GetDetails('Classes/GetAllClasses').subscribe(
      data => {
        ;
        this.lstClasses = data;
        if (this.lstClasses.length > 0) {


          for (var i = 0; i < this.lstClasses.length; i++) {
            this.lstClasses[i].select = false;
          }
          this.dataSource.data = this.lstClasses;
        } else {
          this.global.openDialog("Classes", "No record fund.");
        }
      }, (err) => {
        // alert("error occured ")
      });
  }

}
