export class FormAndModules {
}
export class BLL_SysForm{
    formID : number ;
    formName: string;
    moduleID : number ;
    formPath : string;
    formOrder: number ;
    parentID : number ;
    visible : number ;
    hasMobile  : boolean;
    showToEmp : boolean;
    showToClient :boolean;
    token : string;
    select : boolean;
}
export class Modules{
    moduleID : number;
    fK_ParentModule  : number;
    moduleName  : number;
    seq : number;
    select : boolean = false;
    moduleName_Lang : string 
}