export class RamazanPeriod {
    
        ramadanPeriodId : number 
        fk_CompanyId: number
        fromDate : Date;
        toDate : Date; 
        cREATED_BY :string
        selected: boolean;
    /**
     *
     */
    constructor() {
        this.ramadanPeriodId =0;
        this.fk_CompanyId = 0;
        var date = new Date();
        this.fromDate  = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate =  new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.cREATED_BY = "";
        this.selected = false;
    }
}
