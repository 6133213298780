import { Component, OnInit, ViewChild } from '@angular/core';
import { TeacherComplaintRecord } from 'app/Classes/teacher';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-complaint-for-teacher',
  templateUrl: './complaint-for-teacher.component.html',
  styleUrls: ['./complaint-for-teacher.component.css']
})
export class ComplaintForTeacherComponent extends BaseComponent implements OnInit {

  objTeacherComplaintRecord: TeacherComplaintRecord;
  lstTeacherComplaintRecord: TeacherComplaintRecord[];

  TeacherComplaintRecord_displayedColumns: string[] = ['select', 'complaintDetail', 'fK_ComplaintID', 'Edit'];
  TeacherComplaintRecord_dataSource = new MatTableDataSource<TeacherComplaintRecord>();
  TeacherComplaintRecord_selection = new SelectionModel<TeacherComplaintRecord>(true, []);
  @ViewChild(MatSort, { static: true }) TeacherComplaintRecord_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) TeacherComplaintRecord_paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);
 
    this.lstTeacherComplaintRecord =[];
    this.objTeacherComplaintRecord = new TeacherComplaintRecord();
  }

  ngOnInit() {
  }

}
