import { Component, OnInit } from '@angular/core';
import { Classes } from 'app/Classes/classes';
import { StudentAttendance } from 'app/Classes/student';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';

@Component({
  selector: 'app-attendence',
  templateUrl: './attendence.component.html',
  styleUrls: ['./attendence.component.css']
})
export class AttendenceComponent  extends BaseComponent implements OnInit {

  lstStudentAttendance: StudentAttendance[];
  objStudentAttendance: StudentAttendance;
  lstClasses: Classes[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.objStudentAttendance = new  StudentAttendance();
    this.lstClasses = [];
  }

  ngOnInit() {
    this.getAllClasses();
  }

  getAllClasses(){
    debugger
    var id = this.global.getUserID();
    this._svc.getGenericParmas(id,"fk_teacherID",'Classes/GetByTeacherId').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }

  getStudentsbyClassid(){
    debugger
    var id = this.global.getUserID();
    this._svc.getGenericParmas(this.objStudentAttendance.classID,"ClassID",'Student/SelectStudentByCLassID').subscribe(
      data => {

        this.lstStudentAttendance = [];
        this.lstStudentAttendance = data;
        this.lstStudentAttendance.filter( x=> x.studentAttendanceStatus = 'p')
      }, (err) => { 
      });
  }
  Change_attendance_Status(type: string , fK_StudentID : number){
    debugger;
    for(var x = 0; x< this.lstStudentAttendance.length ; x++){
      if(this.lstStudentAttendance[x].fK_StudentID == fK_StudentID){
        this.lstStudentAttendance[x].studentAttendanceStatus = type;
        break;
      }
    }
    //this.lstStudentAttendance.filter( x => x.studentAttendanceStatus  = type && x.fK_StudentID == fK_StudentID);
  }

  SaveAttendance(){
    debugger
    var id = this.global.getUserID();
   this.lstStudentAttendance.filter( x => x.teacherID = Number(id));
   this.lstStudentAttendance.filter( x => x.studentAttendanceDate = this.objStudentAttendance.studentAttendanceDate);
   this.lstStudentAttendance.filter( x => x.createdBy = id);
   this.lstStudentAttendance.filter( x => x.classID = this.objStudentAttendance.classID);
    this._svc.SaveList(this.lstStudentAttendance,'Student/AddUpdateStudentAttendance').subscribe(
      data => {
      debugger
     this.global.openDialog("Attendance","Attendance has been updated.")
      }, (err) => {
      });
  }
}
