export class Sys_users {
    userName: string;
    user_FullName: string;
    user_FullName_LL: string;
    userPwd: string;
    uniqueNumber: string;
    userEmail: string;
    phoneNo: string;
    jobDesc: string;
    cREATED_BY: string;
    token: string;
    lAST_UPDATE_BY: string;
    userId: number;
    userStatus: number;
    userType: number;
    isFirstLogin: number;
    fK_EmployeeId: string;
    fK_CompanyId: number;
    companyId: number;
    fK_OrgUnitId: number;
    fK_GroupId: number;
    userDefaultLAng: string;
    cREATED_DATE: Date;
    lastLoginDate: Date;
    lAST_UPDATE_DATE: Date;
    lastChange_Password: Date;
    active: number;
    remarks :string;
    groupIds :string;
    companyName :string;
    employeeNo :string;
    select: boolean;
    fK_LogicalGroup :number;
    mobileNo : string ;
    picture: string ;
    pictureExt: string ;
    courseId: number;
      /**
     *
     */
    constructor() {
        this.userName = "";
        this.user_FullName = "";
        this.user_FullName_LL = "";
        this.userPwd = "";
        this.uniqueNumber = "";
        this.userEmail = "";
        this.phoneNo = "";
        this.jobDesc = "";
        this.cREATED_BY = "";
        this.token = "";
        this.lAST_UPDATE_BY = "";
        this.companyId = 0;
        this.userId = 0;
        this.userStatus = 0;
        this.userType = 0;
        this.isFirstLogin = 0;
        this.fK_EmployeeId = "";
        this.fK_CompanyId = 0;
        this.fK_OrgUnitId = 0;
        this.userDefaultLAng = "";
        this.cREATED_DATE = new Date();
        this.lastLoginDate = new Date();
        this.lAST_UPDATE_DATE = new Date();
        this.lastChange_Password = new Date();
        this.active = 0;
        this.remarks = "";
        this.groupIds = "";
        this.companyName = "";
        this.employeeNo = "";
        this.fK_LogicalGroup = 0;
        this.select = false;
        this.fK_GroupId = 0;
        this.mobileNo = "";
        this.picture ="";
        this.pictureExt = "";
    }
}

export class Login {
    UserName: string;
    Password: string;
    UserId: number;
    constructor() {
        this.UserName = "";
        this.Password = "";
        this.UserId = 0;
    }
}

export class Menu {
    ApplicationName: string;
    modules: Modules[];
    isActive:boolean = true;
    /**
     *
     */
    constructor() {
        this.ApplicationName = '';
        this.modules = [];
    }
}
export class Modules {
    ModuleName: string;
    forms: Forms[];
    constructor() {
        this.ModuleName = '';
        this.forms = [];
    }
}
export class Forms {
    formName: string;
    formPath: string;
    formid : number;
    formOrder : number;
    selected: boolean;
    constructor() {
        this.formName = "";
        this.formPath = "";
        this.formid = 0;
        this.formOrder =0;
        this.selected = false;
    }
}

export class UserInput {
    userid: number;
    lang: number;
    constructor() {
        this.userid = 0;
        this.lang = 0;
    }
}


export class LoginInfo {
    UserId: number;
    IsError: number;
    CompanyId: number;
    OrgUnitName: string;
    OrgUnitId: string;
    User_FullName: string;
    UserEmail: string;
    EmployeeNo: string;
    CompanyName: string;
    ErrorMessage: string;

    constructor() {
        this.UserId = 0;
        this.IsError = 0;
        this.CompanyId = 0;
        this.OrgUnitName = "";
        this.OrgUnitId = "";
        this.User_FullName = "";
        this.UserEmail = "";
        this.EmployeeNo = "";
        this.CompanyName = "";
        this.ErrorMessage = "";
    }
}
export class Languages {
    langId: number;
    langCode: string;
    languageName: string;
    selected:boolean;
    /**
     *
     */
    constructor() {
        this.langId = 0;
        this.langCode = "";
        this.languageName = "";
        this.selected = false;
    }
}