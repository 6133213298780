import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-assing-ta-policy',
  templateUrl: './assing-ta-policy.component.html',
  styleUrls: ['./assing-ta-policy.component.css']
})
export class AssingTaPolicyComponent extends BaseComponent implements OnInit {

  SelectedEmpType: number = 1;
  Temporary: boolean = true;

  displayedColumns: string[] = ['selected', 'overtimeTypeName', 'overtimeTypeName_LL', 'minOvertime', 'maxOvertime', 'compensateToLeave', 'mustRequested', 'approvalRequired', 'minAutoApproveDuration', 'allowEditOverTime', 'edit'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
  }

  ngOnInit() {
  }
  RadioChanged(id: any, type: string) {
    if (type = "Employee") {
      this.SelectedEmpType = id;
    }
  }

  LogicalGroups = new FormControl();
  LogicalGroup: string[] = ['Group 1', 'Group 2', 'Group 3', 'Group 4'];
  EmployeeLists = new FormControl();
  EmployeeList: string[] = ['Employee 1', 'Employee 2', 'Employee 3', 'Employee 4'];
  entityLists = new FormControl();
  entityList: string[] = ['Entity 1', 'Entity 2', 'Entity 3', 'Entity 4'];
  WorklocationsLists = new FormControl();
  WorklocationsList: string[] = ['Work Location 1', 'Work Location 2', 'Work Location 3', 'Work Location 4'];


}