import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { OrgUnit, Org_logicalGroup, Org_WorkLocation, OrgCompany } from 'app/Classes/org';
import { Employee, Employee_Status, Employee_Type } from 'app/Classes/employee';
import { LookUpTable, LookUpTableValues } from 'app/Classes/lookup';
import { Sys_Groups } from 'app/Classes/groups';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent extends BaseComponent implements OnInit {

  ApplicationType = new FormControl('', [
    Validators.required
  ]);
  Applicant = new FormControl('', [
    Validators.required
  ]);
  ApplicationStatus = new FormControl('', [
    Validators.required
  ]);

  selectedValue: string = "";
  lstOrgUnit: OrgUnit[];
  Employee: Employee;
  objEmployee: Employee;
  lstEmployee: Employee[];
  lstSys_Groups: Sys_Groups[];
  lstOrg_logicalGroup: Org_logicalGroup[];
  lstOrg_WorkLocation: Org_WorkLocation[];
  lstEmployee_Status: Employee_Status[];
  LKPNationality: LookUpTableValues[];
  LKPReligion: LookUpTableValues[];
  LKPMaritalStatus: LookUpTableValues[];
  LKPGrade: LookUpTableValues[];
  LKPDesignation: LookUpTableValues[];

  lstEmployee_Type: Employee_Type[];
  LookUpTable: LookUpTable;
  FK_OrgUnitId: number = 0;
  FK_LogicalGroup: number = 0;
  FK_WorkLocation: number = 0;

  popFK_OrgUnitId: number = 0;
  popFK_EmployeeTypeId: number = 0;
  popFK_CompanyId: number = 0;
  popFK_Status: number = 0;
  popFK_WorkLocation: number = 0;
  popFK_LogicalGroup: number = 0;
  popFK_lkpNationality: number = 0;
  popFK_lkpReligion: number = 0;
  popFK_lkpMaritalStatus: number = 0;
  popFK_lkpGrade: number = 0;
  popFK_lkpDesignation: number = 0;
  OrgCompany: OrgCompany;


  displayedColumns: string[] = ['select', 'EmployeeNo', 'employeeName', 'joinDate', 'edit'];
  dataSource = new MatTableDataSource<Employee>();
  selection = new SelectionModel<Employee>(true, []);
  

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.lstOrgUnit = [];
    this.lstOrg_logicalGroup = [];
    this.lstOrg_WorkLocation = [];
    this.lstEmployee = [];
    this.lstEmployee_Status = [];

    this.LKPNationality = [];
    this.LKPReligion = [];
    this.LKPMaritalStatus = [];
    this.LKPGrade = [];
    this.LKPDesignation = [];
    this.objEmployee = new  Employee();  
    this.LookUpTable = new LookUpTable();
    this.OrgCompany = new OrgCompany;
    this.Employee = new Employee();
  }


  ngOnInit() {
    this.GetOrgUnitByCompany_ForDropdown();
    this.GetLogicalGroupByCompany();
    this.GetWorkLocationByCompany();
    this.SearchEmployeeByOrgUnit_Group_Location()
    this.CallDropdown();
  }

  GetLogicalGroupByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'LogicalGroup/GetLogicalGroupByCompany').subscribe(
      data => {
        
        this.lstOrg_logicalGroup = data;
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
  }


  GetAllGroupsByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Groups/GetAllGroupsByCompany').subscribe(
      data => {
        ;
        this.lstSys_Groups = data;
      }, (err) => {
        // alert("error occured ")
      });
  }

  GetOrgUnitByCompany_ForDropdown() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'OrgUnit/GetOrgUnitByCompany_ForDropdown').subscribe(
      data => {
        
        this.lstOrgUnit = data;
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
  }
  GetWorkLocationByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();

    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'WorkLocation/GetWorkLocationByCompany').subscribe(
      data => {
        
        this.lstOrg_WorkLocation = data;
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
  }
  SearchEmployeeByOrgUnit_Group_Location() {
    
    this.Employee.fK_OrgUnitId = this.FK_OrgUnitId;
    this.Employee.fK_LogicalGroup = this.FK_LogicalGroup;
    this.Employee.fK_WorkLocation = this.FK_WorkLocation;
    this.Employee.fK_CompanyId = this.global.FK_CompanyId();

    this._svc.SearchEmployeeByOrgUnit_Group_Location(this.Employee, 'Employee/SearchEmployeeByOrgUnit_Group_Location').subscribe(
      data => {
        
        this.lstEmployee = data;
        if(this.lstEmployee.length > 0){
        for (var i = 0; i < this.lstEmployee.length; i++) {
          this.lstEmployee[i].select = false;
        }

        this.dataSource.data = this.lstEmployee;
      }else 
      {
        this.global.openDialog("Employee","No record found.");
        this.dataSource.data = [];
      }
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });

  }

  // Modals 
  CallDropdown() {
    this.GetAllGroupsByCompany();
    // this.GetCompanyDetails();
    this.GetEmployeeStatusByCompany();
    this.GetEmployeeTypeByCompany();
    this.GetLookUpValuesByCompany('Nationality');
    this.GetLookUpValuesByCompany('Religion');
    this.GetLookUpValuesByCompany('Marital Status');
    this.GetLookUpValuesByCompany('Grade');
    this.GetLookUpValuesByCompany('Designation');
  }
  // GetCompanyDetails() {
  //   ;
  //   var companyid = this.global.FK_CompanyId();
  //   this._svc.getGenericParmas(companyid, 'CompanyId', 'Org/GetCompanyDetails').subscribe(
  //     data => {
  //       ;
  //       this.OrgCompany = data;
  //     }, (err) => {
  //       this.global.openDialog("Organization", "Some Error Occured.");
  //     });
  // }

  GetEmployeeStatusByCompany() {
  ;
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'EmployeeStatus/GetEmployeeStatusByCompany').subscribe(
      data => {
        this.lstEmployee_Status = data;
      }, (err) => {
      });

  }
  GetEmployeeTypeByCompany() {
    ;
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'EmployeeType/GetEmployeeStatusByCompany').subscribe(
      data => {
        
        this.lstEmployee_Type = data;
      }, (err) => {
        // alert("error occured ")
      });
  }
  GetLookUpValuesByCompany(LKP: any) {
    
    this.LookUpTable.FK_CompanyId = this.global.FK_CompanyId();
    this.LookUpTable.lang = Number(sessionStorage.getItem('lang'));
    this.LookUpTable.LKPName = LKP;
    this._svc.GetLookUpValuesByCompany(this.LookUpTable, 'LKP/GetLookUpValuesByCompany').subscribe(
      data => {
        
        if (LKP == 'Nationality') {
          ;
          this.LKPNationality = data;
        }
        else if (LKP == 'Religion') {
          ;
          this.LKPReligion = data;
        }
        else if (LKP == 'Marital Status') {
          ;
          this.LKPMaritalStatus = data;
        }
        else if (LKP == 'Grade') {
          ;
          this.LKPGrade = data;
        }
        else if (LKP == 'Designation') {
          ;
          this.LKPDesignation = data;
        }
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
  }
  validateEmployee(){
    var s = "";
    if(this.global.isStringNullOrEmplty(this.objEmployee.employeeName)){
      s = "Eployee Name,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.employeeName_LL)){
      s = s + "Employee Name in local language ,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.gender)){
      s = s + "Gender ,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.uniqueId)){
      s =  s +"unique Id ,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.mobileNo)){
      s = s +  "Mobile No ,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.employeeNo)){
      s = s + "Employee No,"
    }
    if(this.global.isStringNullOrEmplty(this.objEmployee.email)){
      s = s + "Email ,"
    }
    return s; 
  }

  SaveEmployee(){
   // Employee/SaveEmployee
   ;
var s = this.validateEmployee()
if(s == ""){
   this.objEmployee.fK_OrgUnitId = this.global.convertNumbre(this.popFK_OrgUnitId);
   this.objEmployee.fK_EmployeeTypeId = this.global.convertNumbre(this.popFK_EmployeeTypeId); 
   this.objEmployee.fK_CompanyId = this.global.FK_CompanyId();
   this.objEmployee.fK_Status = this.global.convertNumbre(this.popFK_Status);
   this.objEmployee.fK_WorkLocation = this.global.convertNumbre(this.popFK_WorkLocation);
   this.objEmployee.fK_LogicalGroup = this.global.convertNumbre(this.popFK_LogicalGroup);
   this.objEmployee.fK_lkpNationality = this.global.convertNumbre(this.popFK_lkpNationality);
   this.objEmployee.fK_lkpReligion = this.global.convertNumbre(this.popFK_lkpReligion);
   this.objEmployee.fK_lkpMaritalStatus = this.global.convertNumbre(this.popFK_lkpMaritalStatus);
   this.objEmployee.fK_lkpGrade = this.global.convertNumbre(this.popFK_lkpGrade);
   this.objEmployee.fK_lkpDesignation = this.global.convertNumbre(this.popFK_lkpDesignation);
  this.objEmployee.cREATED_BY = this.global.getUserID();
  this.objEmployee.lAST_UPDATE_BY = this.global.getUserID();
    this._svc.SearchEmployeeByOrgUnit_Group_Location(this.objEmployee, 'Employee/SaveEmployee').subscribe(
      data => {
        this.Clear();
        this.SearchEmployeeByOrgUnit_Group_Location();
        $("#closeModal").click();
        this.global.openDialog("Employee","Record has been saved.");
      }, (err) => {
        this.global.openDialog("Employee","Some Error Occured.");
      });
    } else {
      this.global.openDialog("Employee","Please fill the following fields " + s);
    }
  }
  edit(EID: any){
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '0';
    this.objEmployee = this.lstEmployee.filter( x => x.employeeId == EID)[0];
    this.popFK_OrgUnitId = this.global.convertNumbre(this.objEmployee.fK_OrgUnitId);
    this.popFK_EmployeeTypeId = this.global.convertNumbre(this.objEmployee.fK_EmployeeTypeId);
    this.popFK_CompanyId = this.global.convertNumbre(this.objEmployee.fK_CompanyId);
    this.popFK_Status = this.global.convertNumbre(this.objEmployee.fK_Status);
    this.popFK_WorkLocation = this.global.convertNumbre(this.objEmployee.fK_WorkLocation);
    this.popFK_LogicalGroup = this.global.convertNumbre(this.objEmployee.fK_LogicalGroup);
    this.popFK_lkpNationality = this.global.convertNumbre(this.objEmployee.fK_lkpNationality);
    this.popFK_lkpReligion = this.global.convertNumbre(this.objEmployee.fK_lkpReligion);
    this.popFK_lkpMaritalStatus = this.global.convertNumbre(this.objEmployee.fK_lkpMaritalStatus);
    this.popFK_lkpGrade = this.global.convertNumbre(this.objEmployee.fK_lkpGrade);
    this.popFK_lkpDesignation  = this.global.convertNumbre(this.objEmployee.fK_lkpDesignation);
  }
  Close(){
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';  
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '155';  
  }
  AddNew(){
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '0';
    this.Clear();
  }
  Clear(){
    this.objEmployee = new Employee();
    this.popFK_OrgUnitId = 0;
    this.popFK_EmployeeTypeId = 0;
    this.popFK_CompanyId = 0;
    this.popFK_Status = 0;
    this.popFK_WorkLocation = 0;
    this.popFK_LogicalGroup = 0;
    this.popFK_lkpNationality = 0;
    this.popFK_lkpReligion = 0;
    this.popFK_lkpMaritalStatus = 0;
    this.popFK_lkpGrade = 0;
    this.popFK_lkpDesignation  = 0;
  }
  selectEmpType(id: any){}
}
