import { Component, OnInit, ViewChild } from '@angular/core';
import { teacher, TeacherComplaintRecord, TeacherEducationalRecord, TeacherProfessionalRecord, TeacherSpeciality } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { StudentLeave } from 'app/Classes/student';

@Component({
  selector: 'app-studentleave',
  templateUrl: './studentleave.component.html',
  styleUrls: ['./studentleave.component.css']
})
export class StudentleaveComponent extends BaseComponent implements OnInit {
  objStudentLeave: StudentLeave;
  lstStudentLeave: StudentLeave[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService)
    this.objStudentLeave = new StudentLeave();
    this.lstStudentLeave = [];
  }
  displayedColumns: string[] = ['select', 'studentName', 'leaveTypeText', 'leaveDateFrom', 'leaveDateTo', 'noOfdasy', 'Edit'];

  dataSource = new MatTableDataSource<StudentLeave>();
  selection = new SelectionModel<StudentLeave>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.getStudentLeaveByStudentID();
  }

  edit(id: any) {
    this.objStudentLeave = this.lstStudentLeave.filter(x => x.leaveID == id)[0];
  }


  Save(){
    debugger;
    this.objStudentLeave.createdBy = this.global.getUserID();
    this.objStudentLeave.lastUpdatedBy = this.global.getUserID();
    
    this.objStudentLeave.createdDate = new Date(this.objStudentLeave.createdDate);
    this.objStudentLeave.createdDate = new Date(this.objStudentLeave.createdDate);
    this._svc.Save(this.objStudentLeave, 'Student/AddUpdateStudentLeave').subscribe(
      data => {
        if (data.includes("Success")) {
          this.getStudentLeaveByStudentID();
          this.global.openDialog("Students", "Record has been saved.");
        }
        else {
          this.global.openDialog("Students", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  getStudentLeaveByStudentID(){
    var id =this.global.getUserID();
    this._svc.getGenericParmas( id,"StudentID","Student/GetStudentLeaveByStudentID").subscribe(
      data => {
        debugger;
        this.lstStudentLeave = data;
        if (this.lstStudentLeave.length > 0) {
          for (var i = 0; i < this.lstStudentLeave.length; i++) {
            this.lstStudentLeave[i].select = false;
          }
          this.dataSource.data = this.lstStudentLeave;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Subject", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
}
