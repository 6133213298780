export class LeaveType {
    leaveTypeId: number;
    fK_CompanyId: number;
    leaveTypeCode: string;
    leaveTypeName = "";
    leaveTypeName_LL = "";
    isHourlyLeave: boolean;
    balance: number;
    monthlyBalancing: number;
    minDuration: number;
    maxDuration: number;
    minServiceDays: number;
    maxRoundBalance: number;
    allowIfBalanceInsufficient: boolean;
    generalGuide = "";
    generalGuide_LL = "";
    fK_ParentLeaveType: number;
    allowedForSelfService: boolean;
    showRemainingBalance: boolean;
    isNotificationException: boolean;
    attachmentIsMandatory: boolean;
    remarksIsMandatory: boolean;
    isSpecificGrade: boolean;
    isSpecificEntity: boolean;
    allowedGender: number;
    allowForSpecificEmployeeType: boolean;
    fK_EmployeeTypeId: number;
    allowedBeforeDays: number;
    allowedAfterDays: number;
    allowInRamadan: boolean;
    approvalRequired: boolean;
    leaveApproval_WF: number;
    isAutoApprove: boolean;
    autoApproveAfter: number;
    autoApprovePolicy: number;
    balanceConsideration_TC: number;
    cREATED_BY = "";
    lAST_UPDATE_BY = "";

    Selected: boolean;

    /**
     *
     */
    constructor() {
        this.leaveTypeId = 0;
        this.fK_CompanyId = 0;
        this.leaveTypeCode = "";
        this.leaveTypeName = "";
        this.leaveTypeName_LL = "";
        this.isHourlyLeave = false;;
        this.balance = 0;
        this.monthlyBalancing = 0;
        this.minDuration = 0;
        this.maxDuration = 0;
        this.minServiceDays = 0;
        this.maxRoundBalance = 0;
        this.allowIfBalanceInsufficient = false;
        this.generalGuide = "";
        this.generalGuide_LL = "";
        this.fK_ParentLeaveType = 0;
        this.allowedForSelfService = false;
        this.showRemainingBalance = false;
        this.isNotificationException = false;
        this.attachmentIsMandatory = false;
        this.remarksIsMandatory = false;
        this.isSpecificGrade = false;
        this.isSpecificEntity = false;
        this.allowedGender = 0;
        this.allowForSpecificEmployeeType = false;
        this.fK_EmployeeTypeId = 0;
        this.allowedBeforeDays = 0;
        this.allowedAfterDays = 0;
        this.allowInRamadan = false;
        this.approvalRequired = false;
        this.leaveApproval_WF = 0;
        this.isAutoApprove = false;
        this.autoApproveAfter = 0;
        this.autoApprovePolicy = 0;
        this.balanceConsideration_TC = 0;
        this.cREATED_BY = "";
        this.lAST_UPDATE_BY = "";
        this.Selected = false;
    }
}

export class Occurance {
    fK_LeaveTypeId: number;
    fK_DurationId: number;
    maximumOccur: number;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.fK_DurationId = 0;
        this.maximumOccur = 0;
        this.Selected = false;
    }

}

export class duration {

    fK_LeaveTypeId: number;
    fK_DurationId: number;
    maximumDuration: number;
    maximumRamadanDuration: number;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.fK_DurationId = 0;
        this.maximumDuration = 0;
        this.maximumRamadanDuration = 0;
        this.Selected = false;
    }

}

export class leavesgrades {

    fK_LeaveTypeId: number;
    fK_GradeId: number;
    gradeBalance: number;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.fK_GradeId = 0;
        this.gradeBalance = 0;
        this.Selected = false;
    }

}

export class leavestypes_entity {
    fK_LeaveTypeId: number;
    fK_EntityId: number;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.fK_EntityId = 0;
        this.Selected = false;


    }
}

export class LeaveTypes_Hourly {
    fK_LeaveTypeId: number;
    isConsiderInWork: boolean
    fK_RelatedTAReason: number
    allowedAfterSpecificTime: boolean
    allowedAfter: number
    allowedBeforeSpecificTime: boolean
    allowedBefore: number
    notAllowedWithStudyNursing: boolean
    hasFullDayPermission: boolean
    hasPermissionForPeriod: boolean
    defaultNoOfDays: number
    hasSpecificTime: boolean
    hasFlexiblePermission: boolean
    flexibleAllowedTime: number
    considerRequestWithinBalance: boolean
    convertToLeave_ExceedDuration: boolean
    mustHaveTransaction: boolean
    deductBalanceFromOvertime: boolean
    overtimeValidityDays: number
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.isConsiderInWork = false;
        this.fK_RelatedTAReason = 0;
        this.allowedAfterSpecificTime = false;
        this.allowedAfter = 0;
        this.allowedBeforeSpecificTime = false;
        this.allowedBefore = 0;
        this.notAllowedWithStudyNursing = false;
        this.hasFullDayPermission = false;
        this.hasPermissionForPeriod = false;
        this.defaultNoOfDays = 0;
        this.hasSpecificTime = false;
        this.hasFlexiblePermission = false;
        this.flexibleAllowedTime = 0;
        this.considerRequestWithinBalance = false;
        this.convertToLeave_ExceedDuration = false;
        this.mustHaveTransaction = false;
        this.deductBalanceFromOvertime = false;
        this.overtimeValidityDays = 0;
        this.Selected = false;
    }
}

export class LeaveType_Daily {
    fK_LeaveTypeId: number;
    isAnnual: boolean;
    excludeOffDays: boolean;
    excludeHolidays: boolean;
    allowedBeforeRestDays: boolean;
    Selected: boolean;
    /**
     *
     */
    constructor() {
        this.fK_LeaveTypeId = 0;
        this.isAnnual = false;
        this.excludeOffDays = false;
        this.excludeHolidays = false;
        this.allowedBeforeRestDays = false;
        this.Selected = false;

    }
}