import { Component, OnInit, ViewChild } from '@angular/core';
import { teacher, TeacherComplaintRecord, TeacherEducationalRecord, teacherLeave, TeacherProfessionalRecord, TeacherSpeciality } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
@Component({
  selector: 'app-teacher-leave',
  templateUrl: './teacher-leave.component.html',
  styleUrls: ['./teacher-leave.component.css']
})
export class TeacherLeaveComponent extends BaseComponent implements OnInit {

  objteacherLeave :teacherLeave;
  lstteacherLeave :teacherLeave[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService) 

    this.objteacherLeave =new teacherLeave();
    this.lstteacherLeave  =[];
  }

  displayedColumns: string[] = ['select'  ,'teacherName' ,'teacherLeaveTypeText' ,'teacherLeaveDateFrom'  ,'teacherLeaveDateTo'  
  ,'teacherLeavenoOfdasy' ,'Edit'];
  dataSource = new MatTableDataSource<teacherLeave>();
  selection = new SelectionModel<teacherLeave>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.GetLeaveByTeacherID();
  }
  
  Save(){
    debugger;
    this.objteacherLeave.createdBy = this.global.getUserID();
    this.objteacherLeave.lastUpdatedBy = this.global.getUserID();
    
    this.objteacherLeave.createdDate = new Date(this.objteacherLeave.createdDate);
    this.objteacherLeave.createdDate = new Date(this.objteacherLeave.createdDate);
    this._svc.Save(this.objteacherLeave, 'Student/AddUpdateStudentLeave').subscribe(
      data => {
        if (data.includes("Success")) {
          this.GetLeaveByTeacherID();
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }

  edit(id: any) {
    this.objteacherLeave = this.lstteacherLeave.filter(x => x.teacherLeaveID == id)[0];
  }
  GetLeaveByTeacherID(){
    var id =this.global.getUserID();
    this._svc.getGenericParmas( id,"TeacherID","Teacher/GetLeaveByTeacherID").subscribe(
      data => {
        debugger;
        this.lstteacherLeave = data;
        if (this.lstteacherLeave.length > 0) {
          for (var i = 0; i < this.lstteacherLeave.length; i++) {
            this.lstteacherLeave[i].select = false;
          }
          this.dataSource.data = this.lstteacherLeave;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Subject", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
}
