import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';

@Component({
  selector: 'app-createvideocourse',
  templateUrl: './createvideocourse.component.html',
  styleUrls: ['./createvideocourse.component.css']
})
export class CreatevideocourseComponent extends BaseComponent implements OnInit {

  objVideoCourse : VideoCourse;
  lstVideoCourse : VideoCourse[];

  displayedColumns: string[] = ['select', 'name', 'Fee','IsActive', 'Edit'];
  dataSource = new MatTableDataSource<VideoCourse>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.objVideoCourse = new VideoCourse();
    this.lstVideoCourse = []
  }

  ngOnInit() {
      this.getAll();
  }

  edit(id : any){
   this.objVideoCourse =  this.dataSource.data.filter(x => x.id == id)[0];
  }

  Delete(id : any){
    this.objVideoCourse =  this.dataSource.data.filter(x => x.id == id)[0];
   }

  applyFilter(){

  }
  save(){
    debugger;
    this.objVideoCourse.fee = Number(this.objVideoCourse.fee)
    this.objVideoCourse.userId = Number(sessionStorage.getItem("userid"));

    this._svc.Save(this.objVideoCourse, 'Course').subscribe(
      data => {
        debugger;
        this.getAll();
      }, (err) => {
        this.global.openDialog("Forms", "Error While Saving.")
      })
  }

  pageNumber :number =1
  pageSize :number =10

  getAll(){
    this._svc.GetDetails("Course?pageNumber=" + this.pageNumber + "&pageSize="+ this.pageSize ).subscribe(rtnData => {
 
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstVideoCourse= rtnData.data;
            this.dataSource.data = this.lstVideoCourse;
         }  
      });
  }
}



export class VideoCourse{
  id  : number;
  userId  : number;
  name  : string
  fee  : number;
  isActive  : boolean
/**
 *
 */
  constructor() {
      this.id =   0;
      this.userId =   0;
      this.name =   "";
      this.fee =   0;
      this.isActive =   true
  }
}