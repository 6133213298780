import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'app/Shared/shared.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BaseComponent } from 'app/Shared/base-component';
import { SaveWorkSchedule_Time, WorkSechedule } from 'app/Classes/work-sechedule';
import { SelectionModel } from '@angular/cdk/collections';
import { from } from 'rxjs';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
import { debug } from 'util';
import $ from 'jquery';
@Component({
  selector: 'app-work-schedule',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.css']
})
export class WorkScheduleComponent extends BaseComponent implements OnInit {

  disableFlexible: boolean = false;
  disableFlexible_r: boolean = false;
  selectedRadio: number = 0;
  SaveWorkSchedule_Time: SaveWorkSchedule_Time;
  lstSaveWorkSchedule_Time: SaveWorkSchedule_Time[];
  WorkSechedule: WorkSechedule;
  listWorkSechedule: WorkSechedule[];

  Ramazan_WorkSechedule: WorkSechedule;
  Ramazan_listWorkSechedule: WorkSechedule[];
  scheduleType: string = "1";
  Ramazan_scheduleType: string = "1";

  isRamazan: boolean = false;

  bNormal: boolean = true;
  bFlexible: boolean = false;
  bOpen: boolean = false;
  bNormal_r: boolean = true;
  bFlexible_r: boolean = false;
  bOpen_r: boolean = false;

  lst_ramazanSaveWorkSchedule_Time: SaveWorkSchedule_Time[];
  obj_ramazanSaveWorkSchedule_Time: SaveWorkSchedule_Time;
  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.SaveWorkSchedule_Time = new SaveWorkSchedule_Time();
    this.listWorkSechedule = [];
    this.WorkSechedule = new WorkSechedule();
    this.Ramazan_WorkSechedule = new WorkSechedule();
    this.Ramazan_listWorkSechedule = [];
    this.lst_ramazanSaveWorkSchedule_Time = [];
    this.obj_ramazanSaveWorkSchedule_Time = new SaveWorkSchedule_Time();
    this.lstSaveWorkSchedule_Time = [];
  }

  displayedColumns: string[] = ['day_name', 'fromTime', 'toTime', 'flixibleTime', 'duration', 'isOffDay'];
  dataSource = new MatTableDataSource<SaveWorkSchedule_Time>();
  selection = new SelectionModel<SaveWorkSchedule_Time>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;



  AddRamazandisplayedColumns: string[] = ['day_name', 'fromTime', 'toTime', 'flixibleTime', 'duration', 'isOffDay'];
  AddRamazandataSource = new MatTableDataSource<SaveWorkSchedule_Time>();
  AddRamazanselection = new SelectionModel<SaveWorkSchedule_Time>(true, []);
  @ViewChild(MatSort, { static: true }) AddRamazansort: MatSort;
  @ViewChild(MatPaginator, { static: true }) AddRamazanpaginator: MatPaginator;



  WorkSchedules_displayedColumns: string[] = ['Selected', 'ScheduleName', 'ScheduleName_LL', 'schedule_type', 'GraceIn', 'GraceOut', 'DelayIsFromGrace', 'EarlyOutIsFromGrace', 'IsDefault', "IsActive", 'edit'];
  WorkSchedules_dataSource = new MatTableDataSource<WorkSechedule>();
  WorkSchedules_selection = new SelectionModel<WorkSechedule>(true, []);
  @ViewChild(MatSort, { static: true }) sort_WorkSchedules: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator_WorkSchedules: MatPaginator;



  ngOnInit() {
    this.insertData();
    this.insertData_r();
    this.GetAllWorkSchedules_ByCompany();
    this.onItemChange(1);
  }

  Close() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '155';
  }
  openRamazanschedule() {

    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '0';
    this.isRamazan = true;
  }
  onItemChange(id: any) {

    this.WorkSechedule.scheduleType = id;
    this.scheduleType = id.toString();
    if (id == "1") {
      this.getDuration(0,'From Time')
      this.disableFlexible = true;
      this.bNormal = true;
      this.bFlexible = false;
      this.bOpen = false;

      for (var i = 0; i < this.dataSource.data.length; i++) {
        this.dataSource.data[i].flixibleTime = 0;
        this.dataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].flixibleTime);
      }

    }
    else if (id == "2") {
      this.bNormal = false;
      this.bFlexible = true;
      this.bOpen = false;
      this.disableFlexible = false;
    }
    else if (id == "3") {
      this.disableFlexible = false;
      this.bNormal = false;
      this.bFlexible = false;
      this.bOpen = true;
      for( var i = 0; i < this.dataSource.data.length; i++){
        this.dataSource.data[i].fromTime = 0;
        this.dataSource.data[i].fromTime_timeFormate = this.convertIntoTime(0)
        this.dataSource.data[i].toTime = 0;
        this.dataSource.data[i].toTime_timeFormate =this.convertIntoTime(0);
        this.dataSource.data[i].flixibleTime = 0;
        this.dataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(0);
      }
    }
    else {
      this.disableFlexible = false;
    }
  }

  Clear() {
    this.resetWorkSchedule();
    this.WorkSechedule = new WorkSechedule();
    this.insertData();
    this.insertData_r();
    this.onItemChange("1");
    this.onItemChange_r("1");
  }
  GetAllWorkSchedules_ByCompany() {
    this.isRamazan = false;
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "WorkSchedule/GetAllWorkSchedules_ByCompany", "TA").subscribe(
      data => {
        this.listWorkSechedule = [];
        this.listWorkSechedule = data;
        for (var i = 0; i < this.listWorkSechedule.length; i++) {
          this.listWorkSechedule[i].Selected = false;
        }
        this.WorkSchedules_dataSource.data = this.listWorkSechedule.filter(x => x.isRamadanSch == false);
        this.WorkSchedules_dataSource.sort = this.sort_WorkSchedules;
        this.WorkSchedules_dataSource.paginator = this.paginator_WorkSchedules;

      }, (err) => {
      });
  }

  initialValidations(type: any) {
    var s = "";
    if (type == "R") {
      if (this.global.isStringNullOrEmplty(this.Ramazan_WorkSechedule.scheduleName)) {
        s = " Schedule Name , "
      }
      if (this.global.isStringNullOrEmplty(this.Ramazan_WorkSechedule.scheduleName_LL)) {
        s = s + "Schedule Name Local Language";
      }
    }
    else {
      if (this.global.isStringNullOrEmplty(this.WorkSechedule.scheduleName)) {
        s = " Schedule Name , "
      }
      if (this.global.isStringNullOrEmplty(this.WorkSechedule.scheduleName_LL)) {
        s = s + "Schedule Name Local Language";
      }
    }
    return s;
  }

  SaveWorkSchedule() {
    
    this.WorkSechedule.fK_CompanyId = this.global.FK_CompanyId();
    this.WorkSechedule.isRamadanSch = false;
    this.WorkSechedule.parentScheduleId = 0;
    this.WorkSechedule.createD_BY = this.global.getUserID();
    this.WorkSechedule.lasT_UPDATE_BY = "";


    this.WorkSechedule.scheduleType = Number(this.WorkSechedule.scheduleType);
    this.WorkSechedule.graceIn = Number(this.WorkSechedule.graceIn);
    this.WorkSechedule.graceOut = Number(this.WorkSechedule.graceOut);
    this.WorkSechedule.parentScheduleId = Number(this.WorkSechedule.parentScheduleId);
    var isedit = false;
    var scheduleId = 0;
    if (this.WorkSechedule.scheduleId != 0) {
      isedit = true;
      scheduleId = this.WorkSechedule.scheduleId;
    }
    var isError = this.initialValidations("Normal");
    if (isError == "") {
      this._svc.SaveWorkSchedule(this.WorkSechedule, 'WorkSchedule/SaveWorkSchedule').subscribe(
        data => {
          var x = "";
          ;
          if (isedit == true) {
            if (data == "Success" || data == "success") {
              x = scheduleId.toString();
              this.SaveWorkScheduleFun(x);
              this.global.openDialog("Work Schedule", "Record has been saved.");
             

            }
            else {
              this.global.openDialog("Work Schedule", "Some Error Occured Work Schedule.");
            }
          }
          else {
            var y = data;
            y = y.split(':')[1];
            y = y.slice(0, -1)
            this.SaveWorkScheduleFun(y);
            this.global.openDialog("Work Schedule", "Record has been saved.");
           // this.GetAllWorkSchedules_ByCompany();
          //  this.Clear();
          }
        }, (err) => {
          this.global.openDialog("Work Schedule", "Some Error Occured while saving Work Schedule.");
        });
    }
    else {
      this.global.openDialog("Work Schedule", "Please fill the mentioned fields : " + isError)
    }
  }
  closePOP() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '155';
    $("#AddRamazanSchedule").click();
    document.getElementById('AddRamazanSchedule').style.display = 'none';
  }
  getDuration(i: any, type: any = "") {

    var fromtime = this.converttoMinutes(this.dataSource.data[i].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.dataSource.data[i].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    this.dataSource.data[i].workHours = dur;
    this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours);
    this.dataSource.data[i].fromTime = fromtime;
    this.dataSource.data[i].toTime = totime;
    this.dataSource.data[i].toTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].toTime)
    this.dataSource.data[i].fromTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].fromTime)
    
    if (type == 'From Time') {
      for (var x = i; x < this.dataSource.data.length; x++) {
        this.dataSource.data[x].fromTime_timeFormate = this.dataSource.data[i].fromTime_timeFormate;
        this.dataSource.data[x].fromTime = this.dataSource.data[i].fromTime;
        this.dataSource.data[x].duration_timeFormate = this.calcDur(x)
        this.dataSource.data[x].workHours = this.calDurMin(x)
      }

    }
    if (type == 'To Time') {
      for (var x = i; x < this.dataSource.data.length; x++) {
        this.dataSource.data[x].toTime_timeFormate = this.dataSource.data[i].toTime_timeFormate;
        this.dataSource.data[x].toTime = this.dataSource.data[i].toTime;
        this.dataSource.data[x].duration_timeFormate = this.calcDur(x);
        this.dataSource.data[x].workHours = this.calDurMin(x);
      }
    }
    this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours);
  }

  calDurMin(x: any) {
    var fromtime = this.converttoMinutes(this.dataSource.data[x].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.dataSource.data[x].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    return dur;
  }
  calcDur(x: any) {
    var fromtime = this.converttoMinutes(this.dataSource.data[x].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.dataSource.data[x].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    return this.convertIntoTime(dur);
  }

  calDurMin_R(x: any) {
    var fromtime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[x].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[x].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    return dur;
  }
  calcDur_R(x: any) {
    var fromtime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[x].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[x].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    return this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[x].workHours);
  }

  insertWorkSchedule_Time(day_name: string, fK_DayId: number) {
    this.SaveWorkSchedule_Time = new SaveWorkSchedule_Time();
    this.SaveWorkSchedule_Time.dayId = fK_DayId;
    this.SaveWorkSchedule_Time.day_name = day_name;
    this.SaveWorkSchedule_Time.fromTime = 0;
    this.SaveWorkSchedule_Time.toTime = 0;
    this.SaveWorkSchedule_Time.flixibleTime = 0;
    this.SaveWorkSchedule_Time.workHours = 0;
    this.SaveWorkSchedule_Time.isOffDay = false;
    this.SaveWorkSchedule_Time.fromTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.fromTime);
    this.SaveWorkSchedule_Time.toTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.toTime);
    this.SaveWorkSchedule_Time.flixibleTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.flixibleTime);
    this.SaveWorkSchedule_Time.duration_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.workHours);
    this.lstSaveWorkSchedule_Time.push(this.SaveWorkSchedule_Time);
  }
  insertWorkSchedule_Time_r(day_name: string, fK_DayId: number) {
    
    this.SaveWorkSchedule_Time = new SaveWorkSchedule_Time();
    this.SaveWorkSchedule_Time.dayId = fK_DayId;
    this.SaveWorkSchedule_Time.day_name = day_name;
    this.SaveWorkSchedule_Time.fromTime = 0;
    this.SaveWorkSchedule_Time.toTime = 0;
    this.SaveWorkSchedule_Time.flixibleTime = 0;
    this.SaveWorkSchedule_Time.workHours = 0;
    this.SaveWorkSchedule_Time.isOffDay = false;
    this.SaveWorkSchedule_Time.fromTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.fromTime);
    this.SaveWorkSchedule_Time.toTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.toTime);
    this.SaveWorkSchedule_Time.flixibleTime_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.flixibleTime);
    this.SaveWorkSchedule_Time.duration_timeFormate = this.convertIntoTime(this.SaveWorkSchedule_Time.workHours);
    this.lst_ramazanSaveWorkSchedule_Time.push(this.SaveWorkSchedule_Time);
  }
  insertData() {
    ;
    // this.dataSource.data = [];
    this.lstSaveWorkSchedule_Time = [];
    this.insertWorkSchedule_Time('Sunday', 1);
    this.insertWorkSchedule_Time('Monday', 2);
    this.insertWorkSchedule_Time('Tuesday', 3);
    this.insertWorkSchedule_Time('Wednesday', 4);
    this.insertWorkSchedule_Time('Thursday', 5);
    this.insertWorkSchedule_Time('Friday', 6);
    this.insertWorkSchedule_Time('Saturday', 7);
    this.dataSource.data = this.lstSaveWorkSchedule_Time;
  }
  insertData_r() {
    this.lst_ramazanSaveWorkSchedule_Time = [];
    this.insertWorkSchedule_Time_r('Sunday', 1);
    this.insertWorkSchedule_Time_r('Monday', 2);
    this.insertWorkSchedule_Time_r('Tuesday', 3);
    this.insertWorkSchedule_Time_r('Wednesday', 4);
    this.insertWorkSchedule_Time_r('Thursday', 5);
    this.insertWorkSchedule_Time_r('Friday', 6);
    this.insertWorkSchedule_Time_r('Saturday', 7);
    this.AddRamazandataSource.data = this.lst_ramazanSaveWorkSchedule_Time;
  }
  convertIntoTime(sec: number) {
    var min = sec % 60;
    var Hour = (sec - min) / 60;
    if (Hour < 10 && min < 10) {
      return "0" + Hour.toFixed(0) + ":" + "0" + min.toFixed(0);
    }
    else if (Hour < 10 && min > 9) {
      return "0" + Hour + ":" + min;
    }
    else if (Hour > 9 && min < 10) {
      return Hour + ":" + "0" + min;
    }
    else if (Hour > 9 && min > 9) {
      return Hour + ":" + min;
    }
  }

  converttoMinutes(Time: string) {
    var x = Time.split(":");
    var retrunTime = 0;
    if (x.length == 1) {
      retrunTime = Number(x[0]) * 60;
    }
    else {
      retrunTime = Number(x[0]) * 60 + Number(x[1]);
    }

    if (retrunTime > 1439 || retrunTime < 0 || retrunTime.toString() == "NaN") {
      this.global.openDialog("Work Schedule", "Please enter valid Time")
      retrunTime = 0;
    }
    return retrunTime;
  }
  checkDays(i: number) {
    //if (this.dataSource.data[i].isOffDay) {
    this.dataSource.data[i].fromTime = 0;
    this.dataSource.data[i].toTime = 0;
    this.dataSource.data[i].flixibleTime = 0;
    this.dataSource.data[i].workHours = 0;
    this.dataSource.data[i].fromTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].fromTime);
    this.dataSource.data[i].toTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].toTime);
    this.dataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].flixibleTime);
    this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours);
    // }
    // else {
    //   this.dataSource.data[i].fromTime = 0;
    //   this.dataSource.data[i].toTime = 0;
    //   this.dataSource.data[i].flixibleTime = 0;
    //   this.dataSource.data[i].workHours = 0;
    //   this.dataSource.data[i].fromTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].fromTime);
    //   this.dataSource.data[i].toTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].toTime);
    //   this.dataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].flixibleTime);
    //   this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours);
    // }
  }

  GetWorkSchedule_Time_ByFK_ScheduleId(id: any , Type: any) {
    this._svc.getGenericParmas(id, 'FK_ScheduleId', "WorkSchedule_Time/GetWorkSchedule_Time_ByFK_ScheduleId", "TA").subscribe(
      data => {
        this.insertData();
        ;
        this.dataSource.data = data;

        for (var i = 1; i < this.dataSource.data.length + 1; i++) {
          if (this.dataSource.data[i - 1].dayId == 1) {
            this.dataSource.data[i - 1].day_name = 'Sunday';
          }
          else if (this.dataSource.data[i - 1].dayId == 2) {
            this.dataSource.data[i - 1].day_name = 'Monday';
          }
          else if (this.dataSource.data[i - 1].dayId == 3) {
            this.dataSource.data[i - 1].day_name = 'Tuesday';
          }
          else if (this.dataSource.data[i - 1].dayId == 4) {
            this.dataSource.data[i - 1].day_name = 'Wednesday';
          }
          else if (this.dataSource.data[i - 1].dayId == 5) {
            this.dataSource.data[i - 1].day_name = 'Thursday';
          }
          else if (this.dataSource.data[i - 1].dayId == 6) {
            this.dataSource.data[i - 1].day_name = 'Friday';
          }
          else if (this.dataSource.data[i - 1].dayId == 7) {
            this.dataSource.data[i - 1].day_name = 'Saturday';
          }
          this.dataSource.data[i - 1].fromTime_timeFormate = this.convertIntoTime(this.dataSource.data[i - 1].fromTime);
          this.dataSource.data[i - 1].toTime_timeFormate = this.convertIntoTime(this.dataSource.data[i - 1].toTime);
          this.dataSource.data[i - 1].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i - 1].flixibleTime);
          this.dataSource.data[i - 1].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i - 1].workHours);
          if(Type != 3){
            this.getDuration(i - 1);
          }
        }
      })
  }
  resetWorkSchedule() {
    this.WorkSechedule = new WorkSechedule();
    this.scheduleType = "1"
  }
  resetRamazan() {
    this.Ramazan_WorkSechedule = new WorkSechedule();
    this.Ramazan_scheduleType = "1";
    this.insertData_r();
  }
  editWorkSchedules(ScheduleId: number) {
    ;
    this.resetRamazan();
    this.WorkSechedule = this.listWorkSechedule.filter(x => x.scheduleId == ScheduleId)[0];
    this.onItemChange(this.WorkSechedule.scheduleType);
    this.GetWorkSchedule_Time_ByFK_ScheduleId(this.WorkSechedule.scheduleId , this.WorkSechedule.scheduleType);
    var ramazanlist = this.listWorkSechedule.filter(x => x.parentScheduleId == ScheduleId);
    if (ramazanlist.length == 1) {
      this.Ramazan_WorkSechedule = ramazanlist[0];
      this.onItemChange_r(this.Ramazan_WorkSechedule.scheduleType.toString());
      this.GetWorkSchedule_Time_ByFK_ScheduleId_ramazan(this.Ramazan_WorkSechedule.scheduleId , this.Ramazan_WorkSechedule.scheduleType)
    }
    else {
      this.Ramazan_WorkSechedule.scheduleId = 0;
      this.Ramazan_WorkSechedule.scheduleType = this.WorkSechedule.scheduleType;
      this.Ramazan_WorkSechedule.parentScheduleId = this.WorkSechedule.scheduleId;
      this.Ramazan_WorkSechedule.isRamadanSch = true;
      this.Ramazan_WorkSechedule.fK_CompanyId = this.global.FK_CompanyId();
      this.Ramazan_WorkSechedule.createD_BY = this.global.getUserID();
      this.Ramazan_WorkSechedule.lasT_UPDATE_BY = "";
      this.Ramazan_WorkSechedule.scheduleType = Number(this.Ramazan_WorkSechedule.scheduleType);
      this.Ramazan_WorkSechedule.graceIn = Number(this.WorkSechedule.graceIn);
      this.Ramazan_WorkSechedule.graceOut = Number(this.WorkSechedule.graceOut);
      this.Ramazan_WorkSechedule.parentScheduleId = Number(this.Ramazan_WorkSechedule.parentScheduleId);

      this.Ramazan_WorkSechedule.isDefault = false;
      this.Ramazan_WorkSechedule.isActive = this.WorkSechedule.isActive;
      this.Ramazan_WorkSechedule.delayIsFromGrace = this.WorkSechedule.delayIsFromGrace;
      this.Ramazan_WorkSechedule.earlyOutIsFromGrace = this.WorkSechedule.earlyOutIsFromGrace;
      this.onItemChange_r(this.WorkSechedule.scheduleType.toString());

    }

  }
  Checked(ScheduleId: number) {
    for (var i = 0; i < this.listWorkSechedule.length; i++) {
      if (this.listWorkSechedule[i].scheduleId == ScheduleId) {
        this.listWorkSechedule[i].Selected = !this.listWorkSechedule[i].Selected;
      }
    }
  }
  ShowConfirmDialog(): void {

    var tempList = this.listWorkSechedule.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("Work Schedule", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.Delete();
        }
      });
    }
    else {
      this.global.openDialog("Work Schedule", "No Record selected.");
    }
  }
  Delete() {
    var tempList = this.listWorkSechedule.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      for (var i = 0; i < tempList.length; i++) {
        this.deleteTime_Schedule(tempList[i].scheduleId);
      }
    }
    else {
      this.global.openDialog("Work Schedule", "No Record selected.");
    }
  }
  deleteTime_Schedule(scheduleId: any) {
    this._svc.DeleteGenericParmas(scheduleId, 'FK_ScheduleId', 'WorkSchedule_Time/DeleteWorkSchedule_ByFK_ScheduleId_Time', "TA").subscribe(
      data => {
        this.deleteSchedule(scheduleId)
      }, (err) => {
        this.global.openDialog("Work Schedule", "Some Error Occured.");
      });
  }
  deleteSchedule(scheduleId: any) {
    this._svc.DeleteGenericParmas(scheduleId, 'ScheduleId', 'WorkSchedule/DeleteWorkSchedule', "TA").subscribe(
      data => {
        this.Clear();
        this.global.openDialog("Work Schedule", "Record has been Deleted.");
        this.GetAllWorkSchedules_ByCompany();
      }, (err) => {
        this.global.openDialog("Work Schedule", "Some Error Occured.");
      });
  }

  SaveWorkScheduleFun(id: any) {
    for (var i = 0; i < this.dataSource.data.length; i++) {
      var j = 1;
      this.SaveWorkSchedule_Time = new SaveWorkSchedule_Time();
      this.SaveWorkSchedule_Time = this.dataSource.data[i];
      this.SaveWorkSchedule_Time.fK_ScheduleId = Number(id);
      ;
      this._svc.SaveWorkSchedule_Time_svc(this.SaveWorkSchedule_Time, 'WorkSchedule_Time/SaveWorkSchedule_Time').subscribe(
        data => {
          ;
          if (j == this.dataSource.data.length-1) {
            this.GetAllWorkSchedules_ByCompany();
            this.Clear();
            this.insertData();
          }
          j++;
        }, (err) => {
          if (j == this.dataSource.data.length-1) {
            this.global.openDialog("Employee", "Some Error Occured while saving work Schedule time.");
          }
          j++;
        });
    }
  }
  AddRamazanSchedule() {
    
    this.Ramazan_WorkSechedule.scheduleType = Number(this.Ramazan_WorkSechedule.scheduleType);
    this.Ramazan_WorkSechedule.graceIn = Number(this.Ramazan_WorkSechedule.graceIn);
    this.Ramazan_WorkSechedule.graceOut = Number(this.Ramazan_WorkSechedule.graceOut);
    this.Ramazan_WorkSechedule.parentScheduleId = Number(this.Ramazan_WorkSechedule.parentScheduleId);
    if (this.Ramazan_WorkSechedule.scheduleId != 0) {
      this.Ramazan_WorkSechedule.lasT_UPDATE_BY = this.global.getUserID();
    }
    var isedit = false;
    var scheduleId = 0;
    if (this.Ramazan_WorkSechedule.scheduleId != 0) {
      isedit = true;
      scheduleId = this.Ramazan_WorkSechedule.scheduleId;
    }
    var isError = this.initialValidations("R");
    if (isError == "") {
      this._svc.SaveWorkSchedule(this.Ramazan_WorkSechedule, 'WorkSchedule/SaveWorkSchedule').subscribe(
        data => {
          var x = "";
          if (isedit == true) {
            if (data == "Success" || data == "success") {
              x = scheduleId.toString();
              this.SaveWorkSchedule_Ramazan(x);
              this.global.openDialog("Work Schedule", "Record has been saved.");
              this.GetAllWorkSchedules_ByCompany();
              this.Clear();
              this.closePOP();
            }
            else {
              this.global.openDialog("Work Schedule", "Some Error Occured Work Schedule.");
            }
          }
          else {
            var y = data;
            y = y.split(':')[1];
            y = y.slice(0, -1)
            this.SaveWorkSchedule_Ramazan(y);
            this.global.openDialog("Ramazan Schedule", "Record has been saved.");
            this.GetAllWorkSchedules_ByCompany();
            this.Clear();
            this.closePOP();
          }
        }, (err) => {
          this.global.openDialog("Ramazan Schedule", "Some Error Occured.");
        });
    }
    else {
      this.global.openDialog("Work Schedule", "Please fill the mentioned fields : " + isError)
    }
  }
  getDuration_R(i: any, type: any = "") {
    
    var fromtime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[i].fromTime_timeFormate);
    var totime = this.converttoMinutes(this.lst_ramazanSaveWorkSchedule_Time[i].toTime_timeFormate);
    var dur = 0;
    if (Number(fromtime) > Number(totime)) {
      dur = fromtime - totime;
    }
    else {
      dur = totime - fromtime;
    }
    this.lst_ramazanSaveWorkSchedule_Time[i].workHours = dur;
    this.lst_ramazanSaveWorkSchedule_Time[i].duration_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].workHours);
    this.lst_ramazanSaveWorkSchedule_Time[i].fromTime = fromtime;
    this.lst_ramazanSaveWorkSchedule_Time[i].toTime = totime;
    this.lst_ramazanSaveWorkSchedule_Time[i].fromTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].fromTime)
    this.lst_ramazanSaveWorkSchedule_Time[i].toTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].toTime);
    this.AddRamazandataSource.data[i].fromTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].fromTime)
    this.AddRamazandataSource.data[i].toTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].toTime);
    this.AddRamazandataSource.data[i].fromTime = fromtime;
    this.AddRamazandataSource.data[i].toTime = totime;
    this.AddRamazandataSource.data[i].workHours = dur;
    this.AddRamazandataSource.data[i].duration_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].workHours);


    if (type == 'From Time') {
      for (var x = i; x < this.lst_ramazanSaveWorkSchedule_Time.length; x++) {
        this.lst_ramazanSaveWorkSchedule_Time[x].fromTime_timeFormate = this.lst_ramazanSaveWorkSchedule_Time[i].fromTime_timeFormate;
        this.lst_ramazanSaveWorkSchedule_Time[x].fromTime = this.lst_ramazanSaveWorkSchedule_Time[i].fromTime;
        this.AddRamazandataSource.data[x].fromTime_timeFormate = this.lst_ramazanSaveWorkSchedule_Time[i].fromTime_timeFormate;
        this.AddRamazandataSource.data[x].fromTime = this.lst_ramazanSaveWorkSchedule_Time[i].fromTime;
        this.lst_ramazanSaveWorkSchedule_Time[x].duration_timeFormate = this.calcDur_R(x);
        this.lst_ramazanSaveWorkSchedule_Time[x].workHours = this.calDurMin_R(x);
        this.AddRamazandataSource.data[x].duration_timeFormate = this.calcDur_R(x);
        this.AddRamazandataSource.data[x].workHours = this.calDurMin_R(x);
      }
    }
    if (type == 'To Time') {
      for (var x = i; x < this.lst_ramazanSaveWorkSchedule_Time.length; x++) {
        this.lst_ramazanSaveWorkSchedule_Time[x].toTime_timeFormate = this.lst_ramazanSaveWorkSchedule_Time[i].toTime_timeFormate;
        this.lst_ramazanSaveWorkSchedule_Time[x].toTime = this.lst_ramazanSaveWorkSchedule_Time[i].toTime;
        this.lst_ramazanSaveWorkSchedule_Time[x].duration_timeFormate = this.calcDur_R(x);
        this.lst_ramazanSaveWorkSchedule_Time[x].workHours = this.calDurMin_R(x);
        this.AddRamazandataSource.data[x].toTime_timeFormate = this.lst_ramazanSaveWorkSchedule_Time[i].toTime_timeFormate;
        this.AddRamazandataSource.data[x].toTime = this.lst_ramazanSaveWorkSchedule_Time[i].toTime;
        this.AddRamazandataSource.data[x].duration_timeFormate = this.calcDur_R(x)
        this.AddRamazandataSource.data[x].workHours = this.calDurMin_R(x)
      }
    }
  }
  getflexible(i: any) {
    
    this.dataSource.data[i].flixibleTime = this.converttoMinutes(this.dataSource.data[i].flixibleTime_timeFormate)
    this.dataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].flixibleTime);
    for (var x = i; x < this.dataSource.data.length; x++) {
      this.dataSource.data[x].flixibleTime = this.converttoMinutes(this.dataSource.data[i].flixibleTime_timeFormate)
      this.dataSource.data[x].flixibleTime_timeFormate = this.convertIntoTime(this.dataSource.data[i].flixibleTime);
    }
  }

  getflexible_r(i: any) {
    
    this.AddRamazandataSource.data[i].flixibleTime = this.converttoMinutes(this.AddRamazandataSource.data[i].flixibleTime_timeFormate)
    this.AddRamazandataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].flixibleTime);
    for (var x = i; x < this.lst_ramazanSaveWorkSchedule_Time.length; x++) {
      this.AddRamazandataSource.data[x].flixibleTime = this.converttoMinutes(this.AddRamazandataSource.data[i].flixibleTime_timeFormate)
      this.AddRamazandataSource.data[x].flixibleTime_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].flixibleTime);
      this.lst_ramazanSaveWorkSchedule_Time[x].flixibleTime = this.converttoMinutes(this.AddRamazandataSource.data[i].flixibleTime_timeFormate)
      this.lst_ramazanSaveWorkSchedule_Time[x].flixibleTime_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].flixibleTime);
    }
  }
  convertDuration(i: any) {
    this.dataSource.data[i].workHours = this.converttoMinutes(this.dataSource.data[i].duration_timeFormate)
    this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours)
    this.dataSource.data[i].workHours = this.converttoMinutes(this.dataSource.data[i].duration_timeFormate)
    this.dataSource.data[i].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours)
    if (this.bOpen) {
      for (var x = i; x < this.dataSource.data.length; x++) {
        this.dataSource.data[x].workHours = this.converttoMinutes(this.dataSource.data[i].duration_timeFormate)
        this.dataSource.data[x].duration_timeFormate = this.convertIntoTime(this.dataSource.data[i].workHours);
      }
    }
  }
  convertDuration_r(i: any) {
    this.AddRamazandataSource.data[i].workHours = this.converttoMinutes(this.AddRamazandataSource.data[i].duration_timeFormate)
    this.AddRamazandataSource.data[i].duration_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].workHours)
    this.lst_ramazanSaveWorkSchedule_Time[i].workHours = this.converttoMinutes(this.AddRamazandataSource.data[i].duration_timeFormate)
    this.lst_ramazanSaveWorkSchedule_Time[i].duration_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].workHours)
    if (this.bOpen_r) {
      for (var x = i; x < this.lst_ramazanSaveWorkSchedule_Time.length; x++) {
        this.AddRamazandataSource.data[x].workHours = this.converttoMinutes(this.AddRamazandataSource.data[i].duration_timeFormate)
        this.AddRamazandataSource.data[x].duration_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].workHours);
        this.lst_ramazanSaveWorkSchedule_Time[x].workHours = this.converttoMinutes(this.AddRamazandataSource.data[i].duration_timeFormate)
        this.lst_ramazanSaveWorkSchedule_Time[x].duration_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].workHours);
      }
    }
  }

  SaveWorkSchedule_Ramazan(id: any) {
    this.lst_ramazanSaveWorkSchedule_Time = [];
    this.lst_ramazanSaveWorkSchedule_Time = this.AddRamazandataSource.data;
    for (var i = 0; i < this.lst_ramazanSaveWorkSchedule_Time.length; i++) {
      var j = 1;
      this.obj_ramazanSaveWorkSchedule_Time = new SaveWorkSchedule_Time();
      this.obj_ramazanSaveWorkSchedule_Time = this.lst_ramazanSaveWorkSchedule_Time[i];
      this.obj_ramazanSaveWorkSchedule_Time.fK_ScheduleId = Number(id);
      this._svc.SaveWorkSchedule_Time_svc(this.obj_ramazanSaveWorkSchedule_Time, 'WorkSchedule_Time/SaveWorkSchedule_Time').subscribe(
        data => {
          if (j == this.lst_ramazanSaveWorkSchedule_Time.length) {
            this.Ramazan_WorkSechedule = new WorkSechedule();
            this.insertData_r();
          }
          j++;
        }, (err) => {
          if (j == this.lst_ramazanSaveWorkSchedule_Time.length) {
            this.global.openDialog("Work Schedule", "Some Error Occured while saving Work Schedule Time.");
          }
          j++;
        });
    }
  }
  check_RamazanDays(i: number) {
    this.lst_ramazanSaveWorkSchedule_Time[i].fromTime = 0;
    this.lst_ramazanSaveWorkSchedule_Time[i].toTime = 0;
    this.lst_ramazanSaveWorkSchedule_Time[i].flixibleTime = 0;
    this.lst_ramazanSaveWorkSchedule_Time[i].workHours = 0;
    this.lst_ramazanSaveWorkSchedule_Time[i].fromTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].fromTime);
    this.lst_ramazanSaveWorkSchedule_Time[i].toTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].toTime);
    this.lst_ramazanSaveWorkSchedule_Time[i].flixibleTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].flixibleTime);
    this.lst_ramazanSaveWorkSchedule_Time[i].duration_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].workHours);
    this.AddRamazandataSource.data[i].fromTime = 0;
    this.AddRamazandataSource.data[i].toTime = 0;
    this.AddRamazandataSource.data[i].flixibleTime = 0;
    this.AddRamazandataSource.data[i].workHours = 0;
    this.AddRamazandataSource.data[i].fromTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].fromTime);
    this.AddRamazandataSource.data[i].toTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].toTime);
    this.AddRamazandataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].flixibleTime);
    this.AddRamazandataSource.data[i].duration_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i].workHours);
  }

  onItemChange_r(id: any) {
    this.Ramazan_WorkSechedule.scheduleType = id;
    this.Ramazan_scheduleType = id.toString();
    if (id == "1") {
      this.getDuration_R(0,'From Time')
      this.disableFlexible_r = true;
      this.bNormal_r = true;
      this.bFlexible_r = false;
      this.bOpen_r = false;
      for (var i = 0; i < this.AddRamazandataSource.data.length; i++) {
        this.AddRamazandataSource.data[i].flixibleTime = 0;
        this.AddRamazandataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(this.AddRamazandataSource.data[i].flixibleTime);
      }
    }
    else if (id == "2") {
      this.bNormal_r = false;
      this.bFlexible_r = true;
      this.bOpen_r = false;
      this.disableFlexible_r = false;
    }
    else if (id == "3") {
      this.disableFlexible_r = false;
      this.bNormal_r = false;
      this.bFlexible_r = false;
      this.bOpen_r = true;

      for( var i = 0; i < this.AddRamazandataSource.data.length; i++){
        this.AddRamazandataSource.data[i].fromTime = 0;
        this.AddRamazandataSource.data[i].fromTime_timeFormate = this.convertIntoTime(0)
        this.AddRamazandataSource.data[i].toTime = 0;
        this.AddRamazandataSource.data[i].toTime_timeFormate =this.convertIntoTime(0);
        this.AddRamazandataSource.data[i].flixibleTime = 0;
        this.AddRamazandataSource.data[i].flixibleTime_timeFormate = this.convertIntoTime(0);
      }
      
    }
    else {
      this.disableFlexible_r = false;
    }
  }


  GetWorkSchedule_Time_ByFK_ScheduleId_ramazan(id: any,Type :any) {
    this._svc.getGenericParmas(id, 'FK_ScheduleId', "WorkSchedule_Time/GetWorkSchedule_Time_ByFK_ScheduleId", "TA").subscribe(
      data => {
        
        this.insertData_r();
        if (data.length != 0) {
          this.lst_ramazanSaveWorkSchedule_Time = data;
          for (var i = 1; i < this.lst_ramazanSaveWorkSchedule_Time.length + 1; i++) {
            if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 1) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Sunday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 2) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Monday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 3) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Tuesday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 4) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Wednesday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 5) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Thursday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 6) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Friday';
            }
            else if (this.lst_ramazanSaveWorkSchedule_Time[i - 1].dayId == 7) {
              this.lst_ramazanSaveWorkSchedule_Time[i - 1].day_name = 'Saturday';
            }
            this.lst_ramazanSaveWorkSchedule_Time[i - 1].fromTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i - 1].fromTime);
            this.lst_ramazanSaveWorkSchedule_Time[i - 1].toTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i - 1].toTime);
            this.lst_ramazanSaveWorkSchedule_Time[i - 1].flixibleTime_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i - 1].flixibleTime);
            this.lst_ramazanSaveWorkSchedule_Time[i - 1].duration_timeFormate = this.convertIntoTime(this.lst_ramazanSaveWorkSchedule_Time[i - 1].workHours);
            if(Type != 3){
            this.getDuration_R(i - 1);
            }
          }
          this.AddRamazandataSource.data = [];
        }
        this.AddRamazandataSource.data = this.lst_ramazanSaveWorkSchedule_Time;
      })
  }
}
