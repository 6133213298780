import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { OrgCompany, OrgLevel, OrgUnit, OrgStructure, Org_WorkLocation, Org_logicalGroup, OrgCompany_Languages } from 'app/Classes/org';
import { SelectionModel } from '@angular/cdk/collections';
import { LookUpTableValues, LookUpTable } from 'app/Classes/lookup';
import { debug } from 'util';
import { Languages } from 'app/Classes/login';
import { Validators, FormControl } from '@angular/forms';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent extends BaseComponent implements OnInit {

  FormName: string = "Organization";
  OrgCompany: OrgCompany;
  listOrgCompany: OrgCompany[];
  OrgLevel: OrgLevel;
  listOrgLevel: OrgLevel[];
  lstLookUpTableValues: LookUpTableValues[];
  LookUpTable: LookUpTable;
  selectedValue: string = "";
  tabindex: number = 0;
  Languages: Languages[];
  chkLanguages: Languages[];
  // OrgUnit: OrgUnit;
  // OrgStructure: OrgStructure;
  Org_WorkLocation: Org_WorkLocation;
  lstOrg_WorkLocation: Org_WorkLocation[];
  Org_logicalGroup: Org_logicalGroup;
  lstOrg_logicalGroup: Org_logicalGroup[];
  selectedCountry: number = 0;


  OrgCompany_Languages: OrgCompany_Languages;
  public dataSource = new MatTableDataSource<OrgLevel>();
  public displayedColumns = ['index', 'LevelName', 'LevelArabicName', 'Edit', 'delete']
  selection = new SelectionModel<OrgLevel>(true, []);


  public WorkLocationSource = new MatTableDataSource<Org_WorkLocation>();
  public WorkLocationColumns = ['select', 'workLocationCode', 'workLocationName', 'workLocationName_LL', 'gPSCoordinates', 'Edit']
  WorkLocationselection = new SelectionModel<Org_WorkLocation>(true, []);


  public logicalGroupSource = new MatTableDataSource<Org_logicalGroup>();
  public logicalGroupColumns = ['select', 'groupId', 'groupName', 'groupName_LL', 'Edit']
  logicalGroupselection = new SelectionModel<Org_logicalGroup>(true, []);

  fK_DefaultLanguageId = new FormControl('', [
    Validators.required
  ]);
  fK_LocalLanguageId = new FormControl('', [
    Validators.required
  ]);

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.global.FormName = this.FormName;
    this.OrgCompany = new OrgCompany();
    this.listOrgCompany = [];
    this.OrgLevel = new OrgLevel();
    this.listOrgLevel = [];
    this.lstLookUpTableValues = [];
    this.lstOrg_logicalGroup = []
    this.lstOrg_WorkLocation = [];
    this.chkLanguages = [];
    this.LookUpTable = new LookUpTable();
    this.OrgCompany_Languages = new OrgCompany_Languages();
    // this.OrgUnit = new OrgUnit();
    // this.OrgStructure = new OrgStructure();
    this.Org_WorkLocation = new Org_WorkLocation();
    this.Org_logicalGroup = new Org_logicalGroup();
  }



  ngOnInit() {
    this.getlanguage();
    this.GetOrgLevel();
    this.GetLookUpValuesByCompany();
    this.GetCompanyDetails();
    this.GetWorkLocationByCompany();
    this.GetLogicalGroupByCompany();
    //this.GetOrgLanguages();
  }
  clearOrgCompany() {
    this.OrgCompany = new OrgCompany();
  }
  pop(index: any) {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '0';
    this.tabindex = index;
  }
  Close() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '155';
  }

  save() {
    
    if (this.OrgCompany.companyId == 0) {
      this.OrgCompany.cREATED_BY = this.global.getUserID();
    }
    else {
      this.OrgCompany.lAST_UPDATE_BY = this.global.getUserID();
      this.OrgCompany.fK_ParentId = 0;
    }
    this.OrgCompany.country = Number(this.selectedCountry);
    this.OrgCompany.logo = "";
    this._svc.SaveOrgCompany(this.OrgCompany, 'Org/SaveOrgCompany').subscribe(
      data => {
        this.clearOrgCompany();
        this.GetCompanyDetails();
        this.global.openDialog("Organization", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }

  GetCompanyDetails() {
    
    var companyid = this.global.FK_CompanyId();
    this._svc.getGenericParmas(companyid, 'CompanyId', 'Org/GetCompanyDetails').subscribe(
      data => {
        
        this.OrgCompany = data;
        this.selectedCountry = Number(this.OrgCompany.country);
      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }

  GetLookUpValuesByCompany() {
    
    this.LookUpTable.FK_CompanyId = this.global.FK_CompanyId();
    this.LookUpTable.lang = Number(sessionStorage.getItem('lang'));
    this.LookUpTable.LKPName = 'Country'
    this._svc.GetLookUpValuesByCompany(this.LookUpTable, 'LKP/GetLookUpValuesByCompany').subscribe(
      data => {
        
        this.lstLookUpTableValues = data;
      }, (err) => {
        // alert("error occured ")
      });
  }
  GetOrgLevel() {
    
    // this.OrgLevel.fK_CompanyId = this.global.FK_CompanyId();
    // this.OrgLevel.levelId = 1;
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Org/GetOrgLevelByCompany').subscribe(
      data => {
        this.dataSource.data = data;

      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }
  EditLevel(levelId: any) {
    this.pop(1);
    this.OrgLevel = this.dataSource.data.filter(x => x.levelId == levelId)[0]
  }
  ClearLevel() {
    this.OrgLevel = new OrgLevel();
  }


  DeleteLevel(levelId: any) {
    this.OrgLevel.fK_CompanyId = this.global.FK_CompanyId();
    this.OrgLevel.levelId = levelId;
    this._svc.GetOrgLevel(this.OrgLevel, 'Org/DeleteOrgLevel').subscribe(
      data => {
        this.GetOrgLevel();
        this.global.openDialog("Organization", "Record has been Deleted.");
      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }

  validateLevel() {
    var s = "";
    if (this.global.isStringNullOrEmplty(this.OrgLevel.levelName)) {
      s = "Level Name ,"
    }
    if (this.global.isStringNullOrEmplty(this.OrgLevel.levelName_LL)) {
      s = "Level Name in local language ,"
    }
    return s;
  }
  SaveOrgLevel() {
    
    var s = this.validateLevel();
    if (s == "") {
      this.OrgLevel.fK_CompanyId = this.global.FK_CompanyId();
      this._svc.GetOrgLevel(this.OrgLevel, 'Org/SaveOrgLevel').subscribe(
        data => {
          this.ClearLevel();
          this.GetOrgLevel();
          this.global.openDialog("Organization", "Record has been saved.");
        }, (err) => {
          this.global.openDialog("Organization", "Some Error Occured.");
        });
    }

    else {
      this.global.openDialog("Organization", "Please fill the following fields " + s);
    }
  }
  validateWorkLocation() {
    var s = "";
    if (this.global.isNumberNullOrEmplty(this.Org_WorkLocation.radius)) {
      s = "Radious ,"
    }
    if (this.global.isStringNullOrEmplty(this.Org_WorkLocation.workLocationCode)) {
      s = "work Location Code ,"
    }

    if (this.global.isStringNullOrEmplty(this.Org_WorkLocation.workLocationName)) {
      s = "work Location Name ,"
    }
    if (this.global.isStringNullOrEmplty(this.Org_WorkLocation.workLocationName_LL)) {
      s = "work Location Name in Local Language ,"
    }

    if (this.global.isStringNullOrEmplty(this.Org_WorkLocation.gPSCoordinates)) {
      s = "Coordinates"
    }
    return s;
  }
  SaveWorkLocation() {
    
    var s = this.validateWorkLocation()
    if (s == "") {
      this.Org_WorkLocation.fK_CompanyId = this.global.FK_CompanyId();
      this.Org_WorkLocation.radius = Number(this.Org_WorkLocation.radius)
      this._svc.SaveWorkLocation(this.Org_WorkLocation, 'WorkLocation/SaveWorkLocation').subscribe(
        data => {
          this.ClearWorkLocation();
          this.GetWorkLocationByCompany();
          this.global.openDialog("Organization", "Record has been saved.");
        }, (err) => {
          this.global.openDialog("Organization", "Some Error Occured.");
        });
    }
    else {
      this.global.openDialog("Organization", "Please fill the following " + s);
    }
  }
  ClearWorkLocation() {
    this.Org_WorkLocation = new Org_WorkLocation();
  }
  checkboxLabel(row: any) {
    
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.WorkLocationSource.data[i].workLocationId == row) {
        if (this.WorkLocationSource.data[i].select) {
          this.WorkLocationSource.data[i].select = false;
        }
        else {
          this.WorkLocationSource.data[i].select = true;
        }
      }
    }
  }
  DeleteWorkLocation() {
    for (var i = 0; i < this.WorkLocationSource.data.length; i++) {
      if (this.WorkLocationSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.WorkLocationSource.data[i].workLocationId, 'WorkLocationId', 'WorkLocation/DeleteWorkLocation').subscribe(
          data => {
            
            this.GetWorkLocationByCompany();
            this.global.openDialog("Organization", "Record has been Deleted.");
          }, (err) => {
            this.global.openDialog("Organization", "Some Error Occured.");
          });
      }
    }

  }

  GetWorkLocationByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();

    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'WorkLocation/GetWorkLocationByCompany').subscribe(
      data => {
        
        this.lstOrg_WorkLocation = data;
        for (var i = 0; i < this.lstOrg_WorkLocation.length; i++) {
          this.lstOrg_WorkLocation[i].select = false;
        }
        this.WorkLocationSource.data = this.lstOrg_WorkLocation;
      }, (err) => {
        // alert("error occured ")
      });
  }
  editWorkLocation(id: any) {
    this.pop(2);
    this.Org_WorkLocation = this.lstOrg_WorkLocation.filter(x => x.workLocationId == id)[0];
  }
  validatelogicalGroup() {
    var s = "";
    if (this.global.isStringNullOrEmplty(this.Org_logicalGroup.groupName)) {
      s = "Group Name ,"
    }
    if (this.global.isStringNullOrEmplty(this.Org_logicalGroup.groupName_LL)) {
      s = "Group Name local language ,"
    }
    return s;
  }
  SavelogicalGroup() {
    var s = this.validatelogicalGroup();
    if (s == "") {
      this.Org_logicalGroup.fK_CompanyId = this.global.FK_CompanyId();
      this._svc.SaveLogicalGroup(this.Org_logicalGroup, 'LogicalGroup/SaveLogicalGroup').subscribe(
        data => {
          this.ClearlogicalGroup();
          this.GetLogicalGroupByCompany();
          this.global.openDialog("Organization", "Record has been saved.");
        }, (err) => {
          this.global.openDialog("Organization", "Some Error Occured.");
          // alert("error occured ")
        });
    } else {
      this.global.openDialog("Organization", "Please fill in the following " + s);
    }
  }
  ClearlogicalGroup() {
    this.Org_logicalGroup = new Org_logicalGroup();
  }
  GetLogicalGroupByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'LogicalGroup/GetLogicalGroupByCompany').subscribe(
      data => {
        
        this.lstOrg_logicalGroup = data;
        for (var i = 0; i < this.lstOrg_logicalGroup.length; i++) {
          this.lstOrg_logicalGroup[i].select = false;
        }
        this.logicalGroupSource.data = this.lstOrg_logicalGroup;
      }, (err) => {
        // alert("error occured ")
      });
  }
  EditGroup(id: any) {
    this.pop(3)
    this.Org_logicalGroup = this.lstOrg_logicalGroup.filter(x => x.groupId == id)[0]
  }

  selectLogicalchexkbox(row: any) {
    
    for (var i = 0; i < this.logicalGroupSource.data.length; i++) {
      if (this.logicalGroupSource.data[i].groupId == row) {
        if (this.logicalGroupSource.data[i].select) {
          this.logicalGroupSource.data[i].select = false;
        }
        else {
          this.logicalGroupSource.data[i].select = true;
        }
      }
    }
  }
  DeleteLogicalGroup() {
    for (var i = 0; i < this.logicalGroupSource.data.length; i++) {
      if (this.logicalGroupSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.logicalGroupSource.data[i].groupId, 'GroupId', 'LogicalGroup/DeleteLogicalGroup').subscribe(
          data => {
            
            this.GetLogicalGroupByCompany();
            this.global.openDialog("Organization", "Record has been saved.");
          },
          (err) => {
            this.global.openDialog("Organization", "Some Error Occured.");

          });
      }
    }

  }

  getlanguage() {
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', 'Language/GetAllLanguages').subscribe(
      data => {
        this.Languages = data;
        this.chkLanguages = data;
        for (var i = 0; i < this.Languages.length; i++) {
          this.Languages[i].selected = false;
          this.chkLanguages[i].selected = false;
        }

        this.GetOrgLanguages();
      }, (err) => {
        // alert("error occured ")
      });
  }
  selectLang(id: any) {

  }
  chklang(i: number) {
    this.chkLanguages[i].selected = !this.chkLanguages[i].selected;
  }
  saveLanguage() {
    
    this.OrgCompany_Languages.fK_CompanyId = this.global.FK_CompanyId();
    var str = "";
    for (var i = 0; i < this.chkLanguages.length; i++) {
      if (this.chkLanguages[i].selected == true) {
        if (str == "") {
            str = this.chkLanguages[i].langId.toString();
        }
        else {
          str = str + "," + this.chkLanguages[i].langId.toString();
        }
      }
    }
    this.OrgCompany_Languages.companyLanguages = str;
    this._svc.SaveOrgLanguages(this.OrgCompany_Languages, 'Org/SaveOrgLanguages').subscribe(
      data => {
        this.global.openDialog("Organization", "Record has been saved.");
      }, (err) => {
        // alert("error occured ")
      });
  }

  GetOrgLanguages(){
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', 'Org/GetOrgLanguages').subscribe(
      data => {
        ;
        this.OrgCompany_Languages =data;
        if(!this.global.isStringNullOrEmplty(this.OrgCompany_Languages.companyLanguages)){
          setTimeout(() => {
            var a = this.OrgCompany_Languages.companyLanguages.split(',');
            for(var i = 0; i< a.length ;i ++){
              for(var j = 0; j< this.chkLanguages.length ;j ++){
                if(this.chkLanguages[j].langId.toString() ==  a[i]){
                  this.chkLanguages[j].selected = true;
                }
              }
            }
          }, 50);
         
        }
      }, (err) => {
        // alert("error occured ")
      });
  }
}
