import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { BaseComponent } from 'src/app/Shared/base-component';
// import { LanguageTranslateService } from 'src/app/Shared/language-translate.service';
// import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';
// import { SharedService } from 'src/app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import $ from 'jquery';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { Lookup, LookUpTableValues_languages, LookUpTableValues, LKPTranslations } from 'app/Classes/lookup';
import { Sys_users, Languages } from 'app/Classes/login';
import { FormControl, Validators } from '@angular/forms';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.css']
})
export class LookupComponent extends BaseComponent implements OnInit {

  FormName: string = "";
  lstLookup: Lookup[];
  objLookup: Lookup;
  NewListLookup: Lookup[];
  lstLKPTranslations: LKPTranslations[];
  objLKPTranslations: LKPTranslations;
  lookupID: number = 0;
  index: number = 0;
  Languages: Languages[];
  objSys_users: Sys_users;
  status: string = "";
  LookUpTableValues_languages: LookUpTableValues_languages;
  LookUpTableValues: LookUpTableValues;
  allselected: boolean = false;
  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    // this.global.FormName = this.FormName;
    this.lstLookup = [];
    this.objLookup = new Lookup();
    this.NewListLookup = [];
    this.lstLKPTranslations = [];
    this.objSys_users = new Sys_users;
    this.LookUpTableValues_languages = new LookUpTableValues_languages();
    this.LookUpTableValues = new LookUpTableValues();
    this.objLKPTranslations = new LKPTranslations();
  }
  displayedColumns: string[] = ['select', 'lookUpValue', 'ValueCode', 'edit'];
  dataSource = new MatTableDataSource<Lookup>();
  selection = new SelectionModel<Lookup>(true, []);


  TranslationdisplayedColumns: string[] = ['select', 'lookUpValue_lang', 'languageName', 'edit'];
  TranslationdataSource = new MatTableDataSource<LookUpTableValues_languages>();
  Translationselection = new SelectionModel<LookUpTableValues_languages>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ApplicationType = new FormControl('', [
    Validators.required
  ]);

  ngOnInit() {
    
    var x = this.global.FormName.split("Define");
    this.FormName = x[1];
    // this.global.FormName = this.FormName;
    this.lookupID = Number(sessionStorage.getItem('lookupID'));
    this.GetAllLookUpValues();
    this.getlanguage();
  }

  openModel() {


    setTimeout(() => {
      $('.modal-backdrop').remove();
      $('.modal-backdrop').show()
      $('#lookuoModal').modal('show');
    }, 10);

  }
  editLookup(lookupValueID: any) {
    this.status = "Modify";
    this.getIndex(1);
    // this.getlanguage();
    setTimeout(() => {
      this.GetLookUpValue(lookupValueID);
    }, 10);
  }
  Clear() {
    this.objLookup = new Lookup();
  }
  GetAllLookUpValues() {
    this._svc.getGenericParmas(this.lookupID, 'FK_LookUpId', 'LKP/GetAllLookUpValues').subscribe(
      data => {
        this.lstLookup = data;
        if (this.lstLookup.length > 0) {
          this.dataSource.data = this.lstLookup;
          for (var i = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].select = false;
          }
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
        else {
          this.global.openDialog("LookUp", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("LookUp", "Some Error Occured.");
      });

  }
  GetLookUpValue(LookUpValueId: any) {

    this._svc.getGenericParmas(LookUpValueId, 'LookUpValueId', 'LKP/GetLookUpValue').subscribe(
      data => {
        ;
        this.LookUpTableValues = data;
        for (var i = 0; i < this.Languages.length; i++) {
          var x = this.LookUpTableValues.lkpTrans.filter(a => a.fK_langId == this.Languages[i].langId);
          if(this.global.hasZeroLength(x)){
            this.objLKPTranslations = new LKPTranslations();
            this.objLKPTranslations.fK_langId = this.Languages[i].langId;
            this.objLKPTranslations.lookUpValue_lang = "";
            // this.lstLKPTranslations.push(this.objLKPTranslations);
            this.LookUpTableValues.lkpTrans.push(this.objLKPTranslations);
          }
        }

      }, (err) => {

      });

  }

  GetTranalationValuesByLookUp(FK_LookUpValueId: any) {
    // LKPTranslation/GetTranalationValuesByLookUp

    this._svc.getGenericParmas(FK_LookUpValueId, 'FK_LookUpValueId', 'LKPTranslation/GetTranalationValuesByLookUp').subscribe(
      data => {
        ;
        this.TranslationdataSource.data = data;
      }, (err) => {

      });

  }

  ShowConfirmDialog(): void {

    const message = 'Are you sure you want to delete selected Records?';
    const dialogData = new CustomConfirmDialogModel("Application  ", message);
    const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
      width: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


      if (dialogResult) {
        this.DeleteLKPValues()
      }
    });
  }


  DeleteLKPValues() {
    ;
    var j = 0;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.dataSource.data[i].lookUpValueId, 'LookUpValueId', 'LKP/DeleteLKPValues').subscribe(
          data => {
            ;
            j++;
            if (i == j) {
              this.GetAllLookUpValues();
              this.global.openDialog("LookUp", "Record has been Deleted.");
            }

          }, (err) => {
            this.global.openDialog("LookUp", "Some Error Occured.");
          });

      }
      else {
        j++;
      }
    }
    //"LKPTranslation/DeleteLKPValues"
  }
  initialValidations() {
    var s = "";
    if (this.global.isStringNullOrEmplty(this.objLookup.lookUpValue)) {
      s = " LookUp Value , "
    }
    if (this.global.isStringNullOrEmplty(this.objLookup.lookUpValueCode)) {
      s = s + " lookUp Value Code";
    }
    return s;
  }
  SaveLKPValues() {
    ;
    if (this.status == "New") {
      // var error = this.initialValidations();
      // error = "";
      // if (error == "") {
      // for (var i = 0; i < this.NewListLookup.length; i++) {
      //   var j = 1;
      if (!this.global.isStringNullOrEmplty(this.LookUpTableValues.lookUpValueCode)) {
        // this.objLookup = new Lookup();
        this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
        this.LookUpTableValues.cREATED_BY = this.objSys_users.user_FullName;
        this.LookUpTableValues.fK_LookUpId = this.lookupID;
        this._svc.SaveLKPValues(this.LookUpTableValues, 'LKP/SaveLKPValues').subscribe(
          data => {
            ;

            this.GetAllLookUpValues();
            document.getElementById('AddLang').style.display = 'none';
            this.global.openDialog("LookUp", "Record has been saved.");
            this.Close();
            document.getElementById('AddLang').style.display = 'none';
          }, (err) => {
            this.global.openDialog("LookUp", "Some Error Occured.");
          });
      }
      //   }
      // this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      // this.objLookup.CREATED_BY = this.objSys_users.user_FullName;
      // this.objLookup.FK_LookUpId = this.lookupID;

      // }
      // else {
      //   this.global.openDialog("LookUp", "Please fill the following fields " + error);
      // }
    }
    else {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      this.LookUpTableValues.cREATED_BY = this.objSys_users.user_FullName;
      this.LookUpTableValues.fK_LookUpId = this.lookupID;
      this._svc.SaveLKPValues(this.LookUpTableValues, 'LKP/SaveLKPValues').subscribe(
        data => {
          ;

          this.GetAllLookUpValues();
          this.Close();
          document.getElementById('AddLang').style.display = 'none';
          this.global.openDialog("LookUp", "Record has been saved.");
        }, (err) => {
          this.global.openDialog("LookUp", "Some Error Occured.");
        });

    }
  }




  /////////////////table finction
  isAllSelected() {
    ;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    ;
    var k = this.isAllSelected();
    var a = this.dataSource.data.filter(x => x.select == true);
    if (a.length > 0) {
      for (var i = 0; i < this.dataSource.data.length; i++) {
        this.dataSource.data[i].select = false;
      }
    }
    else {
      for (var i = 0; i < this.dataSource.data.length; i++) {
        this.dataSource.data[i].select = true;
      }
    }
    // this.isAllSelected() ?
    //   this.selection.clear() :
    //   this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any) {
    ;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].lookUpValueId == row) {
        if (this.dataSource.data[i].select) {
          this.dataSource.data[i].select = false;
        }
        else {
          this.dataSource.data[i].select = true;
        }
      }
    }
  }
  Close() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '155';
  }
  getIndex(index: number) {
    this.index = index;
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex = '0';
  }
  AddLanguage(lookUpValueId: any, index: number) {
    ;
    this.getIndex(index);
    this.getlanguage();
    ;
    this.LookUpTableValues_languages.FK_LookUpValueId = lookUpValueId;
    this.GetTranalationValuesByLookUp(lookUpValueId);
  }
  AddNew() {
    this.status = "New";
    this.getIndex(1);
    this.getlanguageForNew();
    this.objLookup = new Lookup()
  }
  getlanguageForNew() {
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', 'Language/GetAllLanguagesByCompany').subscribe(
      data => {
        ;
        this.Languages = data;
        this.global.Languages = this.langObject;
        this.lstLKPTranslations = [];
        if (this.status == "New") {
          for (var i = 0; i < this.Languages.length; i++) {
            this.objLKPTranslations = new LKPTranslations();
            this.objLKPTranslations.fK_langId = this.Languages[i].langId;
            this.objLKPTranslations.lookUpValue_lang = "";
            this.lstLKPTranslations.push(this.objLKPTranslations);
            this.LookUpTableValues.lkpTrans = this.lstLKPTranslations;
          }
          this.objLookup = new Lookup();
        }
      }, (err) => {
        // alert("error occured ")
      });
  }

  getlanguage() {
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', 'Language/GetAllLanguagesByCompany').subscribe(
      data => {
        ;
        this.Languages = data;
        this.global.Languages = this.langObject;
        this.NewListLookup = [];
        // if (this.status == "New") {
        //   for (var i = 0; i < this.Languages.length; i++) {
        //     this.objLookup = new Lookup();
        //     this.objLookup.lookUpValue = this.Languages[i].languageName;
        //     this.NewListLookup.push(this.objLookup);
        //   }
        //   this.objLookup = new Lookup();
        // }
      }, (err) => {
        // alert("error occured ")
      });
  }
  selectLang(langID: any) {
    ;
    var a = this.Languages.filter(x => x.langId == langID);
    this.LookUpTableValues_languages.LanguageName = a[0].languageName;
  }

  Savelang() {
    
    this._svc.SaveLKPTranslations(this.LookUpTableValues_languages, 'LKPTranslation/SaveLKPTranslations').subscribe(
      data => {
        this.GetTranalationValuesByLookUp(this.LookUpTableValues_languages.FK_LookUpValueId);
        this.ClearLang()
        this.global.openDialog("LookUp", "Record has been saved.");
        this.Close();
        document.getElementById('AddLang').style.display = 'none';
      }, (err) => {
        this.global.openDialog("LookUp", "Some Error Occured.");
      });
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    ;
    if(event.code == "Escape"){
    document.getElementById('AddLang').style.display = 'none';
    this.Close();
    }
    
  }
  ClearLang() {
    var d = this.LookUpTableValues_languages.FK_LookUpValueId
    this.LookUpTableValues_languages = new LookUpTableValues_languages();
    this.LookUpTableValues_languages.FK_LookUpValueId = d;
  }
  editLang(langID: any) {
    ///TranslationdataSource
    var d = this.TranslationdataSource.data.filter(x => x.fK_langId == langID)[0];
    this.LookUpTableValues_languages = d;
  }
  checkLang(langID: any) {

  }


}
