export class Employee {
  employeeId: number;
  fK_Status: number;
  fK_EmployeeTypeId: number;
  fK_CompanyId: number;
  fK_OrgUnitId: number;
  fK_WorkLocation: number;
  fK_LogicalGroup: number;
  fK_lkpNationality: number;
  fK_lkpReligion: number;
  fK_lkpMaritalStatus: number;
  fK_lkpGrade: number;
  fK_lkpDesignation: number;
  isTerminated: boolean;
  employeeNo: string;
  employeeActiveCard: string;
  externalPartyName: string;
  employeeName: string;
  employeeName_LL: string;
  gender: string;
  orgUnitHierarchy: string;
  remarks: string;
  empImagePath: string;
  uniqueId: string;
  mobileNo: string;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  email: string;
  contractEndDate: Date;
  dob: Date;
  cREATED_DATE: Date;
  lAST_UPDATE_DATE: Date;
  terminateDate: Date;
  joinDate: Date;
  select: boolean;

  /**
   *
   */
  constructor() {
    this.employeeId = 0;
    this.fK_Status = 0;
    this.fK_EmployeeTypeId = 0;
    this.fK_CompanyId = 0;
    this.fK_OrgUnitId = 0;
    this.fK_WorkLocation = 0;
    this.fK_LogicalGroup = 0;
    this.fK_lkpNationality = 0;
    this.fK_lkpReligion = 0;
    this.fK_lkpMaritalStatus = 0;
    this.fK_lkpGrade = 0;
    this.fK_lkpDesignation = 0;
    this.isTerminated = false;
    this.employeeNo ="";
    this.employeeActiveCard =""; 
    this.externalPartyName  ="";
    this.employeeName  ="";
    this.employeeName_LL  ="";
    this.gender  ="";
    this.orgUnitHierarchy  ="";
    this.remarks  ="";
    this.empImagePath  ="";
    this.uniqueId  ="";
    this.mobileNo  ="";
    this.cREATED_BY  ="";
    this.lAST_UPDATE_BY  ="";
    this.email  ="";
    this.contractEndDate = new Date();
    this.dob = new Date();
    this.cREATED_DATE = new Date();
    this.lAST_UPDATE_DATE = new Date();
    this.terminateDate = new Date();
    this.joinDate = new Date();
    this.select = false
  }
}

export class Employee_Status {
  fK_CompanyId: number;
  statusId: number;
  statusCode: string;
  statusName: string;
  statusName_LL: string;
  statusDescription: string;
  cosiderEmployeeActive: boolean;
  select: boolean;

  /**
   *
   */
  constructor() {
    this.fK_CompanyId = 0;
    this.statusId = 0;
    this.statusCode = "";
    this.statusName = "";
    this.statusName_LL = "";
    this.statusDescription = "";
    this.cosiderEmployeeActive = false;
    this.select = false;

  }
}


export class Employee_Type {
  fK_CompanyId: number;
  employeeTypeId: number;
  typeName: string;
  typeName_LL: string;
  employeeNumberInitial: string;
  isInternaltype: boolean
  select: boolean;
  /**
   *
   */
  constructor() {
    this.fK_CompanyId = 0;
    this.employeeTypeId = 0;
    this.typeName = "";
    this.typeName_LL = "";
    this.employeeNumberInitial = "";
    this.isInternaltype = false;
    this.select = false;
  }
}