import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LayoutModule } from './layout/layout.module';
import { LanguageTranslateService } from './Shared/language-translate.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LookupComponent } from './Administration/Definition/lookup/lookup.component';
import { MaterialModuleModule } from './material-module/material-module.module';
import { UsersComponent } from './Administration/Definition/users/users.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrganizationComponent } from './Administration/Definition/organization/organization.component';
import { EntityComponent } from './Administration/Definition/entity/entity.component';
import { EmployeeStatusComponent } from './Administration/Definition/employee-status/employee-status.component';
import { EmployeeTypeComponent } from './Administration/Definition/employee-type/employee-type.component';
import { EmployeeComponent } from './Administration/Definition/employee/employee.component';
import { GroupsComponent } from './Administration/Definition/groups/groups.component';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { CustomConfirmModalsComponent } from './custom-confirm-modals/custom-confirm-modals.component';
import { WorkScheduleComponent } from './TAProj/work-schedule/work-schedule.component';
import { NumberOnlyDirective } from './Shared/number.directive';
import { TaPolicyComponent } from './TAProj/ta-policy/ta-policy.component';
import { RamadanPeriodComponent } from './TAProj/ramadan-period/ramadan-period.component';
import { OvertimeTypesComponent } from './TAProj/overtime-types/overtime-types.component';
import { LeavesTypesComponent } from './TAProj/leaves-types/leaves-types.component';
import { ApplicationSettingsComponent } from './TAProj/application-settings/application-settings.component';
import { ManagersComponent } from './TAProj/managers/managers.component';
import { TAExceptionComponent } from './TAProj/taexception/taexception.component';
import { AssingTaPolicyComponent } from './TAProj/assing-ta-policy/assing-ta-policy.component';
import { AssignWorkScheduleComponent } from './TAProj/assign-work-schedule/assign-work-schedule.component';
import { ValidationExceptionComponent } from './TAProj/validation-exception/validation-exception.component';
import { ModulesComponent } from './Administration/Definition/modules/modules.component';
import { SysFormsComponent } from './Administration/Definition/sys-forms/sys-forms.component';
import { StudentProfileComponent } from './SchoolStudent/student-profile/student-profile.component';
import { ClassesComponent } from './SchoolStudent/classes/classes.component';
import { TeacherComponent } from './teacher/teacher.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { AssignSubjectToTeacherComponent } from './assign-subject-to-teacher/assign-subject-to-teacher.component';
import { AttendenceComponent } from './attendence/attendence.component';
import { ComplaintForTeacherComponent } from './complaint-for-teacher/complaint-for-teacher.component';
import { ComplaintForStudentComponent } from './complaint-for-student/complaint-for-student.component';
import { AddNoticeBoardComponent } from './add-notice-board/add-notice-board.component';
import { ViewNoticeBoardComponent } from './view-notice-board/view-notice-board.component';
import { TeacherLeaveComponent } from './teacher-leave/teacher-leave.component';
import { StudentleaveComponent } from './studentleave/studentleave.component';
import { AssignmentdetailsComponent } from './assignmentdetails/assignmentdetails.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { CreatedatesheetComponent } from './createdatesheet/createdatesheet.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { CameraComponent } from './Twillio/camera/camera.component';
import { HomeComponent } from './Twillio/home/home.component';
import { ParticipantsComponent } from './Twillio/participants/participants.component';
import { RoomsComponent } from './Twillio/rooms/rooms.component';
import { DeviceSelectComponent } from './Twillio/settings/device-select.component';
import { SettingsComponent } from './Twillio/settings/settings.component';
import { DatePipe } from '@angular/common';
import { DefineLectureComponent } from './define-lecture/define-lecture.component';
import { ViewRecordedlectureComponent } from './view-recordedlecture/view-recordedlecture.component';
import { UploadRecordedlectureComponent } from './upload-recordedlecture/upload-recordedlecture.component';
import { CreatevideocourseComponent } from './createvideocourse/createvideocourse.component';
import { VideoLectureRequestComponent } from './video-lecture-request/video-lecture-request.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader.service';
import { StudentFeeComponent } from './student-fee/student-fee.component';
import { MyFeeComponent } from './my-fee/my-fee.component';
// import { StudentComponent } from './Classes/student/student.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    HomePageComponent,
    LookupComponent,
    UsersComponent,
    OrganizationComponent,
    EntityComponent,
    EmployeeStatusComponent,
    EmployeeTypeComponent,
    EmployeeComponent,
    GroupsComponent,
    PopupModalComponent,
    CustomConfirmModalsComponent,
    WorkScheduleComponent,
    NumberOnlyDirective,
    TaPolicyComponent,
    RamadanPeriodComponent,
    OvertimeTypesComponent,
    LeavesTypesComponent,
    ApplicationSettingsComponent,
    ManagersComponent,
    TAExceptionComponent,
    AssingTaPolicyComponent,
    ValidationExceptionComponent,
    AssignWorkScheduleComponent,
    ModulesComponent,
    SysFormsComponent,
    StudentProfileComponent,
    ClassesComponent,
    TeacherComponent,
    SubjectsComponent,
    AssignSubjectToTeacherComponent,
    AttendenceComponent,
    ComplaintForTeacherComponent,
    ComplaintForStudentComponent,
    AddNoticeBoardComponent,
    ViewNoticeBoardComponent,
    TeacherLeaveComponent,
    StudentleaveComponent,
    AssignmentdetailsComponent,
    AssignmentsComponent,
    CreatedatesheetComponent,
    StudentDashboardComponent,
    TeacherDashboardComponent,
    CameraComponent,
    HomeComponent,
    ParticipantsComponent,
    RoomsComponent,
    DeviceSelectComponent,
    SettingsComponent,
    DefineLectureComponent,
    ViewRecordedlectureComponent,
    UploadRecordedlectureComponent,
    CreatevideocourseComponent,
    VideoLectureRequestComponent,
    StudentFeeComponent,
    MyFeeComponent
    // StudentComponent  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModuleModule,
    BrowserAnimationsModule
    ],
    exports:[
      HttpClientModule
    ] ,
    entryComponents: [ PopupModalComponent ,CustomConfirmModalsComponent],
  providers: [LayoutModule ,LanguageTranslateService,DatePipe , LoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
