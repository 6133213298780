import { Component, Input, OnInit, ViewChild, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Classes } from 'app/Classes/classes';
import { Student, StudentFee, StudentFeeRequest } from 'app/Classes/student';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Sys_users } from 'app/Classes/login';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-student-fee',
  templateUrl: './student-fee.component.html',
  styleUrls: ['./student-fee.component.css']
})
export class StudentFeeComponent extends BaseComponent implements OnInit {
  classId: number = 0
  lstClasses: Classes[];
  lstStudent: StudentFee[];
  StudentFee_request: StudentFeeRequest

  s_status:string;

  Unpaid: boolean = true
  paid: boolean = true
  submitted : boolean = true

  


  displayedColumns: string[] = ['select', 'userFullName', 'className', 'feeAmount', 'dueDate', 'lateFee', 'status','view','update'];
  dataSource = new MatTableDataSource<StudentFee>();
  selection = new SelectionModel<StudentFee>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;



  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService,
    private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.lstClasses = [];

    this.StudentFee_request = new StudentFeeRequest();
  }

  ngOnInit() {
    this.getAllClasses();
  }

  getAllClasses() {
    debugger
    this._svc.GetAll('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }

  getStudentsbyClassid() {
    debugger;

     if (this.s_status == '0' || this.s_status == null) {
      this.StudentFee_request.status = 'All'
    }
    else if (this.s_status == '1') {
      this.StudentFee_request.status = 'paid'
    }
    else if (this.s_status == '2') {
      this.StudentFee_request.status = 'unpaid'
    }
    else if (this.s_status == '3' ) {
      this.StudentFee_request.status = 'submitted'
    }
    // else {
    //   this.global.openDialog("Student Fee", "No Record Found.");
    //   return;
    // }

    this._svc.Save(this.StudentFee_request, 'Student/GetStudentFeesByClassID').subscribe(
      data => {
        debugger;
        this.lstStudent = [];

        this.lstStudent = data;
        this.dataSource.data = this.lstStudent

      }, (err) => {
      });
  }

  clear(){
    
  }
  // Method to handle row selection when a checkbox is clicked
  selectRow(row: any): void {
    // Your implementation for handling row selection
  }

  // Method to retrieve student fee data from an API or service
  getStudentFees(): void {
    // Your implementation to fetch data from an API or service and assign it to dataSource
  }

  // Utility method to format date objects into a readable string format


  // Method to handle editing fee details based on the fee ID
  editFeeDetails(feeId: number): void {
    // Your implementation for editing fee details
  }

  // Method to filter the displayed data based on a search input
  applyFilter(filterValue: string): void {
    // Your implementation for filtering data
  }

  // Method to sort the table data by a specific column
  sortByColumn(column: string): void {
    // Your implementation for sorting data
  }

  // Method to handle pagination events (page change, page size change, etc.)
  paginate(event: PageTransitionEvent): void {
    // Your implementation for pagination handling
  }
  @Input() base64Document: string | null = null;
  viewFile(id: number){
    debugger;
    this._svc.GetAll( 'Student/viewFile?FeeId='+id).subscribe(
      data => {
        debugger;
        this.base64Document = data;

        this.downloadPDF(this.base64Document, id.toString())
      }, (err) => {
        alert("error")
      });
  }



  downloadPDF(base64Data: string, fileName: string) {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }


  markasPaid(id: number){
    debugger;
    this._svc.GetAll( 'Student/markasPaid?FeeIdstr='+ btoa(id.toString())).subscribe(
      data => {
        debugger;
      this.global.openDialog("Fee",data)
      this.getStudentsbyClassid();
      }, (err) => {
        this.global.openDialog("Fee","Some Error Occured!")
      });
  }
  convertIdToBase64(id: any): string {
    // Assuming id is a string
    const base64Encoded = btoa(id);
    return base64Encoded;
  }

}


