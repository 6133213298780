import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Login, UserInput, Sys_users } from '../Classes/login';
import { Lookup, LookUpTableValues_languages, LookUpTable, LookUpTableValues } from 'app/Classes/lookup';
import { OrgCompany, OrgLevel, OrgUnit, Org_WorkLocation, Org_logicalGroup, OrgCompany_Languages } from 'app/Classes/org';
import { Employee_Status, Employee_Type, Employee } from 'app/Classes/employee';
import { Sys_Groups } from 'app/Classes/groups';
import { WorkSechedule, SaveWorkSchedule_Time } from 'app/Classes/work-sechedule';
import { TAPolicy, TAPolicy_Break } from 'app/Classes/tapolicy';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  token: string = "";
  headers: HttpHeaders;
  ////////   EmployeeType/GetEmployeeStatusByCompany

  //  serviceIP: string = 'https://localhost:44381/api/'

  serviceIP: string = 'https://prodlmsapi.trots.education/api/'
  // serviceIP: string = 'http://localhost/SMSAPI/api/'
    // serviceIP: string = 'http://ammarmaqsood-001-site1.etempurl.com/api/'
    //  serviceIP: string = 'http://isabam-001-site2.atempurl.com/api/'
    // ////////TAAPI
      TA_serviceIP: string = 'http://isabam-001-site2.atempurl.com/api/';


  constructor(private httpclient: HttpClient) { }

  CreateHeader(){
    this.token = sessionStorage.getItem('token'); 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return headers;
  }
  getGenericParmas(value: any, paramName: string, UrlName: string, Region: string =""): Observable<any> {
    
    let headers = this.CreateHeader();
    var url = "";
    if(Region ==""){
        url = this.serviceIP + UrlName;
    }
    else if(Region =="TA") {
      url = this.TA_serviceIP + UrlName;
    }
    
    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.get(url ,   { params: param1 ,  headers: headers } )
  }

  DeleteGenericParmas(value: any, paramName: string, UrlName: string , Region: string =""): Observable<any> {
    let headers = this.CreateHeader();
    var url = "";
    if(Region ==""){
        url = this.serviceIP + UrlName;
    }
    else if(Region =="TA") {
      url = this.TA_serviceIP + UrlName;
    }
    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.delete(url ,   { params: param1 ,  headers: headers } )
  }

  LoginUser(Object: Login, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    
    var url = this.serviceIP + APIName;
    // return this.httpclient.post(url, Object , {headers: headers})
    return this.httpclient.post(url, Object )
  }
  GetModulesAndForms(Object: UserInput, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }

  GetDetails(UrlName: string): Observable<any> {
    let headers = this.CreateHeader();
    let hea = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    var Url = this.serviceIP + UrlName;
    return this.httpclient.get(Url , {headers: hea} );
  }

  SaveLKPValues(Object:LookUpTableValues , APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveLKPTranslations(Object:LookUpTableValues_languages , APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveOrgCompany(Object:OrgCompany , APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  GetOrgLevel(Object : OrgLevel , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  GetLookUpValuesByCompany(Object : LookUpTable , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.put(url, Object , {headers: headers})
  }
  SaveOrgUnit(Object : OrgUnit , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveWorkLocation(Object : Org_WorkLocation , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveLogicalGroup(Object : Org_logicalGroup , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveEmployeeStatus(Object : Employee_Status , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveEmployeeType(Object : Employee_Type , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SearchEmployeeByOrgUnit_Group_Location(Object : Employee , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  CreateApplicationUser(Object : Sys_users , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.put(url, Object , {headers: headers})
  }
  SearchApplicationUser(Object : Sys_users , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveGroup(Object : Sys_Groups, APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveOrgLanguages(Object : OrgCompany_Languages, APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }

  ///////////TAAPI
  TA_getGenericParmas(value: any, paramName: string, UrlName: string): Observable<any> {
    
    let headers = this.CreateHeader();
    var url = this.TA_serviceIP + UrlName;
    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.get(url ,   { params: param1 ,  headers: headers } )
  }
  SaveWorkSchedule(Object : WorkSechedule , APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.TA_serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveWorkSchedule_Time_svc(Object :SaveWorkSchedule_Time, APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.TA_serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }

  DeleteWorkSchedule_Time(value1: any,paramName1: string ,value2: any, paramName2: string, UrlName: string , Region: string =""): Observable<any> {
    let headers = this.CreateHeader();
    var url = "";
    if(Region ==""){
        url = this.serviceIP + UrlName;
    }
    else if(Region =="TA") {
      url = this.TA_serviceIP + UrlName;
    }
    let param1 = new HttpParams().set(paramName1, value1)
    param1.append(paramName2, value2);
    return this.httpclient.delete(url ,   { params: param1 ,  headers: headers } )
  }
  SaveTAPolicy(Object :TAPolicy, APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.TA_serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }
  SaveObject(Object :any, APIName: string = ''): Observable<any>{
    let headers = this.CreateHeader();
    var url = this.TA_serviceIP + APIName;
    return this.httpclient.post(url, Object , {headers: headers})
  }

  detelewithTwo( UrlName: string , Region: string =""): Observable<any> {
      let headers = this.CreateHeader();
      var url = "";
      if(Region ==""){
          url = this.serviceIP + UrlName;
      }
      else if(Region =="TA") {
        url = this.TA_serviceIP + UrlName;
      }
      return this.httpclient.delete(url )
    }

    GetAll(UrlName: string): Observable<any> {
      let headers = this.CreateHeader();
      let hea = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      var Url = this.serviceIP + UrlName;
      return this.httpclient.get(Url , {headers: hea} );
    }
    Save(Object :any, APIName: string = ''): Observable<any>{
      let headers = this.CreateHeader();
      var url = this.serviceIP + APIName;
      return this.httpclient.post(url, Object , {headers: headers})
    }



    put(Object :any, APIName: string = ''): Observable<any>{
      let headers = this.CreateHeader();
      var url = this.serviceIP + APIName;
      return this.httpclient.put(url, Object , {headers: headers})
    }

    SaveList(list :any[], APIName: string = ''): Observable<any>{
      let headers = this.CreateHeader();
      var url = this.serviceIP + APIName;
      return this.httpclient.post(url, JSON.stringify(list) , {headers: headers})
    }
}
