export class Classes {

    fee: number;
    fk_OrgUnitId: number;
    active: boolean;
    createdby: string;
    lastUpdatedby: string;
    className: string;
    classID: number;
    select: boolean;
    medium: string;
    section: string;
    classIncharge : number

    classDate: string
    timeFrom: string
    timeTo: string
    isOnline: boolean;
    /**
     *
     */
    constructor() {
        this.fee = 0;
        this.fk_OrgUnitId = 0;
        this.active = true;
        this.createdby = "";
        this.lastUpdatedby = "";
        this.className = "";
        this.classID = 0;
        this.select = false;
        this.medium = "";
        this.section = "";
        this.classIncharge = 0;
        this.isOnline = true;
        
        this.classDate = "";
        this.timeFrom = "";
        this.timeTo = "";
    }
}


export class DefineComplaintRecord
{
     defineComplaintRecordID : number;  
     complaintName : string;  
     complaintType : string;  
     complaintDetail : string;  
     createdBy : string;  
     createdDate : Date;  
     lastUpdatedBy : string;  
     lastUpdatedDate : Date;  
     isActive : boolean;  

     /**
      *
      */
     constructor() {
         this.defineComplaintRecordID = 0;  
         this.complaintName = "";  
         this.complaintType = "";  
         this.complaintDetail = "";  
         this.createdBy = "";  
         this.createdDate = new Date();  
         this.lastUpdatedBy = "";  
         this.lastUpdatedDate = new Date();  
         this.isActive = false;  
     }
}