import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.css']
})
export class ApplicationSettingsComponent  extends BaseComponent implements OnInit {
 
  displayedColumns: string[] = ['selected', 'overtimeTypeName' ,'overtimeTypeName_LL' ,	'minOvertime' ,'maxOvertime'  ,'compensateToLeave','mustRequested','approvalRequired','minAutoApproveDuration' ,'allowEditOverTime' ,'edit'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) { 
      super(languageTranslateService); 
    
    
    }

  ngOnInit() {
  }

}
