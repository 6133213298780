import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { OrgStructure, OrgUnit } from 'app/Classes/org';
declare const $: any;
@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.css']
})
export class EntityComponent extends BaseComponent implements OnInit {

  FormName: string= "Entity";
  lstOrgStructure : OrgStructure[];
  OrgStructure : OrgStructure;
  FKParentID: number = 0;
  checkHasChild: string = "";
  id: string = '';
  deleteCompany: string = "";
  deleteEntity: string = "";
  companyFKid: number;
  entityName: string;
  OrgUnit: OrgUnit;

    constructor(public languageTranslateService: LanguageTranslateService,
      private _svc: SharedService, public global: GlobalVariablesService) {
      super(languageTranslateService);
      // this.global.FormName = this.FormName;
      this.OrgStructure = new  OrgStructure();
      this.lstOrgStructure  = [];
      this.OrgUnit = new OrgUnit();
    }
      
  ngOnInit() {
    this.GetOrgStructure()
  }

  SaveOrgUnit(){
  ;  
  if(this.OrgUnit.fK_CompanyId == 0){
    this.OrgUnit.cREATED_BY = this.global.getUserID();
    this.OrgUnit.lAST_UPDATE_BY = "";
  }else {
    this.OrgUnit.cREATED_BY = "";
    this.OrgUnit.lAST_UPDATE_BY = this.global.getUserID();
  }
    this._svc.SaveOrgUnit(this.OrgUnit, "OrgUnit/SaveOrgUnit").subscribe(
      data => {
        this.GetOrgStructure();

        this.global.openDialog("Org Unit","Record has been saved.");
      }, (err) => {
        this.global.openDialog("Org Unit","Some Error Occured.");
      });
  }

  GetOrgStructure(){
    ;
    // "OrgUnit/GetOrgStructure"
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId,'FK_CompanyId', "OrgUnit/GetOrgStructure").subscribe(
      data => {
        this.lstOrgStructure = data;
        this.CompanyTree();

       }, (err) => {
        // alert("error occured ")
      });
  }
  GetOrgUnit(FK_CompanyId : any){
    ;
    this._svc.getGenericParmas(FK_CompanyId,'OrgUnitId', "OrgUnit/GetOrgUnit").subscribe(
      data => {
        this.OrgUnit = data;
       }, (err) => {
        // alert("error occured ")
      });
  }
  AddEntity(){
    this.OrgUnit = new OrgUnit();
    this.OrgUnit.fK_CompanyId = this.global.FK_CompanyId();
    
  }
  CompanyTree(): void {
    ;
    var self = this;
    var data = this.lstOrgStructure;
    ;
    var source =
    {
      datatype: "json",
      datafields: [
        { name: 'id' },
        { name: 'parentid' },
        { name: 'unitName' },
        { name: 'value' },
      ],
      id: 'id',
      localdata: data
    };
    var dataAdapter = new $.jqx.dataAdapter(source);
    dataAdapter.dataBind();
    var records = dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'unitName', map: 'label' }]);
    $('#jqxWidget').jqxTree({ source: records, width: '300px' });
    $('#jqxWidget').on('select', function (event) {
;
      var args = event.args;
      var item = $('#jqxWidget').jqxTree('getItem', args.element);
      var Level = item.level;
      var myConEle = document.querySelector('[ng-controller=myCntrl]');
      var trt = item.value;
      self.checkHasChild = trt;
      var itemsplit = trt.split(":");
      var EntId = itemsplit[0];
      var EntityId = parseInt(EntId);
      var id = itemsplit[1];
      var Company = itemsplit[2];
      var ID = parseInt(id);
      self.companyFKid = id;
      
      if (Company == "Unit") {
        self.deleteCompany = "Company" + "," + id;
        self.deleteEntity = "";
        self.FKParentID = 0; 
         self.GetOrgUnit(EntId);
        // self.GetLevelsByCompanyId(id);
      }
      else {
        self.deleteEntity = "Entity" + "," + EntityId;
        self.deleteCompany = "";
        // self.GetEntityById(EntityId);
      }
    });
  };

}
