import { Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from '../Shared/language-translate.service';
import { GlobalVariablesService } from '../Shared/global-variables.service';
import { SharedService } from '../Shared/shared.service';
import { BaseComponent } from '../Shared/base-component';
declare var Highcharts: any; // Declare Highcharts variable
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent  extends BaseComponent  implements OnInit {

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
  } 
  ngOnInit(): void {
    this.drawCharts();
  }

  drawCharts(): void {
    // Chart 1: Attendance Percentage per Classes
    const attendanceData = [85, 90, 88];
    const classes = ['Class A', 'Class B', 'Class C'];

    const options = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Attendance Percentage per Classes'
      },
      xAxis: {
        categories: classes
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        }
      },
      series: [{
        name: 'Attendance',
        data: attendanceData
      }]
    };

    Highcharts.chart('attendanceChart', options);

    // Legend for Chart 1
    const attendanceLegend = document.getElementById('attendanceLegend')!;
    attendanceLegend.innerHTML = classes.map((className, index) => {
      return `<div style="color: ${Highcharts.getOptions().colors[index]}">
                 <div style="width: 10px; height: 10px; display: inline-block; margin-right: 5px; background-color: ${Highcharts.getOptions().colors[index]}"></div>
                 ${className}
              </div>`;
    }).join('');

    // Repeat the above logic for other charts

    // Chart 2: Fee Collection Graph
    const feeCollectionData = [5000, 6000, 5500, 7000];
    const months = ['Jan', 'Feb', 'Mar', 'Apr'];

    const feeCollectionOptions = {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Fee Collection Graph'
      },
      xAxis: {
        categories: months
      },
      yAxis: {
        title: {
          text: 'Amount'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Fee Collection',
        data: feeCollectionData
      }]
    };

    Highcharts.chart('feeCollectionChart', feeCollectionOptions);

    // Chart 3: Complaints per Day
    const complaintsData = [5, 8, 3, 6, 7];
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

    const complaintsOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Complaints per Day'
      },
      xAxis: {
        categories: days
      },
      yAxis: {
        title: {
          text: 'Count'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Complaints',
        data: complaintsData
      }]
    };

    Highcharts.chart('complaintsChart', complaintsOptions);

    // Chart 4: Conducted Tests per Day
    const testsData = [10, 12, 8, 11, 9];

    const testsOptions = {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Conducted Tests per Day'
      },
      xAxis: {
        categories: days
      },
      yAxis: {
        title: {
          text: 'Count'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Tests Conducted',
        data: testsData
      }]
    };

    Highcharts.chart('testsChart', testsOptions);

    // Chart 5: Student On Leave Types
    const leaveData = [30, 20, 15];
    const leaveTypes = ['Medical', 'Vacation', 'Personal'];

    const leaveOptions = {
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Student On Leave Types'
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
      },
      series: [{
        name: 'Leave Types',
        data: leaveData.map((percentage, index) => ({
          name: leaveTypes[index],
          y: percentage
        }))
      }]
    };

    Highcharts.chart('leaveChart', leaveOptions);



    const unpaidFeesData = [20, 10, 15];
    const paidFeesData = [65, 80, 73];
    const classes_fee = ['Class A', 'Class B', 'Class C'];
  
    const optionsFees = {
      chart: {
        type: 'bar' 
      },
      title: {
        text: 'Unpaid and Paid Fees per Classes'
      },
      xAxis: {
        categories: classes_fee
      },
      yAxis: {
        title: {
          text: 'Fee'
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        x: -10,
        y: 25,
        floating: true,
        borderWidth: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.85)'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        }
      },
      series: [{
        name: 'Unpaid Fee',
        data: unpaidFeesData
      }, {
        name: 'Paid Fee',
        data: paidFeesData
      }]
    };
  
    Highcharts.chart('feesChart', optionsFees);
  }
}