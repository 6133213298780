export class Lookup {
    lookUpValueId : number ;
    FK_LookUpId : number ;
    lang : number ;
    CREATED_DATE : Date;
    LAST_UPDATE_DATE : Date;
    LAST_UPDATE_BY : string ;
    lookUpValueCode : string ;
    lookUpValue : string ;
    Remarks : string ;
    CREATED_BY : string ;
    IsActive : boolean;
    select: boolean;
    
    /**
     *
     */
    constructor() {
        this.lookUpValueId = 0;
        this.FK_LookUpId = 0;
        this.lang = 0;
        this.CREATED_DATE = new Date();
        this.LAST_UPDATE_DATE = new Date();
        this.LAST_UPDATE_BY ="";
        this.lookUpValueCode ="";
        this.lookUpValue ="";
        this.Remarks ="";
        this.CREATED_BY ="";
        this.IsActive = false;
        this.select = false;

    }
}

export class LookUpTableValues_languages
{
    FK_LookUpValueId : number;
    fK_langId : number;
    lookUpValue_lang : string;
    LanguageName : string;

    constructor() {
        this.FK_LookUpValueId = 0;
        this.fK_langId = 0;
        this.lookUpValue_lang = "";
        this.LanguageName = "";
        
    }
}
export class LKPTranslations
{
    fK_langId : number;
    lookUpValue_lang : string;
    /**
     *
     */
    constructor() {
        this.fK_langId = 0;
        this.lookUpValue_lang = "";
    }
}
export class LookUpTableValues
    {
        lang : number;
        lookUpValueId : number;
        fK_LookUpId : number;
        cREATED_DATE :Date;
        lAST_UPDATE_DATE :Date;
        lookUpValueCode : string;
        lookUpValue : string;
        remarks : string;
        cREATED_BY : string;
        lAST_UPDATE_BY : string;
        isActive : boolean;
        lkpTrans :LKPTranslations[];
        /**
         *
         */
        constructor() {
        
        this.lang = 0;
        this.lookUpValueId = 0;
        this.fK_LookUpId = 0;
        this.cREATED_DATE  = new Date;
        this.lAST_UPDATE_DATE  = new Date;
        this.lookUpValueCode = "";
        this.lookUpValue  = "";
        this.remarks  = "";
        this.cREATED_BY = "";
        this.lAST_UPDATE_BY  = "";
        this.isActive = false;
        this.lkpTrans = [];
        }
    }

    export class LookUpTable
    {
        FK_CompanyId : number;
        LKPName : string;
        lang : number;
        /**
         *
         */
        constructor() {
            this.FK_CompanyId = 0;
            this.LKPName = "";
            this.lang = 0;
        }
    }