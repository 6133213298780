import { Component, OnInit } from '@angular/core';
import { Sys_Groups, Sys_Priviliges } from 'app/Classes/groups';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { UserInput, Menu } from 'app/Classes/login';
declare var swal: any;

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})

export class GroupsComponent extends BaseComponent implements OnInit {
  objUserInput: UserInput;
  Menu: Menu[];
  Sys_Groups: Sys_Groups;
  lstSys_Groups: Sys_Groups[];
  Sys_Priviliges : Sys_Priviliges;
  objSys_Groups : Sys_Groups;
  

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.Sys_Groups = new Sys_Groups();
    this.lstSys_Groups = [];
    this.objUserInput = new UserInput();
    this.Menu = [];
    this.objSys_Groups =new  Sys_Groups();
    this.Sys_Priviliges = new Sys_Priviliges();
  }

  displayedColumns: string[] = ['select', 'GroupName', 'GroupName_LL', 'edit'];//, 'DefaultPage'
  dataSource = new MatTableDataSource<Sys_Groups>();
  selection = new SelectionModel<Sys_Groups>(true, []);

  ngOnInit() {
    this.GetAllGroupsByCompany();
  }
  ChearSys_user(){
    this.Sys_Groups = new Sys_Groups();
  }
  saveGroup(){
    
    this.Sys_Groups.priviliges = [];
    for (var i = 0; i < this.Menu.length; i++) {
      for (var j = 0; j <  this.Menu[i].modules.length; j++) {
        for (var k = 0; k <  this.Menu[i].modules[j].forms.length; k++) {
         if(this.Menu[i].modules[j].forms[k].selected ==true){
          this.Sys_Priviliges = new Sys_Priviliges();
          this.Sys_Priviliges.formID = this.Menu[i].modules[j].forms[k].formid;
          this.Sys_Groups.priviliges.push(this.Sys_Priviliges); 
         }
        }
      }
    }
    
    this.Sys_Groups.defaultPage  = Number(this.Sys_Groups.defaultPage );
    this.Sys_Groups.fK_CompanyId = this.global.FK_CompanyId();
    this._svc.SaveGroup(this.Sys_Groups, 'Groups/SaveGroup').subscribe(
      data => {
        ;
        this.Clear();
        this.global.openDialog("Define Groups","Record has been saved." )
        this.GetAllGroupsByCompany();
        document.getElementById('AddGroup').style.display='none';
      }, (err) => {
        // alert("error occured ")
      });
  }
  GetAllGroupsByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Groups/GetAllGroupsByCompany').subscribe(
      data => {
        ;
        this.lstSys_Groups = data;
        if (this.lstSys_Groups.length > 0) {


          for (var i = 0; i < this.lstSys_Groups.length; i++) {
            this.lstSys_Groups[i].select = false;
          }
          this.dataSource.data = this.lstSys_Groups;
        } else {
          this.global.openDialog("Org Unit", "No record fund.");
        }
      }, (err) => {
        // alert("error occured ")
      });
  }

  edit(groupID: any) {
    this.AddNew();
    setTimeout(() => {
      this.Sys_Groups = this.lstSys_Groups.filter(x => x.groupID == groupID)[0];
      ;
          this._svc.getGenericParmas(this.Sys_Groups.groupID, "GroupID", 'Groups/getGroup').subscribe(
            data => {
              this.objSys_Groups = data;
               
              for (var i = 0; i < this.Menu.length; i++) {
                for (var j = 0; j < this.Menu[i].modules.length; j++) {
                  for (var k = 0; k < this.Menu[i].modules[j].forms.length; k++) {
                    for(var s = 0 ; s < this.objSys_Groups.priviliges.length ; s++){
                       if(this.Menu[i].modules[j].forms[k].formid == this.objSys_Groups.priviliges[s].formID){
                        this.Menu[i].modules[j].forms[k].selected = true;
                        break;
                       }
                    }
                  }
                }
              }
            }, (err) => {
              // alert("error occured ")
            });
     }, 20);
   }
  Clear() {
    this.Sys_Groups = new Sys_Groups();
  }

  selectGroups(groupID: any) {
    
  }
  Delete() {
    
  }
  AddGroup(){}
  Close(){
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';  
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '155';  
  }

  AddNew() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '0';


    this.Sys_Groups = new Sys_Groups();
    var lang = sessionStorage.getItem('lang');

    ;
    this._svc.getGenericParmas(Number(lang), "lang", 'Groups/GetAllPagesAndModules').subscribe(
      data => {
        ;
        this.Menu = data;
    for (var i = 0; i < this.Menu.length; i++) {
      for (var j = 0; j <  this.Menu[i].modules.length; j++) {
        for (var k = 0; k <  this.Menu[i].modules[j].forms.length; j++) {
          this.Menu[i].modules[j].forms[k].selected = false;
        }
      }
    }
      }, (err) => {

      });


  }
  valueChange(MenuIndex : number, modulesIndex: number , formsIndex: number){
    this.Menu[MenuIndex].modules[modulesIndex].forms[formsIndex].selected = !this.Menu[MenuIndex].modules[modulesIndex].forms[formsIndex].selected;
  }
  SaveEmployeeType() { }
}
