import { Component, OnInit } from '@angular/core';
import { Classes } from 'app/Classes/classes';
import { teacher } from 'app/Classes/teacher';
import { ApiServiceService } from 'app/Shared/api-service.service';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { VideoCourse } from 'app/createvideocourse/createvideocourse.component';
import { uploadrecordedlectureProperties } from 'app/upload-recordedlecture/upload-recordedlecture.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-view-recordedlecture',
  templateUrl: './view-recordedlecture.component.html',
  styleUrls: ['./view-recordedlecture.component.css']
})
export class ViewRecordedlectureComponent extends BaseComponent implements OnInit {

  lstVideoCourse : VideoCourse[];
  lstVideoCourse_All : VideoCourse[];
  lstTeacher: teacher[];
  pageNumber: number = 1
  pageSize: number = 10
  isNew:boolean = false;

  CourseSubscriptionClass : CourseSubscriptionClass;
  lstCourseSubscriptionClass : CourseSubscriptionClass[];
  lstuploadrecordedlectureProperties : uploadrecordedlectureProperties[]

  resData: any;
  baseURLFile :string =""
  envData;
  baseURL : string ="";
  
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private apiService: ApiServiceService) {
    super(languageTranslateService);
    this.lstVideoCourse = []
    this.lstTeacher = [];
    this.lstVideoCourse_All = [];
    this.CourseSubscriptionClass = new  CourseSubscriptionClass();
    this.lstCourseSubscriptionClass = [];
    this.lstuploadrecordedlectureProperties = []
    this.envData = environment;
    this.baseURL = this.envData.apiConn;    
    this.baseURLFile = this.envData.baseURL

  }
fee : number = 0;
courseId:number =0
teacherId:number =0
  ngOnInit() {
    this.getAllTeacher()
    this.getAllCourse()
    this.CourseSubscriptionsubscribedcourses();
  }

  getAllTeacher() {
    this._svc.GetDetails('Teacher/GetAllTeacher').subscribe(
      data => {
        this.lstTeacher = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  getAllCourse(){
    this._svc.GetDetails("Course?pageNumber=" + this.pageNumber + "&pageSize=100000" ).subscribe(rtnData => {
       debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstVideoCourse_All= rtnData.data;
         }  
      });
  }

  CourseSubscriptionsubscribedcourses(){
    var userId =  sessionStorage.getItem("userid")
    this._svc.GetDetails("CourseSubscription/subscribedcourses?studentId="+userId ).subscribe(rtnData => {
       debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstVideoCourse= rtnData.data;
         }  
      });

  }
  NewCourse(){
    this.isNew = true;
  }
  Search(){
  debugger;
    this.lstuploadrecordedlectureProperties = []
    var userId =  sessionStorage.getItem("userid")
    //CourseSubscription/lectures/2019?courseId=4&pageNumber=1&pageSize=100
    this._svc.GetDetails("CourseSubscription/lectures/"+userId+"?courseId="+this.courseId+"&pageNumber=" + this.pageNumber + "&pageSize="+ this.pageSize ).subscribe(rtnData => {
      debugger;
       console.log('rtnData', rtnData);
        if (rtnData.success == true) {
          this.lstuploadrecordedlectureProperties= rtnData.data;
          for(var i = 0 ; i< this.lstuploadrecordedlectureProperties.length;  i++){
            this.lstuploadrecordedlectureProperties[i].filePath  = this.baseURLFile+ this.lstuploadrecordedlectureProperties[i].filePath; 
          }
        }  
     });
  
  }

  Cancel(){
    this.isNew = false;
  }

  next(){
    this.pageNumber = this.pageNumber + 1;
    this.Search()    
  }

  previous(){
    if(this.pageNumber != 1){
    this.pageNumber = this.pageNumber - 1;
    this.Search()
    }
  }

  getFee(){
    debugger;
    var fee = this.lstVideoCourse_All.filter( x => x.id == this.CourseSubscriptionClass.courseId)[0];
    this.fee = Number(fee.fee)
  }
  Save(){
    var userId =  sessionStorage.getItem("userid")
    this.CourseSubscriptionClass.studentId = Number(userId);
    this._svc.Save(this.CourseSubscriptionClass   ,"CourseSubscription" ).subscribe(rtnData => {
       debugger;
        console.log('rtnData', rtnData);
        this.global.openDialog("Teacher", "Request has been submitted.");
      });
  }
}



export class CourseSubscriptionClass{
  studentId: number;
  courseId: number;
  teacherId: number;

  /**
   *
   */
  constructor() {
    this.studentId = 0;
    this.courseId = 0;
    this.teacherId = 0;
  }
}