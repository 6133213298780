import { Injectable } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderComponent: LoaderComponent;

  setLoaderComponent(loaderComponent: LoaderComponent) {
    this.loaderComponent = loaderComponent;
  }

  showLoader() {
    if (this.loaderComponent) {
      this.loaderComponent.showLoader = true;
    }
  }

  hideLoader() {
    if (this.loaderComponent) {
      this.loaderComponent.showLoader = false;
    }
  }
}
