import { Assignments, Subjects } from 'app/Classes/subjects';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent extends BaseComponent implements OnInit {
  objAssignments :Assignments;
  lstAssignments :Assignments [];
  lstSubjects: Subjects[];
   imageSrc: any;
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService) 
    this.objAssignments = new Assignments();
    this.lstAssignments =  [];
    this.lstSubjects =[];
  }

  displayedColumns: string[] = ['select'  
  ,'subjectID'
  ,'subjectName'
  ,'assingmentMarks'
  ,'dueDate'
  ,'assigmnentFileName'
  ,'assignmentDetails'
  ,'assignmentSubject'
  ,'Edit'];
  dataSource = new MatTableDataSource<Assignments>();
  selection = new SelectionModel<Assignments>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  ngOnInit() {
    this.GetAllAssignments();
    this.GetAllSubjects()
  }

  Save(){
    debugger;    
    this.objAssignments.createdBy = this.global.getUserID();
    this.objAssignments.lastUpdatedby = this.global.getUserID();   
    this.objAssignments.createdDate = new Date(this.objAssignments.createdDate);
    this.objAssignments.lastupdatedDate = new Date(this.objAssignments.lastupdatedDate);
    this.objAssignments.assingmentMarks = Number(this.objAssignments.assingmentMarks);
    this.objAssignments.subjectID = Number(this.objAssignments.subjectID)
    this.objAssignments.dueDate = new Date(this.objAssignments.dueDate);

    this._svc.Save(this.objAssignments, 'Assignments/AddUpdateAssignments').subscribe(
      data => {
        if (data.includes("Success")) {
          this.GetAllAssignments();
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  GetAllSubjects() {
    debugger;
    this._svc.GetAll("Subjects/GetAllSubjects").subscribe(
      data => {
        debugger;
        this.lstSubjects = data;
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
  edit(id: any) {
    this.objAssignments = this.lstAssignments.filter(x => x.assignmentID == id)[0];
  }
  GetAllAssignments(){
    var id =this.global.getUserID();
    this._svc.GetDetails( "Assignments/GetAllAssignments").subscribe(
      data => {
        debugger;
        this.lstAssignments = data;
        if (this.lstAssignments.length > 0) {
          for (var i = 0; i < this.lstAssignments.length; i++) {
            this.lstAssignments[i].select = false;
          }
          this.dataSource.data = this.lstAssignments;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Subject", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }

  handleInputChange(e, id: any = '') {
    debugger;
    let me = this;
    let file = e.target.files[0];
   this.objAssignments.assignmentDetails = file.name;
   // this.objAssignments.assigmnentFileName= file.name.split('.')[1];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = this._handleReaderLoaded.bind(this, id);
  }
  // transform() {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64, ' + this.imagepath);//data:image/jpg;base64, 
  // }
  _handleReaderLoaded(id: any, e) {

    let reader = e.target;
    this.objAssignments.filedata = reader.result;
    // this.objSaveFiles.Image = reader.result;
    // this.imagepath = this.objSaveFiles.Image.replace('data:image/png;base64,', '');
   // this.transform();
    // var item = this.secondlistGeoSpatialMaps.filter(a => a.GeoMapId == id)
    // if (item == null || item == undefined || item.length == 0) {
    //   var item1 = this.listGeoSpatialMaps.filter(b => b.GeoMapId == id)
    //   item1.filter(c => c.UploadedMap = this.imagepath);

    // }
    // else {
    //   item.filter(c => c.UploadedMap = this.imagepath);
    // }
  }
}
