import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { TAPolicy, TAPolicy_Break, TAPolicy_Voilation, TAPolicy_ViolationActions } from 'app/Classes/tapolicy';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OvertimeType } from 'app/Classes/overtime-type';
@Component({
  selector: 'app-ta-policy',
  templateUrl: './ta-policy.component.html',
  styleUrls: ['./ta-policy.component.css']
})
export class TaPolicyComponent extends BaseComponent implements OnInit {
  objTAPolicy: TAPolicy;
  listTAPolicy: TAPolicy[];
  objTAPolicy_Break: TAPolicy_Break;
  lstTAPolicy_Break: TAPolicy_Break[];
  objTAPolicy_Voilation: TAPolicy_Voilation;
  lstTAPolicy_Voilation: TAPolicy_Voilation[];
  list_dropdown_TAPolicy_Voilation: TAPolicy_Voilation[];
  objTAPolicy_ViolationActions: TAPolicy_ViolationActions;
  lstTAPolicy_ViolationActions: TAPolicy_ViolationActions[];
  breakToTime: string = ""
  breakFromTime: string = ""
  bBeforeSchedule: boolean = false;
  bAfterSchedule: boolean = false;
  bBeforeAfterSchedule: boolean = false;
  s_beforeAfterSchedule: string = "";
  formGroup: FormGroup;
  listOvertimeType: OvertimeType[];
  repeaterList_ViolationActions: TAPolicy_ViolationActions[];
  repeaterObject_ViolationActions: TAPolicy_ViolationActions;
  WorkdayOvertimeType: OvertimeType;
  OffdayOvertimeType: OvertimeType;
  HolidayOvertimeType: OvertimeType;
  ReligionHolidayOvertimeType: OvertimeType;
  panelOpenState = false;
  APIcounter: number =0;
  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService,
    private formBuilder: FormBuilder) {
    super(languageTranslateService);
    this.objTAPolicy = new TAPolicy();
    this.listTAPolicy = [];
    this.objTAPolicy_Break = new TAPolicy_Break();
    this.objTAPolicy_Voilation = new TAPolicy_Voilation();
    this.list_dropdown_TAPolicy_Voilation = [];
    this.objTAPolicy_ViolationActions = new TAPolicy_ViolationActions();
    this.lstTAPolicy_Break = [];
    this.lstTAPolicy_Voilation = [];
    this.lstTAPolicy_ViolationActions = [];
    this.listOvertimeType = [];
    this.WorkdayOvertimeType = new OvertimeType();
    this.OffdayOvertimeType = new OvertimeType();
    this.HolidayOvertimeType = new OvertimeType();
    this.ReligionHolidayOvertimeType = new OvertimeType();
    this.repeaterList_ViolationActions = [];
    this.repeaterObject_ViolationActions = new TAPolicy_ViolationActions();
  }

  //  displayedColumns: string[] = ['Selected', 'TAPolicyName', 'TAPolicyName_LL', 'GraceIn', 'GraceOut', 'NoOfNotCompleteHours', 'BeforeAfterSchedule', 'DelayIsFromGrace', 'EarlyOutIsFromGrace', 'Consider_FILO_Only', 'ConsiderAbsentIfNotCompleteNoOfHours', 'Active', 'OT_Eligibility', 'OT_HasHighTime', 'edit'];
  displayedColumns: string[] = ['Selected', 'TAPolicyName', 'TAPolicyName_LL', 'edit'];
  dataSource = new MatTableDataSource<TAPolicy>();
  selection = new SelectionModel<TAPolicy>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  Break_displayedColumns: string[] = ['Selected', 'fromTime', 'toTime', 'flixibleDuration', 'noOfAllowedBreak', 'toBeCompensated', 'isFlixible', 'edit'];
  Break_dataSource = new MatTableDataSource<TAPolicy_Break>();
  Break_selection = new SelectionModel<TAPolicy_Break>(true, []);
  @ViewChild(MatSort, { static: true }) Break_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) Break_paginator: MatPaginator;

  Voilation_displayedColumns: string[] = ['Selected', 'violationName', 'violationName_LL', 'violationRuleType', 'variable1', 'variable2', 'variable3', 'scenarioMode', 'considerAbsent', 'edit'];
  Voilation_dataSource = new MatTableDataSource<TAPolicy_Voilation>();
  Voilation_selection = new SelectionModel<TAPolicy_Voilation>(true, []);
  @ViewChild(MatSort, { static: true }) Voilation_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) Voilation_paginator: MatPaginator;


  Action_displayedColumns: string[] = ['Selected', 'actionSequence', 'fK_lkpViolationActionId', 'edit'];
  Action_dataSource = new MatTableDataSource<TAPolicy_ViolationActions>();
  Action_selection = new SelectionModel<TAPolicy_ViolationActions>(true, []);
  @ViewChild(MatSort, { static: true }) Action_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) Action_paginator: MatPaginator;



  ngOnInit() {
    this.pushdata_forRepeater()
    this.createForm();
    this.GetAllTAPolicy_ByCompany();
    this.GetAllTAPolicy_Break_ByCompany();
    this.GetAllTAPolicy_Violations_ByCompany();
    this.GetAllTAPolicy_ViolationActions_ByCompany();
    this.GetAllOvertime();
  }
  pushdata_forRepeater() {
    
    this.repeaterObject_ViolationActions = new TAPolicy_ViolationActions();
    this.repeaterObject_ViolationActions.fK_CompanyId = this.global.FK_CompanyId();
    this.repeaterList_ViolationActions.push(this.repeaterObject_ViolationActions);
  }
  DeleteRepeaterData(index: number) {
    var list = this.repeaterList_ViolationActions;
    this.repeaterList_ViolationActions = [];
    for (var i = 0; i < list.length; i++) {
      if (i != index) {
        this.repeaterList_ViolationActions.push(list[i]);
      }
    }
  }
  GetAllTAPolicy_ByCompany() {
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "TAPolicy/GetAllTAPolicy_ByCompany", "TA").subscribe(
      data => {
        ;
        this.listTAPolicy = [];
        this.listTAPolicy = data;
        for (var i = 0; i < this.listTAPolicy.length; i++) {
          this.listTAPolicy[i].Selected = false;
        }
        this.dataSource.data = this.listTAPolicy;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      }, (err) => {
        // alert("error occured ")
      });
  }

  GetAllTAPolicy_Break_ByFK_PolicyId(id: any) {
    ;

    this._svc.getGenericParmas(id, 'FK_PolicyId', "TAPolicy_Break/GetAllTAPolicy_Break_ByFK_PolicyId", "TA").subscribe(
      data => {
        ;
        this.lstTAPolicy_Break = [];
        this.lstTAPolicy_Break = data;
        for (var i = 0; i < this.lstTAPolicy_Break.length; i++) {
          this.lstTAPolicy_Break[i].Selected = false;
          this.lstTAPolicy_Break[i].DisplayfromTime = this.convertIntoTime(this.lstTAPolicy_Break[i].fromTime)
          this.lstTAPolicy_Break[i].DisplaytoTime = this.convertIntoTime(this.lstTAPolicy_Break[i].toTime)
        }
        this.Break_dataSource.data = this.lstTAPolicy_Break;
        this.Break_dataSource.sort = this.Break_sort;
        this.Break_dataSource.paginator = this.Break_paginator;
      }, (err) => {
        // alert("error occured ")
      });
  }

  GetAllTAPolicy_Break_ByCompany() {
    ;
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "TAPolicy_Break/GetAllTAPolicy_Break_ByCompany", "TA").subscribe(
      data => {
        ;
        this.lstTAPolicy_Break = [];
        this.lstTAPolicy_Break = data;
        for (var i = 0; i < this.lstTAPolicy_Break.length; i++) {
          this.lstTAPolicy_Break[i].Selected = false;
          this.lstTAPolicy_Break[i].DisplayfromTime = this.convertIntoTime(this.lstTAPolicy_Break[i].fromTime)
          this.lstTAPolicy_Break[i].DisplaytoTime = this.convertIntoTime(this.lstTAPolicy_Break[i].toTime)
        }
        this.Break_dataSource.data = this.lstTAPolicy_Break;
        this.Break_dataSource.sort = this.Break_sort;
        this.Break_dataSource.paginator = this.Break_paginator;
      }, (err) => {
        // alert("error occured ")
      });
  }

  GetAllTAPolicy_Violations_ByFK_PolicyId_Table(id: any) {
    // TAPolicy_Violations/GetAllTAPolicy_Violations_ByFK_PolicyId
    ;
    this._svc.getGenericParmas(id, 'FK_TAPolicyId', "TAPolicy_Violations/GetAllTAPolicy_Violations_ByFK_PolicyId", "TA").subscribe(
      data => {
        ;
        this.lstTAPolicy_Voilation = [];
        this.lstTAPolicy_Voilation = data;
        for (var i = 0; i < this.lstTAPolicy_Voilation.length; i++) {
          this.lstTAPolicy_Voilation[i].Selected = false;
        }
        this.Voilation_dataSource.data = this.lstTAPolicy_Voilation;
        this.Voilation_dataSource.sort = this.Voilation_sort;
        this.Voilation_dataSource.paginator = this.Voilation_paginator;
      }, (err) => {
        // alert("error occured ")
      });
  }

  GetAllTAPolicy_Violations_ByCompany() {
    // TAPolicy_Violations/GetAllTAPolicy_Violations_ByFK_PolicyId
    ;
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "TAPolicy_Violations/GetAllTAPolicy_Violations_ByCompany", "TA").subscribe(
      data => {
        ;
        this.lstTAPolicy_Voilation = [];
        this.lstTAPolicy_Voilation = data;
        for (var i = 0; i < this.lstTAPolicy_Voilation.length; i++) {
          this.lstTAPolicy_Voilation[i].Selected = false;
        }
        this.Voilation_dataSource.data = this.lstTAPolicy_Voilation;
        this.Voilation_dataSource.sort = this.Voilation_sort;
        this.Voilation_dataSource.paginator = this.Voilation_paginator;
      }, (err) => {
        // alert("error occured ")
      });
  }
  //  TAPolicy_ViolationActions/GetAllTAPolicy_ViolationActions_ByCompany?FK_CompanyId=1
  GetAllTAPolicy_ViolationActions_ByCompany() {

    ;
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "TAPolicy_ViolationActions/GetAllTAPolicy_ViolationActions_ByCompany", "TA").subscribe(
      data => {
        ;
        this.lstTAPolicy_ViolationActions = [];
        this.lstTAPolicy_ViolationActions = data;
        for (var i = 0; i < this.lstTAPolicy_ViolationActions.length; i++) {
          this.lstTAPolicy_ViolationActions[i].Selected = false;
        }
        this.Action_dataSource.data = this.lstTAPolicy_ViolationActions;
        this.Action_dataSource.sort = this.Action_sort;
        this.Action_dataSource.paginator = this.Action_paginator;
      }, (err) => {
        // alert("error occured ")
      });
  }
  Checked_Break(id: any) {
    ;
    for (var i = 0; i < this.Break_dataSource.data.length; i++) {
      if (this.Break_dataSource.data[i].breakId == id) {
        this.Break_dataSource.data[i].Selected = !this.Break_dataSource.data[i].Selected;
      }
    }
  }
  edit_Break(id: any) {
    this.objTAPolicy_Break = this.Break_dataSource.data.filter(x => x.breakId == id)[0];
    this.breakToTime = this.convertIntoTime(this.objTAPolicy_Break.toTime)
    this.breakFromTime = this.convertIntoTime(this.objTAPolicy_Break.fromTime)
  }
  editTAPolicy(id: any) {
    ;
    this.objTAPolicy = this.dataSource.data.filter(x => x.taPolicyId == id)[0];
    this.GetOvertime_TypesDetails(this.objTAPolicy.fK_OTHoliday_OT_TypeId,"Holiday");
    this.GetOvertime_TypesDetails(this.objTAPolicy.fK_WorkDay_OT_TypeId,"WorkDay");
    this.GetOvertime_TypesDetails(this.objTAPolicy.fK_OffDay_OT_TypeId,"OffDay");
    this.GetOvertime_TypesDetails(this.objTAPolicy.fK_ReligionHoliday_OT_TypeId,"ReligionHoliday");

    this.onItemChange_r(this.objTAPolicy.beforeAfterSchedule);
    this.GetAllTAPolicy_Violations_ByFK_PolicyId(this.objTAPolicy.taPolicyId);
    this.GetAllTAPolicy_Violations_ByFK_PolicyId_Table(this.objTAPolicy.taPolicyId)
    this.GetAllTAPolicy_Break_ByFK_PolicyId(this.objTAPolicy.taPolicyId)
  }
  GetOvertime_TypesDetails(id: any , type: string){
    this._svc.getGenericParmas(id, "OvertimeTypeId", "Overtime_Types/GetOvertime_TypesDetails", "TA").subscribe(
      data => {
        if(type == "Holiday"){
          this.HolidayOvertimeType = data;
        }
        if(type == "WorkDay"){
          this.WorkdayOvertimeType = data;
        }
        if(type == "OffDay"){
          this.OffdayOvertimeType = data;
        }
        if(type == "ReligionHoliday"){
          this.ReligionHolidayOvertimeType = data;
        }

      }, (err) => {
        // alert("error occured ")
      });
  }
  
  GetAllTAPolicy_Violations_ByFK_PolicyId(id: any) {
    this._svc.getGenericParmas(id, 'FK_TAPolicyId', "TAPolicy_Violations/GetAllTAPolicy_Violations_ByFK_PolicyId", "TA").subscribe(
      data => {
        ;
        this.list_dropdown_TAPolicy_Voilation = [];
        this.list_dropdown_TAPolicy_Voilation = data;
      }, (err) => {
        // alert("error occured ")
      });
  }
  SaveTAPolicy() {
    
    this.objTAPolicy.fK_CompanyId = this.global.FK_CompanyId();
    this.objTAPolicy.lasT_UPDATE_BY = "";
    this.objTAPolicy.graceIn = Number(this.objTAPolicy.graceIn);
    this.objTAPolicy.graceOut = Number(this.objTAPolicy.graceOut);
    this.objTAPolicy.beforeAfterSchedule = Number(this.objTAPolicy.beforeAfterSchedule);
    this.objTAPolicy.noOfNotCompleteHours = Number(this.objTAPolicy.noOfNotCompleteHours);
    if (this.objTAPolicy.taPolicyId == 0) {
      this.objTAPolicy.fK_OTHoliday_OT_TypeId = null;
      this.objTAPolicy.fK_OffDay_OT_TypeId = null;
      this.objTAPolicy.fK_ReligionHoliday_OT_TypeId = null;
      this.objTAPolicy.fK_WorkDay_OT_TypeId = null;
      this.objTAPolicy.createD_BY = this.global.getUserID();
    }
    else {
      this.objTAPolicy.lasT_UPDATE_BY = this.global.getUserID();
    }

    var isError = this.initialValidations();
    if (isError == "") {
      this._svc.SaveTAPolicy(this.objTAPolicy, "TAPolicy/SaveTAPolicy").subscribe(
        data => {
          ;
          if (data.includes("msgid=0")) {
            this.global.openDialog("TA Policy", "Record has been saved.")
            this.GetAllTAPolicy_ByCompany();
            this.Clear();
          }
          else if (data.includes("Success")) {
            this.global.openDialog("TA Policy", "Record has been saved.")
            this.GetAllTAPolicy_ByCompany();
            this.Clear();
          }
          else if (data.includes("NameExists")) {
            this.global.openDialog("TA Policy", "English Name Already Exists");
          }
          else if (data.includes("Name_LLExists")) {
            this.global.openDialog("TA Policy", "Local Language Name Already Exists");
          }
          else {
            this.global.openDialog("TA Policy", "Error While Saving");
          }
        }, (err) => {
          this.global.openDialog("TA Policy", "Some Error Occured.");
        });
    }
    else {
      this.global.openDialog("TA Policy", "Please fill the mentioned fields : " + isError)
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'taPolicyName': [null, Validators.required],
      'taPolicyName_LL': [null, Validators.required],
      'graceIn': [null, Validators.required],
      'graceOut': [null, Validators.required]
    });
  }


  initialValidations() {
    var s = "";
    if (this.global.isStringNullOrEmplty(this.objTAPolicy.taPolicyName)) {
      s = s + " Policy Name ,"
      //this.formGroup.get('taPolicyName').markAsTouched();
    }
    if (this.global.isStringNullOrEmplty(this.objTAPolicy.taPolicyName_LL)) {
      s = s + " Policy Name in Local Language ,"
      //  this.formGroup.get('taPolicyName_LL').markAsTouched();
    }


    if (this.global.isNumberNullOrEmplty(this.objTAPolicy.graceIn) || this.objTAPolicy.graceIn == 0) {
      s = s + " GraceIn ,"
      // this.formGroup.get('graceIn').markAsTouched();
    }
    if (this.global.isNumberNullOrEmplty(this.objTAPolicy.graceOut) || this.objTAPolicy.graceOut == 0) {
      s = s + " GraceOut "
      //this.formGroup.get('graceOut').markAsTouched();
    }
    return s;
  }
  Clear() {
    this.objTAPolicy = new TAPolicy();
    this.onItemChange_r(this.objTAPolicy.beforeAfterSchedule);
  }
  ShowConfirmDialog(): void {

    var tempList = this.dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("TA Policy", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.Delete();
        }
      });
    }
    else {
      this.global.openDialog("TA Policy", "No Record selected.");
    }
  }
  Delete() {

    var tempList = this.dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      var j = 0;
      for (var i = 0; i < tempList.length; i++) {
        this._svc.DeleteGenericParmas(tempList[i].taPolicyId, 'TAPolicyId', 'TAPolicy/DeleteTAPolicy', "TA").subscribe(
          data => {
            if (j == 0) {
              this.Clear();
              this.GetAllTAPolicy_ByCompany();
              this.global.openDialog("Work Schedule", "Record has been Deleted.");
            }
            j++
          }, (err) => {
            this.global.openDialog("TA Policy", "Some Error Occured.");
          });
      }
    }
    else {
      this.global.openDialog("TA Policy", "No Record selected.");
    }
  }
  Checked(taPolicyId: number) {
    ;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].taPolicyId == taPolicyId) {
        this.dataSource.data[i].Selected = !this.dataSource.data[i].Selected;
      }
    }
  }
  ClearBreak() {
    this.objTAPolicy_Break = new TAPolicy_Break();
  }

  ClearPolicy_Voilation() {
    this.objTAPolicy_Voilation = new TAPolicy_Voilation()
  }
  SavePolicy_Voilation() {
    ;
    var s = this.Voilation_validation()
    if (s == "") {

      this.objTAPolicy_Voilation.fK_TAPolicyId = this.objTAPolicy.taPolicyId;
      this.objTAPolicy_Voilation.violationRuleType = Number(this.objTAPolicy_Voilation.violationRuleType)
      this.objTAPolicy_Voilation.variable1 = Number(this.objTAPolicy_Voilation.variable1)
      this.objTAPolicy_Voilation.variable2 = Number(this.objTAPolicy_Voilation.variable2)
      this.objTAPolicy_Voilation.variable3 = Number(this.objTAPolicy_Voilation.variable3)
      this.objTAPolicy_Voilation.scenarioMode = Number(this.objTAPolicy_Voilation.scenarioMode)
      //  considerAbsent: boolean;
      this.objTAPolicy_Voilation.fK_CompanyId = this.global.FK_CompanyId();

      if (this.objTAPolicy_Voilation.violationId == 0) {
        this.objTAPolicy_Voilation.cREATED_BY = this.global.getUserID();
      }
      else {
        this.objTAPolicy_Voilation.lAST_UPDATE_BY = this.global.getUserID();
      }
      this._svc.SaveObject(this.objTAPolicy_Voilation, "TAPolicy_Violations/SaveTAPolicy_Violations").subscribe(
        data => {
          if (data.includes("msgid")) {
            this.global.openDialog("Policy Violations", "Record has been saved.")
            this.GetAllTAPolicy_Violations_ByFK_PolicyId_Table(this.objTAPolicy_Voilation.fK_TAPolicyId)
            this.ClearPolicy_Voilation();
            var id = data.split(':');
            ;
            this.repeaterList_ViolationActions.map(x => x.violationId = id[1].substring(0, id[1].length - 1))
            this.SavePolicy_Action();
          }
          else if (data.includes("Success")) {
            this.global.openDialog("Policy Violations ", "Record has been saved.")
            this.GetAllTAPolicy_Violations_ByFK_PolicyId_Table(this.objTAPolicy_Voilation.fK_TAPolicyId);
            this.ClearPolicy_Voilation();
            this.SavePolicy_Action();
          }
          else if (data.includes("NameExists")) {
            this.global.openDialog("Policy Violations ", "English Name Already Exists");
          }
          else if (data.includes("Name_LLExists")) {
            this.global.openDialog("Policy Violations", "Local Language Name Already Exists");
          }
          else {
            this.global.openDialog("Policy Violations ", "Error While Saving");
          }
        }, (err) => {
          this.global.openDialog("Policy Violations", "Error While Saving");
        });
    }
    else {
      if (s == "*") {
        this.global.openDialog("Voilation Break Policy", "Please select TA policy first");
      }
      else {
        this.global.openDialog("Voilation Break Policy", "Please fill the mentioned fields : " + s)
      }
    }
  }
  Voilation_validation() {
    //this.objTAPolicy_Voilation

    var s = "";
    if (this.objTAPolicy.taPolicyId == 0) {
      s = "*";
    }
    else {
      if (this.global.isStringNullOrEmplty(this.objTAPolicy_Voilation.violationName)) {
        s = s + " Violation Name";
      }
      if (this.global.isStringNullOrEmplty(this.objTAPolicy_Voilation.violationName_LL)) {
        s = s + " ,Violation Name Local Language";
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Voilation.violationRuleType) || this.objTAPolicy_Voilation.violationRuleType == 0) {
        s = s + " , Violation Rule Type"
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Voilation.variable1) || this.objTAPolicy_Voilation.variable1 == 0) {
        s = s + " , Variable 1"
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Voilation.variable2) || this.objTAPolicy_Voilation.variable2 == 0) {
        s = s + " , Variable 2"
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Voilation.variable3) || this.objTAPolicy_Voilation.variable3 == 0) {
        s = s + " , Variable 3"
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Voilation.scenarioMode) || this.objTAPolicy_Voilation.scenarioMode == 0) {
        s = s + " , Scenario Mode"
      }
    }
    return s;
  }
  SaveBreak() {
    ;
    var s = this.Berak_initialValidations()
    if (s == "") {
      // if(this.objTAPolicy_Break.breakId == 0){
      //   this.objTAPolicy_Break.
      // }
      this.objTAPolicy_Break.fK_CompanyId = this.global.FK_CompanyId();
      this.objTAPolicy_Break.fK_PolicyId = this.objTAPolicy.taPolicyId;
      this.objTAPolicy_Break.fK_lkpBreakType = 1;
      this.objTAPolicy_Break.flixibleDuration = Number(this.objTAPolicy_Break.flixibleDuration);
      this.objTAPolicy_Break.noOfAllowedBreak = Number(this.objTAPolicy_Break.noOfAllowedBreak);
      this._svc.SaveObject(this.objTAPolicy_Break, "TAPolicy_Break/SaveTAPolicy_Break").subscribe(
        data => {
          if (data.includes("msgid=0")) {
            this.global.openDialog("Break Policy", "Record has been saved.")
            this.GetAllTAPolicy_Break_ByFK_PolicyId(this.objTAPolicy_Break.fK_PolicyId);
            this.ClearBreak();
          }
          else if (data.includes("Success")) {
            this.global.openDialog("Break Policy", "Record has been saved.")
            this.GetAllTAPolicy_Break_ByFK_PolicyId(this.objTAPolicy_Break.fK_PolicyId);
            this.ClearBreak();
          }
          else if (data.includes("NameExists")) {
            this.global.openDialog("Break Policy", "English Name Already Exists");
          }
          else if (data.includes("Name_LLExists")) {
            this.global.openDialog("Break Policy", "Local Language Name Already Exists");
          }
          else {
            this.global.openDialog("Break Policy", "Error While Saving");
          }
        }, (err) => {
          this.global.openDialog("Break Policy", "Error While Saving");
        });
    }
    else {
      if (s == "*") {
        this.global.openDialog("Break Policy", "Please select TA policy first");
      }
      else {
        this.global.openDialog("Break Policy", "Please fill the mentioned fields : " + s)
      }
    }
  }
  Berak_initialValidations() {
    var s = "";
    if (this.objTAPolicy.taPolicyId == 0) {
      s = "*";
    }
    else if (this.objTAPolicy_Break.fromTime > this.objTAPolicy_Break.toTime) {
      s = "To Time Should be Greater Than Or Equal From Time"
    }
    else {
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Break.fromTime) || this.objTAPolicy_Break.fromTime == 0) {
        s = " From Time"
      }
      if (this.global.isNumberNullOrEmplty(this.objTAPolicy_Break.toTime) || this.objTAPolicy_Break.toTime == 0) {
        s = s + " , To Time"
      }
    }

    return s;
  }
  ConvertTime_toNumber(Type: any) {
    ;

    if (Type == 'To_Break') {
      var To_Break = this.converttoMinutes(this.breakToTime);
      this.objTAPolicy_Break.toTime = To_Break;
      if (To_Break == 0) {
        this.breakToTime = "00:00";
      }
      else {
        this.breakToTime = this.convertIntoTime(this.objTAPolicy_Break.toTime)
      }

    }
    else if (Type == 'From_Break') {
      var From_Break = this.converttoMinutes(this.breakFromTime);
      this.objTAPolicy_Break.fromTime = From_Break;
      if (From_Break == 0) {
        this.breakFromTime = "00:00";
      }
      else {
        this.breakFromTime = this.convertIntoTime(this.objTAPolicy_Break.fromTime)
      }
    }

  }
  converttoMinutes(Time: string) {
    var x = Time.split(":");
    var retrunTime = 0;
    if (x.length == 1) {
      retrunTime = Number(x[0]) * 60;
    }
    else {
      retrunTime = Number(x[0]) * 60 + Number(x[1]);
    }

    if (retrunTime > 1439 || retrunTime < 0 || retrunTime.toString() == "NaN") {
      this.global.openDialog("Work Schedule", "Please enter valid Time")
      retrunTime = 0;
    }
    return retrunTime;
  }
  convertIntoTime(sec: number) {
    var min = sec % 60;
    var Hour = (sec - min) / 60;
    if (Hour < 10 && min < 10) {
      return "0" + Hour.toFixed(0) + ":" + "0" + min.toFixed(0);
    }
    else if (Hour < 10 && min > 9) {
      return "0" + Hour + ":" + min;
    }
    else if (Hour > 9 && min < 10) {
      return Hour + ":" + "0" + min;
    }
    else if (Hour > 9 && min > 9) {
      return Hour + ":" + min;
    }
  }

  ShowBreakConfirmDialog(): void {

    var tempList = this.Break_dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("TA Policy", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.Delete_Break();
        }
      });
    }
    else {
      this.global.openDialog("TA Policy", "No Record selected.");
    }
  }

  Delete_Break() {
    ;
    var tempList = this.Break_dataSource.data.filter(x => x.Selected == true)

    for (var i = 0; i < tempList.length; i++) {
      this._svc.DeleteGenericParmas(tempList[i].breakId, 'BreakId', 'TAPolicy_Break/DeleteTAPolicy_Break', "TA").subscribe(
        data => {
          this.ClearBreak();
          this.GetAllTAPolicy_Break_ByCompany()
          this.global.openDialog("Break Policy", "Record has been Deleted.");
        }), (err) => {
          this.global.openDialog("Break Policy", "Some Error Occured.");
        }
      //http://isabam-001-site4.atempurl.com/api/TAPolicy_Break/DeleteTAPolicy_Break?BreakId=4
    }
  }
  edit_violation(id: any) {
    this.objTAPolicy_Voilation = this.Voilation_dataSource.data.filter(x => x.violationId == id)[0];
    this.GetTAPolicy_ViolationActionsDetails(this.objTAPolicy_Voilation.violationId);
  }
  GetTAPolicy_ViolationActionsDetails(id: any) {
    //    this._svc.getGenericParmas(id, 'ViolationId', "TAPolicy_ViolationActions/GetTAPolicy_ViolationActionsDetails", "TA").subscribe(
    this._svc.getGenericParmas(id, 'ViolationId', "TAPolicy_ViolationActions/GetAllTAPolicy_ViolationActions_ByViolationId", "TA").subscribe(
      data => {
        ;
        this.repeaterList_ViolationActions = [];
        this.repeaterList_ViolationActions = data;
      }, (err) => {
        // alert("error occured ")
      });

  }
  Checked_violation(id: any) {
    ;
    for (var i = 0; i < this.Voilation_dataSource.data.length; i++) {
      if (this.Voilation_dataSource.data[i].violationId == id) {
        this.Voilation_dataSource.data[i].Selected = !this.Voilation_dataSource.data[i].Selected;
      }
    }
  }

  ShowPolicy_Voilation_ConfirmDialog(): void {

    var tempList = this.Voilation_dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("Policy Violatio", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.Delete_Policy_Voilation();
        }
      });
    }
    else {
      this.global.openDialog("Policy Violation", "No Record selected.");
    }
  }

  Delete_Policy_Voilation() {

    var tempList = this.Voilation_dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      var j = 0;
      for (var i = 0; i < tempList.length; i++) {
        this._svc.DeleteGenericParmas(tempList[i].violationId, 'ViolationId', 'TAPolicy_Violations/DeleteTAPolicy_Violations', "TA").subscribe(
          data => {
            if (j == 0) {
              this.ClearPolicy_Voilation();
              this.GetAllTAPolicy_Violations_ByCompany();
              this.global.openDialog("Policy Violation", "Record has been Deleted.");
            }
            j++
          }, (err) => {
            this.global.openDialog("Policy Violation", "Some Error Occured.");
          });
      }
    }
    else {
      this.global.openDialog("Policy Violation", "No Record selected.");
    }
  }
  Clear_Action() {
    this.objTAPolicy_ViolationActions = new TAPolicy_ViolationActions();
  }
  SavePolicy_Action() {
    ;
    // if (this.objTAPolicy.taPolicyId == 0) {
    //   this.global.openDialog("Policy Violation Actions", "Please select TA policy first");
    // }
    // else {
    // this.objTAPolicy_ViolationActions.fK_CompanyId = this.global.FK_CompanyId();
    // this.objTAPolicy_ViolationActions.violationId = 2;
    // this.objTAPolicy_ViolationActions.actionSequence = Number(this.objTAPolicy_ViolationActions.actionSequence);
    // this.objTAPolicy_ViolationActions.fK_lkpViolationActionId = Number(this.objTAPolicy_ViolationActions.fK_lkpViolationActionId);
    var j = 0;
    for (var i = 0; i < this.repeaterList_ViolationActions.length; i++) {
      this.objTAPolicy_ViolationActions = new TAPolicy_ViolationActions();
      this.objTAPolicy_ViolationActions = this.repeaterList_ViolationActions[i];

      this._svc.SaveObject(this.objTAPolicy_ViolationActions, "TAPolicy_ViolationActions/SaveTAPolicy_ViolationActions").subscribe(
        data => {
          j++
          if (j == 1) {
            if (data.includes("msgid=0")) {
              this.global.openDialog("Policy Violation Actions", "Record has been saved.")
              this.GetAllTAPolicy_ViolationActions_ByCompany();
              this.Clear_Action();
            }
            else if (data.includes("Success")) {
              this.global.openDialog("Policy Violation Actions", "Record has been saved.")
              this.GetAllTAPolicy_ViolationActions_ByCompany();
              this.Clear_Action();
            }
            else if (data.includes("NameExists")) {
              this.global.openDialog("Policy Violation Actions", "English Name Already Exists");
            }
            else if (data.includes("Name_LLExists")) {
              this.global.openDialog("Policy Violation Actions", "Local Language Name Already Exists");
            }
            else {
              this.global.openDialog("Policy Violation Actions", "Error While Saving");
            }

          }
        }, (err) => {
          this.global.openDialog("Policy Violation Actions", "Error While Saving");
        });

    }
    // }
  }

  Action_ConfirmDialog(): void {

    var tempList = this.Action_dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel("Policy Violation Actions", message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.DeleteAction();
        }
      });
    }
    else {
      this.global.openDialog("Policy Violation Actions", "No Record selected.");
    }
  }

  DeleteAction() {

    var tempList = this.Action_dataSource.data.filter(t => t.Selected == true);
    if (tempList.length > 0) {
      var j = 0;
      for (var i = 0; i < tempList.length; i++) {

        var x = "TAPolicy_ViolationActions/DeleteTAPolicy_Violations?ViolationId=" + tempList[i].violationId + "&FK_lkpViolationActionId=" + tempList[i].fK_lkpViolationActionId;
        this._svc.detelewithTwo(x, "TA").subscribe(
          data => {
            if (j == 0) {
              this.ClearPolicy_Voilation();
              this.GetAllTAPolicy_Violations_ByCompany();
              this.global.openDialog("Policy Violation Actions", "Record has been Deleted.");
            }
            j++
          }, (err) => {
            this.global.openDialog("Policy Violation Actions", "Some Error Occured.");
          });
      }
    }
    else {
      this.global.openDialog("Policy Violation Actions", "No Record selected.");
    }
  }

  Checked_action(id: any) {
    ;
    for (var i = 0; i < this.Action_dataSource.data.length; i++) {
      if (this.Action_dataSource.data[i].violationId == id) {
        this.Action_dataSource.data[i].Selected = !this.Action_dataSource.data[i].Selected;
      }
    }
  }
  edit_Action(id: any) {
    this.objTAPolicy_ViolationActions = this.Action_dataSource.data.filter(x => x.violationId == id)[0];
  }
  onItemChange_r(id: any) {
    this.objTAPolicy.beforeAfterSchedule = Number(id);
    this.s_beforeAfterSchedule = this.objTAPolicy.beforeAfterSchedule.toString();
    if (id == "1") {
      this.bBeforeSchedule = true;
      this.bAfterSchedule = false;
      this.bBeforeAfterSchedule = false;
    }
    else if (id == "2") {
      this.bBeforeSchedule = false;
      this.bAfterSchedule = true;
      this.bBeforeAfterSchedule = false;
    }
    else {
      this.bBeforeSchedule = false;
      this.bAfterSchedule = false;
      this.bBeforeAfterSchedule = true;
    }

  }

  GetAllOvertime() {
    // 
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "Overtime_Types/GetAllOvertime_Types_ByCompany", "TA").subscribe(
      data => {
        ;
        this.listOvertimeType = [];
        this.listOvertimeType = data;
      }, (err) => {
        // alert("error occured ")
      });

  }
  Update_WorkDay(obj: TAPolicy) {
    
    this._svc.SaveObject(obj, "TAPolicy/Update_WorkDay").subscribe(
      data => {
        ;
        this.APIcounter = this.APIcounter + 1;
        if (this.APIcounter == 1) {
          this.global.openDialog("Overtime Types", "Record has been saved.")
        }

      }, (err) => {
        this.global.openDialog("Overtime Types", "Error While Saving.")
      });
  }
  Update_OffDay(obj: TAPolicy) {
    
    this._svc.SaveObject(obj, "TAPolicy/Update_OffDay").subscribe(
      data => {
        ;
        this.APIcounter = this.APIcounter + 1;
        if (this.APIcounter == 1) {
          this.global.openDialog("Overtime Types", "Record has been saved.")
        }

      }, (err) => {
        this.global.openDialog("Overtime Types", "Error While Saving.")
      });
  }
  Update_OTHoliday(obj: TAPolicy) {
    
    this._svc.SaveObject(obj, "TAPolicy/Update_OTHoliday").subscribe(
      data => {
        ;
        this.APIcounter = this.APIcounter + 1;
        if (this.APIcounter == 1) {
          this.global.openDialog("Overtime Types", "Record has been saved.")
        }

      }, (err) => {
        this.global.openDialog("Overtime Types", "Error While Saving.")
      });

  }
  Update_ReligionHoliday(obj: TAPolicy) {
    
    this._svc.SaveObject(obj, "TAPolicy/Update_ReligionHoliday").subscribe(
      data => {
        ;
        this.APIcounter = this.APIcounter + 1;
        if (this.APIcounter == 1) {
          this.global.openDialog("Overtime Types", "Record has been saved.")
        }

      }, (err) => {
        this.global.openDialog("Overtime Types", "Error While Saving.")
      });
  }
  SaveOvertime() {
    this.Save(this.WorkdayOvertimeType , 'Workday');
    this.Save(this.OffdayOvertimeType , 'Offday');
    this.Save(this.HolidayOvertimeType , 'Holiday');
    this.Save(this.ReligionHolidayOvertimeType , 'ReligionHoliday');
  }
  Save(OvertimeType: OvertimeType , Type: string ) {
    ;
    // Overtime_Types/SaveOvertime_Types
    // this.OvertimeType.fK_TAPolicyId
    OvertimeType.fk_TAPolicyId = Number(this.objTAPolicy.taPolicyId);
    OvertimeType.fk_CompanyId = this.global.FK_CompanyId();
    OvertimeType.minOvertime = this.global.convertNumbre(OvertimeType.minOvertime)
    OvertimeType.maxOvertime = this.global.convertNumbre(OvertimeType.maxOvertime)
    OvertimeType.overtimeRate = this.global.convertNumbre(OvertimeType.overtimeRate)
    OvertimeType.approvalBy = null;
    OvertimeType.minAutoApproveDuration = this.global.convertNumbre(OvertimeType.minAutoApproveDuration);
    OvertimeType.overtimeCalculationConsideration = this.global.convertNumbre(OvertimeType.overtimeCalculationConsideration)
    OvertimeType.overtimeChangeValue = this.global.convertNumbre(OvertimeType.overtimeChangeValue)
    OvertimeType.fK_LeaveTypeId = this.global.convertNumbre(OvertimeType.fK_LeaveTypeId)

    if (this.global.isNumberNullOrEmplty(OvertimeType.overtimeTypeId) || OvertimeType.overtimeTypeId == 0) {
      OvertimeType.cREATED_BY = this.global.getUserID();
    } else {
      OvertimeType.lAST_UPDATE_BY = this.global.getUserID();
      // this.OvertimeTyp = this.global.getUserID();
    }
    var isError = this.initialValidations();
    if (isError == "") {
      this._svc.SaveObject(OvertimeType, "Overtime_Types/SaveOvertime_Types").subscribe(
        data => {
          ;
          if(data == "Success"){
            this.global.openDialog("Overtime Types", "Record has been updated"); 
          }
          else {
            if(Type == "Workday"){
              if(this.checkSaveFunction(data , "Work Day")){
                var id = data.split(':');
                this.objTAPolicy.fK_WorkDay_OT_TypeId = Number(id[1].substring(0, id[1].length - 1));
                this.Update_WorkDay(this.objTAPolicy);
              }
            }
            if(Type == "Offday"){
              if(this.checkSaveFunction(data , "Off Day")){
              var id = data.split(':');
              this.objTAPolicy.fK_OffDay_OT_TypeId  = Number(id[1].substring(0, id[1].length - 1));
              this.Update_OffDay(this.objTAPolicy);
              }
            }
            if(Type == "Holiday"){
              if(this.checkSaveFunction(data , "Holiday")){
              var id = data.split(':');
              this.objTAPolicy.fK_OTHoliday_OT_TypeId = Number(id[1].substring(0, id[1].length - 1));
              this.Update_OTHoliday(this.objTAPolicy);
              }
            }
            if(Type == "ReligionHoliday"){
              if(this.checkSaveFunction(data , "Religion Holiday")){
              var id = data.split(':');
              this.objTAPolicy.fK_ReligionHoliday_OT_TypeId = Number(id[1].substring(0, id[1].length - 1));
              this.Update_ReligionHoliday(this.objTAPolicy);
              }
            }
          }
        
        }, (err) => {
          this.global.openDialog("Overtime Types", "Error While Saving.")
        });
    }
    else {
      this.global.openDialog("Overtime Types", "Please fill the mentioned fields : " + isError)
    }
  }

  checkSaveFunction(data:any, type : string){
    if (data.includes("NameExists")) {
      this.global.openDialog("TA Policy", "English Name Already Exists for "+ type);
      return false;
    }
    else if (data.includes("Name_LLExists")) {
      this.global.openDialog("TA Policy", "Local Language Name Already Exists for "+ type);
      return false
    }
    else {
      return true;
    }
  }
  Clear_Overtime() {
    this.WorkdayOvertimeType = new OvertimeType();
    this.OffdayOvertimeType = new OvertimeType();
    this.HolidayOvertimeType = new OvertimeType();
    this.ReligionHolidayOvertimeType = new OvertimeType();
  }
  Delete_Overtime() {
    
  }
  removeOvertime(type: any){
    ;
    if(type == "ReligionHoliday"){
      this.ReligionHolidayOvertimeType = new OvertimeType();
    }
    if(type == "Holiday"){
      this.HolidayOvertimeType = new OvertimeType();
    }
    if(type == "Offday"){
      this.OffdayOvertimeType  = new OvertimeType();
    }
    if(type == "Workday"){
      this.WorkdayOvertimeType  = new OvertimeType();
    }
  }
}
