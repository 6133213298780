import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StudentFee, StudentFeeRequest } from 'app/Classes/student';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@aspnet/signalr';

@Component({
  selector: 'app-my-fee',
  templateUrl: './my-fee.component.html',
  styleUrls: ['./my-fee.component.css']
})
export class MyFeeComponent extends BaseComponent implements OnInit {

  base64Data : string =""
  lstStudent: StudentFee[];
  StudentFee_request: StudentFeeRequest
  updateStudentFee: updateStudentFee
  selectedFile: File | null = null;

  displayedColumns: string[] = ['select', 'userFullName', 'className', 'feeAmount', 'dueDate', 'lateFee', 'status', 'Edit'];
  dataSource = new MatTableDataSource<StudentFee>();
  selection = new SelectionModel<StudentFee>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;



  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService,
    private sanitizer: DomSanitizer) {
    super(languageTranslateService);
this.updateStudentFee = new updateStudentFee()
    this.StudentFee_request = new StudentFeeRequest();
    this.base64Data =""
  }

  ngOnInit() {
    this.GetFeeBystudentId();
  }


  GetFeeBystudentId() {
    debugger;
    this.StudentFee_request.studentId = sessionStorage.getItem('userid')
    this._svc.Save(this.StudentFee_request, 'Student/GetFeeBystudentId').subscribe(
      data => {
        debugger;
        this.lstStudent = [];
        this.lstStudent = data;
        this.dataSource.data = this.lstStudent
      }, (err) => {
        
      });
  }


  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.selectedFile = file;
    } else {
      alert('Please select a valid PDF file.');
      this.selectedFile = null;
    }
  }

  uploadFile(feeid : any): void {

    debugger
    if (this.selectedFile) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
         this.base64Data = e.target.result.split(',')[1];
        // Send the base64Data to the server using an HTTP request
        this.sendToServer(feeid);
  
      };

      reader.readAsDataURL(this.selectedFile);
    } else {
      alert('Please select a file first.');
    }
  }

  sendToServer(feeid : any): void {

    this.updateStudentFee.file = this.base64Data;
    this.updateStudentFee.feeId = feeid;
    this._svc.Save(this.updateStudentFee, 'Student/updateFee').subscribe(
      data => {
        if (data.includes("Success")) {

          this.global.openDialog("Fee Updated", "Record has been saved.");
        }
        else {
          this.global.openDialog("Fee Updated", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Fee Update", "Some Error Occured.");
        // alert("error occured ")
      });
  }

  }




export class updateStudentFee{
  feeId: number 
  file: string 
  /**
   *
   */
  constructor() {
    this.feeId = 0 
    this.file= "" 
    
  }

}