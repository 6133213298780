import { Component, OnInit } from '@angular/core';
import { Classes } from 'app/Classes/classes';
import { OrgUnit } from 'app/Classes/org';
import { teacher } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-define-lecture',
  templateUrl: './define-lecture.component.html',
  styleUrls: ['./define-lecture.component.css']
})
export class DefineLectureComponent extends BaseComponent implements OnInit {
  objdefineLectClass: defineLectClass;
  lstdefineLectClass: defineLectClass[];
  objClasses: Classes;
  lstClasses: Classes[];
  lstOrgUnit: OrgUnit[];
  lstTeacher: teacher[];

  displayedColumns: string[] = ['select', 'ClassName', 'Fee', 'Active', 'Edit'];
  dataSource = new MatTableDataSource<Classes>();
  selection = new SelectionModel<Classes>(true, []);



  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.objClasses = new Classes();
    this.lstClasses = [];
    this.lstOrgUnit = [];
    this.lstTeacher = [];
    this.objdefineLectClass = new defineLectClass();
    this.lstdefineLectClass = [];
  }

  ngOnInit() {
    this.getAllTeacher();
    this.getAllClasses();
  }

  getAllTeacher() {
    this._svc.GetDetails('Teacher/GetAllTeacher').subscribe(
      data => {
        this.lstTeacher = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }

  selectedDate: string;
  onDateSelected(d: any) {
    debugger;
    this.value = d;
    var ti = d.getTime();
    var ofs = d.getTimezoneOffset();
    var nvewd = new Date(ti + Math.abs(ofs) * 60000)
    this.objdefineLectClass.lectureDate = nvewd.toISOString();
  }

  getAllClasses() {
    this._svc.GetDetails('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
        if (this.lstClasses.length > 0) {
          for (var i = 0; i < this.lstClasses.length; i++) {
            this.lstClasses[i].select = false;
          }
          this.dataSource.data = this.lstClasses;
        } else {
          this.global.openDialog("Classes", "No record fund.");
        }
      }, (err) => {
      });
  }

  value: Date;
  selectedTime: string = "";
  timeSeleted(atr: any) {
    debugger;
    this.selectedTime = atr;
    var temp = atr.split('-')
    this.objdefineLectClass.timeFrom = temp[0]
    this.objdefineLectClass.timeTo = temp[1]
  }

  Save() {


    this._svc.Save(this.objdefineLectClass, 'Classes/DefineLecture').subscribe(
      data => {
        debugger;
          this.global.openDialog("Classes", "Record has been saved.")
      }, (err) => {
        this.global.openDialog("Forms", "Error While Saving.")
      })
  }


}


export class defineLectClass {
  classId: number;
  teacherId: number;
  lectureDate: string
  timeFrom: string
  timeTo: string

  /**
   *
   */
  constructor() {
    this.classId = 0;
    this.teacherId = 0;
    this.lectureDate = ""
    this.timeFrom = ""
    this.timeTo = ""


  }
}
