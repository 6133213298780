import { Component, OnInit, ViewChild } from '@angular/core';
import { teacher, TeacherComplaintRecord, TeacherEducationalRecord, TeacherProfessionalRecord, TeacherSpeciality } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { Subjects } from 'app/Classes/subjects';
import { Classes } from 'app/Classes/classes';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent extends BaseComponent implements OnInit {
  objSubjects: Subjects;
  lstSubjects: Subjects[];
  lstClasses: Classes[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService)
    this.objSubjects = new Subjects();
    this.lstSubjects = [];
    this.lstClasses = [];
  }
  displayedColumns: string[] = ['select', 'subjectID', 'subjectName', 'subjectMedium','className','fK_SchoolID','isActive', 'Edit'];
  dataSource = new MatTableDataSource<Subjects>();
  selection = new SelectionModel<Subjects>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
  this.GetAllSubjects();
  this.getAllClasses();
  }
  getAllClasses(){
    this._svc.GetDetails('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }
  edit(subjectID : number ){ 
    this.objSubjects = this.lstSubjects.filter( x=> x.subjectID == subjectID)[0]

  }
  selectSubject(subjectID : any ){

  }
  ShowConfirmDialog(){

  }
  Clear(){
    this.objSubjects = new Subjects();
  }
  Save(){
    debugger;
    this.objSubjects.createdBy = this.global.getUserID();
    this.objSubjects.lastUpdatedBy = this.global.getUserID();
    this.objSubjects.fK_ClassID = Number(this.objSubjects.fK_ClassID)
    this.objSubjects.fK_SchoolID = Number(this.objSubjects.fK_SchoolID)

    this.objSubjects.createdDate = new Date(this.objSubjects.createdDate);
    this.objSubjects.createdDate = new Date(this.objSubjects.createdDate);
    this._svc.Save(this.objSubjects, 'Subjects/AddUpdateSubjects').subscribe(
      data => {
        if (data.includes("Success")) {
          this.GetAllSubjects();
          this.global.openDialog("Students", "Record has been saved.");
        }
        else {
          this.global.openDialog("Students", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  GetAllSubjects(){
    debugger;
    this._svc.GetAll( "Subjects/GetAllSubjects").subscribe(
      data => {
        debugger;
        this.lstSubjects = data;
        if (this.lstSubjects.length > 0) {
          for (var i = 0; i < this.lstSubjects.length; i++) {
            this.lstSubjects[i].select = false;
          }
          this.dataSource.data = this.lstSubjects;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Subject", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
}
