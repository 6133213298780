import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Classes } from 'app/Classes/classes';
import { AssignSubjecttoTeacher, Subjects } from 'app/Classes/subjects';
import { teacher } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-assign-subject-to-teacher',
  templateUrl: './assign-subject-to-teacher.component.html',
  styleUrls: ['./assign-subject-to-teacher.component.css']
})
export class AssignSubjectToTeacherComponent extends BaseComponent implements OnInit {
  objAssignSubjecttoTeacher: AssignSubjecttoTeacher;
  lstTeacher: teacher[];
  lstSubjects: Subjects[];
  lstClasses: Classes[];
  lstAssignSubjecttoTeacher: AssignSubjecttoTeacher[];


  displayedColumns: string[] = ['select', 'subjectName', 'teacherName', 'className', 'Edit'];
  dataSource = new MatTableDataSource<AssignSubjecttoTeacher>();
  selection = new SelectionModel<AssignSubjecttoTeacher>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.objAssignSubjecttoTeacher = new AssignSubjecttoTeacher();

    this.lstTeacher = [];
    this.lstSubjects = [];
    this.lstClasses = [];
    this.lstAssignSubjecttoTeacher = [];
  }

  ngOnInit() {
    this.getAllteacer();
    this.GetAllSubjects();
    this.getAllClasses();
    this.getAllAssignedSubjects();
  }
  getAllteacer() {
    this._svc.GetDetails('Teacher/GetAllTeacher').subscribe(
      data => {
        this.lstTeacher = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }

  GetAllSubjects() {
    debugger;
    this._svc.GetAll("Subjects/GetAllSubjects").subscribe(
      data => {
        debugger;
        this.lstSubjects = data;
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
  getAllClasses() {
    debugger
    var id = this.global.getUserID();
    this._svc.GetAll('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }
  GetAllSubjectsByClassID(id: any) {
    debugger;
    //GetAllSubjectsByClassID
    this._svc.getGenericParmas(id, 'fK_ClassID', 'Subjects/GetAllSubjectsByClassID').subscribe(
      data => {
        this.lstSubjects = [];
        this.lstSubjects = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  getAllAssignedSubjects() {
    debugger
    var id = this.global.getUserID();
    this._svc.GetAll('Subjects/getAllAssignedSubjects').subscribe(
      data => {
        this.lstAssignSubjecttoTeacher = data;
        if (this.lstAssignSubjecttoTeacher.length > 0) {
          for (var i = 0; i < this.lstAssignSubjecttoTeacher.length; i++) {
            this.lstAssignSubjecttoTeacher[i].select = false;
          }
          this.dataSource.data = this.lstAssignSubjecttoTeacher;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }, (err) => {
      });
  }
  select(assignSubjectID: any) {
    debugger;
  }
  Save(){
    this.objAssignSubjecttoTeacher.fkclassID = this.global.convertNumbre(this.objAssignSubjecttoTeacher.fkclassID);
    this.objAssignSubjecttoTeacher.fK_TeacherID = this.global.convertNumbre(this.objAssignSubjecttoTeacher.fK_TeacherID);
    this.objAssignSubjecttoTeacher.fK_SubjectID = this.global.convertNumbre(this.objAssignSubjecttoTeacher.fK_SubjectID);
    this.objAssignSubjecttoTeacher.fkclassID = this.global.convertNumbre(this.objAssignSubjecttoTeacher.fkclassID);
    this.objAssignSubjecttoTeacher.createdby = this.global.getUserID();
    this.objAssignSubjecttoTeacher.lastUpdatedby = this.global.getUserID();

    this._svc.Save(this.objAssignSubjecttoTeacher, 'Subjects/AddUpdateAssignSubjects').subscribe(
      data => {
        debugger;
        if (data == "Success") {
          this.global.openDialog("Classes", "Record has been saved.")
          this.getAllAssignedSubjects();
          this.Clear()
        }
        else if (data == "Duplicate") {
          this.global.openDialog("Forms", "Name alreay exist.")
        }
        else {
          this.global.openDialog("Forms", "Error While Saving.")
        }
      }, (err) => {
        this.global.openDialog("Forms", "Error While Saving.")
      })
  }
  Clear(){
    this.objAssignSubjecttoTeacher = new AssignSubjecttoTeacher();
  }
  edit(id: any){
    debugger;
  this.objAssignSubjecttoTeacher = this.lstAssignSubjecttoTeacher.filter( x => x.assignSubjectID == id)[0];
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
