import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { AssignmentDetails, Subjects } from 'app/Classes/subjects';

@Component({
  selector: 'app-assignmentdetails',
  templateUrl: './assignmentdetails.component.html',
  styleUrls: ['./assignmentdetails.component.css']
})
export class AssignmentdetailsComponent  extends BaseComponent implements OnInit {
  objAssignmentDetails: AssignmentDetails;
  lstAssignmentDetails: AssignmentDetails[];
  lstSubjects: Subjects[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService) 

    this.objAssignmentDetails = new AssignmentDetails();
    this.lstAssignmentDetails = [];
    this.lstSubjects = [];
  }

  displayedColumns: string[] = ['select'  
 ,'assignmentName'
  ,'studentName'
  ,'className'
  ,'obtainedMarks' 
  ,'studentsRemarks'
  ,'teacherRemarks'
  ,'assigmnentFileName'
  ,'assignmentSubject'
  ,'className'
  ,'Edit'];
  dataSource = new MatTableDataSource<AssignmentDetails>();
  selection = new SelectionModel<AssignmentDetails>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.GetAssignmentDetails();
    this.GetAllSubjects()
  }
  Save(){
    debugger;
    this.objAssignmentDetails.createdBy = this.global.getUserID();
 
    this.objAssignmentDetails.createdDate = new Date(this.objAssignmentDetails.createdDate);
    this._svc.Save(this.objAssignmentDetails, 'Assignments/AddUpdateAssignmentDetails').subscribe(
      data => {
        if (data.includes("Success")) {
          this.GetAssignmentDetails();
          this.global.openDialog("Assignment details", "Record has been saved.");
        }
        else {
          this.global.openDialog("Assignment details", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Assignment details", "Some Error Occured.");
        // alert("error occured ")
      });
  }

  edit(id: any) {
    this.objAssignmentDetails = this.lstAssignmentDetails.filter(x => x.assignmentdetailID == id)[0];
  }
  GetAllSubjects() {
    debugger;
    this._svc.GetAll("Subjects/GetAllSubjects").subscribe(
      data => {
        debugger;
        this.lstSubjects = data;
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
      });
  }
  GetAssignmentDetails(){
    debugger;
    var id =this.global.getUserID();
    this._svc.GetDetails( "Assignments/GetAssignmentDetails").subscribe(
      data => {
        debugger;
        this.lstAssignmentDetails = data;
        if (this.lstAssignmentDetails.length > 0) {
          for (var i = 0; i < this.lstAssignmentDetails.length; i++) {
            this.lstAssignmentDetails[i].select = false;
          }
          this.dataSource.data = this.lstAssignmentDetails;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Assignment details", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Assignment details", "Some Error Occured.");
      });
  }

  Search(){
    this._svc.Save(this.objAssignmentDetails, 'Assignments/getAssignmentDetewise').subscribe(
      data => {
        if (data.includes("Success")) {
          this.GetAssignmentDetails();
          this.global.openDialog("Assignment details", "Record has been saved.");
        }
        else {
          this.global.openDialog("Assignment details", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Assignment details", "Some Error Occured.");
        // alert("error occured ")
      });
  }
}
