import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Modules } from 'app/Classes/form-and-modules';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent extends BaseComponent implements OnInit {
  Modules: Modules;
  ModulesList: Modules[];
  lstParentModule: Modules[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, public dialog: MatDialog) {
    super(languageTranslateService);
    this.Modules = new Modules();
    this.ModulesList = [];
    this.lstParentModule = [];
  }
  displayedColumns: string[] = ['select', 'moduleName', 'moduleName_Lang', 'edit'];//, 'DefaultPage'
  dataSource = new MatTableDataSource<Modules>();
  selection = new SelectionModel<Modules>(true, []);
  ApplicationType = new FormControl('', [
    Validators.required
  ]);

  ngOnInit() {
    this.GetAllModules();
    this.FillDropDown();
  }
  clear() {
    this.Modules = new Modules();
  }
  edit(id: any) {
    ;
    var x = this.dataSource.data.filter(x => x.moduleID == id);
    this.Modules = x[0];
    this.Modules.fK_ParentModule = this.global.convertNumbre(this.Modules.fK_ParentModule);
    var y = this.lstParentModule.filter(x => x.moduleID == this.Modules.fK_ParentModule)
  }
  ConfirmDelete() {
    ;
    var count = this.dataSource.data.filter(x => x.select == true);
    if (count.length > 0) {

      const message = 'Are you sure you want to delete selected Records?';
      const dialogData = new CustomConfirmDialogModel('Modules', message);
      const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
        width: "400px",
        data: dialogData
      });
      var res = "";
      dialogRef.afterClosed().toPromise().then(dialogResult => {
        
        if (dialogResult) {
          this.DeleteModules();
        }
        else {
          this.global.openDialog("Modules", "Operation aborted.");
        }
      });
    }
    else {
      this.global.openDialog("Modules", "Please select records.");
    }
    //DeleteModules
  }

  DeleteModules() {
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.dataSource.data[i].moduleID, 'moduleID', 'SysForm/DeleteModules').subscribe(
          data => {
            ;
            this.GetAllModules();
            this.global.openDialog("Modules", "Record has been deleted.");
          }, (err) => {
            this.global.openDialog("Modules", "Some Error Occured.");
          });
      }
    }
  }
  selectModules(id: any) {
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (id == this.dataSource.data[i].moduleID) {
        this.dataSource.data[i].select = !this.dataSource.data[i].select;
      }
    }
  }
  GetAllModules() {
    ;
    this._svc.GetAll('SysForm/GetAllModules').subscribe(
      data => {
        if (data.length > 0) {

          this.ModulesList = data;
          this.dataSource.data = this.ModulesList;
          for (var i = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].select = false;
          }
        }
      }, (err) => {
        this.global.openDialog("Modules", "Error While Saving.")
      })
  
  
  this.clear();
  }

  FillDropDown() {
    ;
    this._svc.GetAll('SysForm/GetAllParentModules').subscribe(
      data => {
        if (data.length > 0) {
          this.lstParentModule = data;
        }
        else {
          this.lstParentModule = [];
        }
      }, (err) => {
        this.global.openDialog("Modules", "Error while getting record.")
      })

  }

  initialValideation(){ 
    if(this.global.isNumberNullOrEmplty(this.Modules.fK_ParentModule) || this.Modules.fK_ParentModule == 0 ){
      
      return true;
    }
    else {
      return false;
    }
  }
   SaveModules() {
    ;
    if( this.initialValideation()){
      this.global.openDialog("Modules", "Please select module.")
    }
    else {
      this.Modules.moduleID = this.global.convertNumbre(this.Modules.moduleID);
      this.Modules.fK_ParentModule = this.global.convertNumbre(this.Modules.fK_ParentModule);
      this.Modules.seq = this.global.convertNumbre(this.Modules.seq);
      this._svc.Save(this.Modules, 'SysForm/AddUpdateSysModules').subscribe(
        data => {
          ;
          if(data == "Success"){
            this.global.openDialog("Modules", "Record has been saved.")
            this.GetAllModules();
          }
          else if(data =="Duplicate"){
            this.global.openDialog("Modules", "Name alreay exist.")
          }
          else{
            this.global.openDialog("Modules", "Error While Saving.")
          }
          
        }, (err) => {
          this.global.openDialog("Modules", "Error While Saving.")
        })
    }
    
  }
}
