import { Component, OnInit } from '@angular/core';
import { Classes } from 'app/Classes/classes';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { ApiServiceService } from 'app/Shared/api-service.service';
import { environment } from 'environments/environment';
import { VideoCourse } from 'app/createvideocourse/createvideocourse.component';
import { teacher } from 'app/Classes/teacher';
import { LoaderService } from 'app/loader.service';


@Component({
  selector: 'app-upload-recordedlecture',
  templateUrl: './upload-recordedlecture.component.html',
  styleUrls: ['./upload-recordedlecture.component.css']
})
export class UploadRecordedlectureComponent extends BaseComponent implements OnInit {
 isNew:boolean = false;
  lstVideoCourse : VideoCourse[];
  lstClasses: Classes[];
  teacherId : string = "";

  isTeacher :string =""
  lstuploadrecordedlectureProperties : uploadrecordedlectureProperties[]
  objuploadrecordedlectureProperties : uploadrecordedlectureProperties
  lstTeacher: teacher[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private apiService: ApiServiceService,
        private http: HttpClient , private loaderService: LoaderService) {
    super(languageTranslateService);
    this.lstClasses = [];
    this.lstVideoCourse = []
    this.envData = environment;
    this.baseURL = this.envData.apiConn;    
    this.baseURLFile = this.envData.baseURL
    this.lstTeacher = [];
    this. lstuploadrecordedlectureProperties  = [];
    this.objuploadrecordedlectureProperties = new  uploadrecordedlectureProperties()
  
}
baseURLFile :string =""
  classId:string ="";
  ngOnInit() {
  this.isTeacher = sessionStorage.getItem('isTeacher')  
    if(this.isTeacher !="true"){
      this.getAllTeacher();
    }
    // this.getAllClasses();
    this.getAll();
    this.getAllCourse()
  }
NewClick(){
  this.isNew = true;
}
CancelClick(){
  this.isNew = false;
}
  
   

  getAllTeacher() {
    this._svc.GetDetails('Teacher/GetAllTeacher').subscribe(
      data => {
        this.lstTeacher = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }

  next(){
    this.pageNumber = this.pageNumber + 1;
    this.search()    
  }

  previous(){
    if(this.pageNumber != 1){
    this.pageNumber = this.pageNumber - 1;
    this.search()
    }
  }
  pageNumber: number = 1
  pageSize: number = 10

  getAll(){
    this._svc.GetDetails("CourseLecture?pageNumber=" + this.pageNumber + "&pageSize="+ this.pageSize ).subscribe(rtnData => {
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstuploadrecordedlectureProperties= rtnData.data;
            for(var i = 0 ; i< this.lstuploadrecordedlectureProperties.length;  i++){
              this.lstuploadrecordedlectureProperties[i].filePath  = this.baseURLFile+ this.lstuploadrecordedlectureProperties[i].filePath; 
            }
          }  

          console.log(this.lstuploadrecordedlectureProperties)
      });
  }
  getAllCourse(){
    this._svc.GetDetails("Course?pageNumber=" + this.pageNumber + "&pageSize="+ this.pageSize ).subscribe(rtnData => {
 
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstVideoCourse= rtnData.data;
         }  
      });
  }
  // getAllClasses() {
  //   this._svc.GetDetails('Classes/GetAllClasses').subscribe(
  //     data => {
  //       this.lstClasses = data;
  //     }, (err) => {
  //     });
  // }


  CourseLectureBycourseId() {
    this._svc.GetDetails("CourseLecture/"+ this.objuploadrecordedlectureProperties.courseId +"?pageNumber=" + this.pageNumber + "&pageSize="+ this.pageSize ).subscribe(rtnData => {
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstuploadrecordedlectureProperties= rtnData.data;
            for(var i = 0 ; i< this.lstuploadrecordedlectureProperties.length;  i++){
              this.lstuploadrecordedlectureProperties[i].filePath  = this.baseURLFile+ this.lstuploadrecordedlectureProperties[i].filePath; 
            }
          }  

          console.log(this.lstuploadrecordedlectureProperties)
      });
  }

  CourseLectureBycourseIdTeacherId() {
    this._svc.GetDetails("CourseLecture/"+ this.objuploadrecordedlectureProperties.courseId +"/"+this.teacherId).subscribe(rtnData => {
      debugger;
        console.log('rtnData', rtnData);
         if (rtnData.success == true) {
            this.lstuploadrecordedlectureProperties= rtnData.data;
            for(var i = 0 ; i< this.lstuploadrecordedlectureProperties.length;  i++){
              this.lstuploadrecordedlectureProperties[i].filePath  = this.baseURLFile+ this.lstuploadrecordedlectureProperties[i].filePath; 
            }
          }  

          console.log(this.lstuploadrecordedlectureProperties)
      });
  }

  search(){
    debugger;
    if(this.isTeacher == "true"){
      this.CourseLectureBycourseId()
    } else {
      this.CourseLectureBycourseIdTeacherId()
    }
  }

  image: File | undefined;
  resData: any;
  selectedFile :any;
  envData;
  baseURL : string ="";

  onFileSelected(event:any) {
    debugger
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    debugger;
    // this.onSubmit();
  }

  onSubmit() {
    debugger;
    this.loaderService.showLoader();
        const payload = new FormData();
       if(this.isTeacher =="true"){
          var teacherId =  sessionStorage.getItem("userid")
          payload.append('TeacherId',teacherId);
        } else {
          payload.append('TeacherId',this.teacherId);
        }
        payload.append('CourseId',this.objuploadrecordedlectureProperties.courseId.toString());
        payload.append('Title',this.objuploadrecordedlectureProperties.title);
        payload.append('SequenceNumber', this.objuploadrecordedlectureProperties.sequenceNumber.toString());
        payload.append('file', this.selectedFile, this.selectedFile.name);
        this.http.post(this.baseURL + "CourseLecture",
            payload, { headers: { 'encType': "multipart/form-data"}
        }).subscribe((data: any) => {
          this.loaderService.hideLoader();
            this.resData = data;
            console.log(this.resData);
            debugger;
            this.global.openDialog("Upload", this.resData.message);
            this.search()
          });
  }


}


export class uploadrecordedlectureProperties
{
  id : string  
  courseId : number;
  courseName : string 
  teacherId : number
  teacherName : string  
  title : string  
  filePath : string 
  sequenceNumber : number;
  isActive : boolean
  isDeleted : boolean
  
  /**
   *
   */
  constructor() {
    this.courseId = 0;
    this.teacherId = 0;
    this.id = ""  ;
    this.courseName = ""; 
    this.teacherName = "" ; 
    this.title = "" ; 
    this.filePath = ""; 
    this.sequenceNumber  = 0;
    this.isActive = false;
    this.isDeleted = false;
  }
}