import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentComplaint } from 'app/Classes/student';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DomSanitizer } from '@angular/platform-browser';
import { Classes,DefineComplaintRecord } from 'app/Classes/classes';
import { StudentAttendance } from 'app/Classes/student';
@Component({
  selector: 'app-complaint-for-student',
  templateUrl: './complaint-for-student.component.html',
  styleUrls: ['./complaint-for-student.component.css']
})
export class ComplaintForStudentComponent extends BaseComponent implements OnInit {
  objStudentComplaint : StudentComplaint;
  lstStudentComplaint : StudentComplaint[];
  lstClasses: Classes[];
  classID : number =0;
  lstDefineComplaintRecord:DefineComplaintRecord[];
  lstStudentAttendance: StudentAttendance[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);
    this.objStudentComplaint = new  StudentComplaint();
    this.lstStudentComplaint = [];
     this.lstClasses= [];
     this.lstStudentAttendance = [];
     this.lstDefineComplaintRecord = [];
   }

   StudentComplaintdisplayedColumns: string[] = ['select', "ComplaintName" ,  "createdDate" ,  "isActive",  'Edit'];
  StudentComplaintdataSource = new MatTableDataSource<StudentComplaint>();
  StudentComplaintselection = new SelectionModel<StudentComplaint>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.getAllClasses();
    this.getAllComplaintType();
    this.GetViewMyComplaintsbyID();
  }

  GetViewMyComplaintsbyID(){
    var userid = sessionStorage.getItem('userid')
    this._svc.getGenericParmas(userid, "FK_studentid", "Student/GetViewMyComplaintsbyID").subscribe(
      data => {
        if(data.length > 0){
          this.StudentComplaintdataSource.data = data;
          this.StudentComplaintdataSource.sort = this.sort;
          this.StudentComplaintdataSource.paginator = this.paginator;
        }
      }, (err) => {
        this.global.openDialog("Students", "Some Error Occured.");
      });
  }
  editStudentComplaint(id : any){
    this.objStudentComplaint = this.StudentComplaintdataSource.data.filter( x=> x.studentComplaintID == id )[0]
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.StudentComplaintdataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllClasses(){
    debugger
    this._svc.GetAll('Classes/GetAllClasses').subscribe(
      data => {
        this.lstClasses = data;
      }, (err) => {
      });
  }

  getStudentsbyClassid(id: any){
    debugger
    this._svc.getGenericParmas(id,"ClassID",'Student/SelectStudentByCLassID').subscribe(
      data => {

        this.lstStudentAttendance = [];
        this.lstStudentAttendance = data;
      }, (err) => { 
      });
  }

  // GetViewMyComplaintsbyID(){
  //   debugger
  //   var id = this.global.getUserID();
  //   this._svc.getGenericParmas(id,"ClassID",'Student/GetViewMyComplaintsbyID').subscribe(
  //     data => {

  //  this.StudentComplaintdataSource.data = data;
  //  this.StudentComplaintdataSource.paginator = this.paginator;
  //  this.StudentComplaintdataSource.paginator = this.paginator;
   
   
  //     }, (err) => { 
  //     });
  // }

  getAllComplaintType(){
    debugger
    this._svc.GetAll('Common/getAllComplaintType').subscribe(
      data => {
        this.lstDefineComplaintRecord = data;
      }, (err) => {
      });
  }

  SaveStudentComplaint(){
    debugger
    this.objStudentComplaint.complaintID = Number(this.objStudentComplaint.complaintID);
    this.objStudentComplaint.fK_StudentID = Number(this.objStudentComplaint.fK_StudentID)
    this.objStudentComplaint.createdBy = this.global.getUserID();
    this.objStudentComplaint.lastUpdatedBy = this.global.getUserID();
    this._svc.Save(this.objStudentComplaint,'Student/AddUpdateStudentComplaintRecord').subscribe(
      data => {
     debugger;
        this.lstDefineComplaintRecord = data;
      }, (err) => {
      });
  }
}
