import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { BaseComponent } from 'app/Shared/base-component';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
import { OvertimeType } from 'app/Classes/overtime-type';
import { TAPolicy } from 'app/Classes/tapolicy';

@Component({
  selector: 'app-overtime-types',
  templateUrl: './overtime-types.component.html',
  styleUrls: ['./overtime-types.component.css']
})
export class OvertimeTypesComponent  extends BaseComponent implements OnInit {
  OvertimeType:OvertimeType;
  listOvertimeType:OvertimeType[];
  listTAPolicy: TAPolicy[];
  taPolicyId:string = "";
  displayedColumns: string[] = ['selected', 'overtimeTypeName' ,'overtimeTypeName_LL' ,	'minOvertime' ,'maxOvertime'  ,'compensateToLeave','mustRequested','approvalRequired','minAutoApproveDuration' ,'allowEditOverTime' ,'edit'];
  dataSource = new MatTableDataSource<OvertimeType>();
  selection = new SelectionModel<OvertimeType>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public languageTranslateService: LanguageTranslateService, public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) { 
      super(languageTranslateService); 
      this.OvertimeType = new OvertimeType();
      this.listOvertimeType = [];
      this.listTAPolicy = [];
    }

  ngOnInit() {
    this.GetAllOvertime();
    this.GetAllTAPolicy_ByCompany();
  }

  GetAllTAPolicy_ByCompany() {
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "TAPolicy/GetAllTAPolicy_ByCompany", "TA").subscribe(
      data => {
        ;
        this.listTAPolicy = [];
        this.listTAPolicy = data;
      }, (err) => {
        // alert("error occured ")
      });
  }


  GetAllOvertime(){
    // 
    var companyId = this.global.FK_CompanyId()
    this._svc.getGenericParmas(companyId, 'FK_CompanyId', "Overtime_Types/GetAllOvertime_Types_ByCompany", "TA").subscribe(
      data => {
        ;
        this.listOvertimeType = [];
        this.listOvertimeType = data;
        for (var i = 0; i < this.listOvertimeType.length; i++) {
          this.listOvertimeType[i].selected= false;
        }
        this.dataSource.data = this.listOvertimeType;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, (err) => {
        // alert("error occured ")
      });

  }
  select(id:any){

  }
  edit(id:any){

    this.OvertimeType = this.listOvertimeType.filter(x => x.overtimeTypeId == id)[0];
    this.taPolicyId = this.OvertimeType.fk_TAPolicyId.toString();
  }
 

  Save(){
    ;
    // Overtime_Types/SaveOvertime_Types
    // this.OvertimeType.fK_TAPolicyId
    this.OvertimeType.fk_TAPolicyId = Number(this.taPolicyId);
    this.OvertimeType.fk_CompanyId = this.global.FK_CompanyId();
    this.OvertimeType.minOvertime = this.global.convertNumbre(this.OvertimeType.minOvertime)
    this.OvertimeType.maxOvertime  = this.global.convertNumbre(this.OvertimeType.maxOvertime )
    this.OvertimeType.overtimeRate   = this.global.convertNumbre(this.OvertimeType.overtimeRate)
    this.OvertimeType.approvalBy ="";
    this.OvertimeType.overtimeCalculationConsideration=0;
    this.OvertimeType.overtimeChangeValue =0;
    // this.OvertimeType.compensateToLeave = this.global.convertNumbre(this.OvertimeType.compensateToLeave)
    this.OvertimeType.fK_LeaveTypeId = this.global.convertNumbre(this.OvertimeType.fK_LeaveTypeId)
    
if(this.global.isNumberNullOrEmplty(this.OvertimeType.overtimeTypeId) || this.OvertimeType.overtimeTypeId == 0){
  this.OvertimeType.cREATED_BY = this.global.getUserID();
}else {
  this.OvertimeType.cREATED_BY = this.global.getUserID();
  // this.OvertimeTyp = this.global.getUserID();
}
    var isError = this.initialValidations();
    if (isError == "") {
      this._svc.SaveObject(this.OvertimeType, "Overtime_Types/SaveOvertime_Types").subscribe(
        data => {
          ;
          if (data.includes("msgid=0")) {
            this.global.openDialog("Overtime Types", "Record has been saved.")
            //this.GetAllRamadanPeriods_ByCompany();
            this.Clear();
          }
          else if (data.includes("Success")) {
            this.global.openDialog("Overtime Types", "Record has been saved.")
            //this.GetAllRamadanPeriods_ByCompany();
            this.Clear();
          }
          else if (data.includes("NameExists")) {
            this.global.openDialog("Overtime Types", "English Name Already Exists");
          }
          else if (data.includes("Name_LLExists")) {
            this.global.openDialog("Overtime Types", "Local Language Name Already Exists");
          }
          else {
            this.global.openDialog("Overtime Types", "Error While Saving");
          }
        }, (err) => {
          this.global.openDialog("Overtime Types", "Error While Saving.")
        });
    }
    else {
      this.global.openDialog("Overtime Types", "Please fill the mentioned fields : " + isError)
    }
  }
  initialValidations(){
  return "";
  }
  Clear(){

  }
  ShowConfirmDialog(){
    
  }
}
