import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { NoticeBoardAnnuncement } from 'app/Classes/work-sechedule';
@Component({
  selector: 'app-add-notice-board',
  templateUrl: './add-notice-board.component.html',
  styleUrls: ['./add-notice-board.component.css']
})
export class AddNoticeBoardComponent  extends BaseComponent implements OnInit {

  objNoticeBoardAnnuncement: NoticeBoardAnnuncement;
  lstNoticeBoardAnnuncement: NoticeBoardAnnuncement[];

  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService) 
  
    this.objNoticeBoardAnnuncement = new NoticeBoardAnnuncement();
    this.lstNoticeBoardAnnuncement = []; 
  }

  ngOnInit() {
  }

}
