export class OvertimeType {
    overtimeTypeId : number;
	fk_CompanyId  : number;
	fk_TAPolicyId  : number;
	overtimeTypeName : string;
	overtimeTypeName_LL : string;
	minOvertime : number;
	maxOvertime : number;
	overtimeRate  : number;
	compensateToLeave : number;
	fK_LeaveTypeId  : number;
	mustRequested : boolean;
	approvalRequired : boolean;
	minAutoApproveDuration : number;
	approvalBy : string;
	allowEditOverTime :  boolean;
	overtimeCalculationConsideration : number;
	overtimeChangeValue :  number;
	cREATED_BY : string;
	lAST_UPDATE_BY: string;
	selected : boolean ;
}
