import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { UserInput, Sys_users, Menu, Languages } from 'app/Classes/login';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent extends BaseComponent implements OnInit {
  lang: string = '1';
  objUserInput: UserInput;
  objSys_users: Sys_users[];
  Menu: Menu[];
  Languages: Languages[];
  constructor(public languageTranslateService: LanguageTranslateService, private router: Router,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.objUserInput = new UserInput();
    this.objSys_users = [];
    this.Menu = [];
  }

  ngOnInit() {
    
    this.lang = sessionStorage.getItem('lang')
    this.changeLanguage(sessionStorage.getItem('lang'));
    // if(this.global.hasZeroLength(this.global.Languages)){
    //   this.getlanguage();
    //   this.changeLanguage('2');
    // }
    // else {
    //   this.Languages = this.global.Languages;
    //   this.getlanguage();
    //   this.changeLanguage(sessionStorage.getItem('lang'));
    // }

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./assets/Theme_assets/scripts/main.07a59de7b920cd76b874.js"; 
    document.getElementsByTagName("head")[0].appendChild(script);

    
     this.GetModulesAndForms()
       
  }
  GetModulesAndForms() {

    var lang = sessionStorage.getItem('lang');
    var userid = sessionStorage.getItem('userid')
    this.objUserInput.lang = Number(lang);
    this.objUserInput.userid = Number(userid);
    ;
    if(this.global.hasZeroLength(this.global.glbMenu)){
    this._svc.GetModulesAndForms(this.objUserInput, 'Forms/GetModulesAndForms').subscribe(
      data => {
        this.Menu = data;
        this.global.glbMenu = this.Menu;
        for(var i = 0; i< this.Menu.length ; i++){
          this.Menu[i].isActive = true;
        }
      }, (err) => {
        // alert("error occured ")
      });
    }
    else {
      this.Menu = this.global.glbMenu;
      for(var i = 0; i< this.Menu.length ; i++){
        this.Menu[i].isActive = true;
      }
    }
  }
  getlanguage() {

    var companyId = this.global.FK_CompanyId()
        this._svc.getGenericParmas(companyId, 'FK_CompanyId','Language/GetAllLanguagesByCompany').subscribe(
          data => {
        this.Languages = data;
        this.global.Languages = this.langObject;
      }, (err) => {
        // alert("error occured ")
      });
  }

  changeLanguage(lang: string) {
    
    sessionStorage.setItem('lang', lang);
    if (lang == '1'){
      this.languageTranslateService.setLang("en");
    }
    else if(lang == '2')
    {
      this.languageTranslateService.setLang("ar");
    }
    else {
      this.languageTranslateService.setLang("en");
    }
  }
  getPage(URL : string , formName : string){

    console.log(URL);
    console.log(formName);
    
    this.global.FormName = formName;
    sessionStorage.setItem('URL', URL );
    
    if(URL.includes('LookUp')){
      var x = URL.split("/");
      sessionStorage.setItem('lookupID', x[1])
      this.Redirect('lookup');
    }
    else if( formName == "WorkSchedule"){
      this.Redirect('WorkSchedule');
    }
    else if( formName == "TAPolicy"){
      this.Redirect('TAPolicy');
    }
    else if( formName == "RamadanPeriod"){
      this.Redirect('RamadanPeriod');
    }
    else if( formName == "OvertimeTypes"){
      this.Redirect('OvertimeTypes');
    }
    else if( formName == "LeavesTypes"){
      this.Redirect('LeavesTypes');
    }
    else if( formName == "ApplicationSettings"){
      this.Redirect('ApplicationSettings');
    }
    else if( formName == "Managers"){
      this.Redirect('Managers');
    }
    else if( formName == "TAException"){
      this.Redirect('TAException');
    }
    else if( formName == "AssingTaPolicy"){
      this.Redirect('AssingTaPolicy');
    }
    else if( formName == "ValidationException"){
      this.Redirect('ValidationException');
    }
    else if( formName == "AssignWorkSchedule"){
      this.Redirect('AssignWorkSchedule');
    }
    
    else{
      ;
    var u = URL.toLowerCase();
      this.Redirect(u)
    }
  }

  Redirect(url: string) {
    var myurl = `${url}/${''}`;
    const that = this;
    that.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      that.router.navigate([myurl])
    );
  }

  openclose(id :any){

    for(var i = 0; i< this.Menu.length ; i++){
      if(this.Menu[i].ApplicationName == id)
      this.Menu[i].isActive =      !this.Menu[i].isActive
    }
  }
  setClass(str : any){
    
    sessionStorage.setItem("themeClass", str)
  }
}
