export const DROPDOWN_LANGS = ['English', 'Arabic']
export const BUTTONS = ['Save', 'Delete', 'Clear', 'Cancel', 'Update']
export const ApplicationSetting = ['Application Settings', 'Application Settings', 'Customer English Name', 'Customer English Short Name', 'Customer Arabic Name', 'Customer Arabic Short Name']
export const Login = 'Log in'
export const LookUpValue = ' Name'
export const LookUpValueCode = ' Code'
export const OrgUnit = "Org Unit";
export const Group = "Group";
export const EmployeeNo = 'Employee No.';
export const Search = 'Search';
export const AddNew = 'Add New';
export const Clear = 'Clear';
export const Delete = 'Delete';
export const Save = 'Save';
export const UserName = 'User Name';
export const FullName = "Full Name"
export const Edit = "Edit"
export const addLanguage = "Add Language"
export const ModalHeader = "Modal Header"
export const AddNewUser = "Add New User"
export const Organization = "Organization"
export const Name = "Name"
export const ShortName = "Short Name"
export const Address = "Address"
export const PhoneNo = "Phone No"
export const Fax = "Fax"
export const URL = "URL"
export const Level = "Level "
export const LevelName = "Level Name"
export const ArabicName = "Arabic Name"
export const OrganizationStructure = 'Organization Structure'
export const EnglishName = 'English Name'
export const EntityCode = 'Entity Code'
export const EntityInfo = 'Entity Info'

export const UserID = "User ID";
export const Email = "Email";
export const FullNameInlocalLanguage = "Full Name in Local Language ";
export const UniqueID = "Unique ID";
export const ConfirmPassword = "Confirm Password";
export const Company = "Company";
export const PhoneNumber = "Phone Number";
export const OrganizationEntity = "Organization Entity"
export const JobDescription = "Job Description";
export const isActive = "Is Active"
export const Password = "Password"

export const languageName = "Language"
export const LangId = "LangId"
export const LangCode = "Lang Code"
export const lookUpValue_lang = " Value language"
export const Country = "Country"
export const orgUnit = "Org Unit"

export const orgUnitName_LL = "Unit Name in Local Language";
export const orgUnitName = "Unit Name";
export const orgUnitCode = "Unit Code";
export const fK_LevelId = "fK_LevelId";
export const CompanyName = "Company Name";

export const OrgStructure = "Organization Structure";

export const id = "id"
export const HasChildren = "Has Children";
export const parentid = "parent id ";
export const UnitName = "Unit Name";
export const value = "value";
export const ChildName = "Child Name";
export const WorkLocation = "Work Location"


export const radius = "Radius";
export const workLocationId = "Work Location Id";
export const workLocationCode = "Work Location Code";
export const workLocationName = "Work Location Name";
export const workLocationName_LL = "Work Location Name";
export const gPSCoordinates = "GPS Coordinates";

export const logicalGroup = 'logical Group';
export const GroupId = "Group Id"
export const GroupName = "Group Name";
export const GroupName_LL = "Group Name Local Language";


export const statusCode = "Status Code"
export const StatusName = "Status Name"
export const StatusName_LL = "Status Name Local Language"
export const StatusDescription = "Status Description"
export const CosiderEmployeeActive = "Cosider Employee Active"

export const TypeName = "Type Name"
export const TypeName_LL = "Type Name Local Language"
export const IsInternaltype = "Is Internal Type"
export const EmployeeNumberInitial = "Employee Number Initial"
export const EmployeeNumber = "Employee Number"

export const employeeName = "Employee Name"
export const joinDate = "Join Date"

export const FK_Status = "Status"
export const FK_EmployeeTypeId = "EmployeeTypeId"
export const FK_CompanyId = "School Name"
export const FK_OrgUnitId = "Branch Name"
export const FK_WorkLocation = "Work Location"
export const FK_LogicalGroup = "Logical Group"
export const FK_lkpNationality = "Nationality"
export const FK_lkpReligion = "Religion"
export const FK_lkpMaritalStatus = "MaritalStatus"
export const FK_lkpGrade = "Grade"
export const FK_lkpDesignation = "Designation"
export const IsTerminated = "Is Terminated"
export const EmployeeActiveCard = "Employee Active Card"
export const ExternalPartyName = "External Party Name"
export const employeeName_LL = "Employee Name Local Language"
export const Gender = "Gender"
export const OrgUnitHierarchy = "Org Unit Hierarchy"
export const Remarks = "Remarks"
export const EmpImagePath = "Emp Image Path"
export const MobileNo = "Mobile No"
export const ContractEndDate = "Contract End Date"
export const DOB = "DOB"
export const TerminateDate = "Terminate Date"
export const select = "select"
export const AddNewEmployee = "Add New Employee"
export const addUnit = 'Add Unit'


export const GroupID = 'GroupID';
export const DefaultPage = 'DefaultPage'
export const AddNewGroup = 'Add New Group'
export const Language = 'Language'

export const DefaultLanguage = 'Default Language'
export const LocalLanguage = 'Local Language'
export const LanguageList = 'LanguageList'

export const ScheduleName = "Schedule Name"
export const ScheduleNameLocalLanguage = "Schedule Name Local Language"
export const Normal = "Normal"
export const Flexible = "Flexible"
export const Open = "Open"
export const GraceIn = "Grace In"
export const GraceOut = "Grace Out"
export const ConsiderDelayFromGrace = "Consider Delay From Grace"
export const ConsiderEarlyOutFromGrace = "Consider Early Out From Grace"
export const Default = "Default"
export const Active = "Active"
export const DelayIsFromGrace = "Delay Is From Grace"
export const EarlyOutIsFromGrace = "Early Out Is From Grace"
export const Selected = "Selected"
export const IsRamadanSch = "Is RamadanS"
export const IsDefault = "Is Default"
export const AddRamazanSchedule = "Add Ramazan Schedule"
export const SaveRamazanSchedule = "Save Ramazan Schedule";
export const scheduleType = "schedule Type"
export const ViewRamazanSchedule = "View Ramazan Schedule"



export const TAPolicyName = "Policy Name"
export const TAPolicyName_LL = "Policy Name LL"

export const Consider_FILO_Only = "Consider FILO Only"
export const ConsiderAbsentIfNotCompleteNoOfHours = "Consider Absent If Not Complete No Of Hours"
export const BeforeAfterSchedule = "Before After Schedule"
export const NoOfNotCompleteHours = "No Of Not Complete Hours"
export const OT_Eligibility = "Eligible Overtime"
export const OT_HasHighTime = "Has High Time Overtime"
export const FK_WorkDay_OT_TypeId = "WorkDay OT TypeId"
export const FK_OffDay_OT_TypeId = "OffDay OT TypeId"
export const FK_OTHoliday_OT_TypeId = "OTHoliday OT TypeId"
export const FK_ReligionHoliday_OT_TypeId = 'ReligionHoliday OT TypeId'


export const TAPolicy = "TA Policy"
export const PolicyBreak = "Policy Break"
export const PolicyViolations = "Policy Violations"
export const PolicyViolationActions = "Policy Violation Actions"


export const flixibleDuration = "Flixible Duration";
export const fromTime = "From Time";
export const toTime = "To Time";
export const isFlixible = "Is Flixible";
export const toBeCompensated = "To Be Compensated";
export const noOfAllowedBreak = "No Of Allowed Break";


export const violationName = "Violation Name"
export const violationName_LL = "Violation Name LL"
export const violationRuleType = "Violation Rule Type"
export const variable1 = "Variable 1"
export const variable2 = "Variable 2"
export const variable3 = "Variable 3"
export const scenarioMode = "Scenario Mode"
export const considerAbsent = "Consider Absent"

export const violationId = "Violation Id"
export const actionSequence = "Action Sequence"
export const fK_lkpViolationActionId = "LKP Violation Action Id"


export const BeforeSchedule = "Before Schedule"
export const AfterSchedule = "After Schedule"

export const Add = "Add"
export const Remove = "Remove"


export const fromDate = "From Date";
export const toDate = "To Date";


export const overtimeTypeName = "overtime Type Name";
export const overtimeTypeName_LL = "overtime Type Name Local Language";
export const minOvertime = "Min Over Time";
export const maxOvertime = "Max Over time";
export const overtimeRate = "Overtime Rate";
export const compensateToLeave = "Compensate To Leave";
export const mustRequested = "Must Requested";
export const approvalRequired = "Approval Required";
export const minAutoApproveDuration = "Min Auto Approve Duration";;
export const approvalBy = "Approval By";
export const allowEditOverTime = "Allow Edit OverTime";
export const overtimeCalculationConsideration = "Overtime Calculation Consideration";
export const overtimeChangeValue = "Overtime Change Value";
export const Leave = "Leave";

export const Absent = "Absent"
export const DelayEarlyOut = "Delay & Early Out"
export const MissingInorOut = "Missing In or Out"


export const Continuous = "Continuous"
export const Seperated = "Seperated"
export const Occuerence = "Occuerence"

export const Notice = "Notice"
export const Warning = "Warning"
export const DeductfromSalary = "Deduct from Salary"
export const Suspension = "Suspension"
export const Dismiss = "Dismiss"
export const Overtime = "Overtime"

export const WorkDay = "Work Day"
export const OffDay = "Off Day"
export const Holiday = "Holiday"
export const ReligionHoliday = "Religion Holiday"
export const leavestypes = "Leaves Types"
export const occuerrence = "Occuerrence"
export const duration = "Duration"
export const leavesgrades = "Leaves Grades "
export const leavestypes_entity = "Leaves Types Entity"


export const fK_RelatedTAReason = "Related TAReason"
export const allowedAfter = "Allowed After"
export const defaultNoOfDays = "Default No Of Days"
export const flexibleAllowedTime = "Flexible Allowed Time"
export const allowedBefore = "Allowed Before"
export const overtimeValidityDays = "Overtime Validity Days"

export const isConsiderInWork = "Is Consider In Work"
export const allowedAfterSpecificTime = "Allowed After Specific Time"
export const allowedBeforeSpecificTime = "Allowed Before Specific Time"
export const notAllowedWithStudyNursing = "Not Allowed With Study Nursing"
export const hasFullDayPermission = "Has FullDay Permission"
export const hasPermissionForPeriod = "Has Permission For Period"
export const hasSpecificTime = "Has Specific Time"
export const hasFlexiblePermission = "Has Flexible Permission"
export const considerRequestWithinBalance = "Consider Request Within Balance"
export const convertToLeave_ExceedDuration = "Convert To Leave Exceed Duration"
export const mustHaveTransaction = "MustHave Transaction"
export const deductBalanceFromOvertime = "Deduct Balance From Overtime"
export const fK_LeaveTypeId = "Leave TypeId"
export const maximumOccur = "Maximum Occur"

export const maximumRamadanDuration = "Maximum Ramadan Duration"
export const maximumDuration = "Maximum Duration"

export const gradeBalance = "Grade Balance"


export const leaveTypeCode = "Leave Type Code";
export const leaveTypeName = "leave Type Name";
export const leaveTypeName_LL = "Leave Type Name Local Language";
export const isHourlyLeave = "Is Hourly Leave";
export const balance = "Balance"
export const monthlyBalancing = "Monthly Balancing";
export const minDuration = "Min Duration"
export const maxDuration = "Max Duration";
export const minServiceDays = "Min Service Days";
export const maxRoundBalance = "Max Round Balance";
export const allowIfBalanceInsufficient = "Allow If Balance Insufficient";
export const generalGuide = "General Guide";
export const generalGuide_LL = "General Guide in Local Language";
export const ParentLeaveType = "Parent Leave Type";
export const allowedForSelfService = "Parent Leave Type"
export const showRemainingBalance = "Show Remaining Balance"
export const isNotificationException = "Is Notification Exception";
export const attachmentIsMandatory = "Attachment IsvMandatory"
export const remarksIsMandatory = "Remarks Is Mandatory";
export const isSpecificGrade = "Is Specific Grade";
export const isSpecificEntity = "Is Specific Entity";
export const allowedGender = "Allowed Gender";
export const allowForSpecificEmployeeType = "Allow For Specific Employee Type";
export const EmployeeTypeId = "Employee Type Id";
export const allowedBeforeDays = "Allowed Before Days";
export const allowedAfterDays = "Allowed After Days";
export const allowInRamadan = "Allow In Ramadan";
export const leaveApproval_WF = "leave Approval WF"
export const isAutoApprove = "Is Auto Approve"
export const autoApproveAfter = "Auto Approve After";
export const autoApprovePolicy = "Auto Approve Policy";
export const balanceConsideration_TC = "Balance Consideration TC";

export const isDailyLeaves = "Is Daily Leaves" 
export const ConsequenceTransactions ="Consequence Transactions"
export const FirstInOut ="First In\Last Out"
export const LastInFirstOut ="Last In\First Out"
export const MinimumTimeBetweenTransactions ="Minimum Time Between Transactions"
export const TAPolicyGraceBy ="TA Policy Grace By"
export const WorkSchedule ="Work Schedule"
export const LeaveWorkFlow ="Leave WorkFlow"
export const ApplicationUsersType ="Application Users Type"
export const SystemUsersOnly ="System Users Only"
export const ActiveDirectoryOnly ="Active Directory Only"
export const Both ="Both"
export const ShowLoginPage ="Show Login Page"
export const Yes ="Yes"
export const No ="No"
export const WeekStartDay ="Week Start Day"
export const ConsiderAbsentAfter ="Consider Absent After"
export const ConsiderAbsentEvenIfAttend ="Consider Absent Even If Attend"
export const ShiftScheduleWithNoShifts ="Shift Schedule With No Shifts"
export const RadioButtonsRestDay ="Radio Buttons Rest Day"
export const DefaultWorkSchedule ="Default Work Schedule"
export const WorkDaysMinutes ="Work Days Minutes"
export const AutoPersonalHourlyLeaveForDelay ="Auto Personal Hourly Leave For Delay"
export const AutoPersonalHourlyLeaveDuration ="Auto Personal Hourly Leave Duration"
export const AutoPersonalHourlyLeaveForEarlyOut ="Auto Personal Hourly Leave For Early Out"
export const ArchivingMonths ="Archiving Months"
export const ShowDailyLeaveRequest ="Show Daily Leave Request"
export const ShowDailyLeaveRequestLinkInViolationCorrection ="Show Daily Leave Request Link In Violation Correction"
export const ShowHourlyLeaveRequestLinkInViolationCorrection ="Show Hourly Leave Request Link In Violation Correction"
export const ConsiderLeaveOnOffDays ="Consider Leave On Off Days"
export const ViolationJustificationDays ="Violation Justification Days"
export const ExcludeFromViolationJustificationDaysChecklist ="Exclude From Violation Justification Days Check list"
export const Holidays ="Holidays"
export const DailyLeaves ="Daily Leaves"
export const RestDay ="Rest Day"
export const AutoPersonalHourlyLeaveForDuration ="Auto Personal Hourly Leave For Duration"
export const SpecificEntity = "Specific Entity"
export const All ="All"
export const employees = "Employees"
export const Entity = "Entity"
export const LogicalGroup = "Logical Group"
export const EmployeeList = "Employee List"
export const entityList = "Entity List"
export const WorklocationsList = "Work Locations"
export const Temporary = "Temporary"

export const SpecificGroup = 'Specific Group'
export const Worklocation ="Worklocation"
export const entity ="Entity"
export const Manager = "Manager"

export const fk_ParentModule  ="Parent Module"
export const moduleName  ='Module Name';
export const seq ="Sequence"
export const moduleName_Lang= "Parent Module"


export const formID = "Form ID";
export const formName ="Form Name";
export const moduleID = "Module ID" ;
export const formPath = "Form Path";
export const formOrder = "Form Order" ;
export const parentID = "Parent ID" ;
export const visible ="Visible";
export const hasMobile  = "Has Mobile";
export const showToEmp = "Show To Emp";
export const showToClient = "Show To Client";



//----------------------- Student Statrt
export const StudentName ="Student Name"
export const Weight ='Weight (kg)'
//export const Gender = 'Gender'
export const Height ='Height (cm)'
export const DateofBirth= 'Date of Birth'
export const BloodGroup = 'Blood Group'
export const BirthPlace = "Birth Place"
export const Hand = 'Hand'
export const AnyDisability = 'Any Disability'
export const Religion = 'Religion'
export const BForm ='B-Form #'
export const Nationality = 'Nationality'
export const Picture ='Picture'
export const AddressHouseFlatStreet = 'Address (House/Flat/Street)'
export const CityOrTown ='City / Town'
export const PostalCode ='Postal Code'
//export const PhoneNo ='Phone No.'
//export const MobileNo ='Mobile No'
export const EmailAddress = 'Email Address'
export const School ='School'
export const TaxExemption ='Tax Exemption'
export const ClassSection ='Class / Section'
export const RegistrationNo ='Registration No'
export const RegistrationDate ='Registration Date'
export const JoiningDate ='Joining Date'
export const RollNo = 'Roll No'
export const PreviousSchool ='Previous School'
export const PreviousClass ='Previous Class'
export const TaxPayer ='Tax Payer'

export const FatherName ='Father Name'
export const FatherAddress ='Father Address'
export const FatherCNIC ='Father C.N.I.C #'
export const FatherNTN ='Father N.T.N'
export const FatherMaritalStatus ='Father Marital Status'
export const FatherMarried ='Father Married'
export const FatherQualification ='Father Qualification'
export const FatherOccupation ='Father Occupation'
export const FatherCompany ='Father Company'
export const FatherDepartment ='Father Department'
export const FatherDesignation ='Father Designation'
export const FatherAddressHouseFlatStreet ='Father Address (House/Flat/Street)'
export const FatherCityTown ='Father City / Town'
export const FatherPostalCode ='Father Postal Code'
export const FatherPhoneNoLandline ='Father Phone No (Landline)'
export const FatherMobileNo ='Father Mobile No'
export const FatherEmailAddress ='Father Email Address'
export const MotherName = 'Mother Name'
export const MotherCNIC ='Mother C.N.I.C #'
export const MotherNTN = 'Mother N.T.N'
export const MotherMaritalStatus = 'Mother Marital Status'
export const MotherQualification ='Mother Qualification'
export const MotherOccupation ='Mother Occupation'
export const MotherCompany ='Mother Company'
export const MotherDepartment ='Mother Department'
export const MotherDesignation ='Mother Designation'
export const MotherAddressHouseFlatStreet ='Mother Address (House/Flat/Street)'
export const MotherCityTown ='Mother City / Town'
export const MotherPostalCode = 'Mother Postal Code'
export const MotherPhoneNo ='Mother Phone No (Landline)'
export const MotherMobileNo ='Mother Mobile No'
export const MotherEmailAddress ='Mother Email Address'
export const EmergencyContactName ='Emergency Contact Name'
export const EmergencyRelation ='Emergency Relation (with student)'
export const EmergencyPhoneNoLandline = 'EmergencyPhoneNo (Landline)'
export const EmergencyMobileNo ='Emergency Mobile No'
export const EmergencyAddress ='Emergency Address (House/Flat/Street)'
export const EmergencyCityTown ='Emergency City / Town'
export const studentProfile ='Student Profile'
export const StudentDetails ='Student Details'
export const JobDesc = "Job Description";

export const ClassName = "Class Name"
export const Fee = "Fee";
export const Medium = "Medium"
export const Section = "Section" 

export const Female = "Female";
export const Male = "Male"
export const Other  = "Other"

export const TeacherID = "Teacher";
export const TeacherFName = "Teacher Fisrt Name";
export const TeacherLName = "Teacher Last Name";
export const TeacherGender = "Teacher Gender";
export const Teacher = "Teacher";
export const TeacherCNIC = "Teacher CNIC";
export const TeacherPhoneNumber = "Teacher Phone Number";
export const TeacherAddress = "Teacher Address";
export const TeacherCity = "Teacher City";
export const TeacherDistt = "Teacher Distt";
export const TeacherType = "Teacher Type";
export const TeacherContactNumber = "Teacher Contact Number";
export const TeacherEmailID = "Teacher Email ID";
export const SchoolID = "School ID";
export const TeacherEmergencyContactNumber = "Teacher Emergency Contact Number";
export const TeacherEmergency_ContactName = "Teacher Emergency Contact Name";
export const TeacherEmergency_relatioship = "Teacher Emergency Relatioship";
export const TeacherPicture = "Teacher Picture";
export const TeacherPictureExt = "Teacher Picture Ext";
export const TeacherJoiningDate = "Teacher Joining Date";
export const TeacherDOB = "Teacher DOB";
export const CreatedBy = "Created By";
export const CreatedDate = "Created Date";
export const LastUpdatedBy = "Last Updated By";
export const LastUpdatedDate = "Last Updated Date";
export const IsActive = "Is Active";
export const TeacherReligion = "Teacher Religion";


export const TeacherSpecialityID = "Teacher Speciality ID";
export const FK_TeacherID = "Teacher";
export const FK_SubjectID = "Subject";


export const TeacherProfessionalRecordID = "Teacher Professional Record";
export const institutename = "Institute Name";
export const TenureFromDate = "Tenure From Date";
export const TenureToDate = "Tenure To Date";
export const AppoaintedAS = "Appoainted AS";
export const Lastdesignation = "Last Designation";
export const Salary = "Salary";
export const TeacherProfessionalcertificate = "Teacher Professional Certificate";
export const TeacherProfessionalcertificate_Ext = "Teacher Professional Certificate Ext";


export const TeacherEducationalrecordID = "Teacher Educational Record";
export const DegreeName = "Degree Name";
export const InstituteName = "Institute Name";
export const FromDate = "From Date";
export const ToDate = "To Date";
export const MarksObtained = "Marks Obtained";
export const TotalMarks = "Total Marks";
export const Percntage = "Percntage";
export const GradeObtained = "Grade Obtained";
export const DegreeImage = "Degree Image";
export const DegreeImage_Ext = "Degree Image Ext";


export const TeacherComplaintRecordID = "Teacher Complaint Record";
export const FK_ComplaintID = "Complaint";
export const IsOtherThanTeacherComplaint = "Is Other Than Teacher Complaint";
export const TeacherOtherComplaint = "Teacher Other Complaint";
export const ComplaintDetail = "Complaint Detail";

export const TeacherSpeciality = "Teacher Speciality"
export const TeacherProfessionalRecord = "Professional Record"
export const TeacherEducationalRecord = "Educational Record"
export const TeacherComplaintRecord = "Complaint Record"
export const Visiting = "Visiting";
export const Permanent = "Permanent";

export const teacherProfile ='Teacher Profile'
export const teacherName ="Teacher Name"

export const SubjectID = "Subject ID";
export const SubjectName = "Subjec tName";
export const SubjectMedium = "Subject Medium";
export const FK_ClassID = "FK Class ID";
export const FK_SchoolID = "FK School ID";
// ===================
export const StudentHistoryID = "Student History ID";
export const StudentHistory = "Student History";
export const StudentID = "Student ID";
export const SchoolName = "School Name";
export const Class = "Class";
export const TotlaMarks = "Totla Marks";
export const Grade = "Grade";
export const PassingDate = "Passing Date";
export const CertificateImage = "Certificate Image";
export const StudentAttendanceID = "Student Attendance ID";
export const FK_StudentID = "Student ID";
export const ClassID = "Class ID";
export const StudentAttendanceStatus = "Student Attendance Status";
export const StudentAttendanceDate = "Student Attendance Date";
export const LeaveID = "Leave ID";
export const StudentComplaintID = "Student Complaint ID";
export const ComplaintID = "Complaint ID";
export const StudentTestResultID = "Student Test Result ID";
export const TestID = "Test ID";
export const ObtainedMarks = "Obtained Marks";
export const Percentage = "Percentage";

export const TestResult = "Test Result"
export const StudentComplaint = "Student Complaint"
export const StudentTestResult = "Student Test Result"
export const StudentAttendance ="Student Attendance"

export const userType = "User Type"
export const Present = "Present"

export const onLeave = "On Leave"
export const assignSubjectID = "AssignSubjectID";

export const LeaveTypeID = "LeaveTypeID";
export const LeaveTypeText = "LeaveTypeText";
export const LeaveDateFrom = "LeaveDateFrom";
export const LeaveDateTo = "LeaveDateTo";
export const noOfdasy = "noOfdasy";
export const AttachementDetails = "AttachementDetails";
export const AttachementID = "AttachementID";


export const TeacherLeaveID = "TeacherLeaveID";

export const TeacherLeaveTypeID = "TeacherLeaveTypeID";
export const TeacherLeaveTypeText = "TeacherLeaveTypeText";
export const TeacherLeaveDateFrom = "TeacherLeaveDateFrom";
export const TeacherLeaveDateTo = "TeacherLeaveDateTo";
export const TeacherLeavenoOfdasy = "TeacherLeavenoOfdasy";
export const TeacherAttachementDetails = "TeacherAttachementDetails";
export const TeacherAttachementID = "TeacherAttachementID";

export const assignmentdetailID = "assignmentdetailID";
export const fk_assignmentID = "fk_assignmentID";
export const fk_studentID = "fk_studentID";
export const fk_ClassID = "fk_ClassID";
export const assignmentName = "assignmentName";
export const studentsRemarks = "studentsRemarks";
export const teacherRemarks = "teacherRemarks";


export const assignmentID = "assignmentID";
export const subjectID = "subjectID";
export const assingmentMarks = "assingmentMarks";
export const dueDate = "dueDate";
export const createdDate = "createdDate";
export const assigmnentFileName = "assigmnentFileName";
export const createdBy = "createdBy";
export const assignmentDetails = "assignmentDetails";
export const assignmentSubject = "assignmentSubject";
export const lastUpdatedby = "lastUpdatedby";
export const lastupdatedDate = "lastupdatedDate";
export const noticeBoardAnnuncementID = "NoticeBoardAnnuncementID";
export const fKClassID = "FKClassID";
export const fKSubjectID = "FKSubjectID";
export const showntoAll = "ShowntoAll";
export const noticeFileName = "NoticeFileName";
export const noticeBoardDetails = "NoticeBoardDetails";
export const pinonTop = "PinonTop";
export const modifiedBy = "ModifiedBy";
export const modifiedDate = "ModifiedDate";

export const isOnline= "is Online";
export const LectureName= "Lecture Name"
export const CourseName= "Course Name"