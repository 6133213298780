import { Injectable } from '@angular/core';
import { Languages, Sys_users, Menu } from 'app/Classes/login';
import { CommonDialogModel, PopupModalComponent } from 'app/popup-modal/popup-modal.component';
import { MatDialog, MatTableDataSource } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {
  FormName: string ="";
  Languages: Languages[];
  objSys_users: Sys_users;
  glbMenu: Menu[];
  constructor(public dialog: MatDialog ) { 
    this.Languages = [];  
    this.glbMenu = [];
  }
  isStringNullOrEmplty(val: string) {
    if (val == null || val == "" || val == undefined)
      return true;
    else
      return false;
  }
  isNumberNullOrEmplty(val: number) {
    if (val == null || val == undefined)
      return true;
    else
      return false;
  }
  getList(val: any) {
    
    return  JSON.parse(sessionStorage.getItem(val));
     
  }
  hasZeroLength(list: any){
    if(list == null){
      return true;
    }
    else if(list.length == 0){
      return true;
    }
    else {
      return false;
    }
  }
  // getUserID(){
  //   this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
  //   return this.objSys_users.user_FullName;
  // }
  getUserID(){
    this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
    return this.objSys_users.userId.toString();
  }
  FK_CompanyId(){
    this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
    return this.objSys_users.companyId;
  }
  companyName(){
    this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
    return this.objSys_users.companyName;
  }
  convertNumbre(num: any){
    if(num == undefined){
      return 0;
    }
    else {
      try{
        var x = Number(num);
        return x;
      }
      catch{
        return 0;
      }
    }
  }

  openDialog(title: string, Msg: string): void {
    const message = Msg;
    const dialogData = new CommonDialogModel(title, message);

    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: '640px', disableClose: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


    });

  }

 

}
// export enum enumMsg{
//   Success  =  "Record has been saved.",
//   NameExists  =  "Record has been saved.",
//   Name_LLExists = "",
//   msgid =""
// }

