export class teacher {
    teacherID: number;
    userID: number;
    teacherFName: string;
    teacherLName: string;
    teacherGender: string;
    teacher: string;
    teacherCNIC: string;
    teacherPhoneNumber: string;
    teacherAddress: string;
    teacherCity: string;
    teacherDistt: string;
    teacherType: string;
    teacherContactNumber: string;
    teacherEmailID: string;
    schoolID: number;
    teacherEmergencyContactNumber: string;
    teacherEmergency_ContactName: string;
    teacherEmergency_relatioship: string;
    teacherPicture: string;
    teacherPictureExt: string;
    teacherJoiningDate: Date;
    teacherDOB: Date;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    teacherReligion: string;


    /**
     *
     */
    constructor() {
        this.teacherID = 0;
        this.userID = 0;
        this.teacherFName = "";
        this.teacherLName = "";
        this.teacherGender = "";
        this.teacher = "";
        this.teacherCNIC = "";
        this.teacherPhoneNumber = "";
        this.teacherAddress = "";
        this.teacherCity = "";
        this.teacherDistt = "";
        this.teacherType = "";
        this.teacherContactNumber = "";
        this.teacherEmailID = "";
        this.schoolID = 0;
        this.teacherEmergencyContactNumber = "";
        this.teacherEmergency_ContactName = "";
        this.teacherEmergency_relatioship = "";
        this.teacherPicture = "";
        this.teacherPictureExt = "";
        this.teacherJoiningDate = new Date();
        this.teacherDOB = new Date();
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.teacherReligion = "";
    }
}
export class TeacherSpeciality {
    teacherSpecialityID: number;
    fK_TeacherID: number;
    fK_SubjectID: number;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    subjectName : string;
    select:boolean;
    constructor() {
        this.teacherSpecialityID = 0;
        this.fK_TeacherID = 0;
        this.fK_SubjectID = 0;
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.select = false;
        this.subjectName  ="";
    }

}

export class TeacherProfessionalRecord {
    teacherProfessionalRecordID: number;
    fK_TeacherID: number;
    institutename: string;
    tenureFromDate: Date;
    tenureToDate: Date;
    appoaintedAS: string;
    lastdesignation: string;
    salary: number;
    teacherProfessionalcertificate: string;
    teacherProfessionalcertificate_Ext: string;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    select:boolean;
    /**
     *
     */
    constructor() {

        this.teacherProfessionalRecordID = 0;
        this.fK_TeacherID = 0;
        this.institutename = "";
        this.tenureFromDate = new Date();
        this.tenureToDate = new Date();
        this.appoaintedAS = "";
        this.lastdesignation = "";
        this.salary = 0;
        this.teacherProfessionalcertificate = "";
        this.teacherProfessionalcertificate_Ext = "";
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.select = false;
    }
}


export class TeacherEducationalRecord {
    teacherEducationalrecordID: number;
    fK_TeacherID: number;
    degreeName: string;
    instituteName: string;
    fromDate: Date;
    toDate: Date;
    marksObtained: number;
    totalMarks: number;
    percntage: number;
    gradeObtained: string;
    degreeImage: string;
    degreeImage_Ext: string;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    select: boolean;
    /**
     *
     */
    constructor() {
        this.teacherEducationalrecordID = 0;
        this.fK_TeacherID = 0;
        this.degreeName = "";
        this.instituteName = "";
        this.fromDate = new Date();
        this.toDate = new Date();
        this.marksObtained = 0;
        this.totalMarks = 0;
        this.percntage = 0;
        this.gradeObtained = "";
        this.degreeImage = "";
        this.degreeImage_Ext = "";
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.select = false;
    }

}

export class TeacherComplaintRecord {
    teacherComplaintRecordID: number;
    fK_TeacherID: number;
    fK_ComplaintID: number;
    isOtherThanTeacherComplaint: boolean;
    teacherOtherComplaint: string;
    complaintDetail: string;
    createdBy: string;
    createdDate: Date;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    isActive: boolean;
    select:boolean;
    /**
     *
     */
    constructor() {

        this.teacherComplaintRecordID = 0;
        this.fK_TeacherID = 0;
        this.fK_ComplaintID = 0;
        this.isOtherThanTeacherComplaint = false;
        this.teacherOtherComplaint = "";
        this.complaintDetail = "";
        this.createdBy = "";
        this.createdDate = new Date();
        this.lastUpdatedBy = "";
        this.lastUpdatedDate = new Date();
        this.isActive = false;
        this.select = false;
    }
}

export class teacherLeave
{
     teacherLeaveID : number;  
     teacherID : number;  
     teacherLeaveTypeID : number;  
     teacherLeaveTypeText : string;  
     teacherLeaveDateFrom : Date;  
     teacherLeaveDateTo : Date;  
     teacherLeavenoOfdasy : number;  
     teacherAttachementDetails : string;  
     teacherAttachementID : number;  
     createdBy : string;  
     createdDate : Date;  
     lastUpdatedBy : string;  
     lastUpdatedDate : Date;  
     isActive : boolean;  
     select: boolean;  

     /**
      *
      */
     constructor() {
        this.teacherLeaveID = 0;  
        this.teacherID = 0;  
        this.teacherLeaveTypeID = 0;  
        this.teacherLeaveTypeText = "";  
        this.teacherLeaveDateFrom = new Date();  
        this.teacherLeaveDateTo = new Date();  
        this.teacherLeavenoOfdasy = 0;  
        this.teacherAttachementDetails = "";  
        this.teacherAttachementID = 0;  
        this.createdBy = "";  
        this.createdDate = new Date();  
        this.lastUpdatedBy = "";  
        this.lastUpdatedDate = new Date();  
        this.isActive = false;  
        this.select = false;
     }
}
