import { Component, OnInit, ViewChild } from '@angular/core';
import { teacher, TeacherComplaintRecord, TeacherEducationalRecord, TeacherProfessionalRecord, TeacherSpeciality } from 'app/Classes/teacher';
import { BaseComponent } from 'app/Shared/base-component';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { OrgCompany, OrgUnit } from 'app/Classes/org';
import { Sys_users } from 'app/Classes/login';
import { SelectionModel } from '@angular/cdk/collections';
import { Sys_Groups } from 'app/Classes/groups';
import { Subjects } from 'app/Classes/subjects';

import { Validators, FormControl } from '@angular/forms';
@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent extends BaseComponent implements OnInit {
  objteacher: teacher;
  objTeacherSpeciality: TeacherSpeciality;
  lstTeacherSpeciality: TeacherSpeciality[];
  objTeacherProfessionalRecord: TeacherProfessionalRecord;
  lstTeacherProfessionalRecord: TeacherProfessionalRecord[];
  objTeacherEducationalRecord: TeacherEducationalRecord;
  lstTeacherEducationalRecord: TeacherEducationalRecord[];
  objTeacherComplaintRecord: TeacherComplaintRecord;
  lstTeacherComplaintRecord: TeacherComplaintRecord[];
  OrgCompany1: OrgCompany;
  lstOrgUnit: OrgUnit[];
  Sys_Users: Sys_users;
  lstSys_Users: Sys_users[];
  lstSys_Groups: Sys_Groups[];
  lstSubjects: Subjects[];
  Religion: number = 1;
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, private sanitizer: DomSanitizer) {
    super(languageTranslateService);


    this.objteacher = new teacher();
    this.objTeacherSpeciality = new TeacherSpeciality();
    this.objTeacherProfessionalRecord = new TeacherProfessionalRecord();
    this.objTeacherEducationalRecord = new TeacherEducationalRecord();
    this.objTeacherComplaintRecord = new TeacherComplaintRecord();
    this.OrgCompany1 = new OrgCompany();
    this.lstOrgUnit = [];
    this.Sys_Users = new Sys_users();
    this.lstSys_Users = [];
    this.lstSys_Groups = [];
    this.lstTeacherSpeciality = [];
    this.lstTeacherProfessionalRecord = [];
    this.lstTeacherEducationalRecord = [];
    this.lstTeacherComplaintRecord =[];
  }

  displayedColumns: string[] = ['select', 'UserName', 'FullName', 'EmployeeNo', 'Edit'];
  dataSource = new MatTableDataSource<Sys_users>();
  selection = new SelectionModel<Sys_users>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  TeacherSpeciality_displayedColumns: string[] = ['select', 'subjectName', 'isActive', 'Edit'];
  TeacherSpeciality_dataSource = new MatTableDataSource<TeacherSpeciality>();
  TeacherSpeciality_selection = new SelectionModel<TeacherSpeciality>(true, []);
  @ViewChild(MatSort, { static: true }) objTeacherSpeciality_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) objTeacherSpeciality_paginator: MatPaginator;

  TeacherProfessionalRecord_displayedColumns: string[] = ['select', 'institutename', 'appoaintedAS', 'lastdesignation', 'salary', 'Edit'];
  TeacherProfessionalRecord_dataSource = new MatTableDataSource<TeacherProfessionalRecord>();
  TeacherProfessionalRecord_selection = new SelectionModel<TeacherProfessionalRecord>(true, []);
  @ViewChild(MatSort, { static: true }) TeacherProfessionalRecord_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) TeacherProfessionalRecord_paginator: MatPaginator;

  TeacherEducationalRecord_displayedColumns: string[] = ['select', 'degreeName', 'instituteName', 'fromDate', 'toDate', 'Edit'];
  TeacherEducationalRecord_dataSource = new MatTableDataSource<TeacherEducationalRecord>();
  TeacherEducationalRecord_selection = new SelectionModel<TeacherEducationalRecord>(true, []);
  @ViewChild(MatSort, { static: true }) TeacherEducationalRecord_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) TeacherEducationalRecord_paginator: MatPaginator;

  TeacherComplaintRecord_displayedColumns: string[] = ['select', 'complaintDetail', 'fK_ComplaintID', 'Edit'];
  TeacherComplaintRecord_dataSource = new MatTableDataSource<TeacherComplaintRecord>();
  TeacherComplaintRecord_selection = new SelectionModel<TeacherComplaintRecord>(true, []);
  @ViewChild(MatSort, { static: true }) TeacherComplaintRecord_sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) TeacherComplaintRecord_paginator: MatPaginator;


  ngOnInit() {
    this.GetOrgUnitByCompany_ForDropdown();
    this.GetCompanyDetails();
    this.SearchApplicationUser();
    this.GetAllGroupsByCompany();
    this.GetAllSubjects();
    this.Sys_Users.userId = 1;
  }
  fK_SubjectID = new FormControl('', [
    Validators.required
  ]);
  GetAllGroupsByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Groups/GetAllGroupsByCompany').subscribe(
      data => {
        this.lstSys_Groups = data;
        for (var i = 0; i < this.lstSys_Groups.length; i++) {
          this.lstSys_Groups[i].select = false;
        }

      }, (err) => {
        // alert("error occured ")
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  TeacherSpeciality_applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TeacherSpeciality_dataSource.filter = filterValue.trim().toLowerCase();
  }
  TeacherProfessionalRecord_applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TeacherProfessionalRecord_dataSource.filter = filterValue.trim().toLowerCase();
  }
  TeacherEducationalRecord_applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TeacherEducationalRecord_dataSource.filter = filterValue.trim().toLowerCase();
  }
  GetCompanyDetails() {

    var companyid = this.global.FK_CompanyId();
    this._svc.getGenericParmas(companyid, 'CompanyId', 'Org/GetCompanyDetails').subscribe(
      data => {
        ;
        this.OrgCompany1 = data;
      }, (err) => {
        this.global.openDialog("Organization", "Some Error Occured.");
      });
  }
  GetOrgUnitByCompany_ForDropdown() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'OrgUnit/GetOrgUnitByCompany_ForDropdown').subscribe(
      data => {
        this.lstOrgUnit = data;
      }, (err) => {

      });
  }
  TeacherType(type: string) {
    this.objteacher.teacherType = type;
  }
  TeacherGender(type: string) {
    this.objteacher.teacherGender = type;
  }
  edit(userid: any, userName: any) {
    debugger;
    this.Sys_Users = this.dataSource.data.filter(x => x.userId == userid)[0];
    // this.objteacher.userID = userid;
    this.GetTeacherbyUserID(userid)
    this.imagepath = this.Sys_Users.picture;
    this.transform();
  }
  imagepath: string = '';
  transform() {
    if (this.Sys_Users.pictureExt.toLowerCase() == 'jpg' || this.Sys_Users.pictureExt.toLowerCase() == 'jpeg') {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + this.imagepath);//data:image/jpg;base64, 

    }
    // if(this.Sys_Users.pictureExt.toLowerCase() == 'jpeg'){
    //   return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64, ' + this.imagepath);//data:image/jpg;base64, 

    // }
    if (this.Sys_Users.pictureExt.toLowerCase() == 'png') {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + this.imagepath);//data:image/jpg;base64, 

    }

    // return this.sanitizer.bypassSecurityTrustResourceUrl(this.imagepath);
  }
  handleInputChange(e) {

    debugger;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    var ext = file.name.split('.');
    this.Sys_Users.pictureExt = ext[1];
    reader.readAsDataURL(file);
  }
  imageSrc: any;
  Picture: string;
  _handleReaderLoaded(e) {
    debugger;
    let reader = e.target;
    this.imageSrc = reader.result;
    this.Sys_Users.picture = reader.result;

    if (this.Sys_Users.pictureExt.toLowerCase() == 'jpg' || this.Sys_Users.pictureExt.toLowerCase() == 'jpeg') {
      this.imagepath = this.Sys_Users.picture.replace('data:image/jpeg;base64,', '');//data:image/jpg;base64, 

    }
    if (this.Sys_Users.pictureExt.toLowerCase() == 'png') {
      this.imagepath = this.Sys_Users.picture.replace('data:image/png;base64,', '');;//data:image/jpg;base64, 

    }

    this.transform();
  }

  CreateApplicationUser() {
    debugger;;
    // this.Sys_Users.userId = Number(this.Sys_Users.userId);
    this.Sys_Users.userType=2;
    this.Sys_Users.companyId = Number(this.OrgCompany1.companyId)

    this.Sys_Users.userStatus = Number(this.Sys_Users.userStatus)
    this.Sys_Users.userType = Number(this.Sys_Users.userType)
    this.Sys_Users.isFirstLogin = Number(this.Sys_Users.isFirstLogin)
    // this.Sys_Users.fK_EmployeeId = Number(this.Sys_Users.fK_EmployeeId)
    this.Sys_Users.fK_CompanyId = this.global.FK_CompanyId();
    this.Sys_Users.lAST_UPDATE_DATE = new Date();
    this.Sys_Users.cREATED_DATE = new Date();
    this.Sys_Users.lastLoginDate = new Date();
    this.Sys_Users.lastChange_Password = new Date();
    this.Sys_Users.fK_OrgUnitId = Number(this.Sys_Users.fK_OrgUnitId);
    this.Sys_Users.groupIds = this.Sys_Users.fK_LogicalGroup.toString();
    // for(var i = 0 ; i< this.lstSys_Groups.length ; i++){
    //   if(this.lstSys_Groups[i].select == true){
    //     this.Sys_Users.groupIds = this.Sys_Users.groupIds +","+ this.lstSys_Groups[i].groupID;
    //   }

    // }

    // this.Sys_Users.fK_GroupId = Number(this.Sys_Users.fK_GroupId );
    this.Sys_Users.userDefaultLAng = 'a';
    //this.Sys_Users.remarks ="";

    this._svc.CreateApplicationUser(this.Sys_Users, 'Users/CreateApplicationUser').subscribe(
      data => {
        /////gddddd
        ;
        this.clear();
        this.SearchApplicationUser();
        this.global.openDialog("Teacher", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  SearchApplicationUser() {
    ;
    this.Sys_Users.userDefaultLAng = '1';
    this.Sys_Users.fK_CompanyId = this.global.FK_CompanyId();
    // "Users/SearchApplicationUser"
    this.Sys_Users.userType=2;
    this._svc.SearchApplicationUser(this.Sys_Users, "Users/SearchApplicationUser").subscribe(
      data => {

        this.lstSys_Users = data;
        if (this.lstSys_Users.length > 0) {
          for (var i = 0; i < this.lstSys_Users.length; i++) {
            this.lstSys_Users[i].select = false;
          }
          this.dataSource.data = this.lstSys_Users;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = [];
          this.global.openDialog("Teacher", "No record found.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  GetTeacherbyUserID(userid: number) {
    this.objteacher.userID = userid;
    this._svc.getGenericParmas(userid, 'UserID', 'Teacher/GetTeacherbyUserID').subscribe(
      data => {
        if (data.length > 0) {
          debugger;
          this.objteacher = data[0];
          this.setValuetoDropDown();
          this.GetTeacherSpecialitybyTeacherID(this.objteacher.teacherID);
          this.GetTeacherTeacherProfessionalRecordbyTeacherID(this.objteacher.teacherID);
          this.GetTeacherEducationalRecordbyID(this.objteacher.teacherID);
          this.GetTeacherComplaintRecordbyTeacherID(this.objteacher.teacherID);
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  clear() {
    this.Sys_Users = new Sys_users();
  }
  bindReligion() {
    if (this.Religion == 1) {
      this.objteacher.teacherReligion = "Islam"
    }
    if (this.Religion == 2) {
      this.objteacher.teacherReligion = "Christianity"
    }
    if (this.Religion == 3) {
      this.objteacher.teacherReligion = "Hinduism"
    }
    if (this.Religion == 4) {
      this.objteacher.teacherReligion = "Judaism"
    }
    if (this.Religion == 5) {
      this.objteacher.teacherReligion = "Buddhism"
    }
    if (this.Religion == 6) {
      this.objteacher.teacherReligion = "Sikhism"
    }
  }

  setValuetoDropDown() {
    if (this.objteacher.teacherReligion == "Islam") {
      this.Religion = 1;
    }
    if (this.objteacher.teacherReligion == "Christianity") {
      this.Religion = 2
    }
    if (this.objteacher.teacherReligion == "Hinduism") {
      this.Religion = 3
    }
    if (this.objteacher.teacherReligion == "Judaism") {
      this.Religion = 4
    }
    if (this.objteacher.teacherReligion == "Buddhism") {
      this.Religion = 5
    }
    if (this.objteacher.teacherReligion == "Sikhism") {
      this.Religion = 6
    }
  }

  AddTeacherDetails() {
    debugger;
    this.objteacher.teacherJoiningDate = new Date(this.objteacher.teacherJoiningDate);
    this.objteacher.teacherDOB = new Date(this.objteacher.teacherDOB);
    this.objteacher.createdBy = this.global.getUserID();
    this.objteacher.lastUpdatedBy = this.global.getUserID();
    // this.objteacher.createdDate = new Date(this.objteacher.createdDate);
    // this.objteacher.lastUpdatedDate = new Date(this.objteacher.lastUpdatedDate);
    //  this.bindReligion();
    this._svc.Save(this.objteacher, 'Teacher/AddUpdateTeacher').subscribe(
      data => {
        if (data.includes("Success")) {
          var teacherID = data.split(":")[1];
          this.objteacher.teacherID = Number(teacherID);
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  clearTeacherDetails() {
    this.objteacher = new teacher();
  }
  GetAllSubjects() {
    debugger;
    this._svc.GetAll("Subjects/GetAllSubjects").subscribe(
      data => {
        this.lstSubjects = data;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  TeacherSpeciality_edit(id: any) {
    var x = this.TeacherSpeciality_dataSource.data.filter(i => i.teacherSpecialityID == id)[0];
    this.objTeacherSpeciality = x;
  }
  TeacherSpeciality_ShowConfirmDialog() {

  }
  clearTeacherSpeciality() {
    this.objTeacherSpeciality = new TeacherSpeciality();
  }

  GetTeacherSpecialitybyTeacherID(teacherID: any) {
    debugger;
    this._svc.getGenericParmas(teacherID, "TeacherID", "Teacher/GetTeacherSpecialitybyTeacherID").subscribe(
      data => {
        this.lstTeacherSpeciality = data;

        for (var i = 0; i < this.lstTeacherSpeciality.length; i++) {
          this.lstTeacherSpeciality[i].select = false;
        }
        this.TeacherSpeciality_dataSource.data = this.lstTeacherSpeciality;
        this.TeacherSpeciality_dataSource.sort = this.objTeacherSpeciality_sort;
        this.TeacherSpeciality_dataSource.paginator = this.objTeacherSpeciality_paginator;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  GetTeacherTeacherProfessionalRecordbyTeacherID(teacherID: any) {
    debugger;
    this._svc.getGenericParmas(teacherID, "TeacherID", "Teacher/GetTeacherTeacherProfessionalRecordbyTeacherID").subscribe(
      data => {
        this.lstTeacherProfessionalRecord = data;

        for (var i = 0; i < this.lstTeacherProfessionalRecord.length; i++) {
          this.lstTeacherProfessionalRecord[i].select = false;
        }
        this.TeacherProfessionalRecord_dataSource.data = this.lstTeacherProfessionalRecord;
        this.TeacherProfessionalRecord_dataSource.sort = this.TeacherProfessionalRecord_sort;
        this.TeacherProfessionalRecord_dataSource.paginator = this.TeacherProfessionalRecord_paginator;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  SaveTeacherSpeciality() {
    debugger;
    this.objTeacherSpeciality.fK_SubjectID = Number(this.objTeacherSpeciality.fK_SubjectID);
    this.objTeacherSpeciality.fK_TeacherID = this.objteacher.teacherID;
    this.objTeacherSpeciality.createdBy = this.global.getUserID();
    this.objTeacherSpeciality.lastUpdatedBy = this.global.getUserID();
    // this.objteacher.createdDate = new Date(this.objteacher.createdDate);
    // this.objteacher.lastUpdatedDate = new Date(this.objteacher.lastUpdatedDate);
    //  this.bindReligion();
    this._svc.Save(this.objTeacherSpeciality, 'Teacher/AddUpdateTeacherSpeciality').subscribe(
      data => {
        if (data.includes("Success")) {
          // var teacherID = data.split(":")[1];
          // this.objteacher.teacherID = Number(teacherID);
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  teacherProfessionalRecord_edit(teacherProfessionalRecordID: any) {
    var i = this.TeacherProfessionalRecord_dataSource.data.filter(x => x.teacherProfessionalRecordID == teacherProfessionalRecordID)[0];
    this.objTeacherProfessionalRecord = i;
  }
  TeacherProfessionalRecord_Check(teacherProfessionalRecordID: any) {
    for (var i = 0; i < this.TeacherProfessionalRecord_dataSource.data.length; i++) {
      if (this.TeacherProfessionalRecord_dataSource.data[i].teacherProfessionalRecordID == teacherProfessionalRecordID) {
        this.TeacherProfessionalRecord_dataSource.data[i].select = !this.TeacherProfessionalRecord_dataSource.data[i].select;
      }
    }
  }
  SaveTeacherProfessionalRecord() {
    debugger;
    this.objTeacherProfessionalRecord.salary = Number(this.objTeacherProfessionalRecord.salary);
    this.objTeacherProfessionalRecord.fK_TeacherID = this.objteacher.teacherID;
    this.objTeacherProfessionalRecord.createdBy = this.global.getUserID();
    this.objTeacherProfessionalRecord.lastUpdatedBy = this.global.getUserID();
    // this.objteacher.createdDate = new Date(this.objteacher.createdDate);
    // this.objteacher.lastUpdatedDate = new Date(this.objteacher.lastUpdatedDate);
    //  this.bindReligion();
    this._svc.Save(this.objTeacherProfessionalRecord, 'Teacher/AddUpdateTeacherProfessionalRecord').subscribe(
      data => {
        if (data.includes("Success")) {
          // var teacherID = data.split(":")[1];
          // this.objteacher.teacherID = Number(teacherID);
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
  clearTeacherProfessionalRecord() {
    this.objTeacherProfessionalRecord = new TeacherProfessionalRecord();
  }
  TeacherProfessionalRecord_ShowConfirmDialog() {

  }
  calculatepercentage() {
    this.objTeacherEducationalRecord.marksObtained = Number(this.objTeacherEducationalRecord.marksObtained)
    this.objTeacherEducationalRecord.totalMarks = Number(this.objTeacherEducationalRecord.totalMarks)
    this.objTeacherEducationalRecord.percntage = (this.objTeacherEducationalRecord.marksObtained * 100) / this.objTeacherEducationalRecord.totalMarks;
  }
  SaveTeacherEducationalRecord() {
    debugger;
    this.objTeacherEducationalRecord.fK_TeacherID = this.objteacher.teacherID;
    this.objTeacherEducationalRecord.createdBy = this.global.getUserID();
    this.objTeacherEducationalRecord.lastUpdatedBy = this.global.getUserID();
    this.objTeacherEducationalRecord.marksObtained = Number(this.objTeacherEducationalRecord.marksObtained)
    this.objTeacherEducationalRecord.totalMarks = Number(this.objTeacherEducationalRecord.totalMarks)

    this._svc.Save(this.objTeacherEducationalRecord, 'Teacher/AddUpdateTeacherEducationalRecord').subscribe(
      data => {
        if (data.includes("Success")) {
          // var teacherID = data.split(":")[1];
          // this.objteacher.teacherID = Number(teacherID);
          this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  clearTeacherEducationalRecord() {
    this.objTeacherEducationalRecord = new TeacherEducationalRecord();
  }
  TeacherEducationalRecord_ShowConfirmDialog() {

  }
  TeacherEducationalRecord_Check(teacherEducationalrecordID: any) {
    for (var i = 0; i < this.TeacherEducationalRecord_dataSource.data.length; i++) {
      if (this.TeacherEducationalRecord_dataSource.data[i].teacherEducationalrecordID == teacherEducationalrecordID) {
        this.TeacherEducationalRecord_dataSource.data[i].select = !this.TeacherEducationalRecord_dataSource.data[i].select;
      }
    }

  }
  GetTeacherEducationalRecordbyID(teacherID: any) {
    debugger;
    this._svc.getGenericParmas(teacherID, "TeacherID", "Teacher/GetTeacherEducationalRecordbyID").subscribe(
      data => {
        this.lstTeacherEducationalRecord = data;

        for (var i = 0; i < this.lstTeacherEducationalRecord.length; i++) {
          this.lstTeacherEducationalRecord[i].select = false;
        }
        this.TeacherEducationalRecord_dataSource.data = this.lstTeacherEducationalRecord;
        this.TeacherEducationalRecord_dataSource.sort = this.TeacherEducationalRecord_sort;
        this.TeacherEducationalRecord_dataSource.paginator = this.TeacherEducationalRecord_paginator;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }

  SaveTeacherComplaintRecord() {
    debugger;
    this.objTeacherComplaintRecord.fK_ComplaintID = Number(this.objTeacherComplaintRecord.fK_ComplaintID);
    this.objTeacherComplaintRecord.fK_TeacherID = this.objteacher.teacherID;
    this.objTeacherComplaintRecord.createdBy = this.global.getUserID();
    this.objTeacherComplaintRecord.lastUpdatedBy = this.global.getUserID();
    // this.objteacher.createdDate = new Date(this.objteacher.createdDate);
    // this.objteacher.lastUpdatedDate = new Date(this.objteacher.lastUpdatedDate);
    //  this.bindReligion();
    this._svc.Save(this.objTeacherComplaintRecord, 'Teacher/AddUpdateTeacherComplaintRecord').subscribe(
      data => {
        if (data.includes("Success")) {
          // var teacherID = data.split(":")[1];
          // this.objteacher.teacherID = Number(teacherID);
          // this.global.openDialog("Teacher", "Record has been saved.");
        }
        else {
          this.global.openDialog("Teacher", "Some Error Occured.");
        }
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  TeacherComplaintRecord_ShowConfirmDialog() {

  }
  clearTeacherComplaintRecord() {
    this.objTeacherComplaintRecord = new TeacherComplaintRecord();
  }
  TeacherComplaintRecord_applyFilter() {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TeacherComplaintRecord_dataSource.filter = filterValue.trim().toLowerCase();
  }

  TeacherComplaintRecord_edit(teacherComplaintRecordID: any) {
    var i = this.TeacherComplaintRecord_dataSource.data.filter(x => x.teacherComplaintRecordID == teacherComplaintRecordID)[0];
    this.objTeacherComplaintRecord = i;
  }
  TeacherComplaintRecord_Check(TeacherComplaintRecordID: any) {
    for (var i = 0; i < this.TeacherComplaintRecord_dataSource.data.length; i++) {
      if (this.TeacherComplaintRecord_dataSource.data[i].teacherComplaintRecordID == TeacherComplaintRecordID) {
        this.TeacherComplaintRecord_dataSource.data[i].select = !this.TeacherComplaintRecord_dataSource.data[i].select;
      }
    }
  }
  GetTeacherComplaintRecordbyTeacherID(teacherID: any) {
    debugger;
    this._svc.getGenericParmas(teacherID, "TeacherID", "Teacher/GetTeacherComplaintRecordbyTeacherID").subscribe(
      data => {
        this.lstTeacherComplaintRecord = data;

        for (var i = 0; i < this.lstTeacherComplaintRecord.length; i++) {
          this.lstTeacherComplaintRecord[i].select = false;
        }
        this.TeacherComplaintRecord_dataSource.data = this.lstTeacherComplaintRecord;
        this.TeacherComplaintRecord_dataSource.sort = this.TeacherComplaintRecord_sort;
        this.TeacherComplaintRecord_dataSource.paginator = this.TeacherComplaintRecord_paginator;
      }, (err) => {
        this.global.openDialog("Teacher", "Some Error Occured.");
      });
  }
}
