import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import $ from 'jquery';
import { FormControl } from '@angular/forms';
import { Org_logicalGroup, OrgUnit } from 'app/Classes/org';
import { Sys_users } from 'app/Classes/login';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';
import { MatDialog,  MatSort, MatPaginator } from '@angular/material';
import { Sys_Groups } from 'app/Classes/groups';
import { debug } from 'util';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit {

  FormName: string = "Users";
  lstOrgUnit: OrgUnit[];
  lstOrg_logicalGroup: Org_logicalGroup[];
  selectedValue: string;
  selectedCar: string;
  Sys_Users: Sys_users;
  lstSys_Users: Sys_users[];
  popFK_LogicalGroup: number;
  popgroupIds: number = 0;
  ConfirmPWD : string= "";
  lstSys_Groups: Sys_Groups[];

  constructor(public languageTranslateService: LanguageTranslateService,public dialog: MatDialog,
    private _svc: SharedService, public global: GlobalVariablesService) {
    super(languageTranslateService);
    this.lstOrg_logicalGroup = [];
    this.lstOrgUnit = [];
    this.lstSys_Users = [];
    this.Sys_Users = new Sys_users();
    this.lstSys_Groups =[];
  }
  displayedColumns: string[] = ['select', 'UserName', 'FullName', 'EmployeeNo', 'Edit'];
  dataSource = new MatTableDataSource<Sys_users>();
  selection = new SelectionModel<Sys_users>(true, []);
  ngOnInit() {
    this.global.FormName = this.FormName;
   // this.GetLogicalGroupByCompany();
    this.GetAllGroupsByCompany();
    this.GetOrgUnitByCompany_ForDropdown();
    this.SearchApplicationUser();
  }

  CreateApplicationUser() {
    ;
    // this.Sys_Users.userId = Number(this.Sys_Users.userId);
    this.Sys_Users.companyId = this.global.FK_CompanyId();

    this.Sys_Users.userStatus = Number(this.Sys_Users.userStatus)
    this.Sys_Users.userType = Number(this.Sys_Users.userType)
    this.Sys_Users.isFirstLogin = Number(this.Sys_Users.isFirstLogin)
    // this.Sys_Users.fK_EmployeeId = Number(this.Sys_Users.fK_EmployeeId)
    this.Sys_Users.fK_CompanyId =  this.global.FK_CompanyId();
    this.Sys_Users.lAST_UPDATE_DATE = new Date();
    this.Sys_Users.cREATED_DATE = new Date();
    this.Sys_Users.lastLoginDate = new Date();
    this.Sys_Users.lastChange_Password = new Date();
    this.Sys_Users.fK_OrgUnitId = this.popFK_LogicalGroup;
    for(var i = 0 ; i< this.lstSys_Groups.length ; i++){
      if(this.lstSys_Groups[i].select == true){
        this.Sys_Users.groupIds = this.Sys_Users.groupIds +","+ this.lstSys_Groups[i].groupID;
      }
      
    }
    
    this.Sys_Users.fK_GroupId = Number(this.popgroupIds);
    this.Sys_Users.userDefaultLAng = 'a';
    this.Sys_Users.remarks ="";

    this._svc.CreateApplicationUser(this.Sys_Users, 'Users/CreateApplicationUser').subscribe(
      data => {
        /////gddddd
        this.clear();
        this.SearchApplicationUser();
        $("#close").click();
        this.global.openDialog("Users", "Record has been saved.");
      }, (err) => {
        this.global.openDialog("Users", "Some Error Occured.");
        // alert("error occured ")
      });
  }
  valueChange(index : any){
    this.lstSys_Groups[index].select = !this.lstSys_Groups[index].select;
  }
  clear() {
    
    this.Sys_Users = new Sys_users()
    $("#close").click();

  }
  // GetLogicalGroupByCompany() {
  //   var FK_CompanyId = this.global.FK_CompanyId();
  //   this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'LogicalGroup/GetLogicalGroupByCompany').subscribe(
  //     data => {
  //       ;
  //       this.lstOrg_logicalGroup = data;
  //     }, (err) => {

  //     });
  // }

  GetOrgUnitByCompany_ForDropdown() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'OrgUnit/GetOrgUnitByCompany_ForDropdown').subscribe(
      data => {
        ;
        this.lstOrgUnit = data;
      }, (err) => {

      });
  }

  SearchApplicationUser() {
    ;
    this.Sys_Users.userDefaultLAng = '1';
    this.Sys_Users.fK_CompanyId = this.global.FK_CompanyId();
    // "Users/SearchApplicationUser"
    this._svc.SearchApplicationUser(this.Sys_Users, "Users/SearchApplicationUser").subscribe(
      data => {
        
        this.lstSys_Users = data;
        if(this.lstSys_Users.length > 0 ){
        for (var i = 0; i < this.lstSys_Users.length; i++) {
          this.lstSys_Users[i].select = false;
        }
        this.dataSource.data = this.lstSys_Users;
      } else {
        this.dataSource.data =[];
        this.global.openDialog("Users","No record found.");
      }
      }, (err) => {
        this.global.openDialog("Users","Some Error Occured.");
      });
  }


  selectUserscheck(row: any, index: any) {
    ;
    if (this.dataSource.data[index].select) {
      this.dataSource.data[index].select = false;
    }
    else {
      this.dataSource.data[index].select = true;
    }
  }

  ShowConfirmDialog(): void {

    const message = 'Are you sure you want to delete selected Records?';
    const dialogData = new CustomConfirmDialogModel("Application  ", message);
    const dialogRef = this.dialog.open(CustomConfirmModalsComponent, {
      width: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


      if (dialogResult) {
        this.Deleteusers();
      }
    });
  }

  Deleteusers() {
    
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].select == true) {
        this._svc.DeleteGenericParmas(this.dataSource.data[i].userId, 'UserId', 'Users/DeleteApplicationUser').subscribe(
          data => {
            ;
            this.SearchApplicationUser();
            this.global.openDialog("Users","Record has been Deleted.");
          }, (err) => {
            this.global.openDialog("Users","Some Error Occured.");
          });
      }
    }

  }

  GetAllGroupsByCompany() {
    var FK_CompanyId = this.global.FK_CompanyId();
    this._svc.getGenericParmas(FK_CompanyId, 'FK_CompanyId', 'Groups/GetAllGroupsByCompany').subscribe(
      data => {
        ;
        this.lstSys_Groups = data;
        for(var i = 0 ; i< this.lstSys_Groups.length ; i++){
          this.lstSys_Groups[i].select = false;
        }

      }, (err) => {
        // alert("error occured ")
      });
  }
  Close(){
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '14';  
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '155';  
  }
  
  edit(userid: any , userName: any) {
    
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '0';
      this.Sys_Users = this.dataSource.data.filter( x=> x.userId == userid )[0];
      this.popgroupIds = this.Sys_Users.fK_GroupId;
      this.popFK_LogicalGroup =this.Sys_Users.fK_OrgUnitId ;

    // this._svc.getGenericParmas(userid, "UserId", "Users/GetApplicationUser").subscribe(
    //   data => {
    //     ;
    //     // if(data.length > 0){
    //     this.Sys_Users = data[0]
    //     // }
    //   }, (err) => {

    //   });

    // this.Sys_Users = this.dataSource.data.filter( x => x.userId == userid)[0];
    // if(this.global.isStringNullOrEmplty(this.Sys_Users.remarks)){
    //   this.Sys_Users.remarks = "";
    // }
  }
  openModel() {
    (document.querySelector('.app-sidebar-wrapper') as HTMLElement).style.zIndex = '0';
    (document.querySelector('.ui-theme-settings') as HTMLElement).style.zIndex =  '0';
    setTimeout(() => {
      $('.modal-backdrop').remove();
      $('.modal-backdrop').show()
      $('#id01').modal('show');
    }, 10);

  }


  /////////////////table finction
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
