import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LookupComponent } from './Administration/Definition/lookup/lookup.component';
import { UsersComponent } from './Administration/Definition/users/users.component';
import { OrganizationComponent } from './Administration/Definition/organization/organization.component';
import { EntityComponent } from './Administration/Definition/entity/entity.component';
import { EmployeeStatusComponent } from './Administration/Definition/employee-status/employee-status.component';
import { EmployeeTypeComponent } from './Administration/Definition/employee-type/employee-type.component';
import { EmployeeComponent } from './Administration/Definition/employee/employee.component';
import { GroupsComponent } from './Administration/Definition/groups/groups.component';
import { WorkLocation } from './resources/english';
import { WorkScheduleComponent } from './TAProj/work-schedule/work-schedule.component';
import { TAPolicy } from './Classes/tapolicy';
import { TaPolicyComponent } from './TAProj/ta-policy/ta-policy.component';
import { RamadanPeriodComponent } from './TAProj/ramadan-period/ramadan-period.component';
import { OvertimeTypesComponent } from './TAProj/overtime-types/overtime-types.component';
import { LeavesTypesComponent } from './TAProj/leaves-types/leaves-types.component';
import { ApplicationSettingsComponent } from './TAProj/application-settings/application-settings.component';
import { ManagersComponent } from './TAProj/managers/managers.component';
import { TAExceptionComponent } from './TAProj/taexception/taexception.component';
import { AssingTaPolicyComponent } from './TAProj/assing-ta-policy/assing-ta-policy.component';
import { ValidationExceptionComponent } from './TAProj/validation-exception/validation-exception.component';
import { AssignWorkScheduleComponent } from './TAProj/assign-work-schedule/assign-work-schedule.component';
import { SysFormsComponent } from './Administration/Definition/sys-forms/sys-forms.component';
import { ModulesComponent } from './Administration/Definition/modules/modules.component';
import { StudentProfileComponent } from './SchoolStudent/student-profile/student-profile.component';
import { ClassesComponent } from './SchoolStudent/classes/classes.component';
import { TeacherComponent } from './teacher/teacher.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { AssignSubjectToTeacherComponent } from './assign-subject-to-teacher/assign-subject-to-teacher.component';
import { AttendenceComponent } from './attendence/attendence.component';
import { ComplaintForTeacherComponent } from './complaint-for-teacher/complaint-for-teacher.component';
import { ComplaintForStudentComponent } from './complaint-for-student/complaint-for-student.component';
import { ViewNoticeBoardComponent } from './view-notice-board/view-notice-board.component';
import { AddNoticeBoardComponent } from './add-notice-board/add-notice-board.component';
import { TeacherLeaveComponent } from './teacher-leave/teacher-leave.component';
import { StudentleaveComponent } from './studentleave/studentleave.component';
import { AssignmentdetailsComponent } from './assignmentdetails/assignmentdetails.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { CreatedatesheetComponent } from './createdatesheet/createdatesheet.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { HomeComponent } from './Twillio/home/home.component';
import { RoomsComponent } from './Twillio/rooms/rooms.component';
import { SettingsComponent } from './Twillio/settings/settings.component';
import { ParticipantsComponent } from './Twillio/participants/participants.component';
import { DefineLectureComponent, defineLectClass } from './define-lecture/define-lecture.component';
import { ViewRecordedlectureComponent } from './view-recordedlecture/view-recordedlecture.component';
import { UploadRecordedlectureComponent } from './upload-recordedlecture/upload-recordedlecture.component';
import { CreatevideocourseComponent } from './createvideocourse/createvideocourse.component';
import { VideoLectureRequestComponent } from './video-lecture-request/video-lecture-request.component';
import { StudentFeeComponent } from './student-fee/student-fee.component';
import { MyFeeComponent } from './my-fee/my-fee.component';



const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'homepage',
    component: MainLayoutComponent,
    children: [
      { path: '', component: HomePageComponent }
    ]
  },
  {
    path: 'lookup',
    component: MainLayoutComponent,
    children: [
      { path: '', component: LookupComponent }
    ]
  },
  {
    path: 'users',
    component: MainLayoutComponent,
    children: [
      { path: '', component: UsersComponent }
    ]
  }
  , {
    path: 'org',
    component: MainLayoutComponent,
    children: [
      { path: '', component: OrganizationComponent }
    ]
  }
  , {
    path: 'orgunit',
    component: MainLayoutComponent,
    children: [
      { path: '', component: EntityComponent }
    ]
  }
  , {
    path: 'employeestatus',
    component: MainLayoutComponent,
    children: [
      { path: '', component: EmployeeStatusComponent }
    ]
  }
  , {
    path: 'employeetype',
    component: MainLayoutComponent,
    children: [
      { path: '', component: EmployeeTypeComponent }
    ]
  }, {
    path: 'employee',
    component: MainLayoutComponent,
    children: [
      { path: '', component: EmployeeComponent }
    ]
  }, {
    path: 'groups',
    component: MainLayoutComponent,
    children: [
      { path: '', component: GroupsComponent }
    ]
  }, {
    path: 'WorkSchedule',
    component: MainLayoutComponent,
    children: [
      { path: '', component: WorkScheduleComponent }
    ]
  }
  , {
    path: 'TAPolicy',
    component: MainLayoutComponent,
    children: [
      { path: '', component: TaPolicyComponent }
    ]
  }, {
    path: 'RamadanPeriod',
    component: MainLayoutComponent,
    children: [
      { path: '', component: RamadanPeriodComponent }
    ]
  },
  {
    path: 'OvertimeTypes',
    component: MainLayoutComponent,
    children: [
      { path: '', component: OvertimeTypesComponent }
    ]
  },
  {
    path: 'LeavesTypes',
    component: MainLayoutComponent,
    children: [
      { path: '', component: LeavesTypesComponent }
    ]
  },
  {
    path: 'ApplicationSettings',
    component: MainLayoutComponent,
    children: [
      { path: '', component: ApplicationSettingsComponent }
    ]
  },
  {
    path: 'Managers',
    component: MainLayoutComponent,
    children: [
      { path: '', component: ManagersComponent }
    ]
  },
  {
    path: 'TAException',
    component: MainLayoutComponent,
    children: [
      { path: '', component: TAExceptionComponent }
    ]
  },
  {
    path: 'AssingTaPolicy',
    component: MainLayoutComponent,
    children: [
      { path: '', component: AssingTaPolicyComponent }
    ]
  },{
    path : 'ValidationException',
     component: MainLayoutComponent,
    children:[
      {path: '' , component : ValidationExceptionComponent}
    ]
  },{
    path: 'AssignWorkSchedule',
    component: MainLayoutComponent,
    children:[
      {path: '' , component : AssignWorkScheduleComponent}
    ]
  }
  ,{
    path: 'sysform',
    component: MainLayoutComponent,
    children:[
      {path: '' , component : SysFormsComponent}
    ]
  }
  ,{
    path: 'sysmodules',
    component: MainLayoutComponent,
    children:[
      {path: '' , component : ModulesComponent}
    ]
  },
  {
    path:'students',
    component: MainLayoutComponent,
    children:[
      {path:'',component : StudentProfileComponent}
    ]
  },

  {
    path:'studentfee',
    component: MainLayoutComponent,
    children:[
      {path:'',component : StudentFeeComponent}
    ]
  },

  {
    path:'myfee',
    component: MainLayoutComponent,
    children:[
      {path:'',component : MyFeeComponent}
    ]
  },

  {
    path:'defineclasses',
    component: MainLayoutComponent,
    children:[
      {path:'',component : ClassesComponent}
    ]
  },
  
  {
    path:'teacher',
    component: MainLayoutComponent,
    children:[
      {path:'',component : TeacherComponent}
    ]
  },{
    path:'subjects',
    component: MainLayoutComponent,
    children:[
      {path:'',component : SubjectsComponent}
    ]
  },
  {
    path:'assigncoursetoteacher',
    component: MainLayoutComponent,
    children:[
      { path:'',component : AssignSubjectToTeacherComponent}
    ]
  },
  {
    path: "attendance",
    component: MainLayoutComponent,
    children:[
      { path:'',component : AttendenceComponent}
    ]
  },
  {
    path: "complaintforteacher",
    component: MainLayoutComponent,
    children:[
      { path:'',component : ComplaintForTeacherComponent}
    ]
  }
  ,
  {
    path: "complaintforstudent",
    component: MainLayoutComponent,
    children:[
      { path:'',component : ComplaintForStudentComponent}
    ]
  },
  {
    path: "viewnoticeboard",
    component: MainLayoutComponent,
    children:[
      { path:'',component : ViewNoticeBoardComponent}
    ]
  },
  {
    path: "addnoticeboard",
    component: MainLayoutComponent,
    children:[
      { path:'',component : AddNoticeBoardComponent}
    ]
  }

  ,
  {
    path: "teacherleave",
    component: MainLayoutComponent,
    children:[
      { path:'',component : TeacherLeaveComponent}
    ]
  }

  ,
  {
    path: "studentleave",
    component: MainLayoutComponent,
    children:[
      { path:'',component : StudentleaveComponent}
    ]
  }

  ,
  {
    path: "assignmentdetails",
    component: MainLayoutComponent,
    children:[
      { path:'',component : AssignmentdetailsComponent}
    ]
  }

  ,
  {
    path: "assignments",
    component: MainLayoutComponent,
    children:[
      { path:'',component : AssignmentsComponent}
    ]
  }
,
  { path: "Home",
    component: MainLayoutComponent,
    children:[
      { path:'',component : HomeComponent}
    ]
  }  ,  
  {
    path: "Rooms",
    component: MainLayoutComponent,
    children:[
      { path:'',component : RoomsComponent}
    ]
  },  
  {
    path: "Setting",
    component: MainLayoutComponent,
    children:[
      { path:'',component : SettingsComponent}
    ]
  },  
  {
    path: "Participants",
    component: MainLayoutComponent,
    children:[
      { path:'',component : ParticipantsComponent}
    ]
  }
  ,
  {
    path: 'teacherboard',
    component: MainLayoutComponent,
    children: [
      { path: '', component: TeacherDashboardComponent }
    ]
  }
  ,
  {
    path: 'studentdashboard',
    component: MainLayoutComponent,
    children: [
      { path: '', component: StudentDashboardComponent }
    ]
  },
  {
    path:'definelecture',
    component: MainLayoutComponent,
    children:[
      {path:'',component : DefineLectureComponent}
    ]
  },
  {
    path: "createdatesheet",
    component: MainLayoutComponent,
    children:[
      { path:'',component : CreatedatesheetComponent}
    ]
  } ,  {
    path: "viewrecordedlecture",
    component: MainLayoutComponent,
    children:[
      { path:'',component : ViewRecordedlectureComponent}
    ]
  }, {
    path: "uploadrecordedlecture",
    component: MainLayoutComponent,
    children:[
      { path:'',component : UploadRecordedlectureComponent}
    ]
  }, {
    path: "createvideocourse",
    component: MainLayoutComponent,
    children:[
      { path:'',component : CreatevideocourseComponent}
    ]
  },{
    path: "videolecturerequest",
    component: MainLayoutComponent,
    children:[
      { path:'',component : VideoLectureRequestComponent}
    ]
  }


  // { path: 'Home', component: HomeComponent },
  // { path: 'Rooms', component: RoomsComponent },
  // { path: 'Setting', component: SettingsComponent },
  // {path:'Participants', component: ParticipantsComponent}


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
