import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from 'app/Shared/base-component';
import { LanguageTranslateService } from 'app/Shared/language-translate.service';
import { GlobalVariablesService } from 'app/Shared/global-variables.service';
import { SharedService } from 'app/Shared/shared.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { BLL_SysForm, Modules } from 'app/Classes/form-and-modules';
import { CustomConfirmDialogModel, CustomConfirmModalsComponent } from 'app/custom-confirm-modals/custom-confirm-modals.component';


@Component({
  selector: 'app-sys-forms',
  templateUrl: './sys-forms.component.html',
  styleUrls: ['./sys-forms.component.css']
})
export class SysFormsComponent extends BaseComponent implements OnInit {
  SysForm: BLL_SysForm;
  lstBLL_SysForm: BLL_SysForm[];
  ModulesList: Modules[];
  constructor(public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService, public global: GlobalVariablesService, public dialog: MatDialog) {
    super(languageTranslateService);
    this.SysForm = new BLL_SysForm();
    this.lstBLL_SysForm = [];
    this.ModulesList = [];
  }

  displayedColumns: string[] = ['select', 'formName', 'formPath', 'formOrder', 'visible', 'edit'];//, 'DefaultPage'
  dataSource = new MatTableDataSource<BLL_SysForm>();
  selection = new SelectionModel<BLL_SysForm>(true, []);
  ApplicationType = new FormControl('', [
    Validators.required
  ]);
  ngOnInit() {
    this.GetAllModules();
    this.GetAllForms();
  }

  GetAllModules() {
    ;
    this._svc.GetAll('SysForm/GetAllModules').subscribe(
      data => {
        if (data.length > 0) {
          this.ModulesList = data;
        }
      }, (err) => {
        this.global.openDialog("Modules", "Error While Saving.")
      })
  }
  GetAllForms() {
    ;
    this._svc.GetAll('SysForm/GetAllForms').subscribe(
      data => {
        if (data.length > 0) {

          this.lstBLL_SysForm = data;
          this.dataSource.data = this.lstBLL_SysForm;
          for (var i = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].select = false;
          }
        }
      }, (err) => {
        this.global.openDialog("Modules", "Error While Saving.")
      })
  
  
      this.clear();
      }

  initialValideation() {
    if (this.global.isNumberNullOrEmplty(this.SysForm.moduleID) || this.SysForm.moduleID == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  Save() {
    ;
    if (this.initialValideation()) {
      this.global.openDialog("Modules", "Please select module.")
    }
    else {
      this.SysForm.formOrder = this.global.convertNumbre(this.SysForm.formOrder);
      this.SysForm.visible = this.global.convertNumbre(this.SysForm.visible);
      // this.Modules.fK_ParentModule = this.global.convertNumbre(this.Modules.fK_ParentModule);
      // this.Modules.seq = this.global.convertNumbre(this.Modules.seq);
      this._svc.Save(this.SysForm, 'SysForm/AddUpdateSysForm').subscribe(
        data => {
          if (data == "Success") {
            this.global.openDialog("Forms", "Record has been saved.")
            this.GetAllForms();
          }
          else if (data == "Duplicate") {
            this.global.openDialog("Forms", "Name alreay exist.")
          }
          else {
            this.global.openDialog("Forms", "Error While Saving.")
          }
        }, (err) => {
          this.global.openDialog("Forms", "Error While Saving.")
        })
    }

  }
  clear() {
    this.SysForm = new BLL_SysForm();
  }
  ConfirmDelete() {

  }
}
