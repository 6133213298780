import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { corpstdbookings, Tutor } from 'app/Classes/tutor';
import { ApiServiceService } from 'app/Shared/api-service.service';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {

 
  lstTutor: Tutor[];
  lstTutor_currentdate: Tutor[];
  lstcorpstd: corpstdbookings[]
  lstcorpstd_today: corpstdbookings[]
  Psize: number = 1000;
  Pno: number = 1;
  constructor(private apiService: ApiServiceService, private router: Router, private datePipe: DatePipe) {
    this.lstTutor = [];
    this.lstTutor_currentdate = [];
    this.lstcorpstd = [];
    this.lstcorpstd_today = []
  }

  ngOnInit(): void {
    this.Student_Today();
    this.student_upcomming();
  }

  // getClassDetails() {
  //   var stdid = sessionStorage.getItem("userid")
  //   const current = new Date();
  //   current.setMilliseconds(0)
  //   const timestamp = current.getTime();
  //   var str = "Student/upcomingjobs/" + stdid + "?currentdate=" + new Date() + "&timestamp=" + timestamp + "&pagenumber=" + this.Pno + "&pagesize=" + this.Psize
  //   this.apiService.GetDetails(str).subscribe(rtnData => {
  //     console.log('rtnData', rtnData);
  //     if (rtnData.message == "Success") {
  //       this.lstTutor = rtnData.result.upcomingjobs;
  //       for (var i = 0; i < this.lstTutor.length; i++) {
  //         if (i < 2)
  //           this.lstTutor_currentdate.push(this.lstTutor[i])
  //       }
  //     } 
  //   });
  // }

  // isToday(dateValue: string): boolean {
  //   var d = this.getNowDate(new Date(dateValue));
  //   var date = new Date();
  //   // add a day
  //   var dd = this.getNowDate(date);
  //   let isToday = (d === dd);
  //   return isToday;
  // }
  //Booking/corporate/student/upcoming?studentId=1&currentDate=11%2F12%2F2022
  student_upcomming() {
    var stdid = sessionStorage.getItem("userid")
    this.apiService.GetDetails("CorporateBooking/GetStudentClasses?studentId=" + stdid + "&currentDate=" + new Date().toISOString() +"&upcomingClasses=" +true).subscribe(rtnData => {        console.log('rtnData', rtnData);
     if (rtnData.success == true) {
              this.lstcorpstd = rtnData.data;
        } 
      });
  }

  //Booking/corporate/student/today?studentId=1&currentDate=12%2F11%2F2022
  next(key:string){
    // if(key =="previous"){
    //   this.pageno = 
    // }
    // else{

    // }
  }
  Student_Today() {
    var stdid = sessionStorage.getItem("userid")
    debugger;
    var date = new Date();
    var formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    // this.apiService.GetDetails("CorporateBooking/GetStudentClasses?studentId=" + stdid + "&currentDate=" + new Date().toISOString()).subscribe(rtnData => {
      this.apiService.GetDetails("CorporateBooking/GetStudentClasses?studentId=" + stdid + "&currentDate="+ new Date().toISOString() ).subscribe(rtnData => {
 
    debugger;
      console.log('rtnData', rtnData);
       if (rtnData.success == true) {
         this.lstcorpstd_today= rtnData.data;
       } 
       
    });
  }
  getNowDate(d: Date) {
    //return string
    var returnDate = "";
    //get datetime now
    var today = d;
    //split
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //because January is 0! 
    var yyyy = today.getFullYear();
    //Interpolation date
    if (dd < 10) {
      returnDate += `0${dd}.`;
    } else {
      returnDate += `${dd}.`;
    }

    if (mm < 10) {
      returnDate += `0${mm}.`;
    } else {
      returnDate += `${mm}.`;
    }
    returnDate += yyyy;
    return returnDate;
  }

  route(url: string, id: any) {

    sessionStorage.setItem("romname", id.toString())
    var myurl = `${'Home'}/${''}`;
    const that = this;
    that.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      that.router.navigate([myurl])
    );
  }


}
